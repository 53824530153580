import React from 'react';
import { Container, Label } from './styles';
import { StatusIndicator } from 'supplier-portal-shared-components';
import { CommonMessages } from '~/components/Messages';
import { getTestIdAttr } from '~/utils';
import { TEnabledDisabled } from './EnabledDisabled.types';

export const EnabledDisabled: TEnabledDisabled = ({
  enabled,
  hideLabel,
  enabledText,
  disabledText,
  labelId,
}) => {
  return (
    <>
      {enabled ? (
        <Container {...getTestIdAttr('EnabledDisabled')}>
          <StatusIndicator color={'green'} status={''} />
          <Label id={labelId} className="categoryStatus" hidelabel={hideLabel}>
            {enabledText ?? <CommonMessages.Enabled />}
          </Label>
        </Container>
      ) : (
        <Container {...getTestIdAttr('EnabledDisabled')}>
          <StatusIndicator color={'red'} status={''} />
          <Label id={labelId} className="categoryStatus" hidelabel={hideLabel}>
            {disabledText ?? <CommonMessages.Disabled />}
          </Label>
        </Container>
      )}
    </>
  );
};

export default EnabledDisabled;
