import React from 'react';
import { StatusColor, StatusContainer, Text } from './styles';
import { TStatus } from './Status.types';
import { getTestIdAttr } from '~/utils';

export const Status: TStatus = ({ status, color, id, hideStatus, size = 'sm' }) => {
  if (status) {
    return (
      <StatusContainer {...getTestIdAttr('Status')}>
        <StatusColor color={color} />

        <Text size={size} id={id} hide={hideStatus} className="statusLabel">
          {status}
        </Text>
      </StatusContainer>
    );
  }

  return <span id={id}>-</span>;
};
