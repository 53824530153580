export const saveToSessionStorage = (key: string, data: any): void => {
  if (data !== undefined && data !== null) {
    window.sessionStorage.setItem(key, JSON.stringify(data));
  }
};

export const getFromSessionStorage = <T = any>(key: string, defaultValue?: T): T | undefined => {
  const value = window.sessionStorage.getItem(key);
  if (value === null) {
    return defaultValue;
  }
  if (value) {
    try {
      return JSON.parse(value);
    } catch (e) {
      return undefined;
    }
  }
  return undefined;
};
