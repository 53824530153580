import { FormattedNumber } from 'react-intl';
import { TPercent } from '~/components/Percent/Percent.types';
import { getTestIdAttr } from '~/utils';

const Percent: TPercent = ({ value, minDigits = 0, maxDigits = 2 }) => {
  const calculated: number = value / 100;

  return isNaN(calculated) ? null : (
    <FormattedNumber
      {...getTestIdAttr('Percent')}
      value={calculated}
      style={'percent'}
      minimumFractionDigits={minDigits}
      maximumFractionDigits={maxDigits}
    />
  );
};

export default Percent;
