import { ChevronDown, ChevronUp } from '@hexa-ui/icons';
import { Input } from '~/components/Input';
import { Tooltip } from '@hexa-ui/components';
import { TAG_COMBO_BOX, TAG_NAMES } from '~/constants';
import { concatString, getTestIdAttr } from '~/utils';
import { useCallback, useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import {
  Container,
  CurrentItem,
  CustomOption,
  GrayContainer,
  ItemContainer,
  Label,
  List,
  Select,
} from './styles';
import { IDropdown, IDropdownOption, IVisibility } from './Dropdown.types';

const emptyOption: IDropdownOption = {
  value: '',
  label: '',
};

const initialVisibility: IVisibility = {
  openedList: false,
};

const { SEARCH_TYPE } = TAG_NAMES.ACCOUNT.SELECTION;

export const Dropdown = ({
  label = '',
  options = [emptyOption],
  onChange,
  beginsEmpty = false,
  withFilter,
  filterValue = '',
  filterPlaceholder = '',
  onFilterChange,
  beginsAt,
  variant = 'large',
  placeholder,
}: IDropdown): JSX.Element => {
  const listRef = useRef<HTMLUListElement>(null);
  const [currentOption, setCurrentOption] = useState<IDropdownOption>(emptyOption);
  const [visibility, setVisibility] = useState<IVisibility>(initialVisibility);

  const handleItemClick = (item: IDropdownOption) => {
    setCurrentOption(item);
    setVisibility((prev) => ({ ...prev, openedList: false }));
    onChange?.(item.value);
  };

  const handleCurrentClick = () => {
    setVisibility((prev) => ({ ...prev, openedList: !prev.openedList }));
  };

  const handleOutsideClick = useCallback(
    (event: Event) => {
      if (!listRef.current) {
        return;
      }
      if (!listRef.current.contains(event?.target as Node)) {
        setVisibility((prev) => ({ ...prev, openedList: false }));
      }
    },
    [listRef]
  );

  useEffect(() => {
    const firstOption = ((value?: string) => {
      if (!value) {
        return undefined;
      }
      return options.find((option) => option.value === value);
    })(beginsAt);

    if (firstOption) {
      setCurrentOption(firstOption);
      return;
    }
    if (beginsEmpty) {
      setCurrentOption(emptyOption);
      return;
    }
    if (placeholder) {
      const placeholderOption = { value: '', label: placeholder };
      setCurrentOption(placeholderOption);
      return;
    }
    setCurrentOption(options[0]);
  }, [beginsAt, beginsEmpty, options, placeholder]);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      setVisibility((prev) => ({ ...prev, openedList: false }));
    };
  }, [handleOutsideClick]);

  return (
    <Container {...getTestIdAttr('Dropdown')} variant={variant}>
      {label && <Label htmlFor={label}>{label}</Label>}
      <Select
        id={concatString(TAG_COMBO_BOX.CBX, {
          name: SEARCH_TYPE,
        })}
        variant={variant}
        isVisible={visibility.openedList}
        onClick={handleCurrentClick}
        aria-label="combobox"
      >
        <ItemContainer>
          <CurrentItem>{currentOption?.customOption?.() ?? currentOption?.label}</CurrentItem>
        </ItemContainer>
        {visibility.openedList ? <ChevronUp size="medium" /> : <ChevronDown size="medium" />}
      </Select>
      <List ref={listRef} variant={variant} isVisible={visibility.openedList}>
        {beginsEmpty && (
          <CustomOption
            aria-label="combobox-empty-option"
            onClick={() => handleItemClick(emptyOption)}
            variant={variant}
          >
            {emptyOption.label}
          </CustomOption>
        )}
        {withFilter && visibility.openedList && (
          <CustomOption>
            <GrayContainer>
              <Input
                autoFocus={visibility.openedList}
                transparent
                onChange={({ target: { value } }) => onFilterChange?.(value)}
                onClear={() => onFilterChange?.('')}
                value={filterValue}
                hideSearchIcon
                hideSearchButton
                placeholder={filterPlaceholder}
              />
            </GrayContainer>
          </CustomOption>
        )}
        {options.map((option) => (
          <Tooltip text={option?.label} key={uuid()}>
            <CustomOption
              isVisible={visibility.openedList}
              id={concatString(TAG_COMBO_BOX.OPT, {
                name: option.value,
              })}
              onClick={() => handleItemClick(option)}
              variant={variant}
            >
              {option?.customOption?.() ?? option?.label}
            </CustomOption>
          </Tooltip>
        ))}
      </List>
    </Container>
  );
};
