import { IPromoDesign } from '~/interfaces';
import { useFeatureToggleResponse, useSelectedCountry } from '~/hooks/index';
import { usePreferredLanguageV2 } from 'admin-portal-shared-services';
import { useMemo } from 'react';
import { getStatusesForDelete } from '~/configs/statusesForDelete';
import { DELETE_PROMO_BUTTON } from '~/constants';
import { every, isValidDeletionDate } from '~/utils';

export const useCanDeletePromo = (promoDesign: IPromoDesign): boolean => {
  const selectedCountry = useSelectedCountry();
  const { preferredLanguage: localeCode } = usePreferredLanguageV2();

  const isAllowedStatus = useMemo<boolean>(() => {
    return getStatusesForDelete(selectedCountry).includes(promoDesign?.status);
  }, [selectedCountry, promoDesign?.status]);

  const [isFeatureToggleEnabled, isFeatureToggleLoading] = useFeatureToggleResponse(
    DELETE_PROMO_BUTTON,
    { country: selectedCountry }
  );

  const isDeletableByConfig = every(
    isAllowedStatus,
    isFeatureToggleEnabled,
    !isFeatureToggleLoading
  );

  if (!isDeletableByConfig) {
    return false;
  }

  return isValidDeletionDate(promoDesign.periodEndDate?.toString(), localeCode);
};
