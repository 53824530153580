import {
  CREATED_AT,
  PROMO_DESIGN_TITLE,
  PROMO_STRATEGY,
  SUB_STRATEGY_CD,
  DATE_RANGE,
  STATUS,
  CREATOR_NAME,
  PROMO_DESIGN_ID,
  MECHANIC_TYPE,
  PROMO_ID,
  POC_ID,
  SKUS,
  ERP_ID,
} from '~/constants/FilterListOptions';
import {
  SHOW_CREATED_AT_ON_FILTER_OPTION,
  SHOW_CREATOR_NAME_ON_FILTER_OPTION,
  SHOW_DATE_RANGE_ON_FILTER_OPTION,
  SHOW_ERP_ID_ON_FILTER_OPTION,
  SHOW_MECHANIC_TYPE_ON_FILTER_OPTION,
  SHOW_POC_ID_ON_FILTER_OPTION,
  SHOW_PROMO_DESIGN_ID_ON_FILTER_OPTION,
  SHOW_PROMO_DESIGN_TITLE_ON_FILTER_OPTION,
  SHOW_PROMO_ID_ON_FILTER_OPTION,
  SHOW_PROMO_STRATEGY_ON_FILTER_OPTION,
  SHOW_SKUS_ON_FILTER_OPTION,
  SHOW_STATUS_ON_FILTER_OPTION,
  SHOW_SUB_STRATEGY_CD_ON_FILTER_OPTION,
} from '~/constants';
import { useMemo } from 'react';
import useSelectedCountry from './useSelectedCountry';
import { useFeatureTogglesResponse } from './useFeatureToggle';
import { TFeatureToggleMultiResponse, TFeatureToggleResponse } from '~/interfaces';

import { some } from '~/utils';
import { getFilterListOptions } from '~/configs';

enum PROMO_FILTER_KEYS {
  CREATED_AT = 'createdAt',
  PROMO_DESIGN_TITLE = 'promoDesignTitle',
  PROMO_STRATEGY = 'promoStrategy',
  SUB_STRATEGY_CD = 'substrategyCd',
  DATE_RANGE = 'dateRange',
  STATUS = 'status',
  CREATOR_NAME = 'creatorName',
  PROMO_DESIGN_ID = 'promoDesignId',
  MECHANIC_TYPE = 'mechanicType',
  PROMO_ID = 'promoId',
  POC_ID = 'pocId',
  SKUS = 'sku',
  ERP_ID = 'erpId',
}

type PromoFilterKeyResponse = [string[], boolean];
type TFieldToToggleMap = { [key: string]: string };

const featureTogglePromoFilterKeyMapping = {
  [CREATED_AT]: SHOW_CREATED_AT_ON_FILTER_OPTION,
  [PROMO_DESIGN_TITLE]: SHOW_PROMO_DESIGN_TITLE_ON_FILTER_OPTION,
  [PROMO_STRATEGY]: SHOW_PROMO_STRATEGY_ON_FILTER_OPTION,
  [SUB_STRATEGY_CD]: SHOW_SUB_STRATEGY_CD_ON_FILTER_OPTION,
  [DATE_RANGE]: SHOW_DATE_RANGE_ON_FILTER_OPTION,
  [STATUS]: SHOW_STATUS_ON_FILTER_OPTION,
  [CREATOR_NAME]: SHOW_CREATOR_NAME_ON_FILTER_OPTION,
  [PROMO_DESIGN_ID]: SHOW_PROMO_DESIGN_ID_ON_FILTER_OPTION,
  [MECHANIC_TYPE]: SHOW_MECHANIC_TYPE_ON_FILTER_OPTION,
  [PROMO_ID]: SHOW_PROMO_ID_ON_FILTER_OPTION,
  [POC_ID]: SHOW_POC_ID_ON_FILTER_OPTION,
  [SKUS]: SHOW_SKUS_ON_FILTER_OPTION,
  [ERP_ID]: SHOW_ERP_ID_ON_FILTER_OPTION,
} as {
  [key in PROMO_FILTER_KEYS]: string;
};

const convertFieldToFeatureToggle = (field: string): string | undefined => {
  const fetureToggleKeys = featureTogglePromoFilterKeyMapping;
  return fetureToggleKeys?.[field];
};

const mapFieldsToFeatureToggles = (values: string[]): TFieldToToggleMap => {
  return values.reduce<TFieldToToggleMap>((previousValue, currentValue) => {
    const featureToggle = convertFieldToFeatureToggle(currentValue);
    if (!featureToggle) {
      return {
        ...previousValue,
      };
    }
    return {
      ...previousValue,
      [currentValue]: featureToggle,
    };
  }, {});
};

class FeatureToggleResponseChecker {
  protected selectedCountry;
  protected featureToggleIds;
  protected togglesResponse;
  constructor(
    selectedCountry: string,
    featureToggleIds: TFieldToToggleMap,
    togglesResponse: TFeatureToggleMultiResponse
  ) {
    this.selectedCountry = selectedCountry;
    this.featureToggleIds = featureToggleIds;
    this.togglesResponse = togglesResponse;
  }

  getResponse(field: string): TFeatureToggleResponse {
    const toggleResponse = this.togglesResponse?.[this.featureToggleIds?.[field]];
    if (getFilterListOptions(this.selectedCountry).includes(field)) {
      return toggleResponse;
    }
    return [false, false];
  }
}

export const usePromoFilterKeys = (): PromoFilterKeyResponse => {
  const selectedCountry = useSelectedCountry();
  const fields = [
    CREATED_AT,
    PROMO_DESIGN_TITLE,
    PROMO_STRATEGY,
    SUB_STRATEGY_CD,
    DATE_RANGE,
    STATUS,
    CREATOR_NAME,
    PROMO_DESIGN_ID,
    MECHANIC_TYPE,
    PROMO_ID,
    POC_ID,
    SKUS,
    ERP_ID,
  ];
  const featureToggleIds = mapFieldsToFeatureToggles(fields);

  const featureTogglesResponse = useFeatureTogglesResponse(Object.values(featureToggleIds), {
    country: selectedCountry,
  });

  const featureToggleChecker = new FeatureToggleResponseChecker(
    selectedCountry,
    featureToggleIds,
    featureTogglesResponse
  );

  const promoFilterKeys: string[] = useMemo(() => {
    return fields.filter((field) => !!featureToggleChecker.getResponse(field)?.[0]);
  }, [featureTogglesResponse]);

  const isResponseLoading = () =>
    some(...fields.map((field) => !!featureToggleChecker.getResponse(field)?.[1]));

  return [promoFilterKeys, isResponseLoading()];
};
