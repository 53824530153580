import MessageMap from '~/i18n/i18n.d';

const ADD_ITEMS = 'Add products';
const UNIT = 'Business Unit';
const PERCENTAGE_DISCOUNT = 'Discount %';
const ENTER_A_VALUE = 'Enter a value.';
const AVAILABILITY = 'Order availability';
const MAXIMUM_QUANTITY = 'Max quantity';
const ENTER_A_NUMERIC_VALUE = 'Enter a numeric value.';
const MINIMUN_QUANTITY = 'Min quantity';
const ENTER_A_PERCENTAGE_VALUE = 'Enter a % value.';

const enUS: MessageMap = {
  test: {
    translate: 'Translate',
    missing: 'Only English option available',
  },
  CONSTS: {
    ENABLED: 'Enabled',
    DISABLED: 'Disabled',
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
    NAME: 'Name',
    EMAIL: 'Email',
    DESCRIPTION: 'Description',
    SEARCH: 'Search',
    LOADING: 'Loading',
    ERROR: 'Error',
    ALMOST_THERE: "We're almost there...",
    ID: 'ID',
    DOWNLOAD: 'Download',
    SKU: 'SKU',
    GENERIC_LOADING_ERROR: 'An unknown error occurred loading data',
  },
  ADMIN_PAGE: {
    PAGE_TITLE: 'BEES Lift Management',
  },
  USER_MANAGEMENT: {
    PAGE_TITLE: 'User Management',
    CARD_DESCRIPTION: 'Manage BEES Lift user permissions',
  },
  CONFIG_PAGE: {
    PAGE_TITLE: 'Global Config',
    CARD_DESCRIPTION: 'Manage global configuration by country',
  },
  COUNTRY_SELECTOR_MODAL: {
    HEADER: 'Changing Countries?',
    SUB_HEADER:
      'Changing countries will clear all of your saved settings and you will be redirected to the homepage. Would you still like to continue?',
  },
  SIDEBAR: {
    HOME: 'Dashboard',
    NEW_PROMO: 'New Promo',
    REPORTS: 'Reports',
  },
  ERROR_TOAST:
    'Error {errorCode}: Something went wrong with your request. Please contact administrator, or try again later.',
  FORMATS: {
    ALPHANUMERIC_WITH_DASHES: 'alpha-numeric with dashes and no special characters',
  },
  DOWNLOAD: {
    REVIEW_TITLE: 'Review_Promo_Design',
    NO_DATA: 'N/A',
  },
  PAGINATION: {
    OF: 'of',
    ITEMS_PER_PAGE: 'Show {field} items per page',
  },
  FIELDS: {
    OPTIONAL: '(Optional)',
    REQUIRED: 'Required',
    IS_REQUIRED_ERROR_MESSAGE: '{field} is required',
    GREATER_THAN_OR_EQUAL_TO_FIELD_ALLOW_ZERO:
      '{field} must be 0 or greater than or equal to {field2}',
    GREATER_THAN_OR_EQUAL_TO_FIELD: '{field} must be greater than or equal to {field2}',
    LESS_THAN_OR_EQUAL_TO_FIELD: '{field} must be less than or equal to {field2}',
    GREATER_THAN_PREVIOUS_FIELD: '{field} must be greater than the previous {field2} by 1',
    GREATER_THAN_EQUAL_TO_PREVIOUS_FIELD:
      '{field} must be greater than or equal to previous {field2}',
    LESS_THAN_OR_EQUAL_TO_PREVIOUS_FIELD: '{field} must be less than or equal to previous {field2}',
    TWO_DECIMAL_POINTS: '{field} must have 2 decimal places or equal a whole number.',
    INVALID_FORMAT: '{field} does not match the required format: {format}.',
    LESS_THAN_MIN: '{field} must be greater than or equal to {minValue}.',
    GREATER_THAN_MAX: '{field} must be less than or equal to {maxValue}.',
    MUST_HAVE_SELECTED_NUMBER_OF_ITEMS:
      '{minValue, plural, one {{field} must have at least # selected item.} other {{field} must have at least # selected items.}}',
    MUST_BE_A_NUMBER: '{field} must be a number.',
    PAST_DATE: '{field} must equal {minDate} or a future date.',
    PAST_START_DATE: '{field} must equal the start date or a future date.',
    MAX_DATE: '{field} must equal or be less than {maxDate}.',
    MUST_BE_JSON: '{field} must a valid JSON object.',
    MUST_HAVE_GREATER_THAN_NUMBER_OF_ROWS: 'There must be at least {minValue} {field}',
    REQUIRES_DEPENDENT_FIELD: '{field} requires {field2} to contain a value.',
    GREATER_THAN_OR_EQUAL_TO_ADDITIONAL_VALUE:
      '{field} must be equal to {field2} or be greater than {field2} by {additionToValue, number}.',
  },
  MECHANIC_FILTER: {
    TYPE: {
      DISCOUNT: 'Discount',
      STEPPED_DISCOUNT: 'Stepped Discount',
      INTERACTIVE_COMBO: 'Interactive Combo',
      REWARDS_CHALLENGE: 'Rewards',
    },
  },
  STATUSES: {
    PROMOTION: {
      FALLBACK: 'No Status',
      DESIGNED: 'Designed',
      RECEIVED_DS: 'Received DS',
      RECEIVED_ERP: 'Received ERP',
      DRAFT: 'Draft',
      COMPLETE: 'Complete',
      DELETED: 'Deleted',
      INVALID: 'Invalid',
      INVALID_PROMO: 'Invalid promo',
      INVALID_PROMOS: 'Invalid promos',
      INVALID_DESIGN: 'Invalid design',
      PENDING_MODELING: 'Pending modeling',
      PENDING_DELETION: 'Pending deletion',
      PENDING_ALGORITHM: 'Pending algorithm',
      PROMOS_CREATED: 'Promos created',
      PROMOS_DELAYED: 'Promos delayed',
      PROMOS_LOADED: 'Promos loaded',
      FAILED_KAFKA_SEND: 'Failed to queue',
      PENDING_ALGORITHM_DELAYED: 'Pending algorithm delayed',
      LIVE: 'Live',
      SUCCESS: 'Success',
      FAILED: 'Failed',
      CREATED: 'Created',
      DELAYED: 'Delayed',
      SYSTEM_ERROR: 'System error',
    },
  },
  VIRTUALIZATION: {
    LOADING_MORE: 'Loading More ...',
    NO_MORE_ITEMS: 'Nothing more to load',
  },
  FORMS: {
    SKU_PARAMETERS: {
      products: { label: 'Products', hint: 'Select products' },
      minDiscount: { label: 'Min discount %', hint: ENTER_A_PERCENTAGE_VALUE },
      maxDiscount: { label: 'Max discount %', hint: ENTER_A_PERCENTAGE_VALUE },
      allocation: { label: 'Allocation %', hint: ENTER_A_PERCENTAGE_VALUE },
      minQuantity: { label: MINIMUN_QUANTITY, hint: ENTER_A_NUMERIC_VALUE },
      maxQuantity: { label: MAXIMUM_QUANTITY, hint: ENTER_A_NUMERIC_VALUE },
      orderAvailability: { label: AVAILABILITY, hint: ENTER_A_NUMERIC_VALUE },
      maxPoints: { label: 'Max points', hint: ENTER_A_NUMERIC_VALUE },
      budgetId: {
        label: 'Budget ID',
        hint: 'Enter a single ID.',
        hint_link: 'Create a new budget.',
        multiple: {
          hint: 'Enter a single ID or multiple IDs.',
          placeholder: 'Add an ID and press enter.',
        },
      },
      noOptionsText: 'No Options',
      promoId: { label: 'Promo ID', hint: 'Enter a single ID.' },
      userDescription: { label: 'User description', hint: '' },
      skuLimit: { label: 'SKU limit', hint: ENTER_A_VALUE },
      discount: { label: PERCENTAGE_DISCOUNT, hint: ENTER_A_PERCENTAGE_VALUE },
      productName: { label: 'Name', hint: '' },
      sku: { label: 'SKU', hint: '' },
      minQty: { label: MINIMUN_QUANTITY, hint: '' },
      maxQty: { label: MAXIMUM_QUANTITY, hint: '' },
      minPrice: { label: 'Min Price $', hint: 'Enter a $ value.' },
      maxPrice: { label: 'Max Price $', hint: 'Enter a $ value.' },
      steps: { label: 'Steps', hint: '' },
    },
    STEPPED_DISCOUNT: {
      minQuantity: { label: MINIMUN_QUANTITY, hint: ENTER_A_VALUE },
      maxQuantity: { label: MAXIMUM_QUANTITY, hint: ENTER_A_VALUE },
      discount: { label: 'Stepped discount %', hint: ENTER_A_PERCENTAGE_VALUE },
    },
    BUDGET_PARAMETERS: {
      budgetTitle: { label: 'Budget Title', hint: '' },
      budgetValue: { label: 'Budget Value', hint: ENTER_A_VALUE },
      businessUnit: {
        label: UNIT,
        hint: '',
        placeholder: 'Select a business unit.',
      },
      zbb: {
        label: 'Zero Based Budget',
        hint: '',
        placeholder: 'Select a zero based budget.',
        dependencyPlaceholder: 'Select a business unit first.',
      },
    },
    STEPS: 'Step',
  },
  DATE_PICKER_LABELS: {
    START_DATE: 'Start date',
    END_DATE: 'End date',
  },
  BUTTONS: {
    ADD: 'Add',
    EDIT: 'Edit',
    CANCEL: 'Cancel',
    CLEAR_PRODUCTS: 'Clear Products',
    CLEAR_FILTERS: 'Clear Filters',
    BACK: 'Back',
    NEXT: 'Next',
    SUBMIT: 'Submit',
    SAVE: 'Save',
    CREATE_NEW_BUDGET: 'Create new budget',
    ADD_PRODUCTS: ADD_ITEMS,
    ADD_PRODUCT: 'Add product',
    EDIT_PRODUCTS: 'Edit products',
    EDIT_PRODUCT: 'Edit product',
    CLOSE: 'Close',
    DELETE: 'Delete',
    DUPLICATE: 'Duplicate',
    DONE: 'Done',
    OPEN: 'Open',
    CLEAR_ALL: 'Clear all',
    APPLY: 'Apply',
    CONFIRM: 'Confirm',
  },
  TOGGLES: {
    PERSONALIZED: 'Personalized',
    NONPERSONALIZED: 'Non-personalized',
    DISCOUNT_PERCENT: PERCENTAGE_DISCOUNT,
    PRICE_POINT: 'Price point',
  },
  PROMOTION_STEPS: {
    STEP_1: 'Strategy',
    STEP_2: 'Customers',
    STEP_3: 'Products',
    STEP_4: 'Review',
  },
  PROMOTION_STRATEGIES: {
    STRATEGY: 'Strategy',
    SUBSTRATEGY: 'Substrategy',
    INTRODUCE: 'Introduce',
    SUSTAIN: 'Sustain',
    'GENERATE DEMAND': 'Generate Demand',
    OPERATIONS: 'Operations',
    'PRICE TREE': 'Price Tree',
    UNCLASSIFIED: 'Unclassified',
    SUB_STRATEGIES: {
      G1: 'Drive foot traffic through consumer events',
      G2: 'Ensure POCs to maintain everyday price adherence',
      G3: 'Increase consumption through passing lower price to consumers',
      G4: 'Optimize assortment at POCs',
      G5: 'Negotiate feature display shelf or planogram placement',
      G6: 'Trade program - discount per target achievement',
      G7: 'Generate demand on low-selling or close to expiration SKUs to reduce supply-chain losses',
      I1: 'Attract customers to try new products',
      I2: 'Improve coverage of existing products',
      I3: 'B2B welcome offer',
      O1: 'Leverage promos to hit monthly/quarterly target',
      O2: 'Respond to market dynamics',
      O3: 'Payment related discount (e.g. cash discount)',
      O4: 'Reduce distribution burden (e.g. anticipated occasion-based demand spikes distribute to KA warehouses etc.)',
      O5: 'Generate demand on low-selling or close to expiration SKUs to reduce supply-chain losses',
      O6: 'Others - Employee discount rewards etc.',
      P1: 'Set up reference price per contract or price agreement',
      S1: 'Encourage customers continuous purchases',
      U: 'Unclassified',
    },
  },
  PROMOTION_MECHANICS: {
    ACTION: 'Action',
    TOOLTIPS: {
      DISCOUNT: 'Promotions where each SKU has a specific discount and required minimum quantity.',
      INTERACTIVE_COMBO:
        'Promotions where a combination of SKUs can be purchased to meet a minimum quantity.',
      STEPPED_DISCOUNT: 'Promotions where higher quantities purchased result in deeper discounts.',
      FIXED_DISCOUNT:
        'Tool-tip Instruction:  Lorum placeholder java dice cup. \n\nTool-tip Example:  Lorum placeholder java dice cup.',
      FREE_GOODS:
        'Tool-tip Instruction:  Lorum placeholder java dice cup. \n\nTool-tip Example:  Lorum placeholder java dice cup.',
      REWARDS:
        'Challenges where each SKU has a specific Reward points payout and required minimum quantity',
      REWARDS_CHALLENGE:
        'Challenges where each SKU has a specific Reward points payout and required minimum quantity',
    },
    OPTIONS: {
      discount_percent: PERCENTAGE_DISCOUNT,
      price_point: 'Price point',
      purchase_multiple_volume_fixed: 'Volume - Fixed Points',
    },
    PERSONALIZED: 'Personalized',
    NONPERSONALIZED: 'Non-personalized',
    METHOD: {
      INTERACTIVE_COMBO: 'Interactive',
      STEPPED_DISCOUNT: 'Stepped',
      DISCOUNT: 'Discount',
      REWARDS: 'Rewards',
      REWARDS_CHALLENGE: 'Rewards',
    },
    DISCOUNT: 'Discount',
    REWARDS: 'Rewards',
    REWARDS_CHALLENGE: 'Rewards',
    INTERACTIVE_COMBO: 'Interactive Combo',
    STEPPED_DISCOUNT: 'Stepped Discount',
    FIXED_DISCOUNT: 'Fixed Discount',
    FREE_GOODS: 'Free Goods',
    EMPTY_DISCOUNT_MESSAGE: 'No discounts added',
    EMPTY_TYPE: 'No mechanics available',
    EMPTY_TYPE_OPTION: 'No options available',
  },
  DESIGN_PAGE: {
    CREATE_PROMO: 'Create a promo',
    HEADER_PERSONALIZED: 'Create a personalized promo',
    HEADER_NONPERSONALIZED: 'Create a non-personalized promo',
    GLOBAL_PROMOS_HEADER: 'Promo details',
    BUSINESS_UNIT: {
      HEADER: 'Select business unit',
      OPTION: {
        BUSINESS_UNIT_LABEL: 'Business unit',
        PLACEHOLDER_OPTION: 'Select option',
        VALUES: {
          unit1: 'Unit 1',
          unit2: 'Unit 2',
          unit3: 'Unit 3',
        },
      },
    },
    PROMO_DETAILS_DESCRIPTION: 'Description',
    PROMO_DETAILS_DESCRIPTION_PLACEHOLDER: 'Enter description...',
    PROMO_DETAILS_DESCRIPTION_SUBHEADER:
      'The string informed will be used as a prefix for the promo description.',
    COVER_IMAGE: {
      TITLE: 'Cover Image',
      MESSAGE:
        'Cover image must be {fileFormat} format, {maxWidth}x{maxHeight} sizing and less than {maxSize}.',
      MESSAGE_WITHOUT_DIMENSION: 'Cover image must be {fileFormat} format and less than {maxSize}.',
      UPLOAD_BUTTON: 'Browse files',
      ERROR_MESSAGE: {
        name: 'Error! The file name must not have space or underline. Please, use dashes to separate words.',
        size: 'Error! The file must be less than 1MB to be uploaded.',
        type: 'Error! The file must be JPG/PNG to be uploaded.',
        dimension: 'Error! The file must be 640x370 sizing to be uploaded.',
        spanish_special_character:
          'Error! Ñ or ñ are not valid characters, please rename the file.',
      },
    },
    CANCEL_MODAL: {
      HEADER: 'Cancel promo creation?',
      CONTENT:
        'Are you sure you wish to cancel? This will take you back to the promotions dashboard.',
      CANCEL_BUTTON: 'Take me back to my design',
      CONFIRM_BUTTON: 'Cancel promo creation',
    },
    MECHANICS: {
      HEADER: 'Select promo mechanics',
      OPTION: {
        MECHANICS_TYPE_LABEL: 'Mechanic',
        MECHANICS_TYPE_OPTION_LABEL: 'Type',
        PLACEHOLDER_TYPE: 'Select type',
        PLACEHOLDER_OPTION: 'Select option',
      },
    },
    PERSONALIZATION: {
      HEADER: 'Personalization',
      PERSONALIZED_true: 'Personalized',
      PERSONALIZED_false: 'Non-Personalized',
      CONTENT:
        'Selecting “Personalized” will allow you to apply algorithms to (1) allocate your promo to an optimized set of POCs, and (2) optimize the promo parameters (e.g. discount offered) for each POC. Select "Nonpersonalized" to set a flat discount and static group of POCs.',
    },
    PROMOTION_DATE_SELECTION: 'Select date range',
    PROMOTION_STRATEGY_SELECTION: {
      HEADER: 'Select strategy',
    },
    PROMOTION_RUN_DURATION: 'This promotion will run for {duration}.',
    PROMOTION_SUBSTRATEGY_SELECTION: {
      HEADER: 'Select substrategy',
      EMPTY_CARD_DESC: 'Try selecting strategies',
      EMPTY_CARD_HEADER: 'No results so far',
    },
    PROMOTION_TITLE: {
      HEADER: 'Title',
      PLACEHOLDER: 'Enter a title...',
    },
    PROMOTION_CAMPAIGN_ID: {
      HEADER: 'Campaign ID',
      PLACEHOLDER: 'Enter a Campaign ID...',
      TOOLTIP: 'The same campaign allows for multiple challenges.',
    },
    PRODUCTS: {
      HEADER: ADD_ITEMS,
      PRODUCTS_TABLE_EMPTY: 'Add a product to get started...',
      PRODUCTS_EMPTY_SEARCH_RESULTS: 'No products found',
      PRODUCTS_SEARCH_PLACEHOLDER: 'Search name or SKU',
      LOAD_MORE: 'Load More',
      LOAD_X_PRODUCTS: 'Load {loadXProducts, number} products at a time.',
      SHOWING_X_OUT_OF_X_PRODUCTS:
        'Showing {loadedLength, number} of {allProductsLength, number} products',
      PRODUCTS: 'Products',
      PARAMETERS: 'Parameters',
      EMPTY_PRODUCTS: 'Add a product to get started...',
      EMPTY_PARAMETERS: 'Add Parameters to get started...',
      EMPTY_FILTERS: 'There are no products available with the currently selected filters.',
      DELETE_TOOLTIP: 'Delete',
      EDIT_TOOLTIP: 'Edit',
      LOADING: 'Loading products',
      NA: 'N/A',
      BUDGET_MODAL: {
        HEADER: 'Create a new budget',
        SUB_HEADER: 'New budget initiatives will be assigned to promos in the design',
      },
      PRODUCT_FILTERS: {
        SEGMENT: 'Segments',
        BRAND: 'Brands',
        BRAND_TYPE: 'Brand Types',
        CATEGORY: 'Categories',
        PRESENTATION: 'Presentations',
        TYPE: 'Types',
        BUSINESS_UNITS: 'Business Units',
        BUSINESS_UNIT: UNIT,
        CONTAINER_TYPE: 'Container Type',
        PRICE_SEGMENT_NAME: 'Price Segment',
        SIZE: 'Size',
        PACKING: 'Packing',
        ZONE_CONFIGURATION_MISSING: 'configuration missing',
      },
      PRODUCTS_MODAL: {
        HEADER: 'Add products & set parameters',
        STEP_1: ADD_ITEMS,
        STEP_2: 'Set parameters',
        SUB_HEADER: 'Select products to add to this promo.',
        EMPTY: 'No products added',
        SEARCH_PLACEHOLDER: 'Search',
        PARAMETERS_SUB_HEADER:
          'Please enter the business as usual stepped discount promotion. The algorithm will then personalize each set of min/max quantity ranges for each POC. Discounts at each step will remain as specified for all POCs.',
      },
      TABLE_COLUMNS: {
        actions: 'Actions',
        productName: 'Product',
        sku: 'SKU',
      },
      DETAILS_TABLE: {
        PRODUCTS_TABLE_EMPTY: 'No product found',
      },
      STEPS_TABLE: {
        stepId: 'Step',
        minQuantity: MINIMUN_QUANTITY,
        maxQuantity: MAXIMUM_QUANTITY,
        minDiscount: 'Min discount',
        maxDiscount: 'Max discount',
        discount: 'Discount',
      },
      STEPPED_PARAMETERS: {
        allocation: 'Allocation %',
        orderAvailability: AVAILABILITY,
        skuLimit: 'SKU limit',
        budgetId: 'Budget Id',
      },
    },
    DUPLICATE_PROMOTION: {
      CONFIRMATION_HEADER: 'Duplicate this promo?',
      CONFIRMATION_SUBTITLE: `A new promo design will be generated with the same strategy, POC Scope, SKU and parameter selections. You will be given an opportunity to supply a new date range or edit any inputs before submitting.`,
      PROMO_ID: 'Promo Design ID:',
    },
  },
  HOMEPAGE_PROMOTION: {
    PROMO_LIST: 'All offers',
    SINGLE_DATE: 'Single date',
    FILTER: {
      INPUT_FIELD_PLACEHOLDER: 'Enter {placeholderKey}',
      FILTERED_BY: 'Filtered By:',
      SEARCH_PLACEHOLDER: 'Search by {placeholderKey}',
      NUMBER_OF_OPTIONS_SELECTED: '{selectedOptions} selected',
      EMPTY_RESULT: 'No Result found',
    },

    MESSAGES: {
      NO_PROMOTIONS: 'No promotions were found with the provided search.',
      LOADING_PROMOTIONS: 'Loading promotions',
      VIEW_DETAILS: 'View Details',
      MARK_FOR_DOWNLOAD: 'Mark for Download',
      UNMARK_FOR_DOWNLOAD: 'Unmark for Download',
      DOWNLOAD_DISABLED_FOR_STATUS: 'Download disabled for status: {field}',
      MAX_DOWNLOAD_LIMIT: 'Max download limit reached. Unmark a promotion to mark this one.',
      NOT_AVAILABLE_FOR_DOWNLOAD: 'This promotion is not available for download.',
      NA: 'N/A',
    },
    PROMOTION_TABLE_COLUMNS: {
      actions: 'Actions',
      lastUpdatedTimestamp: 'Created At',
      createdAt: 'Created On',
      promoDesignTitle: 'Promotion Title',
      promoDesignTitleDisplay: 'Promotion Title',
      PROMO_STRATEGY: 'Strategy',
      PROMO_SUBSTRATEGY: 'Substrategy',
      dateRange: 'Date Range',
      status: 'Status',
      creatorName: 'Created By',
      EMAIL: 'Email',
      promoDesignId: 'Promo Design ID',
      mechanicType: 'Mechanic Type',
      SORT_BY_TOOLTIP: 'Sort by',
      promoId: 'Promo ID',
      pocId: 'POC ID',
      sku: 'SKU',
      erpId: 'ERP ID',
    },
    PROMOTION_TABLE_TOPBAR: {
      SEARCH: 'Search',
      NEW_PROMO: 'New Promo',
      DOWNLOAD: 'Download CSV',
      CLEAR_MARKED: 'Clear all marked for download',
      DOWNLOAD_PROMO_ERP_HISTORY: 'Download Promo ERP History CSV',
      ADD_FILTER: 'Add Filter',
    },
    DELETE_MODAL: {
      BACK_LINK: 'Back to Promo Overview',
      HEADER: 'Delete this promo?',
      SUB_HEADER:
        'Promotion design will be deleted. All related promotions will be expired or no longer created.',
      PROMO_ID_TEXT: 'Promo Design ID:',
    },
    DELETE_TOAST:
      'This promo design is pending deletion and will be successfully deleted soon. \u00A0"{promoName}"',
    SUCCESS_CREATED_TOAST: 'Successfully created a promo. \u00A0"{promoName}"',
    ERROR_ON_ELIGIBLE_POCS_TOAST: 'An error occurred loading Eligible POCs',
    PROMO_DESIGN_MODAL: {
      LIFECYCLE_true: 'Lifecycle On',
      LIFECYCLE_false: 'Lifecycle Off',
      createdBy: 'Created by',
      creatorEmail: "Creator's email",
      strategy: 'Strategy',
      subStrategy: 'Substrategy',
      details: 'Details',
      products: 'Products',
      erpHistory: 'ERP History',
      externalHistory: 'External History',
      overlappingPromos: 'Overlapping Promos',
      promoId: 'Promo Design ID',
      download: 'Download',
      periodStartDate: 'Start date',
      periodEndDate: 'End date',
      pocSegment: 'POC Segment',
      pocSubSegment: 'POC Sub-Segment',
      NO_DATA: 'No Data',
      dateRange: 'Date Range',
      createdAt: 'Created at',
      method: 'Method',
      mechanicsOptionType: 'Type',
      businessUnit: UNIT,
      coverImage: 'Cover Image',
      eligiblePOCs: 'Eligible POCs',
      downloadPOCList: 'Download POC list',
      MESSAGES: {
        eligiblePOCs: 'Please wait for Eligible POCs information to load before submit.',
      },
      ACCORDION: {
        salesRegions: 'Sales Regions',
        states: 'States',
        dcids: 'Delivery Center IDs',
        NO_DATA: 'No Data',
        pocInclude: 'Included POC',
        pocExclude: 'Excluded POC',
      },
      challengeId: 'Challenge ID',
    },
  },
  OVERLAPPING_PROMOS: {
    LOADING: 'Loading overlapping promos',
    DOWNLOAD_DETAILS: 'Download details',
    INSTRUCTIONS:
      'Below are the promo designs that overlap with this promo design. Click on a row to expand it and view more details. Alternatively, you can download the JSON to view more details.',
    PERCENT_OVERLAP: 'Overlap',
    NUM_POCS: '# POCs',
    OVERLAPPING: '{count, number} overlapping',
    ELIGIBLE: '{count, number} eligible',
    WITHIN_DESIGN: '{count, number} within design',
    DEAL_ID: 'Deal ID',
    QUANTITY: 'Quantity',
    DISCOUNT: 'Discount',
  },
  ERP_HISTORY_TABLE: {
    LOADING: 'Loading ERP History',
    fields: {
      ERP_ID: 'ERP ID',
      TYPE: 'Type',
      ERP_STATUS: 'Status',
      RESULT: 'Result',
      DATE: 'Date',
      PROMO_ID: 'Promo ID',
      ERP_RESPONSE: 'ERP Response',
      SEQ_ID: 'Sequence ID',
      STAGE: 'Stage',
    },
  },
  EXTERNAL_HISTORY_TABLE: {
    LOADING: 'Loading External History',
    fields: {
      STATUS: 'Status',
      DATE: 'Date',
      EXTERNAL_RESPONSE: 'External Response',
    },
  },
  PRODUCTS_TABLE: {
    HEADERS: {
      products: { label: 'Products' },
      minDiscount: { label: 'Min discount' },
      maxDiscount: { label: 'Max discount' },
      allocation: { label: 'Allocation' },
      minQuantity: { label: MINIMUN_QUANTITY },
      maxQuantity: { label: MAXIMUM_QUANTITY },
      orderAvailability: { label: AVAILABILITY },
      promoId: { label: 'Promo ID' },
      userDescription: { label: 'User description' },
      skuLimit: { label: 'SKU limit' },
      discount: { label: 'Discount' },
      productName: { label: 'Name' },
      sku: { label: 'SKU' },
      minQty: { label: MINIMUN_QUANTITY },
      maxQty: { label: MAXIMUM_QUANTITY },
      minPrice: { label: 'Min price' },
      maxPrice: { label: 'Max price' },
      steps: { label: 'Steps' },
      budgetId: { label: 'Budget ID' },
      maxPoints: { label: 'Max points' },
    },
    LOADING: 'Loading Products',
  },
  PROMO_HISTORY_TABLE: {
    TAB_TITLE: 'Promo History',
    CHALLENGE_TAB_TITLE: 'Challenge History',
    HEADERS: {
      message: 'Message',
      date: 'Date',
      lastUpdatedTimestamp: 'Date',
      promoId: 'Promo ID',
      promoDesignId: 'Promo ID',
      erpId: 'ERP ID',
      erpStatus: 'ERP Status',
      status: 'ERP Status',
      pocsAssigned: 'POCs Assigned',
      pocsExternal: 'POCs in ERP',
      pocsBees: 'POCs on BEES',
    },
    LOADING_TAB: 'Loading promo history',
    EMPTY_TABLE_MESSAGE: 'No promo history found',
    FILE_DOWNLOAD_NAME: 'Promo History Error',
    ERROR_MESSAGE: 'An error occurred loading promo history',
  },
  MESSAGES: {
    COUNTRY_SWITCH_SUCCESS: 'Switched to {countryName}.',
    COUNTRY_ACCESS_DENIED: 'You do not have permission to use {country} on BEES Lift.',
    ERROR_UNAVAILABLE_COUNTRY:
      "Oops! {country} isn't quite ready. \n\nPlease contact administrator, or try again later.",
    ERROR_ACCESS_DENIED:
      'Oops! \n\nYou do not have permission to use {country} on BEES Lift. \n\nPlease contact administrator, or try again later.',
  },
  ERROR_MESSAGES: {
    NO_INTRODUCE_WITH_STEPPED_DISCOUNT:
      'Personalized stepped discounts with strategy "Introduce" are not supported.',
    NO_GENERATE_DEMAND_WITH_INTERACTIVE_COMBO:
      'Personalized interactive combos with strategy "Generate Demand" are not supported.',
    NO_SUSTAIN_WITH_STEPPED_DISCOUNT:
      'Personalized stepped discounts with strategy "Sustain" are not supported.',
    NO_OPERATIONS_WITH_STEPPED_DISCOUNT:
      'Personalized stepped discounts with strategy "Operations" are not supported.',
    NO_OPERATIONS_WITH_REWARDS_CHALLENGE:
      'Personalized rewards challenges with strategy "Operations" are not supported.',
    OVERLAPPING_PROMO_DESIGN:
      '{count, plural, one {This promo design overlaps with promo design {promoDesignId}} other {This promo design overlaps with # other promo designs}}',
    DISTINCT_BUDGET_ID_ERROR: 'There should only be one distinct Budget ID across all SKUs.',
    INVALID_SKUS_ERROR:
      '{count, plural, one {This promo design contains the following invalid SKU: {sku}} other {This promo design contains # invalid SKUs: {sku}}}',
  },
  PROMO_DESIGN_DATE_VALIDATIONS: {
    MODIFY_DATE_RANGE: 'Modify date range',
    DATE_RANGE_INVALID:
      'You must provide valid start and end dates to submit this design. Modify date range to adjust dates.',
    NO_LONGER_VALID_DATE_RANGE:
      'The selected start date {date} is no longer available. The next available start date is {minDate}. Modify date range to adjust dates.',
  },
  IMAGE_UPLOAD: {
    LOADING_IMAGE: 'Loading image',
  },
  POCS_FILTER: {
    FILTER_TITLE: 'Select POCs',
    POCS_BY_FILTER: 'POCs by filter',
    POCS_BY_UPLOAD: 'POCs by upload',
    POCS_SELECTED_COUNT: '{count, plural, =0 {No POCs} one {# POC} other {# POCs}} selected.',
    SELECT_ALL_POCS: 'Select all POCs',
    UPLOAD_EXCLUDE_POCS: 'Upload POCs to exclude',
    SEE_EXCLUSION_LIST: '\u00A0See exclusion list',
    POCS_EXCLUDE_LABEL: 'POCs uploaded to exclude',
    FULL_LIST_SUB_HEADING_EXCLUDE: 'The following POCs will be excluded from your new promo.',
    UPLOAD: {
      MAXIMUM_UPLOAD_SIZE_REACHED:
        'You have exceeded the maximum limit for supported POC files (currently around 750kbytes), please create more than one promotion if you need to enter many POC values',
      INSTRUCTIONS: 'Click to select your files or drag and drop your files here.',
      ON_DRAG_INSTRUCTIONS: 'Drop your files here.',
      EMPTY_UPLOAD_MESSAGE: 'No files uploaded.',
      APPLY_LIFECYCLE: ' Apply lifecycle',
      POCS_UPLOAD_LABEL: 'POCs uploaded',
      POCS_UPLOAD_COUNT: '{count, plural, =0 {No POCs} one {# POC} other {# POCs}} uploaded.',
      SEE_FULL_LIST: '\u00A0See inclusion list',
      FULL_LIST_SUB_HEADING: 'The following POCs will receive your new promo.',
      FORMAT_INSTRUCTIONS: '.csv extension',
      FORMAT_ERROR_MESSAGE:
        'The uploaded file {fileName} is not in the expected format. The file should contain a single column of POC codes with no header and have .csv extension.',
      DUPLICATE_FILE: 'File {fileName} is already uploaded.',
      WARNING: 'warning',
      ERROR: 'error',
      TABLE_HEADER: 'POC ID',
      TABLE_HEADER_STATUS: 'Status',
      INVALID_TABLE_HEADER: 'Invalid POC ID',
      STATUS_VALID: 'Valid',
      STATUS_INVALID: 'Invalid',
      WAIT_FOR_VALIDATE_END:
        'Please wait until the POCs have been validated before attempting to remove additional POCs.',
      INVALID_POCS: {
        TITLE: 'Invalid POCs',
        ALL_INVALID_MESSAGE:
          'None of the uploaded POCs are valid. You will not be able to submit your promo design until this has been corrected. Please upload a new set of POCs with valid IDs.',
        SOME_INVALID_MESSAGE:
          'The following POCs are invalid. You can continue creating your promo design, but these POCs will be skipped.',
        INVALID_MESSAGE_PROCEED:
          'File uploads contained {count, plural, one {only # invalid POC} other {# invalid POCs}}. You can continue creating your promo design, but the {count, plural, one {invalid POC} other {invalid POCs}} will be skipped.',
        INVALID_MESSAGE_CANNOT_PROCEED:
          'File uploads contained {count, plural, one {# invalid POC} other {# invalid POCs}}. None of the POCs uploaded were valid. A promo design must contain at least one valid POC to be submitted.',
        INVALID_ALT_SUPPORT:
          'Alternatively, you can revise your uploads and try again after troubleshooting.',
      },
    },
  },
  AUTOCOMPLETE: {
    ALL: 'All',
  },
  COMMON_HISTORY: {
    ERROR_MESSAGE: 'The promo design encountered an issue: {message}',
    DOWNLOAD_FULL_MESSAGE: 'Download full message',
    EMPTY_PROMO_DESIGN_HISTORY: 'No promotion design history found for this promo design',
    DOWNLOAD_JSON_FAILED:
      'An error occurred while downloading this message. Please try again, or submit a support request.',
  },
  REWARD_HISTORY: {
    HEADER: 'Reward History',
    LOADING: 'Loading Reward History',
    COLUMNS: {
      tyoe: 'Type',
      challengeId: 'Challenge Id',
      description: 'Description',
      requestTraceId: 'Request Trace Id',
      executionDate: 'Execution Date',
      typeOfRequest: 'Type',
      promoDesignId: 'Promo Design Id',
    },
  },
  PROMO_DESIGN_HISTORY: {
    HEADER: 'Promo Design History',
    CHALLENGE_HEADER: 'Challenge Design History',
    LOADING_TAB: 'Loading Promotion Design History',
    COLUMNS: {
      message: 'Message',
      promotionCount: 'Promotions Count',
      promotionCntLoaded: 'Promotions Count',
      timestamp: 'Date',
      lastUpdatedTimestamp: 'Date',
      status: 'Status',
    },
    FILE_DOWNLOAD_NAME: 'Promo Design History Error',
    ERROR_MESSAGE: 'An error occurred loading promo design history',
  },
  REVIEW_TITLE: 'Review Promo design',
  DEFAULT_PLACEHOLDER: 'Enter your input',
  REQUEST_SUPPORT: {
    REPORT_AN_ISSUE: 'Report an issue',
    CHECK_ISSUE_STATUS: 'Check issue status',
    SUBMIT: {
      SUCCESS_MESSAGE: 'Your support ticket was submitted.',
      ERROR_MESSAGE:
        'An error occurred while creating your request for support. Please try again or open a ticket manually.',
    },
    PAGE_TITLE: 'Support Requests',
    TAB_TITLE: 'Support Requests',
    LOADING_REQUESTS: 'Loading support requests',
    EMPTY_PROMO_DESIGN: 'No support requests found for this promo design',
    EMPTY: 'No support requests found',
    FIELDS: {
      SUMMARY: 'Summary',
      DESCRIPTION: 'Description',
    },
    TABLE: {
      KEY: 'Issue Key',
      STATUS: 'Status',
      REPORTER: 'Reporter',
      PRIORITY: 'Priority',
      UPDATED: 'Updated',
      SUMMARY: 'Summary',
    },
    MODAL_TITLE: 'Request Support',
    MODAL_INSTRUCTIONS_GENERIC:
      'Please describe the problem you are having. When you are finished, click the Submit button and your request for support will be dispatched to a team member. Please navigate to "Check issue status" in the sidebar for a list of support tickets.',
    MODAL_INSTRUCTIONS_PROMO_DESIGN:
      'Please describe the problem you are having. When you are finished, click the Submit button and your request for support will be dispatched to a team member. Please check the "Support Requests" tab for a list of support tickets.',
  },
  REVIEW_PROMO: {
    DETAILS_TAB: 'Details',
    PRODUCTS_TAB: 'Products',
  },
  SET_NAME_OPTION: {
    MESSAGE: 'Your user name doesn\'t seem to be updated, it is still "New User". Please\u00A0',
    UPDATE_MESSAGE: 'update the user name.',
    DONT_SHOW_AGAIN_MESSAGE: 'Do not show again.',
  },
  USER_MANAGEMENT_PAGE: {
    TITLE: "Manage User's Permissions",
    TOAST: {
      SUCCESS: "User's permissions configured successfully.",
      ERROR: "Error while trying to configure user's permissions, please try again later.",
    },
    CANCEL_MODAL: {
      HEADER: "Cancel user's permissions changes?",
      CONTENT: "Are you sure you wish to cancel? This will clear user's permission configurations.",
      CANCEL: 'Cancel',
      CANCEL_BUTTON: 'Take me back to my changes',
    },
    SELECT_USER_HEADER: 'Select User',
    POC_FILTERS_HEADER: 'Assign POC Filters',
    PRODUCT_FILTERS_HEADER: 'Assign Product Filters',
    COUNTRY: 'Country',
    USER: 'User',
  },
  FEATURE_TOGGLES_PAGE: {
    TITLE: 'Feature toggles',
    TABS: {
      FEATURE_TOGGLES: 'Feature Toggles',
      AUDIENCES: 'Audiences',
    },
    USER_ID: 'User ID',
    COLUMNS: {
      FEATURE: 'Feature',
      DEFAULT: 'Default',
    },
    LOADING_AUDIENCES: 'Loading audiences',
    LOADING_USERS: 'Loading users',
    NO_AUDIENCES: 'No audiences found',
    NO_USERS: 'No users found',
  },
  AUDIENCES_FORM: {
    CREATE_COUNTRY_AUDIENCE: 'Add country audience',
    CREATE_USER_AUDIENCE: 'Add user audience',
    ADD_USERS: 'Add Users',
    REMOVE_USERS:
      'Remove {quantity, plural, =0 {users} one {# user} other {{quantity, number} users}}',
    NO_IDENTITY_PERMISSIONS: 'Your account does not have permission to read identity information.',
    EDITABLE_DISCLAIMER:
      'This Audience is editable. Manage the users below. Note: you can only manage users that are found for this environment. Users who exist in other environments (e.g. DEV, SIT, UAT, PROD) must be managed within those environments.',
  },
  ADD_USERS: {
    TITLE: 'Add users',
    ADD_ANOTHER: 'Add another',
    ADD_USER: 'Add user ID, name, or email address',
    USER: 'User',
    LOADING_USERS: 'Loading users',
    SUB_HEADER:
      'Adding users to this audience will make changes in the feature toggle provider environment.',
  },
  PROGRESS_BAR: {
    POC_COUNT: '{count, plural, one {# POC} other {# POCs}}',
  },
  CSV: 'CSV',
  POC_FILTERS: {
    SALES_REGION: 'Sales region',
    SUB_REGION: 'Sub region',
    SUB_CANAL: 'Sub channel',
    TYPOLOGY: 'Typology',
    REGION: 'Region',
    MANAGEMENT: 'Management',
    SEGMENT: 'Segment',
    SUB_SEGMENT: 'Subsegment',
    SALES_DISTRICT: 'Sales district',
    SALES_TEAM: 'Sales Team',
    CHANNEL: 'Channel',
    AREA: 'Area',
    SUBREGION: 'Sub region',
    SUBCHANNEL: 'Sub channel',
    CD: 'CD',
    UN: 'UN',
    STATE: 'State',
    UNB: 'UNB',
    ZONE_CONFIGURATION_MISSING: 'Zone configuration file missing',
  },
  COPY_TO_CLIPBOARD: {
    COPIED: 'Copied!',
    ERROR: 'An error occurred while copying to clipboard',
  },
};
export default enUS;
