import { Alert, Paragraph } from '@hexa-ui/components';
import { FormattedMessage, useIntl } from 'react-intl';
import { useCountryConfig, useFeatureToggle, useSelectedCountry, useUserDateLocale } from '~/hooks';
import { SHOW_PROMOTION_DURATION_ON_DESIGN_PAGE_DATE_SELECTION } from '~/constants';
import { useMemo } from 'react';
import { calculateDaysBetween, customFormatDuration } from '~/utils';
import { addDays } from 'date-fns';
import { IDateRange } from '~/interfaces';

type TPromoDesignDurationIndicator = (props: {
  dateSelection: IDateRange;
  errors: { [key: string]: string };
}) => JSX.Element;

const PromoDesignDurationIndicator: TPromoDesignDurationIndicator = ({ dateSelection, errors }) => {
  const selectedCountry = useSelectedCountry();
  const userLocale = useUserDateLocale();
  const { MIN_DATE_RULES } = useCountryConfig();
  const { formatMessage } = useIntl();

  const showDuration = useFeatureToggle(SHOW_PROMOTION_DURATION_ON_DESIGN_PAGE_DATE_SELECTION, {
    country: selectedCountry,
  });

  const duration = useMemo<{
    duration: string | null;
    days: number | null;
  }>(() => {
    const durationFormatted = !Object.keys(errors).length
      ? customFormatDuration(
          new Date(dateSelection.startDate),
          addDays(new Date(dateSelection.endDate), 1),
          userLocale
        )
      : null;

    const days = !Object.keys(errors).length
      ? calculateDaysBetween(
          new Date(dateSelection.startDate),
          addDays(new Date(dateSelection.endDate), 1)
        )
      : null;

    return {
      duration: durationFormatted,
      days,
    };
  }, [dateSelection, userLocale, errors]);

  if (!showDuration) {
    return null;
  }

  if (!duration.duration) {
    return null;
  }

  return (
    <>
      {duration.days > MIN_DATE_RULES.DAYS_WARNING_THRESHOLD ? (
        <Paragraph size={'xsmall'} colortype={'secondary'} weight={'normal'} alignment={'right'}>
          <FormattedMessage
            id={'DESIGN_PAGE.PROMOTION_RUN_DURATION'}
            defaultMessage={'This message will run for {duration}'}
            values={{
              duration: duration.duration,
            }}
          />
        </Paragraph>
      ) : (
        <Alert
          type={'warning'}
          style={{
            marginTop: '10px',
          }}
          message={formatMessage(
            {
              id: 'DESIGN_PAGE.PROMOTION_RUN_DURATION',
              defaultMessage: 'This message will run for {duration}',
            },
            {
              duration: duration.duration,
            }
          )}
        />
      )}
    </>
  );
};

export default PromoDesignDurationIndicator;
