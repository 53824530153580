import { useNumericValueFields } from '~/hooks';
import { NumericField } from '~/components';
import { getTestIdAttr } from '~/utils';
import { TFieldDisplay } from './FieldDisplay.types';

export const FieldDisplay: TFieldDisplay = ({
  item,
  displayProducts,
  selectedMechanicTypeOption,
  defaultMessage = '',
  displayNA = false,
}) => {
  const numericFields = useNumericValueFields(selectedMechanicTypeOption);
  if (displayProducts?.[0]?.[item] === undefined) {
    return <span {...getTestIdAttr('FieldDisplay')}>{defaultMessage}</span>;
  }
  const value = displayProducts[0][item];
  if (!value && displayNA) {
    return <span {...getTestIdAttr('FieldDisplay')}>{defaultMessage}</span>;
  }
  if (numericFields.includes(item)) {
    return (
      <span {...getTestIdAttr('FieldDisplay')}>
        <NumericField
          value={Number(value)}
          fieldName={item}
          selectedMechanicTypeOption={selectedMechanicTypeOption}
        />
      </span>
    );
  }
  return (
    <span {...getTestIdAttr('FieldDisplay')}>
      {Array.isArray(value) ? value?.join(', ') : value}
    </span>
  );
};
