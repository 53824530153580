export const CREATED_AT = 'createdAt';
export const PROMO_DESIGN_TITLE = 'promoDesignTitle';
export const PROMO_STRATEGY = 'promoStrategy';
export const SUB_STRATEGY_CD = 'substrategyCd';
export const DATE_RANGE = 'dateRange';
export const STATUS = 'status';
export const CREATOR_NAME = 'creatorName';
export const PROMO_DESIGN_ID = 'promoDesignId';
export const MECHANIC_TYPE = 'mechanicType';
export const PROMO_ID = 'promoId';
export const POC_ID = 'pocId';
export const SKUS = 'sku';
export const ERP_ID = 'erpId';
