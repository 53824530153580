import { LoadingBuzz } from '@hexa-ui/components';
import { ContentContainer, LoadingSubtitle, LoadingTitle, SpinnerContainer } from './styles';
import { CommonMessages } from '~/components/Messages';
import { TLoadingSpinner } from './LoadingSpinner.types';
import { getTestIdAttr } from '~/utils';

export const LoadingSpinner: TLoadingSpinner = ({
  removeMargin = false,
  titleMessage,
  subtitleMessage,
  hideSubtitle,
}) => {
  return (
    <SpinnerContainer {...getTestIdAttr('LoadingSpinner')}>
      <ContentContainer style={removeMargin ? { margin: 0 } : {}} data-testid="bee-spinner">
        <LoadingBuzz size="large" />
        <LoadingTitle hidesubtitle={hideSubtitle}>
          {titleMessage ?? <CommonMessages.Loading />}
        </LoadingTitle>
        {!hideSubtitle && (
          <LoadingSubtitle>{subtitleMessage ?? <CommonMessages.AlmostThere />}</LoadingSubtitle>
        )}
      </ContentContainer>
    </SpinnerContainer>
  );
};

export default LoadingSpinner;
