import Bff from '~/Api/Bff';
import { ISelectedPOCs } from '~/interfaces';
import { useState } from 'react';
import {
  TChangeSelectedPOCs,
  TDeleteSelectedPOCs,
  TUseSelectedPocs,
} from './UsePOCPermissionsService.types';

export const useSelectedPOCs: TUseSelectedPocs = ({ country }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const getSelectedPOCs = (userId: string, country?: string): Promise<ISelectedPOCs> => {
    setIsLoading(true);
    const url = '/v1/user-poc-filters';
    const options = country ? { headers: { country, userId } } : {};

    return Bff.get<ISelectedPOCs>({
      url,
      options,
    })
      .then((response) => {
        if (response.hasError) {
          throw new Error(response?.data?.error?.message);
        }
        return response.data;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const changeSelectedPOCs: TChangeSelectedPOCs = ({ userId, country, pocParameters }) => {
    setIsSaving(true);
    const url = `v1/user-poc-filters`;
    const options = country ? { headers: { country } } : {};
    return Bff.put({
      url,
      options,
      body: {
        id: userId,
        parameters: pocParameters,
      },
    })
      .then((response) => {
        return response.data;
      })
      .finally(() => setIsSaving(false));
  };

  const deleteSelectedPOCs: TDeleteSelectedPOCs = ({ userId, country }) => {
    setIsSaving(true);
    const url = `v1/user-poc-filters`;
    const options = { headers: { ...(country && { country }), userId } };
    return Bff.delete({
      url,
      options,
    })
      .then((response) => {
        return response.data;
      })
      .finally(() => setIsSaving(false));
  };

  return {
    changeSelectedPOCs,
    deleteSelectedPOCs,
    getSelectedPOCs,
    setIsSaving,
    isLoading,
    isSaving,
  };
};
