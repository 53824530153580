import { TCountryConfig } from '~/interfaces';
import { TCutoffTime } from '~/interfaces/TCountryConfig';

const AREA = 'AREA';
const REGION = 'REGION';
const SUB_REGION = 'SUB_REGION';
const CHANNEL = 'CHANNEL';
const SUBCHANNEL = 'SUBCHANNEL';
const BRAND = 'BRAND';
const PRESENTATION = 'PRESENTATION';
const BUSINESS_UNIT = 'BUSINESS_UNIT';
const CATEGORY = 'CATEGORY';
const CONTAINER_TYPE = 'CONTAINER_TYPE';
const SALES_REGION = 'SALES_REGION';
const SEGMENT = 'SEGMENT';
const SUB_SEGMENT = 'SUB_SEGMENT';
const STATE = 'STATE';
const UNB = 'UNB';
const SALES_DISTRICT = 'SALES_DISTRICT';
const SALES_TEAM = 'SALES_TEAM';
const SIZE = 'SIZE';
const PACKING = 'PACKING';
const MANAGEMENT = 'MANAGEMENT';
const UN = 'UN';
const SUB_CANAL = 'SUB_CANAL';
const TYPOLOGY = 'TYPOLOGY';
const CD = 'CD';
const BRAND_TYPE = 'BRAND_TYPE';
const PRICE_SEGMENT_NAME = 'PRICE_SEGMENT_NAME';

const DEFAULT_DAYS_WARNING_THRESHOLD = 4; // Default minimum number of promo duration days to show warning
const endOfDayCutoffTime: TCutoffTime = { hour: 23, minute: 59 }; // Effectively meaning no cutoff time
const ELEVEN_PM = { hour: 23, minute: 0 };
const TEN_PM = { hour: 22, minute: 0 };
const SEVEN_PM = { hour: 19, minute: 0 };

/*
You must change the test cases in useGetDesignDate.test.tsx if you change the rules for min date
For POC/Product filers, the property value is the translation key from the languages file
*/
const config: { [key: string]: TCountryConfig } = {
  AR: {
    TIMEZONE: 'America/Buenos_Aires',
    MIN_DATE_RULES: {
      ALLOW_SAME_DAY_PROMO_NON_PERSONALIZED: false,
      ALLOW_SAME_DAY_PROMO_PERSONALIZED: false,
      SAME_DAY_CUTOFF_TIME_PERSONALIZED: endOfDayCutoffTime,
      SAME_DAY_CUTOFF_TIME_NON_PERSONALIZED: endOfDayCutoffTime,
      NEXT_DAY_CUTOFF_TIME_PERSONALIZED: endOfDayCutoffTime,
      DAYS_WARNING_THRESHOLD: DEFAULT_DAYS_WARNING_THRESHOLD,
    },
    POC_FILTER: {
      pocSalesHierarchyLvl1: AREA,
      pocSalesHierarchyLvl2: REGION,
      pocSalesHierarchyLvl3: SUB_REGION,
      channel: CHANNEL,
      subChannel: SUBCHANNEL,
    },
    PRODUCT_FILTER: {
      brand: BRAND,
      presentation: PRESENTATION,
      businessUnit: BUSINESS_UNIT,
      category: CATEGORY,
      containerType: CONTAINER_TYPE,
    },
  },
  BR: {
    TIMEZONE: 'America/Sao_Paulo',
    MIN_DATE_RULES: {
      ALLOW_SAME_DAY_PROMO_NON_PERSONALIZED: true,
      ALLOW_SAME_DAY_PROMO_PERSONALIZED: false,
      SAME_DAY_CUTOFF_TIME_NON_PERSONALIZED: ELEVEN_PM,
      SAME_DAY_CUTOFF_TIME_PERSONALIZED: endOfDayCutoffTime,
      NEXT_DAY_CUTOFF_TIME_PERSONALIZED: SEVEN_PM,
      DAYS_WARNING_THRESHOLD: DEFAULT_DAYS_WARNING_THRESHOLD,
    },
    POC_FILTER: {
      pocSalesHierarchyLvl1: SALES_REGION,
      segment: SEGMENT,
      subsegment: SUB_SEGMENT,
      pocState: STATE,
      deliveryDdc: UNB,
    },
    PRODUCT_FILTER: {
      brand: BRAND,
      presentation: PRESENTATION,
      brandType: BRAND_TYPE,
      category: CATEGORY,
    },
  },
  ZA: {
    TIMEZONE: 'Africa/Johannesburg',
    MIN_DATE_RULES: {
      ALLOW_SAME_DAY_PROMO_NON_PERSONALIZED: false,
      ALLOW_SAME_DAY_PROMO_PERSONALIZED: false,
      SAME_DAY_CUTOFF_TIME_NON_PERSONALIZED: endOfDayCutoffTime,
      SAME_DAY_CUTOFF_TIME_PERSONALIZED: endOfDayCutoffTime,
      NEXT_DAY_CUTOFF_TIME_PERSONALIZED: endOfDayCutoffTime,
      DAYS_WARNING_THRESHOLD: DEFAULT_DAYS_WARNING_THRESHOLD,
    },
    POC_FILTER: {
      pocSalesHierarchyLvl1: SALES_REGION,
      segment: SEGMENT,
      subsegment: SUB_SEGMENT,
      pocSalesHierarchyLvl2: SALES_DISTRICT,
      pocSalesHierarchyLvl3: SALES_TEAM,
      channel: CHANNEL,
    },
    PRODUCT_FILTER: {
      brand: BRAND,
      presentation: PRESENTATION,
      brandType: BRAND_TYPE,
      priceSegmentName: PRICE_SEGMENT_NAME,
    },
  },
  CO: {
    TIMEZONE: 'America/Bogota',
    MIN_DATE_RULES: {
      ALLOW_SAME_DAY_PROMO_NON_PERSONALIZED: false,
      ALLOW_SAME_DAY_PROMO_PERSONALIZED: false,
      SAME_DAY_CUTOFF_TIME_NON_PERSONALIZED: endOfDayCutoffTime,
      SAME_DAY_CUTOFF_TIME_PERSONALIZED: endOfDayCutoffTime,
      NEXT_DAY_CUTOFF_TIME_PERSONALIZED: TEN_PM,
      DAYS_WARNING_THRESHOLD: DEFAULT_DAYS_WARNING_THRESHOLD,
    },
    POC_FILTER: {
      pocSalesHierarchyLvl1: REGION,
      pocSalesHierarchyLvl2: MANAGEMENT,
      pocSalesHierarchyLvl3: UN,
      subChannelGrp: SUB_CANAL,
      potentialGrp: TYPOLOGY,
      deliveryDdc: CD,
    },
    PRODUCT_FILTER: {
      brand: BRAND,
      presentation: SIZE,
      containerType: PACKING,
      category: CATEGORY,
    },
  },
};

export const getCountryConfig = (country: string): TCountryConfig => {
  return {
    TIMEZONE: 'UTC',
    MIN_DATE_RULES: {
      ALLOW_SAME_DAY_PROMO_NON_PERSONALIZED: false,
      ALLOW_SAME_DAY_PROMO_PERSONALIZED: false,
      SAME_DAY_CUTOFF_TIME_NON_PERSONALIZED: endOfDayCutoffTime,
      SAME_DAY_CUTOFF_TIME_PERSONALIZED: endOfDayCutoffTime,
      NEXT_DAY_CUTOFF_TIME_PERSONALIZED: endOfDayCutoffTime,
      DAYS_WARNING_THRESHOLD: DEFAULT_DAYS_WARNING_THRESHOLD,
    },
    ...config?.[country],
  };
};

export default config;
