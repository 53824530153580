import { getApiHost } from '~/services/host/HostService';
import { rest } from 'msw';
import {
  singlePromoDesignFixture,
  singlePromoDesignPaginatedFixture,
} from '~/mocks/fixtures/singlePromoDesign';

const hostUrl = getApiHost();

export const fetchSinglePromoDesignHandler = [
  rest.get(`${hostUrl}/v2/promo-design-skus/\'[A-Za-z0-9]+{24}\'`, (_req, res, ctx) => {
    return res(ctx.json({ ...singlePromoDesignFixture }));
  }),
];

export const fetchSinglePromoDesignPaginatedHandler = [
  rest.get(`${hostUrl}/v2/promo-design-skus/\'[A-Za-z0-9]+{24}\'/*`, (req, res, ctx) => {
    return res(ctx.json({ ...singlePromoDesignPaginatedFixture }));
  }),
];
