import Bff from '~/Api/Bff';
import { IGlobalConfigResponse } from '@moneycomb/mcomb-types';
import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useSelectedCountry } from '~/hooks';

const getGlobalConfig = async (): Promise<IGlobalConfigResponse> => {
  try {
    const response = await Bff.get<IGlobalConfigResponse>({
      url: `/v1/global-config`,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const useGetGlobalConfig = (
  options?: UseQueryOptions
): UseQueryResult<IGlobalConfigResponse> => {
  const selectedCountry = useSelectedCountry();
  return useQuery<IGlobalConfigResponse, any, any, any>({
    queryKey: ['getGlobalConfig', selectedCountry],
    queryFn: async () => await getGlobalConfig(),
    ...options,
  });
};
