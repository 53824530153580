import { Container, Typography } from '@material-ui/core';
import { TTitleItem } from './TitleItem.types';
import { CopyToClipboard } from '~/components';
import { getTestIdAttr } from '~/utils';

const TitleItem: TTitleItem = ({
  title,
  text,
  textComponent,
  style,
  allowCopyToClipboard = false,
}) => {
  return (
    <Container
      {...getTestIdAttr('TitleItem')}
      disableGutters
      style={{ display: 'flex', alignItems: 'center', ...style }}
    >
      <div style={{ whiteSpace: 'nowrap' }}>
        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
          {title}:{' '}
        </Typography>
      </div>
      {!!text && (
        <div style={{ whiteSpace: 'normal', flexGrow: 1 }}>
          <Typography
            style={{
              lineHeight: 'normal',
              marginLeft: '8px',
              color: '#424242',
              textAlign: 'left',
            }}
          >
            {allowCopyToClipboard ? <CopyToClipboard text={text} /> : text}
          </Typography>
        </div>
      )}
      {textComponent}
    </Container>
  );
};

export default TitleItem;
