export const productsFixture = {
  status: '200 OK',
  products: {
    products: [
      {
        sku: '25429',
        productName: 'MATUTA CACHACA CRISTAL GARRAFA VIDRO 1 L',
      },
      {
        sku: '25342',
        productName: 'SALTON ESPUMANTE CLASSIC BRUT GFA VD 750 ML',
      },
      {
        sku: '24340',
        productName: 'CASA PERINI VINHO TINTO SECO MERLOT GFA VD 750 ML',
      },
      {
        sku: '25452',
        productName: 'PETYBON MASSA PARAFUSO OVO PACOTE PLAST. 500G',
      },
      {
        sku: '25073',
        productName: 'TEISSEIRE XAROPE ART MACA VERDE GFA VIDRO 700ML',
      },
      {
        sku: '24536',
        productName: 'TIROL LEITE UHT INTEGRAL TETRAPAK 1 L CX C/12',
      },
      {
        sku: '24491',
        productName: 'GALLO AZEITE OLIVA TP UNICO GFA VDR 500ML',
      },
      {
        sku: '25299',
        productName: 'GARRAFEIRA PL. PRETO BEES 1 UN P/24 GFA 300ML',
      },
      {
        sku: '24455',
        productName: 'PIRACANJBLQLCH AVE ZER LACT TETRA PAK 200 ML',
      },
      {
        sku: '24069',
        productName: 'MAGUARY NECTAR LARANJA TETRA PAK 1 L CX C/12',
      },
      {
        sku: '24023',
        productName: 'NATURAL ONE LARANJA GFA PET 1,5 L FD C 6',
      },
      {
        sku: '24492',
        productName: 'GALLO AZEITE OLIVA TP UNICO GFA VDR 250ML',
      },
      {
        sku: '24921',
        productName: 'KIT BUDWEISER LATA 269ML 8UN + 1 COPO 350ML CX C/8',
      },
      {
        sku: '24206',
        productName: 'GCA CENTENARIO (JAB+AC+CUP+MAT+UV+CAJ) LATA 350ML CX CARTÃO C/06 N',
      },
      {
        sku: '23674',
        productName: 'CERVEGELA PLASTICA SKOL 1 UN P/ GFA 600ML CX C/3',
      },
      {
        sku: '23632',
        productName: 'STA. RITA 120 RES. ESP. CAMERNERE GFA VD 750ML',
      },
      {
        sku: '24117',
        productName: 'DAFRUTA NECTAR UVA TETRA PAK 1 L CX C/12',
      },
      {
        sku: '23639',
        productName: 'STA. RITA TRES MEDALLAS CHARDONNAY GFA VD 750ML',
      },
      {
        sku: '25936',
        productName: 'GARRAFEIRA PL. PRETO BEES 1 UN P/12 GFA 1L',
      },
      {
        sku: '24339',
        productName: 'CASA PERINI VINHO TINTO SECO CAB SAUG GFA VD 750 ML',
      },
      {
        sku: '4206',
        productName: 'TIAL NECTAR DE MANGA TETRA PAK 1 L CX C/12',
      },
      {
        sku: '24048',
        productName: 'SAO LOURENCO AGUA MIN. C GAS GFA PET 1260ML FD C 6',
      },
      {
        sku: '24335',
        productName: 'CASA PERINI VINHO ROSE SECO SOLIDARIO GFA VD 750 ML',
      },
      {
        sku: '24080',
        productName: 'MAGUARY NECTAR TANGERINA TETRA PAK 1 L CX C/12',
      },
      {
        sku: '24578',
        productName: 'CHIVAS 18YO GFA VD 750 ML',
      },
      {
        sku: '4157',
        productName: 'TIAL SUCO PRONTO UVA TETRA PAK 1 L CX C/12',
      },
      {
        sku: '24769',
        productName: 'MAGUARY MORANGO. TETRA PAK 1 L CX C/12',
      },
      {
        sku: '25303',
        productName: 'GARRAFEIRA PL. PRETO BEES 1 UN P/24 GFA 600ML',
      },
      {
        sku: '25434',
        productName: 'MATUTA CACHACA MEL E LIMAO GARRAFA VIDRO 1 L',
      },
      {
        sku: '24042',
        productName: 'PUREZA VITAL AGUA MIN. S GAS GFA PET 1,5L FD C/6',
      },
      {
        sku: '25608',
        productName: 'TEISSEIRE XAROPE MORANGO GFA VIDRO 700ML',
      },
      {
        sku: '24410',
        productName: 'QUINTA DO MORGADO VINHO BRANCO SUAVE GFA VD 750 ML',
      },
      {
        sku: '24397',
        productName: 'DOM BOSCO VINHO TINTO SUAVE GFA VDR 1L',
      },
      {
        sku: '24401',
        productName: 'MAXIMO ARROZ NAO PARBOILIZADO PCT 1KG FD C/10',
      },
      {
        sku: '23651',
        productName: 'PARATUDO RAIZES AMARGAS GARRAFA VIDRO 900ML',
      },
      {
        sku: '25546',
        productName: 'GARRAFEIRA PL. AL. LAT. AB. PRETA BEES 1 UN P/ 23 GFA 300ML',
      },
      {
        sku: '25151',
        productName: 'OLD PARR WHISKY GFA VDR 1L',
      },
      {
        sku: '23635',
        productName: 'STA. RITA 120 RES. ESP. SAUV. BLANC GFA VD 750ML',
      },
      {
        sku: '25629',
        productName: 'TEISSEIRE XAROPE MARACUJA GFA VIDRO 700ML',
      },
      {
        sku: '25254',
        productName: 'CARANGUEJO CACHACA PRATA GFA VD 980 ML',
      },
      {
        sku: '23636',
        productName: 'STA. RITA TRES MEDALLAS CAB. SAUV. GFA VD 750ML',
      },
      {
        sku: '25806',
        productName: 'CACHACA MODAO DUAS MADEIRAS GFA VD 750 ML',
      },
      {
        sku: '24486',
        productName: 'GALLO AZEITE OLIVA EX. VIR. GFA VDR 500ML',
      },
      {
        sku: '23637',
        productName: 'STA. RITA TRES MEDALLAS CAMERNERE GFA VD 750ML',
      },
      {
        sku: '25807',
        productName: 'CACHACA MODAO EDICAO KIT SHOW GFA VD 750 ML',
      },
      {
        sku: '24012',
        productName: 'NATURAL ONE UVA E MACA GFA PET 1,5 L FD C 6',
      },
      {
        sku: '25329',
        productName: 'SALTON ESPUMANTE BRUT GFA VD 750 ML',
      },
      {
        sku: '23633',
        productName: 'STA. RITA 120 RES. ESP. MERLOT GFA VD 750ML',
      },
      {
        sku: '25223',
        productName: 'CACHACA 51 PIRASSUNUNGA C VASILHAME GFA DE VDRO 600ML',
      },
      {
        sku: '24275',
        productName: 'GARRAFEIRA PL. AZUL BEES 1 UN P/24 GFA 600ML',
      },
      {
        sku: '25220',
        productName: 'CACHACA 51 PIRASS OURO DESCARTAVEL GFA DE VDRO 965ML',
      },
      {
        sku: '25256',
        productName: 'CARANGUEJO CACHACA PRATA LT 350ML CX C/12',
      },
      {
        sku: '25445',
        productName: 'LEVINHOS SALG PIMENTA PCT PLAST 50G',
      },
      {
        sku: '24090',
        productName: 'GARRAFEIRA PL. AZUL BEES 1 UN P/ 23 GFA 300ML',
      },
      {
        sku: '24408',
        productName: 'QUINTA DO MORGADO VINHO TINTO SECO GFA VD 750 ML',
      },
      {
        sku: '25614',
        productName: 'TEISSEIRE XAROPE ROMA GFA VIDRO 700ML',
      },
      {
        sku: '24440',
        productName: 'PIRACANJUBA LEITE CONDENSADO PCT 2,5KG CX C/4',
      },
      {
        sku: '24488',
        productName: 'GALLO AZEITE OLIVA EX. VIR. GFA VDR 250ML',
      },
      {
        sku: '24223',
        productName: 'JAMEL AGUARDENTE GARRAFA VIDRO 965ML',
      },
      {
        sku: '24161',
        productName: 'S. JOAO BARRA CONHAQUE ALC. GARRAFA VIDRO 900ML',
      },
      {
        sku: '24421',
        productName: 'MAXIMO FEIJAO CARIOCA T1 PCT 1KG FD C/10',
      },
      {
        sku: '24571',
        productName: 'ORLOFF VODKA GFA PET 1,75L',
      },
      {
        sku: '25080',
        productName: 'DUELO NORTE BEB MISTA CATUABA GFA PET 490ML CX 12',
      },
      {
        sku: '24632',
        productName: 'SUKITA TUBAINA PET 2L SHRINK C/8',
      },
      {
        sku: '25816',
        productName: 'SUKITA TUBAINA LATA 350ML SH C/12 NPAL',
      },
      {
        sku: '25402',
        productName: 'IMPOSSIBLE FRUTAS VERMELHAS LT 260 ML CX C 6 NPAL',
      },
      {
        sku: '24806',
        productName: 'BETANIA EDGE LEITE UHT INTEGRAL TETRA PAK 1 L CX C/12',
      },
      {
        sku: '24017',
        productName: 'NATURAL ONE MARACUJA GFA PET 300ML FD C 12',
      },
      {
        sku: '24020',
        productName: 'NATURAL ONE UVA E MACA GFA PET 300ML FD C 12',
      },
      {
        sku: '25159',
        productName: 'SLOVA VODKA GFA VDR980ML CX12',
      },
      {
        sku: '24543',
        productName: 'TIROL LEITE COND. SEMIDESN. TETRAPAK 395G CX C/27',
      },
      {
        sku: '23631',
        productName: 'STA. RITA 120 RES. ESP. CAB. SAUV. GFA VD 750ML',
      },
      {
        sku: '25401',
        productName: 'IMPOSSIBLE LIMAO LT 260 ML CX C 6 NPAL',
      },
      {
        sku: '24430',
        productName: 'PIRACANJUBA LEITE COND. ZERO LACTOSE TETRAPAK 395G CX C/27',
      },
      {
        sku: '25180',
        productName: '51 ICE MARACUJA GARRAFA VD 275ML CX C24',
      },
      {
        sku: '23634',
        productName: 'STA. RITA 120 RES. ESP. CHARDONNAY GFA VD 750ML',
      },
      {
        sku: '24470',
        productName: 'NINHO EDGE LEITE SEMI DESNATADO TETRA PAK 1 L CX C/12',
      },
      {
        sku: '24812',
        productName: 'BETANIA LEITE UHT INTEGRAL TETRA PAK 1 L CX C/12',
      },
      {
        sku: '24265',
        productName: 'SALTON CHAL. VINHO TINTO SUAVE GFA VD 750 ML',
      },
      {
        sku: '24572',
        productName: 'ABSOLUT ELYX GFA VD 750 ML',
      },
      {
        sku: '24057',
        productName: 'SAO LOURENCO AGUA MIN. C GAS GFA PET 300ML FD C 12',
      },
      {
        sku: '25902',
        productName: 'PRINGLES CREME E CEBOLA LATA PAPELAO 120G CX18',
      },
      {
        sku: '23640',
        productName: 'STA. RITA TRES MEDALLAS SAUV. BLANC GFA VD 750ML',
      },
      {
        sku: '23548',
        productName: 'INDAIA AGUA MINERAL C/GAS GFA PET 330ML FD C/12',
      },
      {
        sku: '24411',
        productName: 'QUINTA DO MORGADO VINHO BRANCO SECO GFA VD 750 ML',
      },
      {
        sku: '23584',
        productName: 'COQUEIRO SARDINHA EM OLEO LT 125G CX C/54',
      },
      {
        sku: '24520',
        productName: 'TIROL LEITE UHT INTEGRAL TAMPA ROSCA TETRAPAK 1 L CX C/12',
      },
      {
        sku: '24267',
        productName: 'SALTON CHAL. VINHO BRANCO SECO GFA VD 750 ML',
      },
      {
        sku: '25257',
        productName: 'CARANGUEJO CACHACA OURO GFA VD 980 ML',
      },
      {
        sku: '24082',
        productName: 'DAFRUTA CONC. CAJU GFA PET 500ML PACK C/12',
      },
      {
        sku: '23554',
        productName: 'GRAN DANTE MALBEC GFA OW 750ML CX C6',
      },
      {
        sku: '24413',
        productName: 'QUINTA DO MORGADO VINHO BORDO MEIO SECO GFA VD 750 ML',
      },
      {
        sku: '25805',
        productName: 'CACHACA MODAO AMBURANA GFA VIDRO 700ML',
      },
      {
        sku: '23600',
        productName: 'PIRACANJUBA WHEY BEB. LACT. ZL CACAU TETRAPAK 250ML CX C/12',
      },
      {
        sku: '24384',
        productName: 'DOM BOSCO VINHO TINTO SECO GFA VD 750 ML',
      },
      {
        sku: '25604',
        productName: 'TEISSEIRE XAROPE FRAMBOESA GFA VIDRO 700ML',
      },
      {
        sku: '24403',
        productName: 'MAXIMO ARROZ NAO PARBOILIZADO PCT 5KG FD C/6',
      },
      {
        sku: '25340',
        productName: 'SALTON ESPUMANTE PROSECCO BRUT GFA VD 750 ML',
      },
      {
        sku: '25803',
        productName: 'CACHACA MODAO OURO GFA VIDRO 700ML',
      },
      {
        sku: '25623',
        productName: 'TEISSEIRE XAROPE GENGIBRE GFA VIDRO 700ML',
      },
      {
        sku: '24489',
        productName: 'GALLO AZEITE OLIVA EX. VIR. GFA PET 2 L',
      },
      {
        sku: '25654',
        productName: 'MAGUARY SUCO UVA TINTO SELECAO GFA PET 1500ML CX 6',
      },
      {
        sku: '24380',
        productName: 'DOM BOSCO VINHO BORDO SUAVE GFA VD 750 ML',
      },
      {
        sku: '24392',
        productName: 'TIO URBANO ARROZ NAO PARBOILIZADO PCT 1KG FD C/10',
      },
      {
        sku: '25427',
        productName: 'SELETA CACHACA DA MASSA ATLETICO GARRAFA VIDRO 700ML',
      },
      {
        sku: '1379',
        productName: 'ACAICAMP LATA 260 ML CX C/6',
      },
      {
        sku: '24348',
        productName: 'SOCOCO. TETRA PACK 330 ML CX C/12',
      },
      {
        sku: '24524',
        productName: 'TIROL LEITE UHT SEMI DESNATADO TAMP ROSC TETRAPAK 1 L CX C/12',
      },
      {
        sku: '23549',
        productName: 'INDAIA AGUA MINERAL S/GAS GFA PET 330ML',
      },
      {
        sku: '23638',
        productName: 'STA. RITA TRES MEDALLAS MERLOT GFA VD 750ML',
      },
      {
        sku: '4204',
        productName: 'TIAL AGUA DE COCO TETRA PAK 1 L CX C/12',
      },
      {
        sku: '24071',
        productName: 'MAGUARY MORANGO TETRA PAK 200 ML CX C/27',
      },
      {
        sku: '24808',
        productName: 'BETANIA EDGE LEITE UHT DESNATADO TETRA PAK 1 L CX C/12',
      },
      {
        sku: '24266',
        productName: 'SALTON CHAL. VINHO TINTO SECO GFA VD 750 ML',
      },
      {
        sku: '24301',
        productName: 'ELMA CHIPS AMENDOIM S PELE PCT 100 G CX C 24',
      },
      {
        sku: '24697',
        productName: 'ELEGE UHT DESNAT TETRAPAK 1 L CX C/12',
      },
      {
        sku: '24552',
        productName: 'NESTLE MOLICO LEITE DESNAT PROTE ZER LAC TETRA PK 1L CX12',
      },
      {
        sku: '25333',
        productName: 'SALTON ESPUMANTE MOSCATEL GFA VD 750 ML',
      },
      {
        sku: '25335',
        productName: 'SALTON ESPUMANTE BRUT ROSE GFA VD 750 ML',
      },
      {
        sku: '4106',
        productName: 'MARIA OLEO COMPOSTO TRAD. LATA 500ML CX C/20',
      },
      {
        sku: '24432',
        productName: 'PIRACANJUBA LEITE CONDENSADO TETRAPACK 270G CX27',
      },
      {
        sku: '24188',
        productName: 'PIRAQUE BISC. SALG. BATATA CHURR. PCT 60G CX C/20',
      },
      {
        sku: '23572',
        productName: 'COQUEIRO ATUM SOL. EM OLEO LT 170G CX C/24',
      },
      {
        sku: '24444',
        productName: 'LIDER LEITE UHT INTEGRAL TETRAPAK 1 L CX C/12',
      },
      {
        sku: '23550',
        productName: 'INDAIA AGUA MINERAL S/GAS GFA PET 330ML FD C/12',
      },
      {
        sku: '24376',
        productName: 'ELMA CHIPS STIKSY PCT 120G CX 21',
      },
      {
        sku: '24395',
        productName: 'URBANO FEIJAO PRETO T1 PCT 1KG CX C/10',
      },
      {
        sku: '24297',
        productName: 'ELMA CHIPS DORITOS NACHO PCT 48G CX C 48',
      },
      {
        sku: '23546',
        productName: 'INDAIA AGUA MINERAL C/GAS GFA PET 500ML PACK C/12',
      },
      {
        sku: '22048',
        productName: 'MESA PLASTICA BRAHMA CHOPP 1 UN DE MESA',
      },
      {
        sku: '24034',
        productName: 'NATURAL ONE LARANJA GFA PET 300ML FD C 12',
      },
      {
        sku: '24333',
        productName: 'CASA PERINI VINHO BR. SC. CHARDONNAY GFA VD 750 ML',
      },
      {
        sku: '24452',
        productName: 'PIRACANJUBA BLQLCHFRUT VER ZER ADIC TETRA PAK 200 ML CX C/24',
      },
      {
        sku: '24116',
        productName: 'MAGUARY CONC. UVA GFA PET 500ML PACK C/12',
      },
      {
        sku: '24359',
        productName: 'DO BEMZINHO MARACUJA TETRAPAK TBA 200ML SLIM L.S. 5 CX18',
      },
      {
        sku: '24121',
        productName: 'MAGUARY NECTAR UVA TETRA PAK 1 L CX C/12',
      },
      {
        sku: '23560',
        productName: 'DANTE ROBINO EXTRA BRUT GFA OW 750ML CX C-6',
      },
      {
        sku: '24645',
        productName: 'PERRIER CG GARRAFA DE VIDRO 330ML CX24',
      },
      {
        sku: '24393',
        productName: 'URBANO FEIJAO CARIOCA T1 PCT 1KG CX C/10',
      },
      {
        sku: '24916',
        productName: 'KIT BRAHMA DUPLO MALTE LATA 350ML 6UN + 1 COPO 330ML CX C/8',
      },
      {
        sku: '24055',
        productName: 'SAO LOURENCO AGUA MIN. S GAS GFA PET 510ML FD C 6',
      },
      {
        sku: '25075',
        productName: 'DUELO NORTE CANINHA GFA PET 490ML CX 12',
      },
      {
        sku: '24409',
        productName: 'QUINTA DO MORGADO VINHO TINTO SUAVE GFA VD 750 ML',
      },
      {
        sku: '24608',
        productName: 'SAN PELLEGRINO COM GAS GARRAFA VD 505 ML CX 24',
      },
      {
        sku: '24526',
        productName: 'NINHO LEITE INTEGRAL ZERO LACTOSE VIT TETRA PAK 1 L CX C/12',
      },
      {
        sku: '25890',
        productName: 'ISLA HARD SELTZER FRAMBOESA E AMORA LT 269ML CX C 6',
      },
      {
        sku: '26182',
        productName: 'POMAROLA MOLHO TOMATE MANJERICAO SACHE 300G CX 24',
      },
      {
        sku: '25428',
        productName: 'SELETA CACHACA CINCO ESTRELAS CRUZEIRO GARRAFA VIDRO 700ML',
      },
      {
        sku: '24014',
        productName: 'NATURAL ONE MACA GFA PET 300ML FD C 12',
      },
      {
        sku: '24353',
        productName: 'GUARAVITA FRUTAS CITRICAS COPO PLAS. 300ML CX12',
      },
      {
        sku: '24773',
        productName: 'MAGUARY NECTAR MACA TETRA PAK 200 ML CX C/27',
      },
      {
        sku: '23614',
        productName: 'PIRACANJUBA SEMI DESN. ZERO LACT. CX 1L CX C/12',
      },
      {
        sku: '24064',
        productName: 'MAGUARY MARACUJA TETRA PAK 1 L CX C/12',
      },
      {
        sku: '25174',
        productName: '51 ICE BALADA GARRAFA VD 275ML CX C24',
      },
      {
        sku: '24490',
        productName: 'GALLO AZEITE OLIVA TP UNICO GFA PET 2 L',
      },
      {
        sku: '25078',
        productName: 'DUELO NORTE CANINHA GFA PET 320ML CX 12',
      },
      {
        sku: '24141',
        productName: 'DAFRUTA NECTAR TANGERINA TETRA PAK 1 L CX C/12',
      },
      {
        sku: '24386',
        productName: 'TIO URBANO ARROZ NAO PARBOILIZADO PCT 5KG FD C/6',
      },
      {
        sku: '25804',
        productName: 'CACHACA MODAO PRATA GFA VIDRO 700ML',
      },
      {
        sku: '24751',
        productName: 'MAGUARY NECTAR MACA TETRA PAK 1 L CX C/12',
      },
      {
        sku: '15317',
        productName: 'H2OH LIMONETO LT 310ML SH C/6',
      },
      {
        sku: '24522',
        productName: 'TIROL LEITE UHT SEMI DESNATADO TETRAPAK 1 L CX C/12',
      },
      {
        sku: '25082',
        productName: 'DUELO NORTE BEB ICE LIMAO GFA PET 300ML FD C 12',
      },
      {
        sku: '24415',
        productName: 'KICALDO FEIJAO CARIOCA PCT 1KG FD C/10',
      },
      {
        sku: '24436',
        productName: 'PIRACANJUBA LEITE CONDENSADO PCT 5KG CX C/2',
      },
      {
        sku: '24324',
        productName: 'NATURAL ONE CAJU AMBIENTE GFA PET 900 ML CX/6',
      },
      {
        sku: '25839',
        productName: 'BRAHMA DUPLO MALTE LATA XSLEEK 410ML CX15 NP MULTPACK 15',
      },
      {
        sku: '24466',
        productName: 'ALMOND BREEZE ALIM C AMENDOA TETRAPAK 1 L CX C/12',
      },
      {
        sku: '24965',
        productName: 'MAGUARY NECTAR GOIABA TETRA PAK 1 L CX C/12',
      },
      {
        sku: '24972',
        productName: 'MAGUARY CONC. LARANJA GFA PET 500ML PACK C/12',
      },
      {
        sku: '24355',
        productName: 'GUARAVITA ZERO LIMAO COPO PLAS. 300ML CX12',
      },
      {
        sku: '23602',
        productName: 'PIRACANJUBA WHEY BEB. LACT. ZL BAUNILHA TETRAPAK 250ML CX C/12',
      },
      {
        sku: '25403',
        productName: 'IMPOSSIBLE ABACAXI LT 260 ML CX C 6 NPAL',
      },
      {
        sku: '24412',
        productName: 'QUINTA DO MORGADO VINHO BORDO SUAVE GFA VD 750 ML',
      },
      {
        sku: '24577',
        productName: 'HEINZ MOLHO TRAD. SACHE 250 G CX 28',
      },
      {
        sku: '23672',
        productName: 'CERVEGELA PLASTICA BRAHMA 1 UN P/ GFA 600ML CX C/3',
      },
      {
        sku: '25194',
        productName: 'CACHACA 51 LT 350ML CX C/12',
      },
      {
        sku: '25176',
        productName: '51 ICE FRUIT MIX MORANGO + LARANJA GARRAFA VD 275ML CX C24',
      },
      {
        sku: '25347',
        productName: 'SALTON ESPUMANTE CLASSIC MOSCATEL GFA VD 750 ML',
      },
      {
        sku: '25463',
        productName: 'AGUA MIN NATURAGUA S/GAS PET 500 ML CX12NPAL',
      },
      {
        sku: '24078',
        productName: 'MAGUARY NECTAR UVA TETRA PAK 200 ML CX C/27',
      },
      {
        sku: '24366',
        productName: 'ELMA CHIPS CHEETOS ONDA REQUEIJAO PCT 45G CX C 48',
      },
      {
        sku: '24170',
        productName: 'ELEFANTE EXTRATO DE TOMATE COPO PLAST. 310G CX C 24',
      },
      {
        sku: '24152',
        productName: 'CHOCOLEITE BEBIDA LACTEA GFA PET 880 ML CX C/12',
      },
      {
        sku: '25674',
        productName: 'BEM BRASIL BATATA MAIS BATATA PCT PLAST 2,5KG CX 6',
      },
      {
        sku: '24364',
        productName: 'ELMA CHIPS CHEETOS LUA PCT 40G CX C/40',
      },
      {
        sku: '24139',
        productName: 'DAFRUTA NECTAR GOIABA TETRA PAK 1 L CX C/12',
      },
      {
        sku: '24320',
        productName: 'ELMA CHIPS NA MESA EXTRA FINA PCT 100 G CX C 30',
      },
      {
        sku: '24174',
        productName: 'ELEFANTE EXTR. TOMATE STROG. LT 130G CX C48',
      },
      {
        sku: '23095',
        productName: 'MINUETO WAFER CHOCOLATE PCT 115G CX C/30',
      },
      {
        sku: '24084',
        productName: 'DAFRUTA CONC. UVA GFA PET 500ML PACK C/12',
      },
      {
        sku: '25430',
        productName: 'MATUTA CACHACA UMBURANA GARRAFA VIDRO 1 L',
      },
      {
        sku: '22860',
        productName: 'PIRAQUE AGUA GERGELIM PCT 240G CX C/40',
      },
      {
        sku: '4311',
        productName: 'MINALBA AGUA MINERAL S/GAS GFA PET 510ML FD C/12',
      },
      {
        sku: '23374',
        productName: 'PAI JOAO ARROZ PARB.T1 PCT 1KG FD C/10',
      },
      {
        sku: '24094',
        productName: 'MAGUARY CONC. MANGA GFA PET 500ML PACK C/12',
      },
      {
        sku: '23794',
        productName: 'COLORADO BEES ONE WAY 600ML CX C-12 ARTE',
      },
      {
        sku: '25870',
        productName: 'VIVANT VINHO FRISANTE ROSE LT 269 ML PACK C/4',
      },
      {
        sku: '4217',
        productName: 'TIAL NECTAR DE UVA TETRA PAK 330ML CX C/12',
      },
      {
        sku: '24350',
        productName: 'SOCOCO. TETRA PAK 1 L CX C/12',
      },
      {
        sku: '24609',
        productName: 'MINALBA AGUA PREMIUM S/GAS GFA VDR 300ML CX/12',
      },
      {
        sku: '23564',
        productName: 'DANTE ROBINO MALBEC GFA OW 750ML CX C 6',
      },
      {
        sku: '25265',
        productName: 'CACHACA GOSTOSA GFA PET 480ML CX 12',
      },
      {
        sku: '25178',
        productName: '51 ICE LIMAO GARRAFA VD 275ML CX C24',
      },
      {
        sku: '25182',
        productName: '51 ICE GIN TONICA LT 269 ML CX C/24',
      },
      {
        sku: '20495',
        productName: 'DO BEM MORANGO LIMAO LATA 310ML SH C 6 NPAL',
      },
      {
        sku: '22905',
        productName: 'PIRAQUE ESPAGUETE ALDENTE PCT 1KG CX C/12',
      },
      {
        sku: '24334',
        productName: 'ARBO VINHO BRANCO S MOSCATO GFA VD 750 ML',
      },
      {
        sku: '24092',
        productName: 'MAGUARY CONC. GOIABA GFA PET 500ML PACK C/12',
      },
      {
        sku: '23630',
        productName: 'PIRACANJUBA OTIMO COMP. LACTEO SACO AL. 400G FD C/25',
      },
      {
        sku: '24530',
        productName: 'POMAROLA MOL DE TOMATE TRAD. SACHE 320G CX 24',
      },
      {
        sku: '25259',
        productName: 'CARANGUEJO CACHACA OURO LT 350ML CX C/12',
      },
      {
        sku: '23594',
        productName: 'PIRAKIDS BEBIDA LACTEA CHOCOLATE TETRA PAK 200 ML CX C/27',
      },
      {
        sku: '26211',
        productName: 'BUDWEISER LT SLEEK 350ML CX CART C 12 LOLLAPALOOZA',
      },
      {
        sku: '24693',
        productName: 'ELEGE UHT INTEGRAL TETRAPAK 1 L CX C/12',
      },
      {
        sku: '24180',
        productName: 'PIRAQUE TORRADAS TRADICIONAL PCT 142G CX C/32',
      },
      {
        sku: '25192',
        productName: '51 ICE CITRUS GARRAFA VD 275ML CX C24',
      },
      {
        sku: '24641',
        productName: 'PERRIER CG GARRAFA VD 750 ML CX 12',
      },
      {
        sku: '24085',
        productName: 'SAO LOURENCO AGUA MIN. C GAS GFA PET 510ML FD C 6',
      },
      {
        sku: '24142',
        productName: 'DAFRUTA NECTAR LARANJA TETRA PAK 200 ML CX C/27',
      },
      {
        sku: '22263',
        productName: 'DO BEM LARANJA TETRAPAK 1L CX12 NPAL',
      },
      {
        sku: '24423',
        productName: 'MAXIMO FEIJAO PRETO T1 PCT 1KG FD C/10',
      },
      {
        sku: '23606',
        productName: 'PIRACANJUBA WHEY B. LACT. ZL FRUTAS VERM TETRAPAK 250ML CX C/12',
      },
      {
        sku: '24832',
        productName: 'TIAL NECTAR GOIABA TETRA PAK 1 L CX C/12',
      },
      {
        sku: '24540',
        productName: 'TIROL UHT CREME DE LEITE TETRA PAK 200 ML CX C/27',
      },
      {
        sku: '23562',
        productName: 'DANTE ROBINO CABERNET SAUVIGNON GFA OW 750ML CX C 6',
      },
      {
        sku: '22346',
        productName: 'DO BEM GOIABA TETRAPAK 1L CX12 NPAL',
      },
      {
        sku: '4108',
        productName: 'MARIA OLEO COMPOSTO TRAD. GARRAFA PET 500MLCX C/20',
      },
      {
        sku: '4264',
        productName: 'POLAR PURO MALTE GFA VD 1L',
      },
      {
        sku: '25505',
        productName: 'PIRACANJUBA LEITE EM PO INTEGRAL PACOTE PLAST 800G CX 12',
      },
      {
        sku: '24134',
        productName: 'DAFRUTA CONC. MARACUJA GFA PET 500ML PACK C/12',
      },
      {
        sku: '24472',
        productName: 'PIRACANJUBA FARINHA LACTEA SACHE 180G CX C/12',
      },
      {
        sku: '24102',
        productName: 'SUKITO PET 450ML SH C/12',
      },
      {
        sku: '24140',
        productName: 'DAFRUTA NECTAR LARANJA TETRA PAK 1 L CX C/12',
      },
      {
        sku: '24306',
        productName: 'RED BULL MELANCIA LATA 250ML FOUR PACK NPAL',
      },
      {
        sku: '24070',
        productName: 'MAGUARY MARACUJA TETRA PAK 200 ML CX C/27',
      },
      {
        sku: '24326',
        productName: 'NATURAL ONE PESSEGO BLEND AMBIENTE GFA PET 900 ML CX/6',
      },
      {
        sku: '24434',
        productName: 'PIRACANJUBA WHEY BEB.LACT. ZL BATAT DOCE TETRAPAK 250ML CX C/12',
      },
      {
        sku: '23598',
        productName: 'PIRAKIDS BEBIDA LACTEA CHOCOLATE CX 1L CX C/12',
      },
      {
        sku: '25186',
        productName: '51 ICE TANGERINA GARRAFA VD 275ML CX C24',
      },
      {
        sku: '24312',
        productName: 'ELMA CHIPS AMENDOIM OVINHO PCT 170G CX C 22',
      },
      {
        sku: '24555',
        productName: 'QUERO GRAO DE BICO TETRA PAK 180G CX/24',
      },
      {
        sku: '4141',
        productName: 'PATAGONIA AMB LAG NACIONAL LT SLEEK 350ML C 8 CX CARTAO',
      },
      {
        sku: '24150',
        productName: 'CHOCOLEITE BEBIDA LACTEA GFA PET 260 ML CX C/24',
      },
      {
        sku: '15994',
        productName: 'DO BEM UVA TETRAPAK 330ML CX12',
      },
      {
        sku: '23588',
        productName: 'PESCADOR SARDINHA LAJE EM OLEO LT 125G CX C/54',
      },
      {
        sku: '25404',
        productName: 'IMPOSSIBLE LARANJA LT 260 ML CX C 6 NPAL',
      },
      {
        sku: '25320',
        productName: 'CELESTERA DO PARANA 600ML',
      },
      {
        sku: '23566',
        productName: 'COQUEIRO ATUM PEDACOS EM OLEO LT 170G CX C/24',
      },
      {
        sku: '20717',
        productName: 'GUARANA CHP ANTARCTICA 100% NATURAL LT 260ML SH C 15',
      },
      {
        sku: '23449',
        productName: 'MIKES HARD LEMONADE N LT 269ML SH C12 NP',
      },
      {
        sku: '24202',
        productName: 'PIRAQUE BISC.SALG. PIMENTA PCT 90G CX 20',
      },
      {
        sku: '21843',
        productName: 'ISLA LIMAO SICILIANO C GENGIBRE LT 269ML CX CARTAO C/8 NPAL',
      },
      {
        sku: '25762',
        productName: 'STELLA ARTOIS LATA 410 ML CX C15 NP MULTPACK',
      },
      {
        sku: '23062',
        productName: 'MOLICO DESNATADO VITAMINA CX 1L CX C/12',
      },
      {
        sku: '24316',
        productName: 'KEROCOCO KIDS TETRA PAK 200 ML CX C/27',
      },
      {
        sku: '22089',
        productName: 'MIKES HARD LEMONADE TANGERINA N LT 269ML SH C12 NPAL',
      },
      {
        sku: '24044',
        productName: 'PUREZA VITAL AGUA MIN. C GAS GFA PET 510ML FD C/12',
      },
      {
        sku: '23612',
        productName: 'PIRACANJUBA LEITE PO INTEG. INST. SACO AL. 400G FD C/25',
      },
      {
        sku: '23435',
        productName: 'QUATA UHT SEMIDESNATADO CX 1L CX C/12',
      },
      {
        sku: '23610',
        productName: 'PIRACANJUBA LEITE PO DESN. INST. SACO AL. 200G FD C/50',
      },
      {
        sku: '23641',
        productName: 'PIRAKIDS BEB. LACT. CHOC. ZERO LAC. TETRA PAK 200 ML CX C/24',
      },
      {
        sku: '24310',
        productName: 'ELMA CHIPS DORITOS QUEIJO NACHO PCT 84G CX C 25',
      },
      {
        sku: '16810',
        productName: 'DO BEM TODO DIA UVA TETRAPAK 1L CX12',
      },
      {
        sku: '25263',
        productName: 'COROTE COQUETEL PESSEGO GARRAFA PET 500 ML CX C/12',
      },
      {
        sku: '22497',
        productName: 'GALIOTTO VINHO TINTO SECO GARRAFA VIDRO 1 L',
      },
      {
        sku: '21073',
        productName: 'ÁGUA MINERAL C/GÁS AMA LT SLEEK 350ML SH C 12 NPA',
      },
      {
        sku: '24456',
        productName: 'PIRACANJBLQLCH AVE ZER LACT TETRA PAK 200 ML CX C/24',
      },
      {
        sku: '23556',
        productName: 'GRAN DANTE BONARDA GFA OW 750ML CX C6',
      },
      {
        sku: '24428',
        productName: 'CHOPP KONA BLOND ALE NAC BARRIL KEG 30L NPAL',
      },
      {
        sku: '25858',
        productName: 'BUDWEISER LATA 410 ML MULTIPACK .',
      },
      {
        sku: '25837',
        productName: 'SPATEN N LT 473ML CX CARTAO C/12',
      },
      {
        sku: '25387',
        productName: 'KNORR BASE TOMATE DESIDRATADO SACHE PLASTICO 750G CX6',
      },
      {
        sku: '23273',
        productName: 'CAMIL ARROZ CARRETEIRO P T1 PCT 5KG FD C/6',
      },
      {
        sku: '24753',
        productName: 'MAGUARY NECTAR ABACAXI TETRA PAK 1 L CX C/12',
      },
      {
        sku: '23582',
        productName: 'COQUEIRO SARDINHA M. TOMATE LT 125G CX C/54',
      },
      {
        sku: '22913',
        productName: 'PIRAQUE ESPAGUETE SEMOLA PCT 1KG CX C/12',
      },
      {
        sku: '24377',
        productName: 'FROZEN BEATS GT SACHE 100ML CX 72',
      },
      {
        sku: '23628',
        productName: 'PIRACANJUBA OTIMO COMP. LACTEO SACO AL. 200G FD C/50',
      },
      {
        sku: '24613',
        productName: 'ACQUA PANNA S/G GARRAFA VD 505 ML CX 24',
      },
      {
        sku: '23398',
        productName: 'ITALAC UHT INTEGRAL CX EDGE 1L CX C/12',
      },
      {
        sku: '23552',
        productName: 'INDAIA AGUA MINERAL S/GAS GFA PET 500ML PACK C/12',
      },
      {
        sku: '23810',
        productName: 'BEEFEATER E TONICA ANT. RTD LT 269ML FOURPACK BAND C/6',
      },
      {
        sku: '24969',
        productName: 'MAGUARY CONC. ABACAXI GFA PET 500ML PACK C/12',
      },
      {
        sku: '22333',
        productName: 'DO BEM COCO TETRAPAK 1L CX12 NPAL',
      },
      {
        sku: '24458',
        productName: 'PIRACANJBLQLCCHOC MEIO AM ZE LA TETRA PAK 200 ML CX C/24',
      },
      {
        sku: '16635',
        productName: 'DO BEM MANGA TETRAPAK 1L CX12',
      },
      {
        sku: '26099',
        productName: 'TRIDENT XFRESH INTENSE ENVELOPE 8G CX21_',
      },
      {
        sku: '4222',
        productName: 'PATAGONIA IPA BARRIL KEG 50L NPAL',
      },
      {
        sku: '23604',
        productName: 'PIRACANJUBA WHEY BEB. LACT. ZL AMENDOIN TETRAPAK 250ML CX C/12',
      },
      {
        sku: '22262',
        productName: 'DO BEM TODO DIA UVA TETRAPAK 1L CX12 NPAL',
      },
      {
        sku: '24374',
        productName: 'ELMA CHIPS STIKSY PCT 48G CX C/45',
      },
      {
        sku: '24208',
        productName: 'CHOPP SPATEN N BARRIL KEG 30L NPAL',
      },
      {
        sku: '24073',
        productName: 'MAGUARY NECTAR LARANJA TETRA PAK 200 ML CX C/27',
      },
      {
        sku: '22476',
        productName: 'HEINZ KETCHUP SACHE 8G CX C/192',
      },
      {
        sku: '23493',
        productName: 'UNIAO ACUCAR REFINADO PCT 5KG CX C/5',
      },
      {
        sku: '23558',
        productName: 'GRAN DANTE NATURE GFA OW 750ML CX 6',
      },
      {
        sku: '26214',
        productName: 'TARANTELLA MOLHO DE TOMATE TRADICIONAL SACHE 300G CX 24',
      },
      {
        sku: '23046',
        productName: 'PATAGONIA WEISSE BIB 6L NPAL',
      },
      {
        sku: '25189',
        productName: '51 ICE KIWI GARRAFA VD 275ML CX C24',
      },
      {
        sku: '25835',
        productName: 'SKOL LATA 410 ML CX C15 NP MULTPACK 15',
      },
      {
        sku: '24028',
        productName: 'NOON LN 355ML CX C/12',
      },
      {
        sku: '24122',
        productName: 'CHOPP SPATEN N BARRIL KEG 50L NPAL',
      },
      {
        sku: '4219',
        productName: 'TIAL NECTAR DE PESSEGO TETRA PAK 330ML CX C/12',
      },
      {
        sku: '23306',
        productName: 'NAMORADO ARROZ PARB. T1 PCT 5KG FD C/6',
      },
      {
        sku: '24172',
        productName: 'SKOL LT 269ML SH C12 NPAL KLOS',
      },
      {
        sku: '24046',
        productName: 'PUREZA VITAL AGUA MIN. S GAS GFA PET 510ML FD C/12',
      },
      {
        sku: '21974',
        productName: 'TRIDENT TUTTI-FRUTTI ENVELOPE 8G CX C/21',
      },
      {
        sku: '26188',
        productName: 'STELLA ARTOIS LT 473ML CX CARTAO C/12',
      },
      {
        sku: '19520',
        productName: 'CHOPP COLORADO LAGER BARRIL KEG 30L NPAL',
      },
      {
        sku: '15342',
        productName: 'CHOPP COLORADO CAUIM 016 BARRIL KEG 30L NPAL',
      },
      {
        sku: '24563',
        productName: 'QUERO MOLHO TRAD. SACHE 340G CX 24',
      },
      {
        sku: '19824',
        productName: 'CHOPP COLORADO LAGER BARRIL KEG 20L',
      },
      {
        sku: '25881',
        productName: 'BUDWEISER OW 330ML CX C/24 COPA 2022',
      },
      {
        sku: '15407',
        productName: 'CHOPP COLORADO INDICA BARRIL KEG 20L',
      },
      {
        sku: '24194',
        productName: 'PIRAQUE BISC. SALG. CALAB PCT 90G CX 20',
      },
      {
        sku: '13332',
        productName: 'SKOL BARRIL KEG 30L CERV. PILSEN',
      },
      {
        sku: '24089',
        productName: 'MAGUARY CONC. CAJU GFA PET 500ML PACK C/12',
      },
      {
        sku: '23570',
        productName: 'COQUEIRO ATUM RAL. EM OLEO LT 170G CX C/24',
      },
      {
        sku: '23402',
        productName: 'ITALAC UHT SEMIDESNATADO CX EDGE 1L CX C/12',
      },
      {
        sku: '24285',
        productName: 'ELMA CHIPS RUFFLES SAL PCT 57G CX C/30',
      },
      {
        sku: '23608',
        productName: 'PIRACANJUBA LEITE EM PO INTEGRAL SACO AL. 200G FD C/50',
      },
      {
        sku: '26202',
        productName: 'CORONA EXTRA N LN 330ML CX C/24 EXP UR',
      },
      {
        sku: '21973',
        productName: 'TRIDENT MELANCIA ENVELOPE 8G CX C/21',
      },
      {
        sku: '4241',
        productName: 'TIAL NECTAR DE LARANJA TETRA PAK 330ML CX C/12',
      },
      {
        sku: '10635',
        productName: 'AGUA MIN PUREZA VITAL S/GAS PET 1,5 SHRINK C/6',
      },
      {
        sku: '22815',
        productName: 'BRAHMA DUPLO MALTE LATA 310ML CX C/6 MULTPACK',
      },
      {
        sku: '24148',
        productName: 'CHOCOLEITE BEBIDA LACTEA GFA VD 208 ML CX C/24',
      },
      {
        sku: '25878',
        productName: 'LACTA BIS XTRA AO LEITE PAPELAO 180G CX24',
      },
      {
        sku: '16637',
        productName: 'DO BEM PESSEGO TETRAPAK 1L CX12',
      },
      {
        sku: '23322',
        productName: 'BUTUI ARROZ PARB. T1 PCT 5KG FD C/6',
      },
      {
        sku: '24132',
        productName: 'FROZEN BEATS MIX (GT+GINGER+MINT) SACHE 100ML CX C/6 MULTPACK',
      },
      {
        sku: '16639',
        productName: 'DO BEM GOIABA TETRAPAK 1L CX12',
      },
      {
        sku: '23235',
        productName: 'HEINZ KETCHUP SACHE 7G CX C/192',
      },
      {
        sku: '24059',
        productName: 'SAO LOURENCO AGUA MIN. S GAS GFA PET 300ML FD C 12',
      },
      {
        sku: '25885',
        productName: 'ABIUDA SERGIPE LATA 350ML SH C-12 NPAL',
      },
      {
        sku: '17329',
        productName: 'CHOPP PATAGONIA WEISSE BARRIL KEG 50L NPAL',
      },
      {
        sku: '16021',
        productName: 'DO BEM MATE LIMÃO TETRAPAK 1L CX12',
      },
      {
        sku: '24200',
        productName: 'PIRAQUE BISC. SALG. PAO DE ALHO PCT 90G CX 20',
      },
      {
        sku: '24308',
        productName: 'ELMA CHIPS RUFFLES SAL PCTE 115G CX 15',
      },
      {
        sku: '19820',
        productName: 'CHOPP COLORADO SESSION IPA BARRIL KEG 30L NPAL',
      },
      {
        sku: '4238',
        productName: 'TIAL NECTAR DE MANGA TETRA PAK 330ML CX C/12',
      },
      {
        sku: '21968',
        productName: 'TRIDENT HORTELA ENVELOPE 8G CX C/21',
      },
      {
        sku: '24360',
        productName: 'SOCOCO. TETRA PAK 200 ML CX C/24',
      },
      {
        sku: '22570',
        productName: 'CAMIL ARROZ PARB. T1 PCT 5KG FD C/6',
      },
      {
        sku: '23807',
        productName: 'KONA BLOND ALE NAC LT 269ML CX CARTAO C/8 NPAL',
      },
      {
        sku: '23323',
        productName: 'BUTUI ARROZ BRANCO T1 PCT 5KG FD C/6',
      },
      {
        sku: '25877',
        productName: 'BOHEMIA LATA 410 ML CX C15 NP MULTPACK 15',
      },
      {
        sku: '22472',
        productName: 'HEINZ KETCHUP PCT BG 2000G',
      },
      {
        sku: '25861',
        productName: 'BUDWEISER LATA 410 ML CX C15 NP MULTIPACK .',
      },
      {
        sku: '23437',
        productName: 'QUATA UHT SEMIDESNATADO ZERO LACTOSE CX 1L CX C/12',
      },
      {
        sku: '5522',
        productName: 'AGUA MIN GOYA S/GAS COPO 200ML CAIXA C/48',
      },
      {
        sku: '25275',
        productName: 'GARRAFA VIDRO VERDE STELLA 600 ML EMBALAGEM PAL C/1872',
      },
      {
        sku: '23568',
        productName: 'COQUEIRO ATUM RAL. MOLHO TOMATE LT 160G CX C/24',
      },
      {
        sku: '11190',
        productName: 'LIPTON LIMAO PET 1,5 SHRINK C/6 HF',
      },
      {
        sku: '22011',
        productName: 'BIS XTRA AO LEITE ENVELOPE 45G CX C/15',
      },
      {
        sku: '25700',
        productName: 'FUSION PET 2L SHRINK C/6',
      },
      {
        sku: '24165',
        productName: 'STELLA ARTOIS LT 269ML CX CART C/15 NP MULTPACK',
      },
      {
        sku: '23214',
        productName: 'TACA CALICE STELLA 250ML 1 UN DE TACA CX C/24',
      },
      {
        sku: '24827',
        productName: 'TIAL SUCO PRONTO 100 LARANJA TETRA PAK 330ML CX C/12',
      },
      {
        sku: '22347',
        productName: 'DO BEM LIMONADA TETRAPAK 1L CX12 NPAL',
      },
      {
        sku: '24158',
        productName: 'PATAGONIA IPA 600ML',
      },
      {
        sku: '11250',
        productName: 'PEPSI TWIST ZERO LATA 350ML SH C/12 NPAL',
      },
      {
        sku: '26112',
        productName: 'QUATA BEBIDA LACTEA UHT SBOR CHOCO TETRA PAK 200 ML CX C/27',
      },
      {
        sku: '21584',
        productName: 'LEITE UHT DESNATADO SANTA CLARA TETRAPAK 1 L CX C/12',
      },
      {
        sku: '15971',
        productName: 'DO BEM COCO TETRAPAK 330ML CX12',
      },
      {
        sku: '23671',
        productName: 'CERVEGELA PLASTICA BRAHMA 1 UN P/ GFA 1L CX C/3',
      },
      {
        sku: '21119',
        productName: 'SKOL BEATS GT LT 269ML CX CARTAO C/8 NPAL',
      },
      {
        sku: '20494',
        productName: 'DO BEM TANGERINA. LATA 310ML SH C 6 NPAL',
      },
      {
        sku: '17328',
        productName: 'CHOPP PATAGONIA WEISSE BARRIL KEG 30L NPAL',
      },
      {
        sku: '20174',
        productName: 'SKOL BEATS 150 BPM PET 100ML SHIRINK C/15',
      },
      {
        sku: '23586',
        productName: 'PESCADOR SARDINHA MOLHO DE TOMATE LT 125G CX C/54',
      },
      {
        sku: '22336',
        productName: 'GOOSE ISLAND HAZY IPA BARRIL KEG 30L NPAL CERV',
      },
      {
        sku: '21071',
        productName: 'CHOPP BOHEMIA AURA LAGER BARRIL KEG 50L NPAL',
      },
      {
        sku: '23415',
        productName: 'POLAR PURO MALTE LATA XSLEEK 350ML SH C 12',
      },
      {
        sku: '24454',
        productName: 'PIRACANJ B L Q L C MAM MAC ZER AD TETRA PAK 200 ML CX C/24',
      },
      {
        sku: '17327',
        productName: 'CHOPP PATAGONIA AMBER LAGER BARRIL KEG 50L NPAL',
      },
      {
        sku: '24186',
        productName: 'PIRAQUE BISC. SALG. BATATA TRAD. PCT 60G CX C/20',
      },
      {
        sku: '24277',
        productName: 'PRATINHA PRATIPA ONE WAY 600ML CX 12 NPAL',
      },
      {
        sku: '21970',
        productName: 'TRIDENT MENTA ENVELOPE 8G CX C/21',
      },
      {
        sku: '25486',
        productName: 'TARANTELLA MOLHO DE TOMATE TRADICIONAL SACHE 340G CX 24',
      },
      {
        sku: '23202',
        productName: 'TRINK REFRESCO LIMAO PCT 25G DP C/15',
      },
      {
        sku: '24378',
        productName: 'FROZEN BEATS MINT SACHE 100ML CX 72',
      },
      {
        sku: '22478',
        productName: 'QUERO MAIONESE PCT 1020G',
      },
      {
        sku: '24771',
        productName: 'MAGUARY NECTAR TANGERINA TETRA PAK 200 ML CX C/27',
      },
      {
        sku: '21789',
        productName: 'ORLOFF GARRAFA VIDRO 1 L',
      },
      {
        sku: '8037',
        productName: 'CHOPP CLARO STELLA ARTOIS BARRIL KEG 30L',
      },
      {
        sku: '24379',
        productName: 'FROZEN BEATS GINGER SACHE 100ML CX 72',
      },
      {
        sku: '6251',
        productName: 'AGUA MIN DIAS DÁVILA S/GAS COPO 200ML CAIXA C/48',
      },
      {
        sku: '5325',
        productName: 'AGUA MINERAL INDAIA S/GAS COPO 200ML CAIXA C/48',
      },
      {
        sku: '23064',
        productName: 'NINHO INTEGRAL VITAMINA CX 1L CX C/12',
      },
      {
        sku: '16013',
        productName: 'DO BEM LARANJA TETRAPAK 1L CX12',
      },
      {
        sku: '23078',
        productName: 'PARMALAT UHT INTEGRAL CX 1L CX C/12',
      },
      {
        sku: '22755',
        productName: 'BRAHMA DUPLO MALTE LATA 310ML SH C 20 MULTPACK 20',
      },
      {
        sku: '24096',
        productName: 'MAGUARY CONC. MARACUJA GFA PET 500ML PACK C/12',
      },
      {
        sku: '22350',
        productName: 'DO BEM TANGERINA 100 TETRAPAK 1L CX12 NPAL',
      },
      {
        sku: '15343',
        productName: 'CHOPP COLORADO CAUIM 016 BARRIL KEG 50L NPAL',
      },
      {
        sku: '22482',
        productName: 'QUERO MAIONESE SACHE 7G CX C/176',
      },
      {
        sku: '24837',
        productName: 'TIAL NECTAR GOIABA TETRA PAK 330ML CX C/12',
      },
      {
        sku: '24292',
        productName: 'RAMAL 33 ONE WAY 600ML CX 12 NPAL',
      },
      {
        sku: '15337',
        productName: 'CHOPP COLORADO DEMOISELLE BARRIL KEG 50L NPAL',
      },
      {
        sku: '24568',
        productName: 'QUERO MILHO VERDE LT 170G CX C/24',
      },
      {
        sku: '22332',
        productName: 'GRELHADITOS PCT 24G CX C/60',
      },
      {
        sku: '23616',
        productName: 'SPATEN N GFA OW 600ML CX C/24',
      },
      {
        sku: '22573',
        productName: 'CAMIL FEIJAO PRETO T1 PCT 1KG FD C/10',
      },
      {
        sku: '23380',
        productName: 'NAMORADO FEIJAO PRETO T1 PCT 1KG FD C/10',
      },
      {
        sku: '23508',
        productName: 'GUARANA ANTARCTICA UVA VERDE LATA 350ML SH C/12',
      },
      {
        sku: '24018',
        productName: 'TONICA ANTARCTICA HIBISCO LT 269ML SH C/15',
      },
      {
        sku: '22487',
        productName: 'HEINZ MOSTARDA SACHE 5G CX C/192',
      },
      {
        sku: '25451',
        productName: 'DO BEMZINHO UVA TETRAPAK TBA 200ML SLIM L.S. 5 CX18',
      },
      {
        sku: '15884',
        productName: 'CHOPP WALS SESSION BARRIL KEG 30L NPAL',
      },
      {
        sku: '22886',
        productName: 'PIRAQUE WAFER CHOCOLATE PCT 160G CX C/20',
      },
      {
        sku: '23106',
        productName: 'MINUETO WAFER LIMAO PCT 115G CX C/30',
      },
      {
        sku: '23256',
        productName: 'PIRACANJUBA CREME DE LEITE TETRAPAK 200G CX C/27',
      },
      {
        sku: '23233',
        productName: 'NATU NOBILIS APERITIVO GARRAFA VIDRO 1 L',
      },
      {
        sku: '23439',
        productName: 'QUATA UHT INTEGRAL CX 1L CX C/12',
      },
      {
        sku: '23424',
        productName: 'PEPSI COLA LATA 310ML SH C 15 NPAL',
      },
      {
        sku: '23055',
        productName: 'PIRACANJUBA UHT INTEGRAL CX 1L CX C/12',
      },
      {
        sku: '22801',
        productName: 'BRAHMA DUPLO MALTE LATA 410ML CX C/6 MULTPACK 06',
      },
      {
        sku: '23502',
        productName: 'GUARACAMP COPO 285ML CX C/36',
      },
      {
        sku: '23190',
        productName: 'SKOL BEATS MOSCOW MULE LT 269ML SH C15 NPAL',
      },
      {
        sku: '22852',
        productName: 'PIRAQUE AGUA E SAL PCT 200G CX C/40',
      },
      {
        sku: '22819',
        productName: 'BOHEMIA LATA 310ML CX C/6 MULTPACK 06',
      },
      {
        sku: '24299',
        productName: 'ELMA CHIPS AMENDOIM JAPONES PCT 45G CX C 85',
      },
      {
        sku: '19803',
        productName: 'CHOPP INOV WIT BARRIL KEG 30L NPAL',
      },
      {
        sku: '16015',
        productName: 'DO BEM MATE NATURAL TETRAPAK 1L CX12',
      },
      {
        sku: '26114',
        productName: 'QUATA BEBIDA LACTEA UHT VITAMINA TETRA PAK 200 ML CX C/27',
      },
      {
        sku: '16944',
        productName: 'BOHEMIA NANO BARRIL KEG 30L',
      },
      {
        sku: '22344',
        productName: 'DO BEM CAJU TETRAPAK 1L CX12 NPAL',
      },
      {
        sku: '21898',
        productName: 'NOVECENTO ESPUMANTE ARGENTINO BRUT GFA OW 750ML CX C/6 NPAL',
      },
      {
        sku: '15917',
        productName: 'LIPTON LIMAO PET 500ML SH C/12 NPAL',
      },
      {
        sku: '25450',
        productName: 'DO BEMZINHO LARANJA TETRAPAK TBA 200ML SLIM L.S. 5 CX18',
      },
      {
        sku: '22793',
        productName: 'BRAHMA CHOPP LATA 310ML SH C 15 NPAL MULTPACK 15',
      },
      {
        sku: '15352',
        productName: 'CHOPP BOHEMIA 14 WEISS BARRIL KEG 30L',
      },
      {
        sku: '16996',
        productName: 'AGUA VIENA MINERAL SEM GAS PET 330 ML CAIXA C/ 12',
      },
      {
        sku: '24304',
        productName: 'TODDYNHO 200ML TETRA PAK 200 ML CX C/27',
      },
      {
        sku: '15321',
        productName: 'CHOPP COLORADO APPIA BARRIL KEG 30L NPAL',
      },
      {
        sku: '25924',
        productName: 'SMART SAL ROSA DO HIMALAIA P CHURRASCO FRASCO PLAST 500G CX 12',
      },
      {
        sku: '24819',
        productName: 'ORIGINAL 300ML',
      },
      {
        sku: '19521',
        productName: 'CHOPP COLORADO LAGER BARRIL KEG 50L NPAL',
      },
      {
        sku: '16011',
        productName: 'DO BEM LARANJA TETRAPAK 330ML CX12',
      },
      {
        sku: '19813',
        productName: 'SKOL BEATS GT LT 269ML SH C15 NPAL',
      },
      {
        sku: '22908',
        productName: 'PIRAQUE PARAFUSO SEMOLA PCT 1KG CX C/10',
      },
      {
        sku: '23097',
        productName: 'MINUETO WAFER MORANGO PCT 115G CX C/30',
      },
      {
        sku: '24639',
        productName: 'CHOCO MILK BEB LAC UHT CHOC BATAVO GFA VDR 200ML CX C/24',
      },
      {
        sku: '22216',
        productName: 'OLD EIGHT GARRAFA VIDRO 1 L',
      },
      {
        sku: '23271',
        productName: 'SKOL BEATS SENSES LONG NECK 269ML SIX-PACK SH C/4',
      },
      {
        sku: '15996',
        productName: 'DO BEM UVA TETRAPAK 1L CX12',
      },
      {
        sku: '23299',
        productName: 'NAMORADO ARROZ BR T1 PCT 1KG FD C/10',
      },
      {
        sku: '22330',
        productName: 'MENDORATO PCT 27G CX C/60',
      },
      {
        sku: '21955',
        productName: 'CHIVAS REGAL 12 ANOS GARRAFA VIDRO 1 L',
      },
      {
        sku: '22009',
        productName: 'CHICLETE ADAMS HORTELA CAIXINHA 2,8G CX C/100',
      },
      {
        sku: '23198',
        productName: 'TRINK REFRESCO UVA PCT 25G DP C/15',
      },
      {
        sku: '17326',
        productName: 'CHOPP PATAGONIA AMBER LAGER BARRIL KEG 30L NPAL',
      },
      {
        sku: '23673',
        productName: 'CERVEGELA PLASTICA SKOL 1 UN P/ GFA 1L CX C/3',
      },
      {
        sku: '15984',
        productName: 'DO BEM LIMONADA TETRAPAK 1L CX12',
      },
      {
        sku: '11062',
        productName: 'GATORADE LIMÃO PET 1L SH C/06',
      },
      {
        sku: '23400',
        productName: 'ITALAC UHT DESNATADO CX EDGE 1L CX C/12',
      },
      {
        sku: '26204',
        productName: 'SPATEN N LT 269ML CX CARTAO C/8 NPAL',
      },
      {
        sku: '4121',
        productName: 'BUDWEISER LATA 410ML CX C/15 MULTPACK 15',
      },
      {
        sku: '18145',
        productName: 'HOEGAARDEN WHITE BARRIL KEG 20L NP',
      },
      {
        sku: '4143',
        productName: 'PATAGONIA BOH PILS NACIONAL LT SLEEK 350ML C 8 CX CARTAO',
      },
      {
        sku: '23237',
        productName: 'HEINZ MAIONESE SACHE 7G CX C/192',
      },
      {
        sku: '16959',
        productName: 'DO BEM TANGERINA 100 TETRAPAK 1L CX12',
      },
      {
        sku: '23336',
        productName: 'CAMIL ARROZ PARB. T1 PCT 1KG FD C/10',
      },
      {
        sku: '23081',
        productName: 'PARMALAT UHT SEMIDESNATADO CX 1L CX C/12',
      },
      {
        sku: '23451',
        productName: 'MIKES HARD LEMONADE TANGERINA N LT 269ML SH C12 NP',
      },
      {
        sku: '23200',
        productName: 'TRINK REFRESCO ABACAXI PCT 25G DP C/15',
      },
      {
        sku: '22495',
        productName: 'HEINZ MOLHO TRAD. PCT 1020G .',
      },
      {
        sku: '16005',
        productName: 'DO BEM TANGERINA TETRAPAK 1L CX12',
      },
      {
        sku: '22140',
        productName: 'AGUA MIN MAR DOCE PREMIUM S/GAS PET 510ML CAIXA C/12',
      },
      {
        sku: '11668',
        productName: 'MENTA AR NAT GRANEL',
      },
      {
        sku: '22027',
        productName: 'COLORADO APPIA LT SLEEK 350ML C8 CX CARTAO NPAL',
      },
      {
        sku: '22471',
        productName: 'HEINZ KETCHUP PCT FP 1033G',
      },
      {
        sku: '12827',
        productName: 'AGUA M S LOURENCO C/GAS PET 510ML CX C/6',
      },
      {
        sku: '22740',
        productName: 'MIKES HARD SELTZER FRAMBOESA N LT 269ML CX C 6',
      },
      {
        sku: '21580',
        productName: 'LEITE UHT INTEGRAL SANTA CLARA TETRAPAK 1 L CX C/12',
      },
      {
        sku: '20198',
        productName: 'MIKES HARD LEMONADE N LT 269ML SH C12 NPAL',
      },
      {
        sku: '22434',
        productName: 'SKOL PURO MALTE LATA 350ML PACK C/6',
      },
      {
        sku: '22477',
        productName: 'QUERO MAIONESE PCT 3KG',
      },
      {
        sku: '20704',
        productName: 'BRAHMA EXTRA PIRAJA BARRIL KEG 30L NPAL',
      },
      {
        sku: '22474',
        productName: 'QUERO KETCHUP SACHE 7G CX C/176',
      },
      {
        sku: '15353',
        productName: 'CHOPP BOHEMIA 14 WEISS BARRIL KEG 50L',
      },
      {
        sku: '23053',
        productName: 'CADEIRA PLASTICA SKOL 1 UN DE CADEIRA',
      },
      {
        sku: '838',
        productName: 'CHOPP BRAHMA CLARO BARRIL KEG 50L',
      },
      {
        sku: '15345',
        productName: 'CHOPP COLORADO CAUIM 016 BARRIL KEG 20L',
      },
      {
        sku: '24190',
        productName: 'PIRAQUE BISC. SALG. BATATA CEBOLA SALSA PCT 60G CX C/20',
      },
      {
        sku: '21020',
        productName: 'BUDWEISER LT SLEEK 350ML CX CART C 12',
      },
      {
        sku: '22032',
        productName: 'SOMM VINHO BRANCO LT 269 ML CX C/4',
      },
      {
        sku: '13065',
        productName: 'H2OH LIMONETO PET 1,5 SHRINK C/06 NPAL',
      },
      {
        sku: '5592',
        productName: 'AGUA MIN FONTE DA ILHA S/GAS PET 1,5L CAIXA C/6',
      },
      {
        sku: '16633',
        productName: 'DO BEM CAJU TETRAPAK 1L CX12',
      },
      {
        sku: '22114',
        productName: 'GCA+SODA PET 2L PACK C/2 MULTPACK',
      },
      {
        sku: '19823',
        productName: 'CHOPP COLORADO LAGER BARRIL KEG 10L N PAL',
      },
      {
        sku: '5317',
        productName: 'AGUA MINERAL INDAIA S/GAS PET 500ML CAIXA C/12',
      },
      {
        sku: '24371',
        productName: 'ELMA CHIPS AMENDOIM OVINHO PCT 255G CX/19',
      },
      {
        sku: '10627',
        productName: 'AGUA M S LOURENCO C/GAS PET 300 ML CAIXA C/ 12',
      },
      {
        sku: '24031',
        productName: 'ORIGINAL OW 300ML CX C/24 + GARRAFEIRA',
      },
      {
        sku: '22912',
        productName: 'PIRAQUE PARAFUSO ALDENTE PCT 500G CX C/16',
      },
      {
        sku: '15414',
        productName: 'AGUA M F DA ILHA S/G PET 500ML CX C-12 PREMIUM',
      },
      {
        sku: '22854',
        productName: 'PIRAQUE CREAM CRACKER PCT 200G CX C/40',
      },
      {
        sku: '22025',
        productName: 'SOMM VINHO FRISANTE LT 269 ML CX C/4',
      },
      {
        sku: '21739',
        productName: 'SKOL BEATS ZODIAC TERRA LT 269ML CX CARTAO C/8 NPAL',
      },
      {
        sku: '22634',
        productName: 'MIKES HARD SELTZER PESSEGO N LT 269ML CX C 6',
      },
      {
        sku: '24604',
        productName: 'MINALBA AGUA PREMIUM C/GAS GFA VDR 300ML CX/12',
      },
      {
        sku: '22198',
        productName: 'SELVAGEM ACAI GARRAFA PET 1L',
      },
      {
        sku: '23269',
        productName: 'SKOL BEATS GT LONG NECK 269ML SIX-PACK SH C/4',
      },
      {
        sku: '23498',
        productName: 'ACAICAMP COPO 285ML CX C/36',
      },
      {
        sku: '17636',
        productName: 'SKOL BEATS FROST LT 269ML CX C/8 FRIDGE PACK',
      },
      {
        sku: '23325',
        productName: 'CAMIL ARROZ CARRET. BR. T1 PCT 1KG FD C/10',
      },
      {
        sku: '11428',
        productName: 'AGUA MINERAL NAT C/GAS REGINA PET 500ML CAIXA C/12',
      },
      {
        sku: '23450',
        productName: 'MIKES HARD LEMONADE PITAIA N LT 269ML SH C12 NP',
      },
      {
        sku: '23194',
        productName: 'SKOL BEATS MOJITO LT 269ML CX CARTAO C/8 NPAL',
      },
      {
        sku: '8383',
        productName: 'PEPSI BLUE PET 591ML CX12 SHRINK LISO',
      },
      {
        sku: '24479',
        productName: 'BOHEMIA LONG NECK 330ML SIX-PACK SHRINK C/4',
      },
      {
        sku: '22086',
        productName: 'CADEIRA PLASTICA BRAHMA CHOPP 1 UN DE CADEIRA',
      },
      {
        sku: '17315',
        productName: 'AGUA MIN LEBRINHA PREMIUM C/GAS PET 350 ML CAIXA C/ 12',
      },
      {
        sku: '15329',
        productName: 'CHOPP COLORADO APPIA BARRIL KEG 10L NPAL',
      },
      {
        sku: '22489',
        productName: 'QUERO EXTR. TOM. PCT 1080G .',
      },
      {
        sku: '4198',
        productName: 'PATAGONIA IPA LT SLEEK 350ML C 8 CX CARTAO',
      },
      {
        sku: '21116',
        productName: 'SKOL BEATS GT LONG NECK 313ML SIX-PACK SH C/4',
      },
      {
        sku: '4117',
        productName: 'STELLA ARTOIS LATA 410ML CX C/15 MULTPACK 15',
      },
      {
        sku: '15895',
        productName: 'CHOPP WALS WITTE BARRIL KEG 30L NPAL',
      },
      {
        sku: '23066',
        productName: 'ISLA HARD SELTZER LIMAO E GENGIBRE LT 269ML CX C 6',
      },
      {
        sku: '828',
        productName: 'CHOPP BRAHMA CLARO BARRIL KEG 30L',
      },
      {
        sku: '23052',
        productName: 'MESA PLASTICA SKOL 1 UN DE MESA',
      },
      {
        sku: '15332',
        productName: 'CHOPP COLORADO VIXNU BARRIL KEG 10L NPAL',
      },
      {
        sku: '23060',
        productName: 'PIRACANJUBA UHT SEMIDESN CX 1L CX C/12',
      },
      {
        sku: '23196',
        productName: 'TRINK REFRESCO LARANJA PCT 25G DP C/15',
      },
      {
        sku: '23058',
        productName: 'PIRACANJUBA DESNATADO CX 1L CX C/12',
      },
      {
        sku: '22116',
        productName: 'GCA+PEPSI PET 2L PACK C/2 MULTPACK',
      },
      {
        sku: '23518',
        productName: 'GUARANA ANTARCTICA CAJA LATA 350ML SH C/12',
      },
      {
        sku: '18508',
        productName: 'CHOPP GOOSE ISLAND MIDWAY NAC BARRIL KEG 30L NPAL',
      },
      {
        sku: '19563',
        productName: 'CHOPP COLORADO BRASIL IPACOCA BARRIL KEG 30L NPAL',
      },
      {
        sku: '7982',
        productName: 'GATORADE LIMÃO PET 500ML SIXPACK',
      },
      {
        sku: '19619',
        productName: 'CHOPP KONA BIG WAVE NAC BARRIL KEG 30L NPAL',
      },
      {
        sku: '22494',
        productName: 'QUERO MILHO VERDE LT 2000G',
      },
      {
        sku: '7481',
        productName: 'LIPTON PESSEGO PET 1,5L CAIXA C/6',
      },
      {
        sku: '15328',
        productName: 'CHOPP COLORADO CAUIM BARRIL KEG 10L NPAL',
      },
      {
        sku: '23504',
        productName: 'BUDWEISER BARRIL 5L CX C/2 NPAL NOVA TECNOLOGIA',
      },
      {
        sku: '13873',
        productName: 'BOHEMIA CAA YARI BARRIL KEG 50L CERVEJA',
      },
      {
        sku: '22470',
        productName: 'HEINZ KETCHUP PCT FP 397G',
      },
      {
        sku: '21615',
        productName: 'LOHN BIER HOP LAGER ONE WAY 600ML CX C/12 NPAL ARTE',
      },
      {
        sku: '12948',
        productName: 'BRAHMA CHOPP ZERO LATA 350ML SH C/12 NPAL',
      },
      {
        sku: '21896',
        productName: 'NOVECENTO VINHO ARG BRANCO CHARDONNAY GFA OW 750ML CX C/6',
      },
      {
        sku: '15331',
        productName: 'CHOPP COLORADO DEMOISELLE BARRIL KEG 10L NPAL',
      },
      {
        sku: '25460',
        productName: 'DONA BENTA MASSA PARAFUSO OVO PCT 500G CX C/24',
      },
      {
        sku: '17002',
        productName: 'AGUA VIENA MINERAL COM GAS PET 510ML CAIXA C/12',
      },
      {
        sku: '22599',
        productName: 'BUDWEISER NITRO BARRIL KEG 30L NPAL',
      },
      {
        sku: '8413',
        productName: 'GUARANA CHP ANTARCTICA DIET PET 1,5 SHRINK C/6',
      },
      {
        sku: '8411',
        productName: 'GUARANA CHP ANTARCTICA PET 1,5 SHRINK C/6',
      },
      {
        sku: '22560',
        productName: 'HOEGAARDEN ZERO LATA 330ML SIXPACK C/4',
      },
      {
        sku: '21928',
        productName: 'ESMERA DE GOIAS 600ML',
      },
      {
        sku: '15408',
        productName: 'CHOPP COLORADO DEMOISELLE BARRIL KEG 20L',
      },
      {
        sku: '22142',
        productName: 'AGUA MIN MAR DOCE PREMIUM C/GAS PET 510ML CAIXA C/12',
      },
      {
        sku: '23731',
        productName: 'GATORADE MELANCIA-MORANGO PET 500ML SIXPACK',
      },
      {
        sku: '23224',
        productName: 'GUARAVITON GINSENG ZERO GFA PET 500ML PACK C/12',
      },
      {
        sku: '23653',
        productName: 'BUDWEISER GARRAFA ALUMINIO 330ML CX C/24',
      },
      {
        sku: '17324',
        productName: 'CHOPP PATAGONIA BOHEMIAN PILSENER BARRIL KEG 30L NPAL',
      },
      {
        sku: '17004',
        productName: 'AGUA VIENA MINERAL SEM GAS PET 1,25L CAIXA C/6',
      },
      {
        sku: '15247',
        productName: 'SKOL BEATS SENSES LT 269ML SH C15 NPAL',
      },
      {
        sku: '23070',
        productName: 'ISLA HARD SELTZER PESSEGO E MARACUJA LT 269ML CX C 6',
      },
      {
        sku: '12935',
        productName: 'AGUA M S LOURENCO C/GAS PET 1,26L CAIXA C/6',
      },
      {
        sku: '22539',
        productName: 'BUDWEISER BARRIL 5L CX C/2 NPAL',
      },
      {
        sku: '24119',
        productName: 'DAFRUTA NECTAR UVA TETRA PAK 200 ML CX C/27',
      },
      {
        sku: '19164',
        productName: 'GUARANA CHP ANTARCTICA PET 1L PACK C/2 MULTPACK',
      },
      {
        sku: '22644',
        productName: 'BORGES AZEITE EXTRA VIRGEM GFA VD 500ML CX C/12',
      },
      {
        sku: '23516',
        productName: 'GUARANA ANTARCTICA JABUTICABA LATA 350ML SH C/12',
      },
      {
        sku: '21767',
        productName: 'BRAHMA DUPLO MALTE LATA 310ML SH C 15 MULTPACK',
      },
      {
        sku: '13566',
        productName: 'SKOL BEATS SENSES LT 269ML CX C/8 FRIDGE PACK',
      },
      {
        sku: '23313',
        productName: 'LOHN BIER CARVOEIRA LN 355ML CX C12 NPA',
      },
      {
        sku: '14111',
        productName: 'WALS BOHEMIA PILSEN ONE WAY 600ML CX C/12 ARTE',
      },
      {
        sku: '18305',
        productName: 'HOEGAARDEN WHITE BARRIL KEG 50L NPA IMPORTACAO DIRETA',
      },
      {
        sku: '7325',
        productName: 'PEPSI COLA PET 1L CAIXA C/12',
      },
      {
        sku: '24294',
        productName: 'GOOSE ISLAND LO IPA LN 355ML CX C12 NP',
      },
      {
        sku: '23485',
        productName: 'UNIAO ACUCAR REFINADO PCT 1KG CX C/10',
      },
      {
        sku: '10746',
        productName: 'GUARANA CHP ANTARCTICA ONE WAY 1L GRFE CX C/12',
      },
      {
        sku: '15320',
        productName: 'CHOPP COLORADO CAUIM BARRIL KEG 30L NPAL',
      },
      {
        sku: '23308',
        productName: 'POP ARROZ BRANCO T1 PCT 1KG FD C/10',
      },
      {
        sku: '15972',
        productName: 'DO BEM COCO TETRAPAK 1L CX12',
      },
      {
        sku: '5944',
        productName: 'AGUA MIN FONTE DA ILHA S/GAS GARRAFÃO PET 5 LITROS CX C/04',
      },
      {
        sku: '23281',
        productName: 'CAMIL ARROZ BR T1 PCT 1KG FD C/10',
      },
      {
        sku: '11643',
        productName: 'BUDWEISER LATA 710ML 3 PACK',
      },
      {
        sku: '15322',
        productName: 'CHOPP COLORADO INDICA BARRIL KEG 30L NPAL',
      },
      {
        sku: '19580',
        productName: 'CHOPP COLORADO JOAO ROCK BARRIL KEG 30L NPAL',
      },
      {
        sku: '21611',
        productName: 'LOHN BIER PILSEN PURO MALTE ONE WAY 600ML CX C/12 NPAL ARTE',
      },
      {
        sku: '15406',
        productName: 'CHOPP COLORADO APPIA BARRIL KEG 20L',
      },
      {
        sku: '23083',
        productName: 'MIKES HARD SELTZER MIX LT 269ML SH C/6 MULTPACK',
      },
      {
        sku: '23510',
        productName: 'GUARANA ANTARCTICA CUPUACU LATA 350ML SH C/12',
      },
      {
        sku: '23413',
        productName: 'SKOL BEATS GT+MINT+GING LT 269ML CX C/ 6',
      },
      {
        sku: '21833',
        productName: 'LOHN BIER CATHARINA SOUR UVA GOETH LN 355ML CX C12 NPA',
      },
      {
        sku: '15330',
        productName: 'CHOPP COLORADO INDICA BARRIL KEG 10L NPAL',
      },
      {
        sku: '15416',
        productName: 'AGUA M F DA ILHA C/G PET 500ML CX C-12 PREMIUM',
      },
      {
        sku: '13572',
        productName: 'SKOL BEATS SENSES LONG NECK 313ML SIX-PACK SH C/4',
      },
      {
        sku: '23254',
        productName: 'QUATA LEITE CONDENSADO TETRAPAK 395G CX C/27',
      },
      {
        sku: '7472',
        productName: 'LIPTON LIMAO PET 1,5L CAIXA C/6',
      },
      {
        sku: '15132',
        productName: 'SKOL BEATS SECRET LT 269ML CX C/8 FRIDGE PACK',
      },
      {
        sku: '22050',
        productName: 'SALIBOA GARRAFA VD 600ML',
      },
      {
        sku: '22557',
        productName: 'CHOPP COLORADO ORGANICA BARRIL KEG 30L NPAL',
      },
      {
        sku: '17261',
        productName: 'CHOPP COLORADO NANO BARRIL KEG 30L NPAL',
      },
      {
        sku: '18117',
        productName: 'CHOPP GOOSE ISLAND NAC BARRIL KEG 30L NPAL',
      },
      {
        sku: '21586',
        productName: 'LEITE UHT ZERO LACTOSE SANTA CLARA TETRAPAK 1 L CX C/12',
      },
      {
        sku: '23287',
        productName: 'GOOSE ISLAND MIDWAY NAC BIB 6L NPAL',
      },
      {
        sku: '15840',
        productName: 'CHOPP WALS CRISTAL TIPO PILSEN BARRIL KEG 30L NPAL',
      },
      {
        sku: '22903',
        productName: 'CHOPP BRAHMA CLARO BIB 12L',
      },
      {
        sku: '13486',
        productName: 'FUSION PET 1L SH C/06',
      },
      {
        sku: '24357',
        productName: 'DO BEMZINHO MORANGO TETRAPAK TBA 200ML SLIM L.S. 5 CX18',
      },
      {
        sku: '1684',
        productName: 'SKOL ONE WAY 1L CXPAPC/12',
      },
      {
        sku: '18879',
        productName: 'AGUA MIN PEDRA AZUL S/GAS PET 300 ML CAIXA C/ 24',
      },
      {
        sku: '18807',
        productName: 'STELLA ARTOIS LONG NECK 330ML SIX-PACK SHRINK C/4',
      },
      {
        sku: '22616',
        productName: 'POLAR EXPORT ONE WAY 1L GRFE CX C/12',
      },
      {
        sku: '21823',
        productName: 'CHOPP FARRA ALL IN BARRIL KEG 30L NPAL',
      },
      {
        sku: '18817',
        productName: 'MAGNIFICA GFA VD 1L',
      },
      {
        sku: '21792',
        productName: 'WHITE HORSE GARRAFA VIDRO 1 L',
      },
      {
        sku: '8506',
        productName: 'AGUA MIN PEDRA AZUL C/GAS PET 1,5L CAIXA C/6',
      },
      {
        sku: '15166',
        productName: 'FUSION LIMAO E HORTELA LT 310ML CX C/8 FRIDGE PACK',
      },
      {
        sku: '11593',
        productName: 'PEPSI COLA GFA VD 1L',
      },
      {
        sku: '22490',
        productName: 'QUERO MOLHO TRAD. PCT 1020G .',
      },
      {
        sku: '21539',
        productName: 'JIM BEAM WHITE GARRAFA VIDRO 1 L',
      },
      {
        sku: '16432',
        productName: 'COLORADO NASSAU ONE WAY 600ML CX C-12 ARTE',
      },
      {
        sku: '17325',
        productName: 'CHOPP PATAGONIA BOHEMIAN PILSENER BARRIL KEG 50L NPAL',
      },
      {
        sku: '18267',
        productName: 'SODA LIMONADA ANTARCTICA PET 200ML SH C/12',
      },
      {
        sku: '21522',
        productName: 'JOTA PE VINHO TINTO SECO GARRAFA VIDRO 1,5L',
      },
      {
        sku: '22675',
        productName: 'BALALAIKA GFA VD 1 L CX C/6',
      },
      {
        sku: '24362',
        productName: 'ELMA CHIPS CHEETOS LUA PCT 125G CX C/12',
      },
      {
        sku: '17278',
        productName: 'PATAGONIA WEISSE NACIONAL LN 355ML CX C/12',
      },
      {
        sku: '10633',
        productName: 'AGUA MIN PUREZA VITAL S/GAS PET 510ML CAIXA C/12',
      },
      {
        sku: '15872',
        productName: 'CHOPP WALS PALE ALE BARRIL KEG 30L NPAL',
      },
      {
        sku: '10720',
        productName: 'GATORADE TANGERINA PET 1L SH C/06',
      },
      {
        sku: '18242',
        productName: 'PATAGONIA AMB LAG NACIONAL LATA 473ML TWELVE PACK SH',
      },
      {
        sku: '18673',
        productName: 'SKOL PURO MALTE GFA VD 300ML CX C/23',
      },
      {
        sku: '21425',
        productName: 'ANTARCTICA PILSEN 600ML CO',
      },
      {
        sku: '15336',
        productName: 'CHOPP COLORADO INDICA BARRIL KEG 50L NPAL',
      },
      {
        sku: '23091',
        productName: 'LOHN BIER PILSEN PURO MALTE LN 355ML CX C12 NPA',
      },
      {
        sku: '21976',
        productName: 'TRIDENT SPEAKMINT ENVELOPE 30.6G CX C/12',
      },
      {
        sku: '23304',
        productName: 'NAMORADO ARROZ PARB. T1 PCT 1KG FD C/10',
      },
      {
        sku: '23186',
        productName: 'SPATEN N 600ML',
      },
      {
        sku: '22543',
        productName: 'ROCKS STRAMBERRY GARRAFA VIDRO 1 L',
      },
      {
        sku: '22630',
        productName: 'MIKES HARD SELTZER LIMAO N LT 269ML CX C 6',
      },
      {
        sku: '2243',
        productName: 'GUARANA CHP ANTARCTICA MIX BAG IN BOX 18L',
      },
      {
        sku: '11098',
        productName: 'AGUA MINERAL ACACIA S/GAS PET 1,5L CAIXA C/6',
      },
      {
        sku: '15334',
        productName: 'CHOPP COLORADO CAUIM BARRIL KEG 50L NPAL',
      },
      {
        sku: '22132',
        productName: 'AGUA MIN MAR DOCE S/GAS PET 330 ML CAIXA C/ 12',
      },
      {
        sku: '13015',
        productName: 'AGUA M F DA ILHA S/G PET 300 ML CAIXA C/ 12',
      },
      {
        sku: '15123',
        productName: 'STELLA ARTOIS LT 310ML CX C/8 FRIDGE PACK',
      },
      {
        sku: '20217',
        productName: 'ORIGINAL GFA VD 300ML CX C/23',
      },
      {
        sku: '15355',
        productName: 'BOHEMIA 838 PALE ALE BARRIL KEG 50L CERV',
      },
      {
        sku: '15335',
        productName: 'CHOPP COLORADO APPIA BARRIL KEG 50L NPAL',
      },
      {
        sku: '12455',
        productName: 'AGUA MIN AGUAS DO PORTO  S/GAS PET 510ML CAIXA C/12',
      },
      {
        sku: '23068',
        productName: 'ISLA HARD SELTZER LICHIA E ROMA LT 269ML CX C 6',
      },
      {
        sku: '18245',
        productName: 'NOSSA PERNAMBUCO GFA VD 300ML CX C/23',
      },
      {
        sku: '13017',
        productName: 'AGUA M F DA ILHA C/G PET 300 ML CAIXA C/ 12',
      },
      {
        sku: '10207',
        productName: 'AGUA MIN NAT PUREZA VITAL S/ GAS PET 510ML CAIXA C/12',
      },
      {
        sku: '11191',
        productName: 'LIPTON PESSEGO PET 1,5 SHRINK C/6 HF',
      },
      {
        sku: '21541',
        productName: 'LARIOS ORIGINAL GARRAFA VIDRO 700ML',
      },
      {
        sku: '21533',
        productName: 'BLACK STONE GARRAFA VIDRO 1 L',
      },
      {
        sku: '15323',
        productName: 'CHOPP COLORADO DEMOISELLE BARRIL KEG 30L NPAL',
      },
      {
        sku: '5526',
        productName: 'AGUA MIN GOYA C/GAS PET 500ML CAIXA C/12',
      },
      {
        sku: '21956',
        productName: 'BALLANTINES 12 ANOS GARRAFA VIDRO 1 L',
      },
      {
        sku: '23441',
        productName: 'SALINAS CRISTALINA CACHACA GARRAFA VIDRO 1 L',
      },
      {
        sku: '5600',
        productName: 'AGUA MIN FONTE DA ILHAC/GAS PVC 500ML CAIXA C/12.',
      },
      {
        sku: '22215',
        productName: 'DRURYS GARRAFA VIDRO 1 L',
      },
      {
        sku: '19227',
        productName: 'RED BULL BR LATA 355ML FOUR PACK .',
      },
      {
        sku: '20248',
        productName: 'MIKES HARD LEMONADE N LONG NECK 275ML CX C/6',
      },
      {
        sku: '1695',
        productName: 'BRAHMA CHOPP GFA VD 1L COM TTC',
      },
      {
        sku: '21523',
        productName: 'JOTA PE VINHO TINTO SUAVE GARRAFA VIDRO 1,5L',
      },
      {
        sku: '23396',
        productName: 'ITALAC UHT SEMIDESNATADO CX 1L CX C/12',
      },
      {
        sku: '15056',
        productName: 'ANTARCTICA SUBZERO GFA VD 300ML CX C/23',
      },
      {
        sku: '23463',
        productName: 'ÁGUA MINERAL S/ GÁS AMA GFA PET 500ML PACK C/12 NOVO SHAPE',
      },
      {
        sku: '22878',
        productName: 'PIRAQUE SALGADINHO PCT 100G CX C/20',
      },
      {
        sku: '13205',
        productName: 'SKOL GFA VD 300ML CX C/23',
      },
      {
        sku: '21839',
        productName: 'LOHN BIER CATHARINA SOUR CAFE FRAMBOESA LN 355ML CX C12 NPA',
      },
      {
        sku: '20278',
        productName: 'COLORADO BRASIL COM S NOVEMBRO ONE WAY 600ML CX C-12 ARTE',
      },
      {
        sku: '21424',
        productName: 'ANTARCTICA PILSEN GFA VD 1L SE',
      },
      {
        sku: '11103',
        productName: 'H2OH CITRUS C/GAS PET 1,5 SHRINK C/06 NPAL',
      },
      {
        sku: '22055',
        productName: 'SOMM VINHO ROSE LT 269 ML CX C/4',
      },
      {
        sku: '20885',
        productName: 'FLAMINGO LAGER ONE WAY 600ML CX 12 NPAL',
      },
      {
        sku: '22034',
        productName: 'SOMM VINHO TINTO LT 269 ML CX C/4',
      },
      {
        sku: '12457',
        productName: 'AGUA MIN AGUAS DO PORTO C/GAS PET 510ML CAIXA C/12',
      },
      {
        sku: '22335',
        productName: 'PRATINHA BIRUDO LN 355ML CX C/12',
      },
      {
        sku: '7703',
        productName: 'PEPSI COLA PET 1L CAIXA C/6',
      },
      {
        sku: '5753',
        productName: 'PEPSI COLA PET 2,25L CAIXA C/6',
      },
      {
        sku: '7954',
        productName: 'AGUA MIN GOYA S/GAS PET 500ML CAIXA C/12',
      },
      {
        sku: '22202',
        productName: 'TONICA ANTARCTICA ZERO PET 1L SH C/06',
      },
      {
        sku: '25418',
        productName: 'ELEFANTE EXTRATO DE TOMATE LT 130G CX C48',
      },
      {
        sku: '13201',
        productName: 'BRAHMA CHOPP GFA VD 300ML CX C/23',
      },
      {
        sku: '21529',
        productName: 'ABSOLUT ORIGINAL GARRAFA VIDRO 1 L',
      },
      {
        sku: '15405',
        productName: 'CHOPP COLORADO CAUIM BARRIL KEG 20L',
      },
      {
        sku: '5509',
        productName: 'AGUA MIN LEBRINHA S/GAS PET 500ML CAIXA C/12',
      },
      {
        sku: '15411',
        productName: 'CHOPP COLORADO VIXNU BARRIL KEG 20L',
      },
      {
        sku: '23108',
        productName: 'PARATI ROSCA CHOCOLATE RADCAU PCT 335G CX C/12',
      },
      {
        sku: '16722',
        productName: 'DO BEM MANGA TETRAPAK 200ML CX18',
      },
      {
        sku: '15868',
        productName: 'CHOPP WALS HOP CORN BARRIL KEG 30L NPAL',
      },
      {
        sku: '371',
        productName: 'MALZBIER BRAHMA LONG NECK 355ML SIX-PACK BANDEJA C/4',
      },
      {
        sku: '23487',
        productName: 'GUARANI ACUCAR REFINADO AMORFO PCT 1KG FD C/10',
      },
      {
        sku: '21389',
        productName: 'SKOL BEATS SENSES LT 269ML SH C15 NPAL PROMO PACK',
      },
      {
        sku: '14099',
        productName: 'BUDWEISER ONE WAY 600ML CX C/12 NPAL',
      },
      {
        sku: '5511',
        productName: 'AGUA MIN LEBRINHA S/GAS PET 1,5L CAIXA C/6',
      },
      {
        sku: '23297',
        productName: 'GUARANA CHP ANTARCTICA LATA 310ML SH C 15 NPAL',
      },
      {
        sku: '22130',
        productName: 'AGUA MIN MAR DOCE S/GAS COPO 200ML CAIXA C/48',
      },
      {
        sku: '5439',
        productName: 'AGUA MINERAL INGÁ C/GÁS PET 500ML CAIXA C/12',
      },
      {
        sku: '2419',
        productName: 'CHOPP ANTARCTICA BARRIL KEG 50L',
      },
      {
        sku: '12459',
        productName: 'AGUA MIN AGUAS DO PORTO  S/GAS PET 1,5L CAIXA C/6',
      },
      {
        sku: '22180',
        productName: 'BUDWEISER ZERO LONG NECK 330ML SIX-PACK SHRINK C/4',
      },
      {
        sku: '22789',
        productName: 'BOHEMIA LATA 310ML SH C 15 NPAL MULTPACK 15',
      },
      {
        sku: '18453',
        productName: 'WALS TIRADENTES ONE WAY 600ML CX 12 NPAL',
      },
      {
        sku: '21391',
        productName: 'ÁGUA MINERAL S/ GÁS AMA PET 1,5L CAIXA C/6 NPAL',
      },
      {
        sku: '12735',
        productName: 'BOHEMIA IMPERIAL BARRIL KEG 50L',
      },
      {
        sku: '10631',
        productName: 'AGUA MIN PUREZA VITAL C/GAS PET 510ML CAIXA C/12',
      },
      {
        sku: '17262',
        productName: 'CHOPP COLORADO NANO BARRIL KEG 50L NPAL',
      },
      {
        sku: '15970',
        productName: 'DO BEM COCO TETRAPAK 200ML CX18',
      },
      {
        sku: '17283',
        productName: 'WALS SESSION HAZE ONE WAY 600ML CX C/12 ARTE',
      },
      {
        sku: '14097',
        productName: 'STELLA ARTOIS BIB 12L',
      },
      {
        sku: '18498',
        productName: 'AGUA MIN NATURAGUA S/GAS PET 500ML CX12NPAL',
      },
      {
        sku: '22500',
        productName: 'VITORIA REGIA GIN ROSE GARRAFA VIDRO 750ML',
      },
      {
        sku: '22134',
        productName: 'AGUA MIN MAR DOCE S/GAS PET 1,5L CAIXA C/6',
      },
      {
        sku: '18118',
        productName: 'CHOPP GOOSE ISLAND NAC BARRIL KEG 50L NPAL',
      },
      {
        sku: '2940',
        productName: 'SODA LIMONADA ANTARCTICA PET 2L SHRINK C/8',
      },
      {
        sku: '21526',
        productName: 'JOHNNIE WALKER RED LABEL GARRAFA VIDRO 1 L',
      },
      {
        sku: '18497',
        productName: 'AGUA MIN NATURAGUA C/GAS PET 500ML SH C/12 NPAL',
      },
      {
        sku: '17757',
        productName: 'BECKS N LONG NECK 330ML SIX-PACK SHRINK C/4',
      },
      {
        sku: '18495',
        productName: 'AGUA MIN NATURAGUA S/GAS PET 1,5 SHRINK C/06 NPAL',
      },
      {
        sku: '20293',
        productName: 'FUSION CITRUS LATA 250ML SIX-PACK',
      },
      {
        sku: '21894',
        productName: 'NOVECENTO VINHO ARG CABERNET SAUVIGNON GFA OW 750ML CX C/6',
      },
      {
        sku: '17276',
        productName: 'PATAGONIA AMB LAG NACIONAL LN 355ML CX C/12',
      },
      {
        sku: '17163',
        productName: 'BARE GUARANA PET 1L CAIXA C/6',
      },
      {
        sku: '21737',
        productName: 'SKOL BEATS ZODIAC FOGO LT 269ML CX CARTAO C/8 NPAL',
      },
      {
        sku: '23248',
        productName: 'PARMALAT LEITE CONDENSADO TETRAPAK 395G CX C/24',
      },
      {
        sku: '21892',
        productName: 'NOVECENTO VINHO ARG MALBEC GFA OW 750ML CX C/6',
      },
      {
        sku: '23440',
        productName: 'SALINAS TRAD. CACHACA GARRAFA VIDRO 1 L',
      },
      {
        sku: '19733',
        productName: 'COLORADO TROPICANA ONE WAY 600ML CX C-12 ARTE',
      },
      {
        sku: '21213',
        productName: 'COLORADO BRASIL COM S JUNHO ONE WAY 600ML CX C-12 ARTE',
      },
      {
        sku: '2006',
        productName: 'ANTARCTICA SUBZERO 600ML',
      },
      {
        sku: '18836',
        productName: 'CORONA EXTRA N LONG NECK 330ML CX C/24 NPAL',
      },
      {
        sku: '20530',
        productName: 'STELLA ARTOIS 600 ML',
      },
      {
        sku: '14138',
        productName: 'SKOL BEATS SENSES LATA 473ML SIX-PACK SH C/2 NPAL',
      },
      {
        sku: '20295',
        productName: 'FUSION TROPICAL LATA 250ML SIX-PACK',
      },
      {
        sku: '20879',
        productName: 'BRAHMA EXTRA PIRAJA BARRIL KEG 50L NPAL',
      },
      {
        sku: '13213',
        productName: 'PEPSI COLA ONE WAY 1L GRFE CX C/12',
      },
      {
        sku: '21010',
        productName: 'FUSION GUARANA LATA 250ML CX CARTAO C/8',
      },
      {
        sku: '2349',
        productName: 'GUARANA CHP ANTARCTICA PET 2L CAIXA C/6',
      },
      {
        sku: '22129',
        productName: 'AGUA MIN MAR DOCE S/GAS COPO 200ML',
      },
      {
        sku: '10765',
        productName: 'AGUA MIN LEV S/ GAS PET 1500 CX06',
      },
      {
        sku: '21113',
        productName: 'STELLA ARTOIS SEM GLUTEN LONG NECK 330ML SIX-PACK SHRINK C/4',
      },
      {
        sku: '24168',
        productName: 'MICHELOB ULTRA N LONG NECK 330ML SIX-PACK SHRINK C/4',
      },
      {
        sku: '20535',
        productName: 'STELLA ARTOIS ONE WAY 600ML CX C/12 NPAL',
      },
      {
        sku: '14096',
        productName: 'GOOSE ISLAND SOFIE LONG NECK 355ML SIX PACK C/4',
      },
      {
        sku: '22563',
        productName: 'CHIVAS REGAL 12 ANOS GARRAFA VIDRO 750ML',
      },
      {
        sku: '16998',
        productName: 'AGUA VIENA MINERAL COM GAS PET 330 ML CAIXA C/ 12',
      },
      {
        sku: '22902',
        productName: 'RELEVOS VINHO TINTO SUAVE GFA OW 750ML CX C/6 NP',
      },
      {
        sku: '23099',
        productName: 'CHEEZ-IT SNACK CHEDDAR PCT 29G CX C/20',
      },
      {
        sku: '20647',
        productName: 'BRAHMA DUPLO MALTE LT 269ML SH C15 NPAL',
      },
      {
        sku: '22051',
        productName: 'BOAZINHA GARRAFA VD 600ML',
      },
      {
        sku: '11105',
        productName: 'H2OH LARANJA C/GAS PET 500ML SHRINK C/12 NPAL',
      },
      {
        sku: '23184',
        productName: 'PITU AGUARDENTE LT 350ML CX C/12',
      },
      {
        sku: '18475',
        productName: 'GUARANA CHP ANTARCTICA LATA 350ML SH C/18 NPAL',
      },
      {
        sku: '1388',
        productName: 'SKOL GFA VD 1L 2,99',
      },
      {
        sku: '21086',
        productName: 'WALS SESSION FREE LONG NECK 355ML SIX-PACK CAIXA C/4',
      },
      {
        sku: '22813',
        productName: 'BUDWEISER LATA 310ML CX C/6 MULTPACK',
      },
      {
        sku: '22485',
        productName: 'QUERO MOSTARDA SACHE 7G CX C/176',
      },
      {
        sku: '10818',
        productName: 'AGUA MIN PEDRA AZUL S/GAS GARRAFÃO PET 5 LITROS CX C/02',
      },
      {
        sku: '21009',
        productName: 'FUSION TROPICAL LATA 250ML CX CARTAO C/8',
      },
      {
        sku: '5513',
        productName: 'AGUA MIN LEBRINHA C/GAS PET 500ML CAIXA C/12',
      },
      {
        sku: '1694',
        productName: 'BRAHMA CHOPP GFA VD 1L',
      },
      {
        sku: '22783',
        productName: 'BUDWEISER LATA 310ML CX CARTAO C/15 MULTPACK 15',
      },
      {
        sku: '19799',
        productName: 'WALS LAGOINHA ONE WAY 600ML CX C/12 ARTE',
      },
      {
        sku: '5598',
        productName: 'AGUA MIN FONTE DA ILHA S/GAS PVC 500ML CAIXA C/12.',
      },
      {
        sku: '18135',
        productName: 'SERRAMALTE LATA 350ML SIX-PACK SH C/2 NPAL',
      },
      {
        sku: '12791',
        productName: 'AGUA MIN LEV C/ GAS PET 500ML CAIXA C/12',
      },
      {
        sku: '5528',
        productName: 'AGUA MIN GOYA S/GAS PET 1,5L CAIXA C/6',
      },
      {
        sku: '19229',
        productName: 'RED BULL BR LATA 250ML SIX PACK NPAL .',
      },
      {
        sku: '17434',
        productName: 'CUCAPA HOPULIN COLORADO LN 355ML SIXPACK SH C/4',
      },
      {
        sku: '20883',
        productName: 'FLAMINGO WIT ONE WAY 300ML CX C/12 ARTE',
      },
      {
        sku: '21880',
        productName: 'GUARANA CHP ANTARCTICA PET 1L PACK C/2 MULTPACK NENO',
      },
      {
        sku: '15952',
        productName: 'COLORADO EUGENIA ONE WAY 600ML CX 12 NPAL',
      },
      {
        sku: '21788',
        productName: 'BALLANTINES FINEST GARRAFA VIDRO 1 L',
      },
      {
        sku: '19747',
        productName: 'CHOPP INOV ALE BARRIL KEG 30L NPAL',
      },
      {
        sku: '22229',
        productName: 'WALS ABACAXI TRIANGULO ONE WAY 600ML CX C/12 ARTE',
      },
      {
        sku: '22211',
        productName: 'GUARAVITON NAT GINSENG GFA PET 500ML PACK C/12',
      },
      {
        sku: '22621',
        productName: 'SUKITA GUARANA PET 2L CAIXA C/8',
      },
      {
        sku: '14711',
        productName: 'PATAGONIA AMBER LAGER LN 355 CX C/12 EXPORTACAO',
      },
      {
        sku: '2353',
        productName: 'GUARANA CHP ANTARCTICA DIET PET 2L CAIXA C/6',
      },
      {
        sku: '23258',
        productName: 'PARMALAT CREME DE LEITE TETRAPAK 200G CX C/24',
      },
      {
        sku: '5943',
        productName: 'AGUA MIN FONTE DA ILHAC/GAS PET 2L CAIXA C/6 SHRINK DECORADO',
      },
      {
        sku: '2592',
        productName: 'SODA LIMONADA ANTARCTICA 290ML',
      },
      {
        sku: '17822',
        productName: 'GOOSE HALIA ONE WAY 765ML C/6',
      },
      {
        sku: '23513',
        productName: 'GUARANA ANTARCTICA ACEROLA LATA 350ML SH C/12',
      },
      {
        sku: '22867',
        productName: 'PIRAQUE RECH. MORANGO PCT 160G CX C/20',
      },
      {
        sku: '2319',
        productName: 'GUARANA CHP ANTARCTICA PET 1L CAIXA C/12',
      },
      {
        sku: '22498',
        productName: 'GALIOTTO VINHO TINTO SUAVE GARRAFA VIDRO 1 L',
      },
      {
        sku: '14552',
        productName: 'COLORADO INDICA ONE WAY 600ML CX C-12 ARTE',
      },
      {
        sku: '14109',
        productName: 'WALS WITTE ONE WAY 600ML CX C/12 ARTE',
      },
      {
        sku: '16009',
        productName: 'DO BEM LARANJA TETRAPAK 200ML CX18',
      },
      {
        sku: '21443',
        productName: 'DO BEM UVA TETRAPAK 1,5L CX6 NPAL',
      },
      {
        sku: '18255',
        productName: 'NOSSA PERNAMBUCO 600ML',
      },
      {
        sku: '22779',
        productName: 'BRAHMA DUPLO MALTE LATA 410ML SH C/12 MULTPACK 12',
      },
      {
        sku: '19305',
        productName: 'TONICA ANTARCTICA ZERO LT 269ML SH C/15',
      },
      {
        sku: '13129',
        productName: 'BOHEMIA ROYALE BARRIL KEG 50L',
      },
      {
        sku: '21609',
        productName: 'ANDES N LA UNICA 600ML',
      },
      {
        sku: '21525',
        productName: 'ARBO VINHO MERLOT GARRAFA VIDRO 750ML',
      },
      {
        sku: '22227',
        productName: 'PRATINHA BIRUDO ONE WAY 600ML CX C/12 ARTE',
      },
      {
        sku: '22514',
        productName: 'BALLANTINES FINEST GARRAFA VIDRO 750ML',
      },
      {
        sku: '22619',
        productName: 'SUKITA LIMAO PET 2L CAIXA C/8',
      },
      {
        sku: '15960',
        productName: 'TRES FIDALGAS ONE WAY 600ML CX 12',
      },
      {
        sku: '22209',
        productName: 'GUARAVITA NAT COPO PLAST 290ML CX C/24',
      },
      {
        sku: '18823',
        productName: 'LEGITIMA GFA VD 300ML CX C/23',
      },
      {
        sku: '22069',
        productName: 'WALS MINASGERAIS ONE WAY 600ML CX C/12 ARTE',
      },
      {
        sku: '17000',
        productName: 'AGUA VIENA MINERAL SEM GAS PET 510ML CAIXA C/12',
      },
      {
        sku: '17166',
        productName: 'BARE POP GUARANA PET 2L CAIXA C/6',
      },
      {
        sku: '18710',
        productName: 'MAGNIFICA 600ML',
      },
      {
        sku: '22888',
        productName: 'PIRAQUE SALGADO PCT 138G CX C/36',
      },
      {
        sku: '21946',
        productName: 'ORIGINAL LT 269ML SH C15 NPAL',
      },
      {
        sku: '8507',
        productName: 'AGUA MIN PEDRA AZUL S/GAS PET 1,5L CAIXA C/6',
      },
      {
        sku: '23110',
        productName: 'PRINGLES BATATA ORIGINAL PCT 41G CX C/12',
      },
      {
        sku: '23192',
        productName: 'SKOL BEATS MOJITO LT 269ML SH C15 NPAL',
      },
      {
        sku: '13874',
        productName: 'BOHEMIA JABUTIPA BARRIL KEG 50L CERVEJA',
      },
      {
        sku: '22214',
        productName: 'H2OH LIMÃO C/GAS LT 269ML SH C15 NPAL',
      },
      {
        sku: '20549',
        productName: 'BRAHMA DUPLO MALTE GFA VD 300ML CX C/23',
      },
      {
        sku: '16946',
        productName: 'DO BEM UVA GFA_VD 1L CX C/6',
      },
      {
        sku: '22091',
        productName: 'MIKES HARD LEMONADE PITAIA N LT 269ML SH C12 NPAL',
      },
      {
        sku: '20162',
        productName: 'NOSSA PERNAMBUCO GFA VD 1L',
      },
      {
        sku: '1732',
        productName: 'H2OH LIMON PET 500ML CAIXA C/12',
      },
      {
        sku: '23232',
        productName: 'GUARAVITA NAT GFA PET 340 ML PAC C/12',
      },
      {
        sku: '21430',
        productName: 'VIVANT VINHO BRANCO LT 269 ML CX C/24',
      },
      {
        sku: '22900',
        productName: 'RELEVOS VINHO TINTO GFA OW 750ML CX C/6 NP',
      },
      {
        sku: '22863',
        productName: 'CAFE SUPLICY CAPSULA 42,0 ML',
      },
      {
        sku: '22131',
        productName: 'AGUA MIN MAR DOCE S/GAS PET 330 ML',
      },
      {
        sku: '10629',
        productName: 'AGUA M S LOURENCO S/GAS PET 300 ML CAIXA C/ 12',
      },
      {
        sku: '21874',
        productName: 'AGUA MIN NATURAGUA S/GAS PET ATHLETIC 500ML SH C/12',
      },
      {
        sku: '21900',
        productName: 'NOVECENTO ESPUMANTE ARGENTINO DULCE GFA OW 750ML CX C/6 NPAL',
      },
      {
        sku: '23220',
        productName: 'PATAGONIA IPA ONE WAY 740ML CX6',
      },
      {
        sku: '20329',
        productName: 'BRAHMA DUPLO MALTE 600ML',
      },
      {
        sku: '13194',
        productName: 'BRAHMA CHOPP ONE WAY 300ML CX C/23',
      },
      {
        sku: '21838',
        productName: 'LOHN BIER CATHARINA SOUR BERGAMOTA LN 355ML CX C12 NPA',
      },
      {
        sku: '12882',
        productName: 'PATAGONIA WEISSE ONE WAY 740ML CX6',
      },
      {
        sku: '22144',
        productName: 'AGUA MIN MAR DOCE PREMIUM C/GAS PET 330 ML CAIXA C/ 12',
      },
      {
        sku: '20702',
        productName: 'CHOPP COLORADO BRASIL C S BARRIL KEG 30L NPAL',
      },
      {
        sku: '21870',
        productName: 'BUDWEISER LONG NECK 330ML SIX-PACK SHRINK C/2 MULTPACK 02',
      },
      {
        sku: '21777',
        productName: 'BOAZINHA GARRAFA VIDRO 1 L',
      },
      {
        sku: '13195',
        productName: 'ANTARCTICA PILSEN ONE WAY 300ML CX C/23',
      },
      {
        sku: '21445',
        productName: 'DO BEM LARANJA TETRAPAK 1,5L CX6 NPAL',
      },
      {
        sku: '21882',
        productName: 'COLORADO TIM MAIA ONE WAY 600ML CX C-12 ARTE',
      },
      {
        sku: '1782',
        productName: 'LIZA OLEO DE MILHO GFA PET 900ML CX C/20',
      },
      {
        sku: '21558',
        productName: 'ANTARCTICA PILSEN 600ML RJ',
      },
      {
        sku: '1706',
        productName: 'LIZA OLEO DE GIRASSOL GFA PET 900ML CX C/20',
      },
      {
        sku: '14844',
        productName: 'GUARANA CHP ANTARCTICA LT 269ML SH C/15',
      },
      {
        sku: '15338',
        productName: 'CHOPP COLORADO VIXNU BARRIL KEG 50L NPAL',
      },
      {
        sku: '12604',
        productName: 'PATAGONIA BOHEIMIAN PILSENER ONE WAY 740ML CX6',
      },
      {
        sku: '15453',
        productName: 'BOHEMIA 14 WEISS ONE WAY 300ML CX C/12',
      },
      {
        sku: '21568',
        productName: 'FOR ME ENHANCE GARRAFA OW 100ML CX C/14',
      },
      {
        sku: '7981',
        productName: 'GATORADE LARANJA PET 500ML SIXPACK',
      },
      {
        sku: '20902',
        productName: 'CITRUS ANTARCTICA LT 269ML SH C/15',
      },
      {
        sku: '20841',
        productName: 'RED BULL SUGAR FREE BR LATA 250ML FOUR PACK BAND C/6 NPAL .',
      },
      {
        sku: '2923',
        productName: 'SEVEN UP LATA 350ML TWELVE PACK SHRINK DECORADO',
      },
      {
        sku: '18655',
        productName: 'GOOSE ISLAND BOUR STOU ONE WAY 500ML CX C/12 2018',
      },
      {
        sku: '21775',
        productName: 'SELETA OURO GARRAFA VIDRO 1 L',
      },
      {
        sku: '7983',
        productName: 'GATORADE MORANGO-MARACUJA PET 500ML SIXPACK',
      },
      {
        sku: '22146',
        productName: 'AGUA MIN MAR DOCE PREMIUM S/GAS PET 330 ML CAIXA C/ 12',
      },
      {
        sku: '14560',
        productName: 'COLORADO ITHACA ONE WAY 600ML CX C-12 ARTE',
      },
      {
        sku: '21520',
        productName: 'JOTA PE VINHO TINTO SECO GARRAFA VIDRO 750ML',
      },
      {
        sku: '14550',
        productName: 'COLORADO APPIA ONE WAY 600ML CX C-12 ARTE',
      },
      {
        sku: '12951',
        productName: 'BRAHMA CHOPP ZERO LN 355ML SIXPACK CX CART C/04',
      },
      {
        sku: '576',
        productName: 'SUKITA 290ML',
      },
      {
        sku: '23267',
        productName: 'QUATA CREME DE LEITE TETRAPAK 200G CX C/27',
      },
      {
        sku: '22566',
        productName: 'CAMIL ARROZ BR T1 PCT 5KG FD C/6',
      },
      {
        sku: '620',
        productName: 'CARACU LONG NECK 355ML SIX-PACK BANDEJA C/4',
      },
      {
        sku: '14120',
        productName: 'BRAHMA EXTRA WEISS LONG NECK 355ML SIX-PACK SHRINK C/4',
      },
      {
        sku: '22213',
        productName: 'GUARAVITON NAT ACAI GFA PET 500ML PACK C/12',
      },
      {
        sku: '14405',
        productName: 'WALS VERANO PALE ALE ONE WAY 600ML CX C/12 ARTE',
      },
      {
        sku: '18678',
        productName: 'SKOL PURO MALTE GFA VD 1L',
      },
      {
        sku: '21845',
        productName: 'SEAGERS GARRAFA VIDRO 1 L',
      },
      {
        sku: '25018',
        productName: 'PUROCOCO AGUA DE COCO TETRA PAK 1 L CX C/12',
      },
      {
        sku: '18062',
        productName: 'POLAR EXPORT GFA VD 300ML CX C/23',
      },
      {
        sku: '22504',
        productName: 'MAKERS MARK BOURBON GARRAFA VIDRO 750ML',
      },
      {
        sku: '2286',
        productName: 'GUARANA CHP ANTARCTICA LATA 350ML BANDEJA C/24',
      },
      {
        sku: '15327',
        productName: 'CHOPP COLORADO ITHACA BARRIL KEG 30L NPAL',
      },
      {
        sku: '22876',
        productName: 'PIRAQUE SALG. QUEIJINHO PCT 100G CX C/20',
      },
      {
        sku: '14523',
        productName: 'WALS HOPCORN IPA ONE WAY 600ML CX C/12 ARTE',
      },
      {
        sku: '22506',
        productName: 'JIM BEAM BLACK EXTRA AGED GARRAFA VIDRO 1 L',
      },
      {
        sku: '21735',
        productName: 'SKOL BEATS ZODIAC AR LT 269ML CX CARTAO C/8 NPAL',
      },
      {
        sku: '23506',
        productName: 'GUARANA ANTARCTICA MATE LATA 350ML SH C/12',
      },
      {
        sku: '20941',
        productName: 'LIPTON PESSEGO PET 200ML SH C/12',
      },
      {
        sku: '1842',
        productName: 'AGUA MINERAL MINALBA C/GÁS PET 510ML CAIXA C/12',
      },
      {
        sku: '15488',
        productName: 'GUARANA ANTARCTICA ZERO LATA 350ML SH C/12 NPAL',
      },
      {
        sku: '17281',
        productName: 'WALS BERLINER ONE WAY 600ML CX C/12 ARTE',
      },
      {
        sku: '7947',
        productName: 'GUARANA CHP ANTARCTICA PET 2,5L CAIXA C/6',
      },
      {
        sku: '13578',
        productName: 'BRAHMA CHOPP ZERO GFA VD 300ML CX C/23',
      },
      {
        sku: '18987',
        productName: 'AGUA MIN PEDRA AZUL C/GAS GARRAFA VIDRO 300ML CX24',
      },
      {
        sku: '12460',
        productName: 'AGUA MIN AGUAS DO PORTO  S/GAS GARRAFÃO PET 5 LITROS',
      },
      {
        sku: '21441',
        productName: 'SUKITA LIMAO PET 2L CAIXA C/6',
      },
      {
        sku: '2026',
        productName: 'STELLA ARTOIS 600ML OW',
      },
      {
        sku: '15915',
        productName: 'LIPTON PESSEGO PET 500ML SH C/12 NPAL',
      },
      {
        sku: '18765',
        productName: 'PATAGONIA WEISSE NACIONAL LATA 473ML 6PACK CARTAO',
      },
      {
        sku: '20531',
        productName: 'BECKS N 600 ML',
      },
      {
        sku: '12799',
        productName: 'HERTOG JAN GRAN PRESTIGE ONE WAY 500ML CX PAP C/8',
      },
      {
        sku: '22745',
        productName: 'SUKITA GUARANA PET 2L SHRINK C/6',
      },
      {
        sku: '21786',
        productName: 'MONTILLA CARTA BRANCA GARRAFA VIDRO 1 L',
      },
      {
        sku: '15135',
        productName: 'STELLA ARTOIS 550 ML',
      },
      {
        sku: '21531',
        productName: 'RAJSKA ORIGINAL GARRAFA VIDRO 1 L',
      },
      {
        sku: '21226',
        productName: 'BECKS N GFA OW 600ML CX C/24',
      },
      {
        sku: '19231',
        productName: 'RED BULL SUGAR FREE BR LATA 250ML FOUR PACK NPAL .',
      },
      {
        sku: '13610',
        productName: 'GUARANA ANTARCTICA BLACK LATA 350ML SH C/12',
      },
      {
        sku: '21527',
        productName: 'TANQUERAY LONDON DRY GARRAFA VIDRO 750ML',
      },
      {
        sku: '10723',
        productName: 'LIPTON PESSEGO PET 500ML SIXPACK',
      },
      {
        sku: '22551',
        productName: 'COROTE COQUETEL TUTTI FRUTTI GARRAFA PET 500 ML CX C/12',
      },
      {
        sku: '22148',
        productName: 'APEROL GARRAFA VIDRO 750ML',
      },
    ],
  },
};
