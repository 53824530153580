import React, { useEffect } from 'react';
import { TextArea as TextAreaHexa, Paragraph } from '@hexa-ui/components';
import { useField } from 'formik';
import CustomError from '~/components/Form/components/CustomError/CustomError';
import { getTestIdAttr } from '~/utils';
import CustomLabel from '~/components/Form/components/CustomLabel/CustomLabel';
import './TextArea.css';

export const TextArea = ({
  label,
  field: { name, value, ...fieldProps },
  form,
  showLabel = true,
  defaultValue,
  maxLength,
  onBlur,
  hint,
  characterCounter,
  placeholder,
  required,
  ...props
}): JSX.Element => {
  const [, , helpers] = useField({
    ...props,
    name,
  });

  useEffect(() => {
    helpers.setValue(defaultValue);
  }, [defaultValue]);

  return (
    <>
      {showLabel && (
        <CustomLabel errors={form?.errors?.[name]} label={label} hint={hint} optional={!required} />
      )}
      <TextAreaHexa
        {...getTestIdAttr('TextArea')}
        {...fieldProps}
        className="text-area-input"
        id={name}
        hasError={!!form?.errors?.[name]}
        value={value}
        onBlur={onBlur}
        required={required}
        maxLength={maxLength}
        placeholder={placeholder}
        {...props}
      />
      <div className="error-and-char-counter-container">
        <CustomError errors={form?.errors?.[name]} />
        {characterCounter && (
          <Paragraph className="formik-char-counter">
            {value?.length ?? 0}/{maxLength}
          </Paragraph>
        )}
      </div>
    </>
  );
};
