import Bff from '~/Api/Bff';
import { useQuery } from '@tanstack/react-query';
import { UseQueryOptions } from '@tanstack/react-query/build/lib/types';
import { IUser } from '~/interfaces';

export const getUsersByIds = (userIds: string[], country?: string): Promise<IUser[]> => {
  const param = userIds.join('&userIds=');
  const url = `/v1/users?userIds=${param}`;
  const options = country ? { headers: { country } } : {};
  return Bff.get<IUser[]>({
    url,
    options,
  }).then((response) => {
    return response.data;
  });
};

export const useGetUsersByIds = (
  userIds: string[],
  canReadFromIdentity = false,
  country?: string,
  options?: UseQueryOptions<any, any, IUser[], any[]>
) => {
  if (options !== undefined) {
    if (options?.enabled !== undefined) {
      options.enabled = canReadFromIdentity && options.enabled;
    } else {
      options.enabled = canReadFromIdentity;
    }
  } else {
    options = {
      enabled: canReadFromIdentity,
    };
  }

  return useQuery<any, any, IUser[], any[]>(
    ['getUsersByIds', userIds, country],
    async () => {
      return await getUsersByIds(userIds, country);
    },
    options
  );
};
