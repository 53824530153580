import { AlertOctagon, Info } from '@hexa-ui/icons';
import { TStatusCodeIcon } from './StatusCodeIcon.types';

export const StatusCodeIcon: TStatusCodeIcon = ({ statusCode }) => {
  switch (Number(statusCode)) {
    case 200:
    case 201:
    case 202:
    case 203:
    case 204:
      return <Info size="large" className="info-icon" />;
    default:
      return <AlertOctagon size="large" className="error-icon" />;
  }
};
