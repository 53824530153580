import { useMemo } from 'react';
import {
  BarChart3 as ReportsIcon,
  Headset as ReportIssueIcon,
  Home,
  Megaphone as NewPromoIcon,
  Settings,
} from '@hexa-ui/icons';
import { BugReportOutlined as CheckIssueStatusIcon } from '@material-ui/icons';
import { useSidebar } from 'admin-portal-shared-services';
import { getReportsLink, insertIf } from '~/utils';
import { useIntl } from 'react-intl';

import {
  SidebarItem,
  SidebarUtilitiesItem,
} from 'admin-portal-shared-services/dist/store/Sidebar/SidebarService.types';
import { useSelectedCountry, useFeatureTogglesResponse } from '~/hooks';
import {
  ADD_NEW_PROMO_BUTTON,
  CAN_ACCESS_FEATURE_TOGGLES_PAGE,
  ENABLE_SUPPORT_REQUESTS,
  SHOW_ERP_HISTORY_TAB,
  LIFT_ROUTES,
  CAN_CONFIGURE_USER_PERMISSIONS,
} from '~/constants';

interface IProps {
  openSupportModal: (open: boolean) => void;
  [key: string]: (arg0: any) => void;
}

const useInitializeSidebar = (handlers: IProps): void => {
  const { formatMessage } = useIntl();
  const selectedCountry = useSelectedCountry();

  const {
    enable_support_requests,
    addNewPromoButton,
    canAccessFeatureTogglesPage,
    canConfigureUserPermissions,
  } = useFeatureTogglesResponse(
    [
      ENABLE_SUPPORT_REQUESTS,
      ADD_NEW_PROMO_BUTTON,
      CAN_ACCESS_FEATURE_TOGGLES_PAGE,
      SHOW_ERP_HISTORY_TAB,
      CAN_CONFIGURE_USER_PERMISSIONS,
    ],
    {
      country: selectedCountry,
    }
  );

  const utils: SidebarUtilitiesItem[] = useMemo(() => {
    return [
      ...insertIf(canAccessFeatureTogglesPage[0] || canConfigureUserPermissions[0], {
        title: formatMessage({
          id: 'ADMIN_PAGE.TITLE',
          defaultMessage: 'Admin',
          description: 'BEES Lift Management',
        }),
        id: 'sidebar-admin-page',
        path: LIFT_ROUTES.ADMIN,
        icon: () => <Settings />,
      }),
      ...insertIf(
        enable_support_requests[0],
        {
          title: formatMessage({
            id: 'REQUEST_SUPPORT.REPORT_AN_ISSUE',
            defaultMessage: 'Report an issue',
          }),
          path: '#',
          icon: () => <ReportIssueIcon />,
          id: 'sidebar-report-an-issue',
          target: '_self',
          onPageChange: () => {
            handlers.openSupportModal(true);
          },
        },
        {
          title: formatMessage({
            id: 'REQUEST_SUPPORT.CHECK_ISSUE_STATUS',
            defaultMessage: 'Check issue status',
            description: 'Link to the check the status of support tickets',
          }),
          path: LIFT_ROUTES.SUPPORT,
          icon: () => <CheckIssueStatusIcon />,
          id: 'sidebar-check-issue-status',
        }
      ),
    ];
  }, [canConfigureUserPermissions[0], enable_support_requests[0], canAccessFeatureTogglesPage[0]]);

  const items: SidebarItem[] = useMemo(() => {
    return [
      {
        title: formatMessage({ id: 'SIDEBAR.HOME', defaultMessage: 'Dashboard' }),
        path: LIFT_ROUTES.HOME,
        icon: () => <Home />,
        id: 'sidebar-home',
      },
      ...insertIf(addNewPromoButton[0], {
        title: formatMessage({
          id: 'SIDEBAR.NEW_PROMO',
          defaultMessage: 'New Promo',
        }),
        id: 'sidebar-create-new-promo',
        path: LIFT_ROUTES.DESIGN,
        icon: () => <NewPromoIcon />,
      }),
      {
        title: formatMessage({
          id: 'SIDEBAR.REPORTS',
          defaultMessage: 'Reports',
        }),
        id: 'sidebar-reports',
        path: '#',
        icon: () => <ReportsIcon />,
        onPageChange: () => {
          window.open(getReportsLink());
          return false;
        },
      },
    ];
  }, [addNewPromoButton[0]]);

  useSidebar({ items, utils });
};

export default useInitializeSidebar;
