import { useSelectedCountry, usePercentValueFields } from '.';
import { getNumberValueFields } from '~/constants/Fields';
import { TPromoDesignSkuField, TMechanicTypeOption } from '~/interfaces';
import { useMemo } from 'react';

export const useNumericValueFields = (
  selectedMechanicTypeOption?: TMechanicTypeOption
): TPromoDesignSkuField[] => {
  const selectedCountry = useSelectedCountry();
  const percentValueFields = usePercentValueFields(selectedCountry, selectedMechanicTypeOption);
  const numberValueFields = getNumberValueFields();
  return useMemo(() => {
    return [...percentValueFields, ...numberValueFields];
  }, [selectedCountry, selectedMechanicTypeOption]);
};
