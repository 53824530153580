import { useFeatureToggleResponse, useFeatureToggle } from './useFeatureToggle';
import { ADD_NEW_PROMO_BUTTON } from '~/constants';
import useSelectedCountry from './useSelectedCountry';
import { TFeatureToggleResponse } from '~/interfaces';

const useCanCreateNewPromoDesignWithLoading = (): TFeatureToggleResponse => {
  const selectedCountry = useSelectedCountry();
  return useFeatureToggleResponse(ADD_NEW_PROMO_BUTTON, { country: selectedCountry });
};

export const useCanCreateNewPromoDesign = (): boolean => {
  const selectedCountry = useSelectedCountry();
  return useFeatureToggle(ADD_NEW_PROMO_BUTTON, { country: selectedCountry });
};

export default useCanCreateNewPromoDesignWithLoading;
