import { createStyles, makeStyles } from '@material-ui/styles';
import Flags from 'country-flag-icons/react/3x2';
import React, { memo } from 'react';
import { TCountryFlagIcon } from '~/components/CountryFlagIcon/CountryFlagIcon.types';

const useStyles = makeStyles(() =>
  createStyles({
    flag: {
      width: '30px',
      height: '30px',
      margin: '0 5px 0 5px',
    },
  })
);

const CountryFlagIcon: TCountryFlagIcon = ({ country }) => {
  const { flag } = useStyles();
  const Icon = Flags?.[country];
  if (!Icon) {
    return null;
  }

  return <Icon role="graphics-symbol" className={flag} />;
};

export default memo<TCountryFlagIcon>(CountryFlagIcon);
