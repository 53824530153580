import { FormattedMessage } from 'react-intl';
import { getTestIdAttr } from '~/utils';

export const Submit = (): JSX.Element => {
  return (
    <FormattedMessage
      {...getTestIdAttr('Submit')}
      id={'BUTTONS.SUBMIT'}
      defaultMessage={'Submit'}
    />
  );
};
