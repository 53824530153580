import { Autocomplete } from '@material-ui/lab/';
import TextField from '@material-ui/core/TextField';
import { useField } from 'formik';
import { useEffect } from 'react';
import CustomLabel from '~/components/Form/components/CustomLabel/CustomLabel';
import CustomError from '~/components/Form/components/CustomError/CustomError';
import { findObjectIndex as findObjIndex, getTestIdAttr } from '~/utils';
import './MultiSelect.css';

export const MultiSelect = ({
  field: { name, value, ...fieldProps },
  label,
  inputLabel,
  inputPlaceholder,
  hint,
  customId,
  customClasses,
  form,
  disabled,
  loading,
  loadingText,
  selectedVal,
  setSelectedValues,
  addedProducts,
  title,
  handlePaste,
  options,
  values,
  placeholder,
  noOptionsText,
  ...props
}): JSX.Element => {
  const [, , helpers] = useField({
    ...props,
    name,
  });

  useEffect(() => {
    helpers.setValue(selectedVal !== undefined ? [selectedVal] : []);
  }, []);

  return (
    <div
      {...getTestIdAttr('MultiSelect')}
      id={customId}
      className={`multi-select-container ms-container ${customClasses} ${
        form?.errors?.[name] && 'error-border'
      }`}
    >
      <CustomLabel errors={form?.errors?.[name]} label={label} hint={hint} />

      <Autocomplete
        multiple
        disabled={disabled}
        onChange={(e, newValue) => {
          setSelectedValues(newValue);
          helpers.setValue(newValue);
        }}
        title={title}
        noOptionsText={noOptionsText}
        autoSelect={false}
        onPaste={(event) => {
          event.preventDefault();
          helpers.setValue(handlePaste(event, options, value));
        }}
        disableCloseOnSelect
        options={options || []}
        value={value || []}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option, value) => {
          const lastAddedProduct = addedProducts[addedProducts?.length - 1];

          if (
            lastAddedProduct &&
            findObjIndex(options, 'label', lastAddedProduct.label) === -1 &&
            findObjIndex(options, 'label', value.label) === -1
          ) {
            // Checking for lastAddedProduct to avoid warnings in console
            // Checking for current value to prevent bug from returning no data
            return option.label;
          }

          return option.label === value.label;
        }}
        filterSelectedOptions
        style={{ width: '100%' }}
        renderInput={(params) => (
          <TextField
            {...getTestIdAttr('MultiSelectTextField')}
            className="multiselect-text-field"
            {...params}
            label={inputPlaceholder}
            variant="outlined"
            placeholder={placeholder}
          />
        )}
      />
      <CustomError errors={form?.errors?.[name]} />
    </div>
  );
};
