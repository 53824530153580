import { getTestIdAttr } from '~/utils';

export const FormField = ({
  id,
  label,
  required,
  children,
  showErrors = true,
  formProps: { touched, errors },
}) => {
  const hasError = errors[id] && touched[id];

  return (
    <div {...getTestIdAttr('FormField')} className="field" id={id}>
      {label && (
        <label htmlFor={id}>
          {label}
          {required && <sup className="required">*</sup>}
        </label>
      )}
      {children}
      {showErrors && hasError && <small className="error">{errors[id]}</small>}
    </div>
  );
};
