import { IPaginationComponentOptions } from '~/interfaces';

export const PAGE_0 = 0;
export const PAGE_1 = 1;
export const PAGE_SIZE_5 = 5;
export const PAGE_SIZE_10 = 10;
export const PAGE_SIZE_20 = 20;
export const PAGE_SIZE_50 = 50;
export const PAGE_SIZE_100 = 100;
export const PAGE_SIZE_OPTIONS = [
  PAGE_SIZE_5,
  PAGE_SIZE_10,
  PAGE_SIZE_20,
  PAGE_SIZE_50,
  PAGE_SIZE_100,
];

export const PAGINATION_DEFAULT_PROPS: IPaginationComponentOptions = {
  DEFAULT_PAGE: PAGE_0,
  DEFAULT_PAGE_SIZE: PAGE_SIZE_5,
  PAGE_SIZE_OPTIONS,
};

export const PAGINATION_DEFAULT_PROPS_STARTING_IN_PAGE_1: IPaginationComponentOptions = {
  DEFAULT_PAGE: PAGE_1,
  DEFAULT_PAGE_SIZE: PAGE_SIZE_5,
  PAGE_SIZE_OPTIONS,
};

export const DEBOUNCE_TIME_500 = 500;

export const STATUS_200 = 200;
export const STATUS_404 = 404;
