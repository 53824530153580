import { Error500 } from '@admin-portal-shared-components/error';
import { useIntl } from 'react-intl';
import { useMemo } from 'react';
import { getTestIdAttr } from '~/utils';
import './CountryUnavailable.css';
import { TCountryUnavailable } from './CountryUnavailable.types';

const CountryUnavailable: TCountryUnavailable = ({ country, hasPermission, hasCountry }) => {
  const { formatMessage, formatDisplayName } = useIntl();

  const errorMessage = useMemo<string>(() => {
    if (!hasPermission) {
      return formatMessage(
        {
          id: 'MESSAGES.ERROR_ACCESS_DENIED',
          defaultMessage:
            'Oops! \n\nYou do not have permission to use {country} on BEES Lift. \n\nPlease contact administrator, or try again later.',
        },
        {
          country: formatDisplayName(country, {
            type: 'region',
          }),
        }
      );
    }
    if (!hasCountry) {
      return formatMessage(
        {
          id: 'MESSAGES.ERROR_UNAVAILABLE_COUNTRY',
          defaultMessage:
            "Oops! {country} isn't quite ready. \n\nPlease contact administrator, or try again later.",
        },
        {
          country: formatDisplayName(country, {
            type: 'region',
          }),
        }
      );
    }
    return '';
  }, [country, hasPermission, hasCountry]);

  return (
    <div {...getTestIdAttr('CountryUnavailable')} className="country-error">
      <Error500 headerText={errorMessage} />
    </div>
  );
};

export default CountryUnavailable;
