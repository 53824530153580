import { useMemo } from 'react';
import { getPercentValueFields } from '~/constants/Fields';
import { TPromoDesignSkuField, TMechanicTypeOption } from '~/interfaces';

export const usePercentValueFields = (
  selectedCountry?: string,
  selectedMechanicTypeOption?: TMechanicTypeOption
): TPromoDesignSkuField[] => {
  return useMemo(() => {
    return getPercentValueFields(selectedCountry, selectedMechanicTypeOption);
  }, [selectedCountry, selectedMechanicTypeOption]);
};
