import { IPromoMechanicOption } from '~/interfaces';
import { every, some } from '@moneycomb/utils';

export interface IValidatePersonalizationStrategiesToggles {
  isLoadingFeatureToggles: boolean;
  isPersonalizedAllowed: boolean;
  isNonPersonalizedAllowed: boolean;
}
export interface IValidatePersonalizationStrategiesResponse {
  isLoading: boolean;
  personalized: boolean;
  nonPersonalized: boolean;
  hasMultipleOptions: boolean;
}

export type TValidatePersonalizationStrategies = (
  featureToggles: IValidatePersonalizationStrategiesToggles,
  option: IPromoMechanicOption
) => IValidatePersonalizationStrategiesResponse;

export const validatePersonalizationStrategies: TValidatePersonalizationStrategies = (
  featureToggles,
  option
) => {
  const isLoading = featureToggles.isLoadingFeatureToggles;

  if (some(isLoading, !option)) {
    return {
      isLoading,
      personalized: false,
      nonPersonalized: false,
      hasMultipleOptions: false,
    };
  }

  const {
    personalized: isPersonalizedAllowedForMechanicOption = false,
    nonPersonalized: isNonPersonalizedAllowedForMechanicOption = false,
  } = option;

  const personalized = every(
    featureToggles.isPersonalizedAllowed,
    isPersonalizedAllowedForMechanicOption
  );

  const nonPersonalized = every(
    featureToggles.isNonPersonalizedAllowed,
    isNonPersonalizedAllowedForMechanicOption
  );

  return {
    personalized,
    nonPersonalized,
    isLoading: false,
    hasMultipleOptions: every(personalized, nonPersonalized),
  };
};
