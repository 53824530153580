import React from 'react';
import { Checkmark2, XCircle } from '@hexa-ui/icons';
import { getTestIdAttr } from '~/utils';

export default ({ value }: { value: boolean }): JSX.Element => {
  return value ? (
    <Checkmark2
      {...getTestIdAttr('BooleanCheckOrXTrue')}
      size="large"
      fill={'green'}
      style={{
        color: 'green',
      }}
    />
  ) : (
    <XCircle
      // {...getTestIdAttr('BooleanCheckOrXFalse')}
      size={'large'}
      style={{
        color: 'red',
      }}
    />
  );
};
