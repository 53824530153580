import { Input } from '@hexa-ui/components';
import { useField } from 'formik';
import { handlePasteFormik as handlePaste, countDecimalPlaces, getTestIdAttr } from '~/utils';
import CustomError from './CustomError/CustomError';
import CustomLabel from './CustomLabel/CustomLabel';
import { FormEvent } from 'react';

export const FloatField = ({
  label,
  field: { name, value, ...fieldProps },
  form,
  hint,
  required,
  enabled,
  placeholder,
  optional = false,
  type,
  ...props
}) => {
  const [, , helpers] = useField({
    ...props,
    name,
  });

  return (
    <div {...getTestIdAttr('FloatField')} className="formik-input">
      <CustomLabel errors={form?.errors?.[name]} label={label} hint={hint} optional={!required} />
      <Input
        className="input-field"
        type="number"
        min={0}
        step={0.01}
        optionalText={''}
        id={name}
        name={name}
        value={value}
        placeholder={placeholder || ''}
        onChange={(e: FormEvent<HTMLInputElement>) => {
          const val = (e?.target as HTMLInputElement)?.value;
          const numberVal = val?.match(/[0-9.]/g)?.join('');
          if (numberVal) {
            helpers.setValue(
              Number(countDecimalPlaces(numberVal) <= 2 ? numberVal : Number(numberVal).toFixed(2))
            );
          } else {
            // Empty Value
            helpers.setValue('');
          }
        }}
        onPaste={(e) => handlePaste(e, helpers, /[0-9|.]/g)}
        onKeyDown={(e) => {
          ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
        }}
        required={required}
        hasError={!!form?.errors?.[name]}
        {...props}
      />
      <CustomError errors={form?.errors?.[name]} />
    </div>
  );
};
