import { ISupportRequest, ISupportRequestResponse } from '~/interfaces';
import Bff from '~/Api/Bff';
import { UseQueryOptions, useQuery, UseQueryResult } from '@tanstack/react-query';
import { checkError, setToastFromResponse } from '~/redux/saga/sagaActions';
import { useAppDispatch } from '~/hooks';
import { AxiosError } from 'axios';

let response: Awaited<ReturnType<typeof getSupportTickets>>;

export const getSupportTickets = async (): Promise<ISupportRequestResponse> => {
  const ticketResponse: any = await Bff.get<ISupportRequestResponse>({
    url: `/v1/support/ticket`,
  });

  return ticketResponse;
};
export const useGetSupportTickets = (
  options?: UseQueryOptions
): UseQueryResult<ISupportRequest[]> => {
  const dispatch = useAppDispatch();
  return useQuery<ISupportRequest[], any, any, any>({
    queryKey: ['getSupportTickets'],
    queryFn: async () => {
      response = await getSupportTickets();
      return response?.data;
    },
    onError: (e: AxiosError) => {
      dispatch(setToastFromResponse(response));
    },
    onSuccess: (e) => {
      dispatch(checkError(e));
    },
    cacheTime: 0,
    staleTime: 0,
    ...options,
  });
};
