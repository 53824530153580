import { Chip, IconButton } from '@hexa-ui/components';
import { X } from '@hexa-ui/icons';
import { Dropdown, CommonMessages } from '~/components';
import { TAG_GENERAL, TAG_NAMES } from '~/constants';
import { useKeyPress } from '~/hooks';
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { concatString, getTestIdAttr } from '~/utils';
import { v4 as uuid } from 'uuid';
import {
  ButtonText,
  ChipContainer,
  Container,
  FormContainer,
  InputArea,
  InputContainer,
  SearchButton,
  StyledInput,
  StyledSearchIcon,
  ValuesContainer,
} from './styles';
import { IInputProps } from './Input.types';
const { SEARCH, CLEAR, SEARCH_FIELD } = TAG_NAMES.ACCOUNT.SELECTION;

export const Input = ({
  value = '',
  onChange,
  onClear,
  onEnter,
  placeholder,
  onOptionSelected,
  maxValues = 1,
  searchButtonDisabled,
  hideClearButton,
  hideSearchIcon,
  transparent,
  dropdownOptions = [],
  containerWidth,
  hideSearchButton,
  autoFocus,
  handleButtonClick,
  disabled,
}: IInputProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const [values, setValues] = useState<string[]>([]);

  const onKeyPress = () => {
    onEnter?.();

    const currentValue = inputRef?.current?.value;
    if (maxValues > 1 && currentValue) {
      setValues((array) =>
        [...array.filter((valueItem) => valueItem !== currentValue), currentValue].slice(
          0,
          maxValues
        )
      );
      setInputValue('');
    }
  };

  useEffect(() => setInputValue(value.toString()), [value]);

  useEffect(() => {
    if (autoFocus) {
      inputRef?.current?.focus();
    }
  }, [autoFocus]);

  const { handler } = useKeyPress({
    callback: onKeyPress,
    keyCodes: ['Enter', 'NumpadEnter'],
  });

  const handleFilterList = (text: string) =>
    setValues((array) => array.filter((item) => item !== text));

  const shouldShowClearButton = useMemo(
    () =>
      !hideClearButton &&
      ((inputRef.current?.value && inputRef.current.value.length > 0) ||
        values?.length > 0 ||
        inputValue?.length > 0),
    [hideClearButton, inputValue?.length, values?.length]
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange?.(event);
    setInputValue(event?.target?.value);
  };

  const handleOnClear = () => {
    if (inputRef && inputRef.current) {
      setInputValue('');
      setValues([]);
      inputRef.current.value = '';
    }
    onClear?.();
  };

  const onButtonClick = () => {
    let returnValue = inputValue;
    if (maxValues > 1 && values?.length > 0) {
      returnValue = values?.join();
    }
    handleButtonClick?.(returnValue);
  };

  const buttonXMedium = useCallback(() => <X size="medium" />, []);

  return (
    <FormContainer {...getTestIdAttr('Input')}>
      <InputContainer containerWidth={containerWidth} transparent={transparent}>
        {onOptionSelected && (
          <Dropdown
            onChange={(optionValue) => onOptionSelected(String(optionValue))}
            variant="small"
            options={dropdownOptions}
          />
        )}
        <InputArea>
          {!hideSearchIcon && (
            <StyledSearchIcon size="large" leftmargin={onOptionSelected && '8px'} />
          )}
          <ValuesContainer>
            {values.map((option) => (
              <ChipContainer key={uuid()}>
                <Chip.Label>{option}</Chip.Label>
                <Chip.DeleteIcon onDelete={() => handleFilterList(option)} />
              </ChipContainer>
            ))}

            <Container>
              <StyledInput
                disabled={maxValues === values?.length || disabled}
                ref={inputRef}
                id={concatString(TAG_GENERAL.IPT, {
                  name: SEARCH_FIELD,
                })}
                onChange={handleChange}
                value={inputValue}
                placeholder={values?.length === 0 ? placeholder : ''}
                onKeyPress={handler}
              />
            </Container>
          </ValuesContainer>
          {shouldShowClearButton && !disabled && (
            <IconButton
              data-testid={concatString(TAG_GENERAL.BTN, {
                name: CLEAR,
              })}
              size="small"
              variant="tertiary"
              onClick={handleOnClear}
              icon={buttonXMedium}
            />
          )}
        </InputArea>
      </InputContainer>
      {!hideSearchButton && (
        <SearchButton
          id={concatString(TAG_GENERAL.BTN, {
            name: SEARCH,
          })}
          size="large"
          onClick={onButtonClick}
          variant="primary"
          disabled={searchButtonDisabled || disabled}
        >
          <ButtonText>
            <CommonMessages.Search />
          </ButtonText>
        </SearchButton>
      )}
    </FormContainer>
  );
};
