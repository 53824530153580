import { createContext, useMemo } from 'react';
import { TCountryConfig } from '~/interfaces';
import { useCountryConfig } from '~/hooks';

type CountryContextParams = {
  countryConfig: TCountryConfig;
};

export const CountryContext = createContext<CountryContextParams>(null);

export function ConfigProvider({ children }): JSX.Element {
  const countryConfig = useCountryConfig();

  const memoizedCountryConfig = useMemo(() => ({ countryConfig }), [countryConfig]);

  return (
    <CountryContext.Provider value={memoizedCountryConfig}>{children}</CountryContext.Provider>
  );
}

export default ConfigProvider;
