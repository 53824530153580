import Bff from '~/Api/Bff';
import { IAudienceResponse } from '~/interfaces';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

export const getAudiences = async (): Promise<IAudienceResponse[]> => {
  return Bff.get<IAudienceResponse[]>({
    url: '/v1/audiences?page=1&pageSize=50',
  }).then((response) => {
    return response.data;
  });
};

export const useGetAudiences = (
  options?: UseQueryOptions<any, any, IAudienceResponse[], any[]>
) => {
  return useQuery<any, any, IAudienceResponse[], any[]>(
    ['getAudiences'],
    async () => {
      return await getAudiences();
    },
    options
  );
};

export const removeUsersFromAudience = async (
  id: string,
  userIds: string[]
): Promise<IAudienceResponse> => {
  return Bff.post<string[], IAudienceResponse>({
    url: `/v1/audiences/${id}/removeUsers`,
    body: userIds,
  }).then((response) => {
    return response.data;
  });
};

export const addUsersToAudience = async (
  id: string,
  userIds: string[]
): Promise<IAudienceResponse> => {
  return Bff.post<string[], IAudienceResponse>({
    url: `/v1/audiences/${id}/addUsers`,
    body: userIds,
  }).then((response) => {
    return response.data;
  });
};

export const createCountryAudience = async (country: string): Promise<IAudienceResponse> => {
  return Bff.post<undefined, IAudienceResponse>({
    url: `/v1/audiences/country/${country}`,
  }).then((response) => {
    return response.data;
  });
};

export const createUserAudience = async (
  name: string,
  description: string,
  userIds: string[]
): Promise<IAudienceResponse> => {
  return Bff.post<
    {
      name: string;
      description: string;
      userIds: string[];
    },
    IAudienceResponse
  >({
    url: `/v1/audiences/user`,
    body: {
      name,
      description,
      userIds,
    },
  }).then((response) => {
    return response.data;
  });
};
