import {
  useAppSelector,
  useCountryConfig,
  useCountryTimezone,
  useFeatureToggleResponse,
  useSelectedCountry,
} from '~/hooks';
import { formatInTimeZone, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { addDays } from 'date-fns';
import { dateWithoutTime } from '~/utils';
import { ENABLE_MIN_START_DATE_LOGIC } from '~/constants';
import { selectIsPersonalized } from '~/redux/slices';
import { useEffect, useMemo, useState } from 'react';
import { TCutoffTime, TPromoDesignMinDateRules } from '~/interfaces/TCountryConfig';

interface TUseGetMinDesignDateResponse {
  isLoading: boolean;
  minDate: string;
}

export const toUTC = (date: Date): Date => {
  const systemTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (systemTimezone === 'UTC') {
    return date;
  }
  return zonedTimeToUtc(date, systemTimezone);
};

export const isNowBeforeCutoffTime = (
  todayInDesiredTimezone: Date,
  cutoffTime: TCutoffTime
): boolean => {
  const MAX_HOURS = 23;
  const MAX_MINUTES = 59;
  const cutoffTimeInDesiredTimezone = new Date(todayInDesiredTimezone);
  cutoffTimeInDesiredTimezone.setHours(
    Math.min(cutoffTime.hour, MAX_HOURS),
    Math.min(cutoffTime.minute, MAX_MINUTES),
    0,
    0
  );
  // The <= is mostly for tests which, to make sure we don't have seconds worth of difference
  return todayInDesiredTimezone <= cutoffTimeInDesiredTimezone;
};

export const isSameDayPromoAllowed = (
  isPersonalized: boolean,
  minDateRules: TPromoDesignMinDateRules,
  todayInDesiredTimezone: Date
): boolean => {
  const {
    ALLOW_SAME_DAY_PROMO_NON_PERSONALIZED,
    ALLOW_SAME_DAY_PROMO_PERSONALIZED,
    SAME_DAY_CUTOFF_TIME_NON_PERSONALIZED,
    SAME_DAY_CUTOFF_TIME_PERSONALIZED,
  } = minDateRules;

  const allowSameDayPromo = isPersonalized
    ? ALLOW_SAME_DAY_PROMO_PERSONALIZED
    : ALLOW_SAME_DAY_PROMO_NON_PERSONALIZED;

  if (!allowSameDayPromo) {
    return false;
  }

  const sameDayCutoffTime = isPersonalized
    ? SAME_DAY_CUTOFF_TIME_PERSONALIZED
    : SAME_DAY_CUTOFF_TIME_NON_PERSONALIZED;

  return isNowBeforeCutoffTime(todayInDesiredTimezone, sameDayCutoffTime);
};

export const isNextDayPromoAllowed = (
  isPersonalized: boolean,
  minDateRules: TPromoDesignMinDateRules,
  todayInDesiredTimezone: Date
): boolean => {
  if (!isPersonalized) {
    return true; // We always allow next day promotions for non-personalized designs
  }
  const { NEXT_DAY_CUTOFF_TIME_PERSONALIZED } = minDateRules;
  return isNowBeforeCutoffTime(todayInDesiredTimezone, NEXT_DAY_CUTOFF_TIME_PERSONALIZED);
};

export const useGetMinDesignDate = (personalized = undefined): TUseGetMinDesignDateResponse => {
  const { MIN_DATE_RULES } = useCountryConfig();
  const {
    ALLOW_SAME_DAY_PROMO_NON_PERSONALIZED,
    ALLOW_SAME_DAY_PROMO_PERSONALIZED,
    SAME_DAY_CUTOFF_TIME_NON_PERSONALIZED,
    SAME_DAY_CUTOFF_TIME_PERSONALIZED,
    NEXT_DAY_CUTOFF_TIME_PERSONALIZED,
  } = MIN_DATE_RULES;
  const timezone = useCountryTimezone();
  const country = useSelectedCountry();
  let isPersonalized = useAppSelector(selectIsPersonalized);
  if (personalized !== undefined) {
    isPersonalized = personalized;
  }
  const [useStartDateLogic, isLoading] = useFeatureToggleResponse(ENABLE_MIN_START_DATE_LOGIC, {
    country,
  });
  const [today, setToday] = useState<Date>(new Date());

  useEffect(() => {
    const TEN_SECONDS = 10000;
    const interval = setInterval(() => {
      setToday(new Date());
    }, TEN_SECONDS);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return useMemo<TUseGetMinDesignDateResponse>(() => {
    if (!useStartDateLogic) {
      return {
        isLoading,
        minDate: dateWithoutTime(today),
      }; // Note: this is legacy behavior, and is not always accurate given the UTC offsets
    }

    const TWO_DAYS = 2;
    // Please be careful modifying this code
    const todayInUtc = toUTC(today); // Convert current time to UTC, if the system is not already in UTC
    const todayInDesiredTimezone = utcToZonedTime(todayInUtc, timezone); //Get the date in the timezone for the country
    const nextDayInDesiredTimezone = addDays(todayInDesiredTimezone, 1); // Add 1 day onto the date in the desired timezone
    nextDayInDesiredTimezone.setHours(0, 0, 0, 0); // Set the time to midnight for that next day
    const nextTwoDayInDesiredTimezone = addDays(todayInDesiredTimezone, TWO_DAYS); // Add 2 day onto the date in the desired timezone
    nextTwoDayInDesiredTimezone.setHours(0, 0, 0, 0);
    const nextDayInUtc = zonedTimeToUtc(nextDayInDesiredTimezone, timezone); // Change that next day at midnight in desired timezone to UTC time
    const nextTwoDayInUtc = zonedTimeToUtc(nextTwoDayInDesiredTimezone, timezone); // Change that next day at midnight in desired timezone to UTC time

    const allowSameDayPromo = isSameDayPromoAllowed(
      isPersonalized,
      MIN_DATE_RULES,
      todayInDesiredTimezone
    );

    let minDate: Date;

    if (allowSameDayPromo) {
      minDate = todayInUtc;
    } else {
      const allowNextDayPromo = isNextDayPromoAllowed(
        isPersonalized,
        MIN_DATE_RULES,
        todayInDesiredTimezone
      );
      if (allowNextDayPromo) {
        minDate = nextDayInUtc;
      } else {
        minDate = nextTwoDayInUtc;
      }
    }

    return {
      isLoading,
      minDate: formatInTimeZone(minDate, timezone, 'yyyy-MM-dd'),
    };
  }, [
    timezone,
    country,
    isPersonalized,
    useStartDateLogic,
    ALLOW_SAME_DAY_PROMO_PERSONALIZED,
    ALLOW_SAME_DAY_PROMO_NON_PERSONALIZED,
    SAME_DAY_CUTOFF_TIME_NON_PERSONALIZED,
    SAME_DAY_CUTOFF_TIME_PERSONALIZED,
    NEXT_DAY_CUTOFF_TIME_PERSONALIZED,
    isLoading,
    today,
  ]);
};
