import Bff from '~/Api/Bff';
import { IAvailableCountries } from '~/interfaces';
import { AxiosResponse } from 'axios';

export const get = async (): Promise<AxiosResponse<IAvailableCountries>> => {
  return Bff.get<IAvailableCountries>({
    url: '/v1/countries',
  }).then((response) => {
    return response;
  });
};
