/**
 * Use this file to configure the order in which the feilds appear
 * Eventually we will move this to a different data source
 * The code is NOT dependent upon all fields being present in this list
 * If a field is sent from the server that is not in this list, it will simply be added later in the form
 */
export const ACTIONS = 'actions';
export const CREATED_AT = 'createdAt';
export const PROMO_DESIGN_TITLE = 'promoDesignTitle';
export const PROMO_STRATEGY = 'promoStrategy';
export const SUBSTRATEGY_CD = 'substrategyCd';
export const DATE_RANGE = 'dateRange';
export const STATUS = 'status';
export const CREATOR_NAME = 'creatorName';
export const PROMO_DESIGN_ID = 'promoDesignId';
export const MECHANIC_TYPE = 'mechanicType';
export const PROMO_DESIGN_TITLE_DISPLAY = 'promoDesignTitleDisplay';
