import React, { forwardRef } from 'react';
import { useIntl } from 'react-intl';
import { Table } from '@hexa-ui/components';
import { useGetSupportTicketsForPromoDesign } from '~/services';
import { ISupportRequest } from '~/interfaces';
import { HrefToJiraIssue, FormatRelative } from '~/components';
import { useUserDateLocale } from '~/hooks';
import { getTestIdAttr } from '~/utils';

const SupportRequestList = ({ promoDesignId }, ref): JSX.Element => {
  const { formatMessage } = useIntl();
  const userLocale = useUserDateLocale();

  const { data: supportRequests = [], isLoading } =
    useGetSupportTicketsForPromoDesign(promoDesignId);

  return (
    <div {...getTestIdAttr('SupportRequestList')}>
      <Table<ISupportRequest>
        {...getTestIdAttr('SupportRequestList-table')}
        data={supportRequests}
        loading={isLoading}
        emptyMessage={formatMessage({
          id: 'REQUEST_SUPPORT.EMPTY_PROMO_DESIGN',
          defaultMessage: 'No support requests found for this promo design',
        })}
        loadingMessage={formatMessage({
          id: 'REQUEST_SUPPORT.LOADING_REQUESTS',
          defaultMessage: 'Loading support requests',
        })}
        columns={[
          {
            Header: formatMessage({ id: 'REQUEST_SUPPORT.TABLE.KEY', defaultMessage: 'Key' }),
            accessor: 'key',
            style: {
              minWidth: '150px',
            },
            customRender: (value: string) => <HrefToJiraIssue value={value} />,
          },
          {
            Header: formatMessage({
              id: 'REQUEST_SUPPORT.TABLE.STATUS',
              defaultMessage: 'Status',
            }),
            accessor: 'status',
          },
          {
            Header: formatMessage({
              id: 'REQUEST_SUPPORT.TABLE.PRIORITY',
              defaultMessage: 'Priority',
            }),
            accessor: 'priority',
          },
          {
            Header: formatMessage({
              id: 'REQUEST_SUPPORT.TABLE.REPORTER',
              defaultMessage: 'Reporter',
            }),
            accessor: 'reporter',
          },
          {
            Header: formatMessage({
              id: 'REQUEST_SUPPORT.TABLE.UPDATED',
              defaultMessage: 'Updated',
            }),
            accessor: 'updated',
            customRender: (value: string) => (
              <FormatRelative value={value} userLocale={userLocale} />
            ),
          },
        ]}
      />
    </div>
  );
};

export default forwardRef(SupportRequestList);
