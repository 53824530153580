import { FormattedMessage } from 'react-intl';
import { getTestIdAttr } from '~/utils';

export const Confirm = (): JSX.Element => {
  return (
    <FormattedMessage
      {...getTestIdAttr('Confirm')}
      id={'BUTTONS.CONFIRM'}
      defaultMessage={'Confirm'}
    />
  );
};
