import { useSelectedCountry } from '~/hooks/index';
import { useMemo } from 'react';
import { TCountryConfig } from '~/interfaces';
import { getCountryConfig } from '~/configs';

export const useCountryConfig = (localCountry?: string): TCountryConfig => {
  const reduxCountry = useSelectedCountry();
  const selectedCountry = localCountry ? localCountry : reduxCountry;

  return useMemo<TCountryConfig>(() => {
    return getCountryConfig(selectedCountry);
  }, [selectedCountry]);
};
