import Bff from '~/Api/Bff';
import { IPocListResponse, IPocParametersResponse, IUploadFilesList } from '~/interfaces';
import { AxiosResponse } from 'axios';

interface ISetPocUploadDataParameters {
  promoDesignId: string;
  payload: {
    data: IUploadFilesList[];
  };
}

export const setPocUploadData = ({
  promoDesignId,
  payload,
}: ISetPocUploadDataParameters): Promise<AxiosResponse<IPocParametersResponse>> => {
  return Bff.post<typeof payload, IPocParametersResponse>({
    url: `/v1/promo-designs/${promoDesignId}/poc-list`,
    body: payload,
  }).then((response) => {
    return response;
  });
};

export const getPocUploadData = (
  promoDesignId: string,
  type: IUploadFilesList['pocListType']
): Promise<AxiosResponse<IPocListResponse[]>> => {
  return Bff.get<IPocListResponse[]>({
    url: `/v1/promo-designs/${promoDesignId}/poc-list?type=${type}`,
  }).then((response) => {
    return response;
  });
};
