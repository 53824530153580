import MessageMap from '~/i18n/i18n.d';

const FECHA_DE_CREACION = 'Fecha de creación';
const SUB_ESTRATEGIA = 'Sub estrategia';
const NO_PERSONALIZADA = 'No personalizada';
const AGREGAR_PRODUCTOS = 'Agregar productos';
const UNIDAD_DE_NEGOCIO = 'Unidad de negocio';
const PORCENTAJE_DE_DESCUENTO = '% de descuento';
const LIMITE_DE_SKU = 'Límite de SKU';
const ID_DE_PROMOCION = 'ID de promoción';
const ORDENES_MAXIMAS = 'Órdenes máximas';
const CANTIDAD_MAXIMA = 'Cantidad máxima';
const INGRESE_UN_VALOR = 'Ingrese un valor.';
const CANTIDAD_MINIMA = 'Cantidad mínima';
const INGRESE_UN_VALOR_DE_PORCENTAJE = 'Ingrese un valor %.';

const es419: MessageMap = {
  test: {
    translate: 'Translate',
    missing: 'Only English option available',
  },
  CONSTS: {
    ENABLED: 'Activa',
    DISABLED: 'Inactiva',
    ACTIVE: 'Activa',
    INACTIVE: 'Inactiva',
    NAME: 'Nombre',
    EMAIL: 'Email',
    DESCRIPTION: 'Descripción',
    SEARCH: 'Buscar',
    LOADING: 'Cargando',
    ERROR: 'Error',
    ALMOST_THERE: 'Carga casi completa...',
    ID: 'ID',
    DOWNLOAD: 'Descargar',
    SKU: 'SKU',
    GENERIC_LOADING_ERROR:
      'Algo salió mal con su solicitud. Contáctese con el administrador o inténte nuevamente',
  },
  ADMIN_PAGE: {
    PAGE_TITLE: 'Gestión de ascensores BEES',
  },
  USER_MANAGEMENT: {
    PAGE_TITLE: 'Gestión de usuarios',
    CARD_DESCRIPTION: 'Adminitsrar permisos de usuario en BEES Lift.',
  },
  CONFIG_PAGE: {
    PAGE_TITLE: 'Configuración global',
    CARD_DESCRIPTION: 'Adminstrar la configuración global por pais.',
  },
  COUNTRY_SELECTOR_MODAL: {
    HEADER: 'Cambiar país',
    SUB_HEADER:
      'Cambiar el país borrará toda la configuración cargada y será redirigido a la página principal. ¿Quieres continuar?',
  },
  SIDEBAR: {
    HOME: 'Dashboard',
    NEW_PROMO: 'Nueva promoción',
    REPORTS: 'Reportes',
  },
  ERROR_TOAST:
    'Error {errorCode}: Algo salió mal con su solicitud. Contáctese con el administrador o inténte nuevamente',
  FORMATS: {
    ALPHANUMERIC_WITH_DASHES: 'Alfanumérico con guiones y sin caracteres especiales',
  },
  DOWNLOAD: {
    REVIEW_TITLE: 'Revisar_diseño_de_promoción',
    NO_DATA: 'N/A',
  },
  PAGINATION: {
    OF: 'de',
    ITEMS_PER_PAGE: 'Mostrar {field} elementos por página',
  },
  FIELDS: {
    OPTIONAL: '(Opcional)',
    REQUIRED: 'Requerido',
    IS_REQUIRED_ERROR_MESSAGE: '{field} es requerido',
    GREATER_THAN_OR_EQUAL_TO_FIELD_ALLOW_ZERO: '{field} debe ser 0 o mayor o igual a {field2}',
    GREATER_THAN_OR_EQUAL_TO_FIELD: '{field} debe ser mayor o igual que {field2}',
    LESS_THAN_OR_EQUAL_TO_FIELD: '{field} debe ser menor o igual a {field2}',
    GREATER_THAN_PREVIOUS_FIELD: '{field} debe superar a {field2} por 1',
    GREATER_THAN_EQUAL_TO_PREVIOUS_FIELD: '{field} debe ser mayor o igual al anterior {field2}',
    LESS_THAN_OR_EQUAL_TO_PREVIOUS_FIELD: '{field} debe ser menor o igual que {field2}',
    TWO_DECIMAL_POINTS: '{field} debe tener 2 decimales o ser un número entero',
    INVALID_FORMAT: '{field} no coincide con el formato requerido: {format}',
    LESS_THAN_MIN: '{field} debe ser mayor o igual a {minValue}',
    GREATER_THAN_MAX: '{field} debe ser menor o igual que {maxValue}',
    MUST_HAVE_SELECTED_NUMBER_OF_ITEMS:
      '{minValue, plural, one {{field} debe contener al menos # item seleccionado} other {{field} debe contener al menos # items seleccionados}}',
    MUST_BE_A_NUMBER: '{field} debe ser un número',
    PAST_DATE: '{field} debe ser {minDate} o una fecha futura',
    PAST_START_DATE: '{field} debe ser igual a la fecha de inicio o una fecha futura',
    MAX_DATE: '{field} debe ser igual o menor que {maxDate}',
    MUST_HAVE_GREATER_THAN_NUMBER_OF_ROWS: 'Debe haber al menos {minValue} {field}',
    REQUIRES_DEPENDENT_FIELD: '{field} requiere que {field2} contenga un valor.',
    GREATER_THAN_OR_EQUAL_TO_ADDITIONAL_VALUE:
      '{field} debe ser igual a {field2} o mayor que {field2} por{additionToValue, number}.',
  },
  MECHANIC_FILTER: {
    TYPE: {
      DISCOUNT: 'Descuento',
      STEPPED_DISCOUNT: 'Descuento escalonado',
      INTERACTIVE_COMBO: 'Combo interactivo',
      REWARDS_CHALLENGE: 'Recompensas',
    },
  },
  STATUSES: {
    PROMOTION: {
      FALLBACK: 'Sin estatus',
      DESIGNED: 'Diseñada',
      PENDING_MODELING: 'Modelado pendiente',
      RECEIVED_DS: 'DS Recibido',
      RECEIVED_ERP: 'ERP Recibido',
      DRAFT: 'Borrador',
      PENDING_DELETION: 'Eliminación pendiente',
      COMPLETE: 'Completado',
      PROMOS_CREATED: 'Promociones creadas',
      DELETED: 'Eliminado',
      INVALID: 'Inválido',
      INVALID_PROMO: 'Promoción inválida',
      INVALID_PROMOS: 'Promociones inválidas',
      INVALID_DESIGN: 'Diseño inválido',
      PROMOS_LOADED: 'Promociones cargadas',
      PENDING_ALGORITHM: 'Algoritmo pendiente',
      LIVE: 'Activa',
      PROMOS_DELAYED: 'Promociones retrasadas',
      FAILED_KAFKA_SEND: 'No se pudo hacer cola',
      PENDING_ALGORITHM_DELAYED: 'Algoritmo pendiente retrasada',
      SUCCESS: 'Éxito',
      FAILED: 'Fallido',
      CREATED: 'Creado',
      DELAYED: 'Demorado',
      SYSTEM_ERROR: 'Error del sistema',
    },
  },
  VIRTUALIZATION: {
    LOADING_MORE: 'Cargando más...',
    NO_MORE_ITEMS: 'Nada más que cargar',
  },
  FORMS: {
    SKU_PARAMETERS: {
      products: { label: 'Productos', hint: 'Seleccionar productos' },
      minDiscount: {
        label: `${PORCENTAJE_DE_DESCUENTO} mínimo`,
        hint: INGRESE_UN_VALOR_DE_PORCENTAJE,
      },
      maxDiscount: {
        label: `${PORCENTAJE_DE_DESCUENTO} máximo`,
        hint: INGRESE_UN_VALOR_DE_PORCENTAJE,
      },
      allocation: { label: '% de alocación', hint: INGRESE_UN_VALOR_DE_PORCENTAJE },
      minQuantity: { label: CANTIDAD_MINIMA, hint: INGRESE_UN_VALOR },
      maxQuantity: { label: CANTIDAD_MAXIMA, hint: INGRESE_UN_VALOR },
      orderAvailability: { label: ORDENES_MAXIMAS, hint: 'Ingrese un valor numérico.' },
      maxPoints: { label: 'Puntos máximos', hint: 'Ingrese un valor numérico.' },
      budgetId: {
        label: 'ID de subregión',
        hint: 'Ingrese un único ID',
        hint_link: 'Crear nuevo presupuesto',
      },
      multiple: {
        hint: 'Ingresa una ID o varias IDs.',
        placeholder: 'Agrega una ID y presiona enter.',
      },
      noOptionsText: 'Sin opciones',
      promoId: { label: ID_DE_PROMOCION, hint: 'Ingrese un único ID.' },
      userDescription: { label: 'Descripción de usuario', hint: '' },
      skuLimit: { label: LIMITE_DE_SKU, hint: INGRESE_UN_VALOR },
      discount: { label: PORCENTAJE_DE_DESCUENTO, hint: INGRESE_UN_VALOR_DE_PORCENTAJE },
      productName: { label: 'Nombre', hint: '' },
      sku: { label: 'Sku', hint: '' },
      minQty: { label: CANTIDAD_MINIMA, hint: '' },
      maxQty: { label: CANTIDAD_MAXIMA, hint: '' },
      minPrice: { label: 'Precio mínimo $', hint: 'Ingrese un valor $.' },
      maxPrice: { label: 'Precio máximo $', hint: 'Ingrese un valor $.' },
      steps: { label: 'Escalones', hint: '' },
    },
    STEPPED_DISCOUNT: {
      minQuantity: { label: CANTIDAD_MINIMA, hint: INGRESE_UN_VALOR },
      maxQuantity: { label: CANTIDAD_MAXIMA, hint: INGRESE_UN_VALOR },
      discount: { label: 'Descuento escalonado %', hint: INGRESE_UN_VALOR_DE_PORCENTAJE },
    },
    BUDGET_PARAMETERS: {
      budgetTitle: { label: 'Título del presupuesto', hint: '' },
      budgetValue: { label: 'Valor del presupuesto', hint: INGRESE_UN_VALOR },
      businessUnit: {
        label: UNIDAD_DE_NEGOCIO,
        hint: '',
        placeholder: 'Seleccionar una unidad de negocio',
      },
      zbb: {
        label: 'Seleccione una unidad de negocio',
        hint: '',
        placeholder: 'Seleccionar presupuesto ZBB',
        dependencyPlaceholder: 'Seleccione primero una unidad de negocio',
      },
    },
    STEPS: 'Escalón',
  },
  DATE_PICKER_LABELS: { START_DATE: 'Fecha de inicio', END_DATE: 'Fecha de finalización' },
  BUTTONS: {
    ADD: 'Agregar',
    EDIT: 'Editar',
    CANCEL: 'Cancelar',
    CLEAR_PRODUCTS: 'Eliminar productos',
    CLEAR_FILTERS: 'Eliminar filtros',
    BACK: 'Atrás',
    NEXT: 'Siguiente',
    SUBMIT: 'Publicar',
    SAVE: 'Guardar',
    CREATE_NEW_BUDGET: 'Crear nuevo presupuesto',
    ADD_PRODUCTS: 'Agregar producto',
    ADD_PRODUCT: AGREGAR_PRODUCTOS,
    EDIT_PRODUCTS: 'Editar productos',
    EDIT_PRODUCT: 'Editar productos',
    CLOSE: 'Cerrar',
    DELETE: 'Eliminar',
    DUPLICATE: 'Duplicar',
    DONE: 'Listo',
    OPEN: 'Abrir',
    CLEAR_ALL: 'Borrar todo',
    APPLY: 'Aplicar',
    CONFIRM: 'Confirmar',
  },
  TOGGLES: {
    PERSONALIZED: 'Personalizada',
    NONPERSONALIZED: NO_PERSONALIZADA,
    DISCOUNT_PERCENT: PORCENTAJE_DE_DESCUENTO,
    PRICE_POINT: 'Punto de precio',
  },
  PROMOTION_STEPS: {
    STEP_1: 'Estrategia',
    STEP_2: 'Clientes',
    STEP_3: 'Productos',
    STEP_4: 'Revisar',
  },
  PROMOTION_STRATEGIES: {
    STRATEGY: 'Estrategia',
    SUBSTRATEGY: SUB_ESTRATEGIA,
    INTRODUCE: 'Introducir',
    SUSTAIN: 'Sostener',
    'GENERATE DEMAND': 'Generar demanda',
    OPERATIONS: 'Operaciones',
    'PRICE TREE': 'Arquitectura de precio',
    UNCLASSIFIED: 'Sin clasificar',
    SUB_STRATEGIES: {
      G1: 'Incrementar demanda por eventos puntuales',
      G2: 'Asegurar que los PDVs mantengan diariamente la adhesión al precio',
      G3: 'Incrementar el consumo ofreciendo un menor precio a los consumidores',
      G4: 'Optimizar surtido de PDVs',
      G5: 'Negociar exhibición en la góndola o en el planograma',
      G6: 'Programa de Trade Marketing - Descuento por alcance de objetivo',
      G7: 'Generar demanda en productos de baja rotación o próximos a vencer para evitar derrame de producto',
      I1: 'Atraer consumidores a probar nuevos productos',
      I2: 'Mejorar la cobertura de productos existentes',
      I3: 'Oferta de bienvenida B2B',
      O1: 'Promocion para alcanzar objetivos mensuales/trimestrales',
      O2: 'Responder a las dinámicas de mercado',
      O3: 'Descuento por método de pago (ej: descuento por pago en efectivo)',
      O4: 'Reducir picos de demanda (ej: anticiparse a grandes compras recurrentes)',
      O5: 'Generar demanda en productos de baja rotación o próximos a vencer para evitar derrame de producto',
      O6: 'Otros - Descuento de empleados',
      P1: 'Establecer ajuste de precio por contrato o acuerdo',
      S1: 'Quiero fomentar la compra continua',
      U: 'Sin clasificar',
    },
  },
  PROMOTION_MECHANICS: {
    ACTION: 'Acción',
    TOOLTIPS: {
      DISCOUNT:
        'Promociones donde cada SKU tiene un descuento especifico y una cantidad mínima requerida.',
      INTERACTIVE_COMBO:
        'Promociones donde una cantidad mínima puede ser satisfecha con la compra de múltiples SKU.',
      STEPPED_DISCOUNT:
        'Promociones donde la compra de una mayor cantidad resulta en mayores descuentos.',
      FIXED_DISCOUNT:
        'Instrucción de información sobre herramientas: Lorem placeholder java dice cup. \n\nEjemplo de información sobre herramientas: Lorum placeholder java dice cup.',
      FREE_GOODS:
        'Instrucción de información sobre herramientas: Loream placeholder java dice cup. \n\nEjemplo de información sobre herramientas: Lorum placeholder java dice cup.',
      REWARDS:
        'Desafios donde la compra de una cantidad requerida de un SKU puntual paga una cantidad especifica de puntos BEES',
      REWARDS_CHALLENGE:
        'Desafios donde la compra de una cantidad requerida de un SKU puntual paga una cantidad especifica de puntos BEES',
    },
    OPTIONS: {
      discount_percent: PORCENTAJE_DE_DESCUENTO,
      price_point: 'Punto de precio',
      purchase_multiple_volume_fixed: 'Volumen - Puntos fijos',
    },
    PERSONALIZED: 'Personalizada',
    NONPERSONALIZED: NO_PERSONALIZADA,
    METHOD: {
      INTERACTIVE_COMBO: 'Interactivo',
      STEPPED_DISCOUNT: 'Escalonado',
      DISCOUNT: 'Descuento',
      REWARDS: 'Recompensas',
      REWARDS_CHALLENGE: 'Recompensas',
    },
    DISCOUNT: 'Descuento',
    REWARDS: 'Recompensas',
    REWARDS_CHALLENGE: 'Recompensas',
    INTERACTIVE_COMBO: 'Combo interactivo',
    STEPPED_DISCOUNT: 'Descuento escalonado',
    FIXED_DISCOUNT: 'Descuento fijo',
    FREE_GOODS: 'Productos sin cargo',
    EMPTY_DISCOUNT_MESSAGE: 'No se agregaron descuentos',
    EMPTY_TYPE: 'Sin mecánicas disponibles',
    EMPTY_TYPE_OPTION: 'Sin opciones disponibles',
  },
  DESIGN_PAGE: {
    CREATE_PROMO: 'Crear promoción',
    HEADER_PERSONALIZED: 'Crear promoción personalizada',
    HEADER_NONPERSONALIZED: 'Crear promoción no personalizada',
    GLOBAL_PROMOS_HEADER: 'Detalles de promoción',
    BUSINESS_UNIT: {
      HEADER: 'Detalles de promoción',
      OPTION: {
        BUSINESS_UNIT_LABEL: UNIDAD_DE_NEGOCIO,
        PLACEHOLDER_OPTION: 'Seleccionar opción',
        VALUES: { unit1: 'Unidad 1', unit2: 'Unidad 2', unit3: 'Unidad 3' },
      },
    },
    PROMO_DETAILS_DESCRIPTION: 'Descrípción de promocíón',
    PROMO_DETAILS_DESCRIPTION_PLACEHOLDER: 'Ingrese descripción…',
    PROMO_DETAILS_DESCRIPTION_SUBHEADER:
      'El valor informado será usado como prefijo en la descripción de la promoción',
    COVER_IMAGE: {
      TITLE: 'Imagen de portada',
      MESSAGE:
        'La imagen de portada debe estar en el formato {fileFormat}, en tamaño {maxWidth}x{maxHeight} y con un peso menor a {maxSize}.',
      MESSAGE_WITHOUT_DIMENSION:
        'La imagen de portada debe estar en formato {fileFormat} y debe pesar menos de {maxSize}.',
      UPLOAD_BUTTON: 'Búsqueda de archivos',
      ERROR_MESSAGE: {
        name: 'Error! El nombre del archivo no debe tener espacios o rayas al piso. Por favor usar "-" para separar palabras.',
        size: 'Error! El archivo debe pesar menos de 1MB para ser cargado.',
        type: 'Error! El archivo debe estar en formato JPG/PNG para ser cargado.',
        dimension: 'Error! El archivo debe tener un tamaño de 640x370 para ser cargado.',
        spanish_special_character:
          'Error! Ñ ​​o ñ no son caracteres válidos, cambie el nombre del archivo.',
      },
    },
    CANCEL_MODAL: {
      HEADER: '¿Cancelar creación de promoción?',
      CONTENT:
        '¿Está seguro que desea cancelar? Esto lo volverá a llevar al tablero de promociones',
      CANCEL_BUTTON: 'Volver a llevarme a mi diseño',
      CONFIRM_BUTTON: 'Cancelar creación de promoción',
    },
    MECHANICS: {
      HEADER: 'Seleccionar mecánicas de promoción',
      OPTION: {
        MECHANICS_TYPE_LABEL: 'Tipo de mecánica',
        MECHANICS_TYPE_OPTION_LABEL: 'Opciones de mecánica',
        PLACEHOLDER_TYPE: 'Seleccionar tipo',
        PLACEHOLDER_OPTION: 'Seleccionar opción',
      },
    },
    PERSONALIZATION: {
      HEADER: 'Personalización',
      PERSONALIZED_true: 'Personalizada',
      PERSONALIZED_false: NO_PERSONALIZADA,
      CONTENT:
        'Seleccionando "Personalizada" podrá aplicar algoritmos para (1) alocar la promoción a un grupo optimizado de PDVs y (2) optimizar los parámetros de promoción (ej: descuento ofrecido) para cada PDV. Seleccione "No personalizada" para dar un descuento fijo a un grupo estático de PDVs.',
    },
    PROMOTION_DATE_SELECTION: 'Seleccionar fecha',
    PROMOTION_STRATEGY_SELECTION: { HEADER: 'Estrategia' },
    PROMOTION_RUN_DURATION: 'Esta promoción durara por {duration}',
    PROMOTION_SUBSTRATEGY_SELECTION: {
      HEADER: SUB_ESTRATEGIA,
      EMPTY_CARD_DESC: 'Pruebe seleccionando estrategias',
      EMPTY_CARD_HEADER: 'Sin resultados momentaneamente',
    },
    PROMOTION_TITLE: {
      HEADER: 'Título',
      PLACEHOLDER: 'Introduzca un ID de campaña...',
    },
    PROMOTION_CAMPAIGN_ID: {
      HEADER: 'ID de campaña',
      PLACEHOLDER: 'Introduzca un ID de campaña...',
      TOOLTIP: 'Una misma campaña permite múltiples desafíos.',
    },
    PRODUCTS: {
      HEADER: AGREGAR_PRODUCTOS,
      PRODUCTS_TABLE_EMPTY: 'Agregue un producto para empezar…',
      PRODUCTS_EMPTY_SEARCH_RESULTS: 'No se encontraron productos',
      PRODUCTS_SEARCH_PLACEHOLDER: 'Buscar nombre o SKU',
      LOAD_MORE: 'Carga más',
      LOAD_X_PRODUCTS: 'Cargue {loadXProducts} productos a la vez.',
      SHOWING_X_OUT_OF_X_PRODUCTS:
        'Mostrando {loadedLength, number} de {allProductsLength, number} productos',
      PRODUCTS: 'Productos',
      PARAMETERS: 'Parametros',
      EMPTY_PRODUCTS: `${AGREGAR_PRODUCTOS} para empezar...`,
      EMPTY_PARAMETERS: 'Agregar parámetros para empezar...',
      EMPTY_FILTERS: 'No hay productos disponibles para los filtros seleccionados.',
      DELETE_TOOLTIP: 'Eliminar',
      EDIT_TOOLTIP: 'Editar',
      LOADING: 'Cargando productos',
      NA: 'N/A',
      BUDGET_MODAL: {
        HEADER: 'Crear un nuevo presupuesto',
        SUB_HEADER:
          'Nuevas iniciativas de presupuesto serán asignadas a las promociones en el diseño',
      },
      PRODUCT_FILTERS: {
        SEGMENT: 'Segmentos',
        BRAND: 'Marcas',
        BRAND_TYPE: 'Tipos de marcas',
        CATEGORY: 'Categorías',
        PRESENTATION: 'Calibre',
        TYPE: 'Tipos',
        BUSINESS_UNITS: 'Unidades de negocio',
        BUSINESS_UNIT: UNIDAD_DE_NEGOCIO,
        CONTAINER_TYPE: 'Presentación',
        PRICE_SEGMENT_NAME: 'Segmento de precio',
        SIZE: 'Tamaño',
        PACKING: 'Empaque',
        ZONE_CONFIGURATION_MISSING: 'falta configuración',
      },
      PRODUCTS_MODAL: {
        HEADER: `${AGREGAR_PRODUCTOS} y establecer parámetros`,
        STEP_1: AGREGAR_PRODUCTOS,
        STEP_2: 'Establecer parámetros',
        SUB_HEADER: 'Seleccionar productos para agregar a esta promoción',
        EMPTY: 'No se agregaron productos',
        SEARCH_PLACEHOLDER: 'Buscar',
        PARAMETERS_SUB_HEADER:
          'Por favor ingrese los descuentos escalonados. El algoritmo personalizará el primer escalón/cantidad mínima a ofrecer a cada PDV, manteniendo constante el descuento para todos los PDVs.',
      },
      TABLE_COLUMNS: { actions: 'Acciones', productName: 'Producto', sku: 'SKU' },
      DETAILS_TABLE: { PRODUCTS_TABLE_EMPTY: 'No se encontro el producto' },
      STEPS_TABLE: {
        stepId: 'Escalón',
        minQuantity: CANTIDAD_MINIMA,
        maxQuantity: CANTIDAD_MAXIMA,
        minDiscount: 'Descuento mínimo',
        maxDiscount: 'Descuento máximo',
        discount: 'Descuento',
      },
      STEPPED_PARAMETERS: {
        allocation: 'Alocación %',
        orderAvailability: ORDENES_MAXIMAS,
        skuLimit: LIMITE_DE_SKU,
        budgetId: 'ID de presupuesto',
      },
    },
    DUPLICATE_PROMOTION: {
      CONFIRMATION_HEADER: '¿Duplicar esta promoción?',
      CONFIRMATION_SUBTITLE:
        'Un nuevo diseño de promoción será generado con la misma estrategia, alcance de PDVs, elección de SKU y elección de parámetros. Se dará la oportunidad de proveer un nuevo rango de fechas o editar algún dato antes de ser enviado. ',
      PROMO_ID: 'ID de diseño de promoción:',
    },
  },
  HOMEPAGE_PROMOTION: {
    PROMO_LIST: 'Todas las ofertas',
    SINGLE_DATE: 'Fecha única',
    FILTER: {
      INPUT_FIELD_PLACEHOLDER: 'Ingrese {placeholderKey}',
      SEARCH_PLACEHOLDER: 'Buscar por {placeholderKey}',
      NUMBER_OF_OPTIONS_SELECTED: '{selectedOptions} seleccionado',
      EMPTY_RESULT: 'No se han encontrado resultados',
    },
    MESSAGES: {
      NO_PROMOTIONS: 'No se encontraron promociones en la búsqueda realizada',
      LOADING_PROMOTIONS: 'Cargando promociones',
      VIEW_DETAILS: 'Ver detalles',
      MARK_FOR_DOWNLOAD: 'Marcar para descargar',
      UNMARK_FOR_DOWNLOAD: 'Desmarcar para descargar',
      DOWNLOAD_DISABLED_FOR_STATUS: 'Descargar deshabilitado por estatus: {field}',
      MAX_DOWNLOAD_LIMIT:
        'Cantidad de descargas máximas alcanzadas. Desmarque otra promoción para poder marcar esta.',
      NOT_AVAILABLE_FOR_DOWNLOAD: 'Esta promoción no puede ser descargada',
      NA: 'N/A',
    },
    PROMOTION_TABLE_COLUMNS: {
      actions: 'Acciones',
      lastUpdatedTimestamp: FECHA_DE_CREACION,
      createdAt: FECHA_DE_CREACION,
      promoDesignTitle: 'Título de promoción',
      promoDesignTitleDisplay: 'Título de promoción',
      PROMO_STRATEGY: 'Estrategia',
      PROMO_SUBSTRATEGY: SUB_ESTRATEGIA,
      dateRange: 'Rango de fecha',
      status: 'Estado',
      creatorName: 'Creada por',
      EMAIL: 'Email',
      mechanicType: 'Tipo de mecánica',
      promoDesignId: 'ID de diseño de promoción',
      SORT_BY_TOOLTIP: 'Filtrado por',
      promoId: ID_DE_PROMOCION,
      pocId: 'ID de POC',
      sku: 'SKU',
      erpId: 'ID de ERP',
    },
    PROMOTION_TABLE_TOPBAR: {
      SEARCH: 'Buscar',
      NEW_PROMO: 'Nueva promoción',
      DOWNLOAD: 'Descargar CSV',
      CLEAR_MARKED: 'Eliminar selección para descargar',
      DOWNLOAD_PROMO_ERP_HISTORY: 'Descargar Promo ERP Historial CSV',
      ADD_FILTER: 'Agregar filtro',
    },
    DELETE_MODAL: {
      BACK_LINK: 'Volver',
      HEADER: '¿Eliminar esta promoción?',
      SUB_HEADER:
        'El diseño de promoción será eliminado. Todas las promociones relacionadas expirarán o no serán creadas',
      PROMO_ID_TEXT: 'ID de diseño de promoción:',
    },
    DELETE_TOAST: 'Promoción eliminada satisfactoriamente. \u00A0"{promoName}"',
    SUCCESS_CREATED_TOAST: 'Promoción creada satisfactoriamente. \u00A0"{promoName}"',
    PROMO_DESIGN_MODAL: {
      LIFECYCLE_true: 'Ciclo de vida activado',
      LIFECYCLE_false: 'Ciclo de vida desactivado',
      createdBy: 'Creada por',
      creatorEmail: 'Email del creador',
      strategy: 'Estrategia',
      subStrategy: SUB_ESTRATEGIA,
      details: 'Detalles',
      products: 'Productos',
      erpHistory: 'Historial del ERP',
      externalHistory: 'Historia externa',
      promoId: 'ID de diseño de promoción',
      download: 'Descarga',
      periodStartDate: 'Fecha de inicio',
      periodEndDate: 'Fecha de finalización',
      pocSegment: 'Segmento del PDV',
      pocSubSegment: 'Sub-segmento del PDV',
      NO_DATA: 'Sin información',
      dateRange: 'Rango de fecha',
      createdAt: FECHA_DE_CREACION,
      method: 'Método',
      mechanicsOptionType: 'Tipo',
      businessUnit: UNIDAD_DE_NEGOCIO,
      coverImage: 'Cover Image',
      ACCORDION: {
        salesRegions: 'Regiones de venta',
        states: 'Estados',
        dcids: 'ID del centro de distribución',
        NO_DATA: 'Sin información  ',
        pocInclude: 'PDV incluído',
        pocExclude: 'PDV excluído',
      },
      challengeId: 'Identificación del desafío',
    },
  },
  OVERLAPPING_PROMOS: {
    LOADING: 'Cargando promociones superpuestas',
    DOWNLOAD_DETAILS: 'Descargar detalles',
    INSTRUCTIONS:
      'Abajo puedes encontrar los diseños promocionales que se cruzan con este diseño promocional. Haz click en una fila para expandirla y ver mas detalles. Como alternativa, también puedes descargar el archivo JSON para ver mas detalles.',
    PERCENT_OVERLAP: 'Superposición',
    NUM_POCS: '# PDVs',
    OVERLAPPING: '{count, number} superpuesto',
    ELIGIBLE: '{count, number} elegible',
    WITHIN_DESIGN: '{count, number} dentro del diseño',
    QUANTITY: 'Cantidad',
    DEAL_ID: 'ID de oferta',
    DISCOUNT: 'Descuento',
  },
  ERP_HISTORY_TABLE: {
    LOADING: 'Cargando historial del ERP',
    fields: {
      ERP_ID: 'ID del ERP',
      TYPE: 'Tipo',
      ERP_STATUS: 'Estado del ERP',
      DATE: 'Fecha',
      PROMO_ID: ID_DE_PROMOCION,
      ERP_RESPONSE: 'Respuesta del ERP',
      SEQ_ID: 'ID de secuencia',
      STAGE: 'Estado',
      RESULT: 'Resultado',
    },
  },
  EXTERNAL_HISTORY_TABLE: {
    LOADING: 'Cargando historia externa',
    fields: {
      STATUS: 'Estado',
      DATE: 'Fecha',
      EXTERNAL_RESPONSE: 'Respuesta externa',
    },
  },
  PRODUCTS_TABLE: {
    HEADERS: {
      products: { label: 'Productos' },
      minDiscount: { label: 'Descuento mínimo' },
      maxDiscount: { label: 'Descuento máximo' },
      allocation: { label: 'Alocación' },
      minQuantity: { label: CANTIDAD_MINIMA },
      maxQuantity: { label: CANTIDAD_MAXIMA },
      orderAvailability: { label: ORDENES_MAXIMAS },
      promoId: { label: ID_DE_PROMOCION },
      userDescription: { label: 'Descripción de usuario' },
      skuLimit: { label: LIMITE_DE_SKU },
      discount: { label: 'Descuento' },
      productName: { label: 'Nombre' },
      sku: { label: 'Sku' },
      minQty: { label: CANTIDAD_MINIMA },
      maxQty: { label: CANTIDAD_MAXIMA },
      minPrice: { label: 'Precio mínimo' },
      maxPrice: { label: 'Precio máximo' },
      steps: { label: 'Escalones' },
      budgetId: { label: 'ID de subregión' },
      maxPoints: { label: 'Puntos máximos' },
    },
    LOADING: 'Cargando productos',
  },
  PROMO_HISTORY_TABLE: {
    TAB_TITLE: 'Historial de promociones',
    CHALLENGE_TAB_TITLE: 'Historial de desafíos',
    HEADERS: {
      message: 'Mensaje',
      date: 'Fecha',
      lastUpdatedTimestamp: 'Fecha',
      promoId: ID_DE_PROMOCION,
      promoDesignId: ID_DE_PROMOCION,
      erpId: 'ID de ERP',
      erpStatus: 'Estado ERP',
      status: 'Estado ERP',
      pocsAssigned: 'POC asignados',
      pocsExternal: 'POC en ERP',
      pocsBees: 'POC en BEES',
    },
    LOADING_TAB: 'Cargando historial de promociones',
    EMPTY_TABLE_MESSAGE: 'No se ha encontrado ningún historial de promociones',
    FILE_DOWNLOAD_NAME: 'Error en el historial de promociones',
    ERROR_MESSAGE: 'Un error ocurrio al cargar el histórico de los promociones.',
  },
  AUTOCOMPLETE: { ALL: 'Todos' },
  COMMON_HISTORY: {
    ERROR_MESSAGE: 'El diseño promocional encontró un error: {message}',
    DOWNLOAD_FULL_MESSAGE: 'Descargar mensaje completo',
    EMPTY_PROMO_DESIGN_HISTORY:
      'No se encontro un histórico de diseño de promoción para este diseño de promoción.',
    DOWNLOAD_JSON_FAILED:
      'Ocurrió un error al descargar este mensaje. Por favor intenta de nuevo o levanta una solicitud de servicio.',
  },
  PROMO_DESIGN_HISTORY: {
    HEADER: 'Historial de diseño promocional',
    CHALLENGE_HEADER: 'Historia del diseño del desafío',
    LOADING_TAB: 'Cargando historial de diseño de promoción',
    COLUMNS: {
      message: 'Mensaje',
      promotionCount: 'Cuenta de promociones',
      promotionCntLoaded: 'Cuenta de promociones',
      timestamp: 'Fecha',
      lastUpdatedTimestamp: 'Fecha',
      status: 'Estado',
    },
    FILE_DOWNLOAD_NAME: 'Error de historial de diseño de promoción',
    ERROR_MESSAGE: 'Un error ocurrio al cargar el histórico del diseño promocional.',
  },
  MESSAGES: {
    COUNTRY_SWITCH_SUCCESS: 'Cambiado a {countryName}',
    COUNTRY_ACCESS_DENIED: 'No posees permiso para usar {country} en BEES Lift',
    ERROR_UNAVAILABLE_COUNTRY:
      '¡Ups! {country} no está listo. \n\nPor favor, contacte a su administrador o intente nuevamente.',
    ERROR_ACCESS_DENIED:
      '¡Ups! \n\nNo posees permisos para usar {country} en BEES Lift. \n\nPor favor, contacte a su administrador o intente nuevamente.',
  },
  POCS_FILTER: {
    FILTER_TITLE: 'Seleccionar PDVs',
    POCS_BY_FILTER: 'PDVs por filtro',
    POCS_BY_UPLOAD: 'PDVs por carga archivo',
    POCS_SELECTED_COUNT: '{count} PDVs seleccionados',
    SELECT_ALL_POCS: 'Seleccionar todos los PDVs',
    UPLOAD_EXCLUDE_POCS: 'Subir los PDVs a excluir',
    SEE_EXCLUSION_LIST: '\u00A0Ver listado de exclusión',
    POCS_EXCLUDE_LABEL: 'PDVs subidos a excluir',
    FULL_LIST_SUB_HEADING_EXCLUDE: 'Los siguientes PDVs serán excluidos de su nueva promoción',
    UPLOAD: {
      MAXIMUM_UPLOAD_SIZE_REACHED:
        'Ha excedido el límite máximo de archivos POC admitidos (actualmente 750kbytes), cree más de una promoción si necesita ingresar muchos valores POC',
      INSTRUCTIONS: 'Clickear para seleccionar los archivos o arrastrarlos aquí',
      ON_DRAG_INSTRUCTIONS: 'Arrastre sus archivos aquí',
      APPLY_LIFECYCLE: 'Aplicar lifecycle',
      EMPTY_UPLOAD_MESSAGE: 'No se ha cargado ningún archivo.',
      POCS_UPLOAD_LABEL: 'PDVs subidos',
      POCS_UPLOAD_COUNT: '{count} de PDVs subidos',
      SEE_FULL_LIST: '\u00A0Ver listado de inclusiones',
      FULL_LIST_SUB_HEADING: 'Los siguientes PDVs recibirán su nueva promoción',
      FORMAT_INSTRUCTIONS: 'Extensión .CSV',
      FORMAT_ERROR_MESSAGE:
        'El archivo subido {fileName} no es del formato esperado. El archivo debe contener una sola columna con los códigos de PDVs sin título y tener formato .CSV',
      DUPLICATE_FILE: 'Archivo {fileName} ya fue subido',
      WARNING: 'Advertencia',
      ERROR: 'Error',
      TABLE_HEADER: 'ID del PDV',
      TABLE_HEADER_STATUS: 'Estado',
      INVALID_TABLE_HEADER: 'ID del PDV inválido',
      STATUS_VALID: 'Válido',
      STATUS_INVALID: 'Inválido',
      WAIT_FOR_VALIDATE_END:
        'Por favor espere hasta que los PDVs hayan sido validados antes de intentar eliminar nuevos PDVs',
      INVALID_POCS: {
        TITLE: 'PDVs inválidos',
        ALL_INVALID_MESSAGE:
          'Ningúno de los PDVs subidos es válido. No podrá crear un su diseño de promoción hasta que esto sea corregido. Por favor suba un nuevo set de PDVs con IDs válidos',
        SOME_INVALID_MESSAGE:
          'Los siguientes PDVs son inválidos. Puede continuar creando su diseño de promoción, pero estos PDVs no se tendrán en cuenta.',
        INVALID_MESSAGE_PROCEED:
          'Los archivos subidos contienen {count} de PDVs inválidos. Puede continuar creando el diseño de promoción, pero los PDVs inválidos no se tendrán en cuenta.',
        INVALID_MESSAGE_CANNOT_PROCEED:
          'Los archivos subidos contienen {count} de PDVs inválidos. Ninguno de los PDVs fueron válidos. Un diseño de promoción debe contener al menos un PDV válido para poder ser enviado',
        INVALID_ALT_SUPPORT:
          'Por otro lado, puede revisar sus archivos subidos y probar nuevamente despúes de solucionar el problema.',
      },
    },
  },
  REVIEW_TITLE: 'Revisar diseño de promocíón',
  DEFAULT_PLACEHOLDER: 'Ingrese su input',
  REQUEST_SUPPORT: {
    REPORT_AN_ISSUE: 'Reportar un problema',
    CHECK_ISSUE_STATUS: 'Controlar estado del problema',
    SUBMIT: {
      SUCCESS_MESSAGE: 'Su ticket para soporte fue ingresado',
      ERROR_MESSAGE:
        'Ocurrió un error al crear el requerimiento para soporte. Por favor intente de nuevo o cree un ticket manualmente',
    },
    PAGE_TITLE: 'Solicitudes de soporte',
    TAB_TITLE: 'Solicitudes de soporte',
    LOADING_REQUESTS: 'Cargando solicitudes de soporte',
    EMPTY_PROMO_DESIGN: 'No se encontraron solicitudes de soporte para este diseño de promoción',
    EMPTY: 'No se encontraron solicitudes de soporte',
    FIELDS: {
      SUMMARY: 'Resumen',
      DESCRIPTION: 'Descripción',
    },
    TABLE: {
      KEY: 'Clave de problema',
      STATUS: 'Estado',
      REPORTER: 'Reportado por',
      PRIORITY: 'Prioridad',
      UPDATED: 'Actualizado',
      SUMMARY: 'Resumen',
    },
    MODAL_TITLE: 'Pedir soporte',
    MODAL_INSTRUCTIONS_GENERIC:
      'Por favor describa el problema que tiene. Una vez finalizado, haga click en el botón Ingresar y su requerimiento para soporte será enviado a un miembro del equipo. Por favor navegue a "Controlar estado del problema" en la barra lateral para ver el listado de tickets.',
    MODAL_INSTRUCTIONS_PROMO_DESIGN:
      'Por favor describa el problema que tiene. Una vez finalizado, haga click en el botón Ingresar y su requerimiento para soporte será enviado a un miembro del equipo. Por favor navegue en la pestaña "Solicitudes de soporte" para ver el listado de tickets.',
  },
  REVIEW_PROMO: {
    DETAILS_TAB: 'Detalles',
    PRODUCTS_TAB: 'Productos',
  },
  SET_NAME_OPTION: {
    MESSAGE: 'Tu nombre de usuario no parece estar actualizado, aun es "Nuevo Usuario". Por\u00A0',
    UPDATE_MESSAGE: 'favor actualízalo',
    DONT_SHOW_AGAIN_MESSAGE: 'No volver a mostrar este mensaje',
  },
  FEATURE_TOGGLES_PAGE: {
    TITLE: 'Alternancias de funciones',
    TABS: {
      FEATURE_TOGGLES: 'Alternancias de funciones',
      AUDIENCES: 'Audiencias',
    },
    USER_ID: 'Id usuario',
    COLUMNS: {
      FEATURE: 'Característica',
      DEFAULT: 'Por defecto',
    },
    LOADING_AUDIENCES: 'Cargando audiencias',
    LOADING_USERS: 'Cargando usuarios',
    NO_AUDIENCES: 'No se encontraron audiencias',
    NO_USERS: 'No se encontraron usuarios',
  },
  AUDIENCES_FORM: {
    CREATE_COUNTRY_AUDIENCE: 'Agregar audiencia de país',
    CREATE_USER_AUDIENCE: 'Agregar audiencia de usuario',
    ADD_USERS: 'Agregar usuarios',
    NO_IDENTITY_PERMISSIONS: 'Tu cuenta no tiene permisos para leer información de identidad.',
    EDITABLE_DISCLAIMER:
      'Esta audiencia es editable. Administra los usuarios mostrados abajo. Nota: solo puedes administrar usuarios que se encuentran en este ambiente. Uusarios que existen en otros ambientes (por ejemplo: DEV, SIT, UAT, PROD) deben ser administrados dentro de esos ambientes.',
  },
  ADD_USERS: {
    TITLE: 'Agregar usuarios',
    ADD_ANOTHER: 'Agrega otro',
    ADD_USER: 'Añadir ID de usuario, nombre, o dirección de correo electronico.',
    USER: 'Usuario',
    LOADING_USERS: 'Cargando usuarios',
    SUB_HEADER:
      'Añadir usuarios a esta audiencia hará cambios en el ambiente del proveedor de palancas de caractersiticas.',
  },
  PROGRESS_BAR: {
    POC_COUNT: '{count, plural, one {1 PDV} other {# PDVs}}',
  },
  CSV: 'CSV',
  ERROR_MESSAGES: {
    NO_INTRODUCE_WITH_STEPPED_DISCOUNT:
      'Descuentos personalizados escalonados con estrategia "Introduce" no son soportados.',
    NO_GENERATE_DEMAND_WITH_INTERACTIVE_COMBO:
      'Combos interactivos personzalizados con estrategia "Generate Demand" nos son soportados.',
    NO_SUSTAIN_WITH_STEPPED_DISCOUNT:
      'Descuentos personalizados escalonados con estrategia "Sustain" no son soportados.',
    NO_OPERATIONS_WITH_STEPPED_DISCOUNT:
      'Descuentos personalizados escalonados con estrategia "Operations" no son soportados.',
    NO_OPERATIONS_WITH_REWARDS_CHALLENGE:
      'Recompensas personalizadas con estrategia "Operations" no son soportados.',
    OVERLAPPING_PROMO_DESIGN:
      '{count, plural, one {Este diseño promocional se cruza con {promoDesignId}} other {Este diseño promocinoal se cruza con # otros diseños promocionales.}}',
    DISTINCT_BUDGET_ID_ERROR: 'Debe haber una sola ID de presupuesto para todos los SKUs.',
    INVALID_SKUS_ERROR:
      '{count, plural, one {Este diseño de promoción incluye un SKU no válido: {sku}} other {Este diseño de promoción incluye # SKU no válidos: {sku}}}',
  },
  IMAGE_UPLOAD: {
    LOADING_IMAGE: 'Cargando imágenes',
  },
  POC_FILTERS: {
    SALES_REGION: 'Region de ventas',
    SUB_CANAL: 'Subcanal',
    TYPOLOGY: 'Tipología',
    REGION: 'Región',
    MANAGEMENT: 'Gerencia',
    SEGMENT: 'Segmento',
    SUB_SEGMENT: 'Subsegmento',
    SALES_DISTRICT: 'Distrito de ventas',
    SALES_TEAM: 'Equipo de ventas',
    CHANNEL: 'Canal',
    AREA: 'Área',
    SUB_REGION: 'SubRegión',
    SUBCHANNEL: 'Subcanal',
    UN: 'UN',
    CD: 'CD',
    STATE: 'Estado',
    UNB: 'UNB',
    ZONE_CONFIGURATION_MISSING: 'Falta el archivo de configuración de zona',
  },
  PROMO_DESIGN_DATE_VALIDATIONS: {
    MODIFY_DATE_RANGE: 'Modificar rango de fechas',
    DATE_RANGE_INVALID:
      'Debe proporcionar fechas de inicio y finalización válidas para enviar este diseño. Modifique el rango de fechas para ajustar las fechas.',
    NO_LONGER_VALID_DATE_RANGE:
      'La fecha de inicio seleccionada {date} ya no está disponible. La próxima fecha de inicio disponible es el {minDate}. Modifique el rango de fechas para ajustar las fechas.',
  },
};

export default es419;
