import { dateWithoutTime } from './dateWithoutTime';
import { addDays } from 'date-fns';
import {
  MAX_PROMOTION_DURATION_DAYS,
  MAX_PROMOTION_END_DAYS_WITHOUT_START_DATE,
} from '~/constants';
import { IDateRange } from '~/interfaces';

export default (
  dateSelection: IDateRange,
  maxDate?: number,
  maxPromoEndDaysWithoutStartDate?: number
): string => {
  const todaysDate: string = dateWithoutTime(new Date());

  return dateSelection.startDate !== ''
    ? dateWithoutTime(
        addDays(new Date(dateSelection.startDate), maxDate ?? MAX_PROMOTION_DURATION_DAYS)
      )
    : dateWithoutTime(
        addDays(
          new Date(todaysDate),
          maxPromoEndDaysWithoutStartDate ?? MAX_PROMOTION_END_DAYS_WITHOUT_START_DATE
        )
      );
};
