import React, { ChangeEvent } from 'react';
import { useIntl } from 'react-intl';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useSelectedCountry } from '~/hooks';
import { TMenuSelection } from './MenuSelection.types';
import { getTestIdAttr } from '~/utils';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
  menuList: {
    height: '100%',
    overflow: 'scroll',
    position: 'absolute',
    width: '100%',
  },
  menuItem: {
    width: '100%',
    padding: '0',
  },
  fieldLabel: {
    margin: '0',
    padding: '10px',
  },
}));

const MenuSelection: TMenuSelection = ({
  open = true,
  inputValue = {},
  handleInputValue,
  i18nMapLocation,
  getOptions,
}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const selectedCountry = useSelectedCountry();

  const handleChecked = (checked: boolean, key: string): void => {
    if (checked) {
      handleInputValue({ ...inputValue, [key]: checked });
    } else {
      const { [key]: checked, ...rest } = inputValue;
      handleInputValue({ ...rest });
    }
  };
  const textView = (name: string, label: string): JSX.Element => {
    return (
      <FormControl component="fieldset" style={{ width: '100%' }}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                color={'default'}
                checked={!!inputValue[name]}
                name={name}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleChecked(event.target.checked, name)
                }
              />
            }
            label={label}
            className={classes.fieldLabel}
          />
        </FormGroup>
      </FormControl>
    );
  };

  return (
    <MenuList
      {...getTestIdAttr('MenuSelection')}
      autoFocusItem={open}
      id="menu-list"
      className={classes.menuList}
    >
      {getOptions(selectedCountry).map((configValues) => (
        <MenuItem key={configValues} className={classes.menuItem}>
          {textView(
            configValues,
            formatMessage({
              id: `${i18nMapLocation}.${configValues}`,
              defaultMessage: configValues,
            })
          )}
        </MenuItem>
      ))}
    </MenuList>
  );
};

export default MenuSelection;
