import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

const useQueryParam = (key: string, defaultVal: string): [string, (val: string) => void] => {
  const [searchParams, setSearchParams] = useSearchParams();

  return useMemo(() => {
    const value = searchParams.has(key) ? searchParams.get(key) : defaultVal;

    return [
      value,
      (updateValue: string) => {
        const newParams = new URLSearchParams(searchParams);
        newParams.set(key, updateValue);
        if (!updateValue) {
          newParams.delete(key);
        }
        setSearchParams(newParams);
      },
    ];
  }, [searchParams]);
};

export default useQueryParam;
