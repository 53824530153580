import { NumberSchema } from 'yup';
import { IValidationMessages, IValidationRule } from '~/interfaces';

export const greaterThanOrEqualToAllowZero = (
  validation: NumberSchema,
  rule: IValidationRule,
  fieldName: string,
  validationCallbacks: IValidationMessages
): NumberSchema => {
  validation = validation.when([rule.field], (dependentValue: number, schema: NumberSchema) => {
    if (dependentValue) {
      return schema.when(fieldName, (thisField) => {
        if (thisField !== 0) {
          return schema.min(
            dependentValue,
            validationCallbacks.greaterThanOrEqualToAllowZero(fieldName, rule.field)
          );
        }
      });
    } else {
      return schema.when(fieldName, (thisField) => {
        if (thisField !== 0 && rule?.dependent) {
          return schema.min(
            dependentValue,
            validationCallbacks.requiresDependentField(fieldName, rule.field)
          );
        }
      });
    }
  });
  return validation;
};
