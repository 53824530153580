import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { TypeToast, useToast } from 'admin-portal-shared-services';
import { selectCountriesState, setCountry } from '~/redux/slices';
import { Button, Dialog, Grid, Heading, LoadingDots, Paragraph } from '@hexa-ui/components';
import { resetAll } from '~/redux/saga/sagaActions';
import { Buttons } from '~/components/Messages';
import { LIFT_ROUTES } from '~/constants';
import { getTestIdAttr } from '~/utils';
import { CountryDropdown, SingleCountry } from '~/components/CountrySelector/components';
import { useAppDispatch, useAppSelector } from '~/hooks';
import { ChangeHandler } from './CountrySelector.types';
import useStyles from './CountrySelector.styles';
import './CountrySelector.css';

const CountrySelector = (): JSX.Element => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [selectedCountry, setSelectedCountry] = useState('BR');
  const [changeCountryModal, setChangeCountryModal] = useState<boolean>(false);
  const [currentSelection, setCurrentSelection] = useState<string>('');
  const { formatMessage, formatDisplayName } = useIntl();
  const dispatch = useAppDispatch();

  const notificationService = useToast();

  const {
    countries,
    selectedCountry: selectedCountryGlobal,
    isLoading,
  } = useAppSelector(selectCountriesState);

  useEffect(() => {
    setSelectedCountry(selectedCountryGlobal);
  }, [selectedCountryGlobal]);

  const handleSelection: ChangeHandler = (event): void => {
    const previous = `${selectedCountryGlobal}`; //shallow copy of this
    const { value } = event?.target || {};
    // @ts-ignore
    setCurrentSelection(value);
    if (previous !== value) {
      setChangeCountryModal(true);
    }
  };

  const changeCountry = (): void => {
    dispatch(setCountry({ selectedCountry: currentSelection }));
    notificationService.notify({
      type: TypeToast.SUCCESS,
      message: formatMessage(
        { id: 'MESSAGES.COUNTRY_SWITCH_SUCCESS', defaultMessage: 'Switched to {countryName}' },
        {
          countryName: formatDisplayName(currentSelection, {
            type: 'region',
          }),
        }
      ),
    });
    localStorage.removeItem('notify');

    setChangeCountryModal(false);
    dispatch(resetAll());
    navigate(LIFT_ROUTES.HOME);
  };

  if (!countries?.length) {
    return null;
  }

  if (countries.length === 1 && selectedCountryGlobal) {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <SingleCountry country={selectedCountryGlobal} />
      </div>
    );
  }

  return (
    <div
      {...getTestIdAttr('CountrySelector')}
      id="country-selector"
      className={classes.componentStyle}
    >
      <Dialog.Root
        open={changeCountryModal}
        onClose={() => setChangeCountryModal(false)}
        title={
          <Grid.Container style={{ width: '100%' }}>
            <Grid.Item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Heading size="H2">
                <FormattedMessage
                  id={'COUNTRY_SELECTOR_MODAL.HEADER'}
                  defaultMessage={'Changing Countries?'}
                />
              </Heading>
            </Grid.Item>
            <Grid.Item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Paragraph colortype="secondary" style={{ marginTop: '8px' }}>
                <FormattedMessage
                  id={'COUNTRY_SELECTOR_MODAL.SUB_HEADER'}
                  defaultMessage={
                    'Changing countries will clear all of your saved settings and you will be redirected to the homepage. Would you still like to continue?'
                  }
                />
              </Paragraph>
            </Grid.Item>
          </Grid.Container>
        }
        actions={
          <div className="button-container">
            <Button
              {...getTestIdAttr('Buttons.Cancel')}
              elevated
              size="medium"
              variant="secondary"
              onClick={() => setChangeCountryModal(false)}
            >
              <Buttons.Cancel />
            </Button>
            <Button
              {...getTestIdAttr('Buttons.Confirm')}
              elevated
              className="confirm-button"
              onClick={() => changeCountry()}
            >
              <Buttons.Confirm />
            </Button>
          </div>
        }
      ></Dialog.Root>
      {isLoading && (
        <div className={classes.countryNoSelection}>
          <LoadingDots color="mono" size="xxlarge" />
        </div>
      )}
      {!isLoading && (
        <CountryDropdown
          countries={countries}
          selectedCountry={selectedCountry}
          handleSelection={handleSelection}
        />
      )}
    </div>
  );
};

export default CountrySelector;
