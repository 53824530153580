import styled from 'styled-components';

export const TagItem = styled.div`
  background-color: #e9f1ff;
  font-size: 14px;
  border-radius: 30px;
  height: 36px;
  padding: 0 1rem;
  display: inline-flex;
  align-items: center;
  margin: 0 0.3rem 0.3rem 0;
  margin-right: 16px;
`;

export const TagButton = styled.button`
  background-color: #e9f1ff;
  color: #0363c4;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font: inherit;
  margin-left: 10px;
  font-weight: 400;
  font-size: 18px;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2px;
`;
