import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import Bff from '~/Api/Bff';
import { useSelectedCountry } from '~/hooks';
import { ISkuParametersResponse, TMechanicType, TMechanicTypeOption } from '~/interfaces';
import { AxiosResponse } from 'axios';

interface IGetSKUParameters {
  isPersonalized: boolean;
  mechanicsType: TMechanicType;
  mechanicsTypeOption: TMechanicTypeOption;
}

export const getSkuParameters = ({
  isPersonalized,
  mechanicsType,
  mechanicsTypeOption,
}: IGetSKUParameters): Promise<AxiosResponse<ISkuParametersResponse>> => {
  return Bff.get<ISkuParametersResponse>({
    url: `/v2/parameters/sku?personalized=${isPersonalized}&mechanicType=${mechanicsType}&mechanicTypeOption=${mechanicsTypeOption}`,
  }).then((response) => {
    return response;
  });
};

export const useGetSkuParameters = (
  payload: IGetSKUParameters,
  options?: UseQueryOptions
): UseQueryResult<ISkuParametersResponse> => {
  const selectedCountry = useSelectedCountry();
  return useQuery(
    ['getSkuParameters', selectedCountry, payload],
    async () => {
      const response = await getSkuParameters(payload);
      return response?.data?.parameters;
    },
    { ...options }
  );
};
