import { configureStore, PreloadedState } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from './storage';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './saga/saga';
import {
  BudgetSlice,
  CountriesReducer,
  PromoDesignReducer,
  GlobalReducer,
  PocFilterReducer,
  SkuReducer,
  ProductsReducer,
  PromoReducer,
  GlobalToastReducer,
} from '~/redux/slices';

let sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

export const reducers = combineReducers({
  global: GlobalReducer,
  countries: CountriesReducer,
  pocFilter: PocFilterReducer,
  sku: SkuReducer,
  products: ProductsReducer,
  promo: PromoReducer,
  budgets: BudgetSlice,
  promoDesign: PromoDesignReducer,
  globalToast: GlobalToastReducer,
});

const persistConfig = {
  key: 'Lift',
  storage: storage('lift'),
  version: 1,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export type StoreState = ReturnType<typeof store.getState>;

export const setupStore = (preloadedState?: PreloadedState<typeof reducers>) => {
  return configureStore({
    reducer: persistedReducer,
    middleware,
    preloadedState,
  });
};

const store = setupStore();

sagaMiddleware.run(rootSaga);

export default store;
export type RootState = ReturnType<typeof reducers>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
