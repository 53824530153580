import { ArraySchema, StringSchema } from 'yup';
import { TValidationType, IValidationRule, IValidationMessages } from '~/interfaces';

export const patternMatch = (
  validation: TValidationType,
  rule: IValidationRule,
  fieldName: string,
  validationCallbacks: IValidationMessages
): StringSchema | ArraySchema<StringSchema> => {
  if (validation.type === 'array') {
    let filteredArrayPattern = [];
    validation = validation.test(
      '',
      validationCallbacks.patternMatch(fieldName, rule.patternId),
      (value) => {
        filteredArrayPattern = value
          ?.map((valx) => {
            const matchVal = valx?.toString().match(rule.value);
            return matchVal ? null : valx;
          })
          .filter((val) => val);
        if (filteredArrayPattern?.length) {
          validation['invalidValues'] = filteredArrayPattern;
        }
        return !filteredArrayPattern?.length;
      }
    ) as ArraySchema<StringSchema>;
  } else {
    validation = (validation as StringSchema).matches(
      new RegExp(rule.value),
      validationCallbacks.patternMatch(fieldName, rule.patternId)
    );
  }
  return validation;
};
