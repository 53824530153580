/**
 * Use this file to configure the order in which the feilds appear
 * Eventually we will move this to a different data source
 * The code is NOT dependent upon all fields being present in this list
 * If a field is sent from the server that is not in this list, it will simply be added later in the form
 */
import { TConfigArrayWithDefault } from '~/interfaces';
import {
  CREATED_AT,
  PROMO_DESIGN_TITLE,
  PROMO_STRATEGY,
  SUB_STRATEGY_CD,
  DATE_RANGE,
  STATUS,
  CREATOR_NAME,
  PROMO_DESIGN_ID,
  MECHANIC_TYPE,
  PROMO_ID,
  POC_ID,
  SKUS,
  ERP_ID,
} from '~/constants';
const DEFAULT_FILTER_LIST_OPTIONS = [
  CREATED_AT,
  PROMO_DESIGN_TITLE,
  PROMO_STRATEGY,
  SUB_STRATEGY_CD,
  DATE_RANGE,
  STATUS,
  CREATOR_NAME,
  PROMO_DESIGN_ID,
  MECHANIC_TYPE,
  PROMO_ID,
  POC_ID,
  SKUS,
  ERP_ID,
];

export const FILTER_LIST_OPTIONS: TConfigArrayWithDefault = {
  DEFAULT: [...DEFAULT_FILTER_LIST_OPTIONS],
  BR: [...DEFAULT_FILTER_LIST_OPTIONS],
};

export const getFilterListOptions = (country: string): string[] => {
  return FILTER_LIST_OPTIONS?.[country] ?? FILTER_LIST_OPTIONS.DEFAULT;
};
