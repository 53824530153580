import { useAuthenticationService } from 'admin-portal-shared-services';
import { useMemo } from 'react';
import { getUserDisplayName } from '~/utils';

export const useGetUserDisplayName = (): string => {
  const authentication = useAuthenticationService();
  const userFullName = authentication.getUserFullNameB2C();

  return useMemo<string>(() => {
    return getUserDisplayName(userFullName);
  }, [userFullName]);
};
