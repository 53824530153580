import { Download } from '@hexa-ui/icons';
import styled from 'styled-components';

export const ItemWrapper = styled.div`
  white-space: normal;
  display: flex;
  padding-top: 5px;
`;

export const ItemTitle = styled.div`
  line-height: normal;
  margin-left: 8px;
  color: #424242;
  text-align: left;
`;

export const StyledDownloadIcon = styled(Download)`
  margin-left: 5px;
`;
