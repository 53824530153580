import React, { forwardRef, useRef } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import { Heading, LoadingBuzz, Paragraph } from '@hexa-ui/components';
import { Form } from '~/components';
import { FormattedMessage, useIntl } from 'react-intl';
import { useCreateSupportTicketGeneric, useCreateSupportTicketPromoDesign } from '~/services';
import { TypeToast, useToast } from 'admin-portal-shared-services';
import { Close } from '@material-ui/icons';
import { useStore } from 'react-redux';
import { StoreState } from '~/redux/store';
import { getTestIdAttr } from '~/utils';
import { getReactQueryData } from './utility';
import { IRequestSupportModal } from './RequestSupportModal.types';
import './RequestSupportModal.css';

const RequestSupportModal = (
  { promoDesign, open, setOpen, handleClose = () => {} }: IRequestSupportModal,
  ref
): JSX.Element => {
  const descriptionRef = useRef(null);
  const { formatMessage } = useIntl();
  const notify = useToast();
  const store = useStore<StoreState>();

  const { mutate: createSupportTicketGeneric, isLoading: isTicketGenericSubmitting } =
    useCreateSupportTicketGeneric();
  const { mutate: createSupportTicketPromoDesign, isLoading: isTicketPromoDesignSubmitting } =
    useCreateSupportTicketPromoDesign();

  const afterSubmitSuccess = (resetForm): void => {
    notify.notify({
      type: TypeToast.SUCCESS,
      message: formatMessage({
        id: 'REQUEST_SUPPORT.SUBMIT.SUCCESS_MESSAGE',
        defaultMessage: 'Your support ticket was submitted',
      }),
    });
    setOpen(false);
    handleClose();
    resetForm({ description: '', summary: '' });
    ref?.current?.reload();
  };

  const notifyError = (): void => {
    notify.notify({
      type: TypeToast.ERROR,
      message: formatMessage({
        id: 'REQUEST_SUPPORT.SUBMIT.ERROR_MESSAGE',
        defaultMessage:
          'An error occurred while creating your request for support. Please try again or open a ticket manually',
      }),
    });
  };

  const onError = () => {
    notifyError();
  };

  const handleOnSubmitPromoDesign = async (values, resetForm): Promise<void> => {
    const reactQueryData = await getReactQueryData();
    createSupportTicketPromoDesign(
      {
        promoDesignId: promoDesign?.promoDesignId,
        description: values?.description,
        state: store?.getState(),
        reactQuery: reactQueryData,
      },
      {
        onError,
        onSuccess: (response) => {
          if (response.hasError) {
            notifyError();
          } else {
            afterSubmitSuccess(resetForm);
          }
        },
      }
    );
  };

  const handleOnSubmitGeneric = async (values, resetForm): Promise<void> => {
    const reactQueryData = await getReactQueryData();
    createSupportTicketGeneric(
      {
        summary: values?.summary,
        description: `${values?.description}\n\n---\nLocation: ${window?.location?.href}\nUser Agent: ${window.navigator?.userAgent}`,
        state: store?.getState(),
        reactQuery: reactQueryData,
      },
      {
        onError,
        onSuccess: (response) => {
          if (response.hasError) {
            notifyError();
          } else {
            afterSubmitSuccess(resetForm);
          }
        },
      }
    );
  };

  return (
    <>
      <Dialog
        {...getTestIdAttr('RequestSupportModal')}
        key={'request-support-generic'}
        open={open}
        className="requestSupportModalContainer"
        title={formatMessage({
          id: 'REQUEST_SUPPORT.MODAL_TITLE',
          defaultMessage: 'Request Support',
        })}
        fullWidth={true}
      >
        <DialogTitle>
          <Heading size={'H1'}>
            <FormattedMessage
              id={'REQUEST_SUPPORT.MODAL_TITLE'}
              defaultMessage={'Request Support'}
            />
          </Heading>
          <IconButton
            aria-label="close"
            className={'close-button'}
            onClick={() => {
              handleClose();
              setOpen(false);
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              margin: 'auto',
              width: '100%',
              height: '100%',
              display: isTicketPromoDesignSubmitting || isTicketGenericSubmitting ? 'flex' : 'none',
              justifyContent: 'center',
            }}
          >
            <div>
              <LoadingBuzz color="mono" size="xxlarge" />
            </div>
          </div>
          <div
            style={{
              display: isTicketPromoDesignSubmitting || isTicketGenericSubmitting ? 'none' : '',
            }}
          >
            <Paragraph
              size={'small'}
              style={{
                marginBottom: '16px',
              }}
            >
              <>
                {promoDesign ? (
                  <FormattedMessage
                    id={'REQUEST_SUPPORT.MODAL_INSTRUCTIONS_PROMO_DESIGN'}
                    defaultMessage={
                      'Please describe the problem you are having. When you are finished, click the Submit button and your request for support will be dispatched to a team member. Please check the "Support Requests" tab for a list of support tickets'
                    }
                  />
                ) : (
                  <FormattedMessage
                    id={'REQUEST_SUPPORT.MODAL_INSTRUCTIONS_GENERIC'}
                    defaultMessage={
                      'Please describe the problem you are having. When you are finished, click the Submit button and your request for support will be dispatched to a team member. Please navigate to "Check issue status" in the sidebar for a list of support tickets'
                    }
                  />
                )}
              </>
            </Paragraph>
            <Form
              formId={
                promoDesign
                  ? `support-request-${promoDesign.promoDesignId}`
                  : `support-request-generic`
              }
              ref={descriptionRef}
              onSubmit={promoDesign ? handleOnSubmitPromoDesign : handleOnSubmitGeneric}
              clearFormOnSubmit={false}
              buttonLabel={formatMessage({ id: 'BUTTONS.SUBMIT', defaultMessage: 'Submit' })}
              schema={[
                {
                  componentType: 'text',
                  default: null,
                  hidden: !!promoDesign,
                  label: formatMessage({
                    id: 'REQUEST_SUPPORT.FIELDS.SUMMARY',
                    defaultMessage: 'Summary',
                  }),
                  name: 'summary',
                  required: !promoDesign,
                  type: 'text',
                  maxLength: 245,
                  fieldContainerStyle: {
                    marginBottom: '16px',
                  },
                },
                {
                  componentType: 'textarea',
                  default: null,
                  hidden: false,
                  label: formatMessage({
                    id: 'REQUEST_SUPPORT.FIELDS.DESCRIPTION',
                    defaultMessage: 'Description',
                  }),
                  showLabel: true,
                  name: 'description',
                  placeholder: '',
                  required: true,
                  type: 'text',
                  rows: 10,
                  cols: 100,
                  style: {
                    resize: 'none',
                  },
                },
              ]}
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default forwardRef(RequestSupportModal);
