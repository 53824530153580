import { FormattedMessage } from 'react-intl';
import React from 'react';
import { getTestIdAttr } from '~/utils';

interface IPocsSelectedMessageProps {
  count?: number;
}
type TPocsSelectedMessage = (props: IPocsSelectedMessageProps) => JSX.Element;

export const PocsSelected: TPocsSelectedMessage = ({ count }) => {
  return (
    <FormattedMessage
      {...getTestIdAttr('PocsSelected')}
      id={'POCS_FILTER.POCS_SELECTED_COUNT'}
      defaultMessage={'{count, plural, =0 {No POCs} one {1 POC} other {# POCs}} selected.'}
      values={{
        count: count ?? 0,
      }}
    />
  );
};
