import { useAuthenticationService } from 'admin-portal-shared-services';
import { useMemo } from 'react';

const IDENTITY_USER_READ = 'Identity.User.Read';

export const useCanReadFromIdentity = (): boolean => {
  const authenticationService = useAuthenticationService();
  const userScopes = JSON.stringify(authenticationService.getUserScopes());
  return useMemo(() => {
    return authenticationService.getUserScopes().includes(IDENTITY_USER_READ);
  }, [userScopes]);
};
