import styled, { css } from 'styled-components';

interface ILabelProps {
  hidelabel?: boolean;
}

export const Label = styled.span<ILabelProps>`
  color: rgba(0, 0, 0, 0.55);
  font-size: 12px;
  margin-left: 5px;
  ${({ hidelabel }) =>
    hidelabel &&
    css`
      visibility: hidden;
    `}
`;

export const Container = styled.div`
  margin-left: 10px;
  display: flex;
`;
