import { all, call, delay, put, spawn, takeEvery } from 'redux-saga/effects';

import { getBudgetParameters } from '~/services/BudgetService';
import { get as getCountries } from '~/services/CountryService';
import { getPocUploadData } from '~/services/ParameterService';

import {
  deleteOne as deleteSingleDesign,
  getAll as getPromoDesign,
} from '~/services/DesignService';

import {
  resetPocFilter,
  resetPocValidations,
  setPocByUploadData,
  setPocUploadFileData,
  setSelectedPocsFilter,
} from '~/redux/slices/pocFilterSlice';
import {
  addSkuProducts,
  clearSkuProducts,
  editSkuProducts,
  removeSkuProducts,
  resetSkuParameters,
  setProducts,
  resetProducts,
  setLoadingFilters,
  setFilters,
  setSelectedFilters,
  resetSelectedFilters,
  resetToast,
  setToast,
  addMarkForDownload,
  clearAllMarkedForDownload,
  removeMarkForDownload,
  resetPromoDesignSlice,
  setLoadingPromoDesign,
  setPromoDesign,
  setReloading,
  resetBudgetParameters,
  setBudgetParameters,
  setIsLoading,
  setAvailableCountries,
  setLoadingPromoSubmit,
  setDuplicateMessage,
  setDeletedDesignLoading,
  setOpenDeleteDialog,
  resetPromoDesignData,
  setSelectedPromoDesignId,
} from '~/redux/slices';

import {
  ADD_MARK_FOR_DOWNLOAD,
  ADD_MARK_FOR_DOWNLOAD_FAILED,
  ADD_SKU_PRODUCT,
  ADD_SKU_PRODUCT_FAILED,
  CHECK_ERROR,
  CHECK_ERROR_FAILED,
  CLEAR_MARK_FOR_DOWNLOAD,
  CLEAR_MARK_FOR_DOWNLOAD_FAILED,
  CLEAR_SKU_PRODUCT,
  CLEAR_SKU_PRODUCT_FAILED,
  DELETE_SINGLE_DESIGN,
  DELETE_SINGLE_DESIGN_FAILED,
  DISMISSED_PROFILE_LINK_FOREVER,
  DISMISSED_PROFILE_LINK_FOREVER_FAILED,
  EDIT_SKU_PRODUCT,
  EDIT_SKU_PRODUCT_FAILED,
  FETCH_AVAILABLE_COUNTRIES,
  FETCH_AVAILABLE_COUNTRIES_FAILED,
  FETCH_BUDGET_UNITS,
  FETCH_BUDGET_UNITS_FAILED,
  FETCH_PRODUCT_FILTERS,
  FETCH_PRODUCT_FILTERS_FAILED,
  FETCH_PROMO_DESIGN,
  FETCH_PROMO_DESIGN_FAILED,
  GET_POC_UPLOAD_FILES_FAILED,
  GET_POC_UPLOAD_FILES_REQUEST,
  POCS_SELECTED_REQUEST,
  POCS_SELECTED_REQUEST_FAILED,
  POC_BY_UPLOAD_REQUEST,
  POC_BY_UPLOAD_REQUEST_FAILED,
  PROMO_DUPLICATION_REQUEST,
  REMOVE_MARK_FOR_DOWNLOAD,
  REMOVE_MARK_FOR_DOWNLOAD_FAILED,
  REMOVE_SKU_PRODUCT,
  REMOVE_SKU_PRODUCT_FAILED,
  RESET_ALL,
  RESET_ALL_FAILED,
  RESET_DISPLAY_TOAST,
  RESET_DISPLAY_TOAST_FAILED,
  RESET_ERROR,
  RESET_ERROR_FAILED,
  RESET_NOTIFICATION,
  RESET_NOTIFICATION_FAILED,
  RESET_POC_VALIDATIONS,
  RESET_PROMO_DESIGN,
  RESET_PROMO_DESIGN_FAILED,
  RESET_SELECTED_FILTERS,
  RESET_SELECTED_FILTERS_FAILED,
  RESET_STRATEGY_DATES,
  RESET_STRATEGY_DATES_FAILED,
  SET_CAMPAIGN_ID,
  SET_CAMPAIGN_ID_FAILED,
  SET_COVER_IMAGE,
  SET_COVER_IMAGE_FAILED,
  SET_MECHANICS_TYPE,
  SET_MECHANICS_TYPE_FAILED,
  SET_MECHANICS_TYPE_OPTION,
  SET_MECHANICS_TYPE_OPTION_FAILED,
  SET_PRODUCTS,
  SET_PRODUCTS_FAILED,
  SET_PROMO_PREFIX,
  SET_PROMO_PREFIX_FAILED,
  SET_REWARDS_DESCRIPTION,
  SET_REWARDS_DESCRIPTION_FAILED,
  SET_SELECTED_FILTERS,
  SET_SELECTED_FILTERS_FAILED,
  SET_SELECTED_PROMO_DESIGN,
  SET_SELECTED_PROMO_DESIGN_FAILED,
  SET_STRATEGY_DATES,
  SET_STRATEGY_DATES_FAILED,
  SET_TITLE,
  SET_TITLE_FAILED,
  SET_TOAST,
  SET_TOAST_FAILED,
  SET_TOAST_FROM_RESPONSE,
  SET_TOAST_FROM_RESPONSE_FAILED,
} from './sagaTypes';
import { checkError, setToastFromResponse } from './sagaActions';

import { getProductFilters } from '~/services/ProductsService/getProductFilters';
import { getUserProductFilters } from '~/services/ProductsService/getUserProductFilters';
import { IAddEditSKUProducts, IRemoveSKUProducts } from '~/interfaces';
import {
  ISetHasDismissedPromptForever,
  resetGlobalParameters,
  resetStrategyDates,
  setCampaignId,
  setCoverImage,
  setDismissPromptForever,
  setMechanicsType,
  setMechanicsTypeOption,
  setPromoPrefix,
  setRewardsDescription,
  setStrategyDates,
  setTitle,
} from '~/redux/slices/globalSlice';

function* workFetchAvailableCountries() {
  let response: Awaited<ReturnType<typeof getCountries>>;
  setIsLoading(true);
  try {
    response = yield call(getCountries);
    yield put(checkError(response));
    yield put(setAvailableCountries(response.data));
  } catch (e) {
    yield put(setToastFromResponse(response));
    yield put({ type: FETCH_AVAILABLE_COUNTRIES_FAILED, message: e.message });
  } finally {
    setIsLoading(false);
  }
}

function* sagaFetchAvailableCountries() {
  yield takeEvery(FETCH_AVAILABLE_COUNTRIES, workFetchAvailableCountries);
}

function* workFetchBudgetParameters() {
  let response: Awaited<ReturnType<typeof getBudgetParameters>>;
  try {
    response = yield call(getBudgetParameters);
    yield put(checkError(response));
    yield put(setBudgetParameters(response.data));
  } catch (e) {
    yield put(setToastFromResponse(response));
    yield put({ type: FETCH_BUDGET_UNITS_FAILED, message: e.message });
  }
}

function* sagaFetchBudgetParameters() {
  yield takeEvery(FETCH_BUDGET_UNITS, workFetchBudgetParameters);
}

function* workSetSelectedPocsFilter(action) {
  try {
    yield put(setSelectedPocsFilter(action.payload));
  } catch (e) {
    yield put({ type: POCS_SELECTED_REQUEST_FAILED, message: e.message });
  }
}

function* sagaSetProducts() {
  yield takeEvery(SET_PRODUCTS, workSetProducts);
}

function* workSetProducts(action) {
  try {
    yield put(setProducts(action.payload));
  } catch (e) {
    yield put({ type: SET_PRODUCTS_FAILED, message: e.message });
  }
}

function* sagaSetSelectedPocsFilterData() {
  yield takeEvery(POCS_SELECTED_REQUEST, workSetSelectedPocsFilter);
}

function* workPocByUploadData(action) {
  try {
    yield put(setPocByUploadData(action.payload));
  } catch (e) {
    yield put({ type: POC_BY_UPLOAD_REQUEST_FAILED, message: e.message });
  }
}

function* sagaPocByUploadData() {
  yield takeEvery(POC_BY_UPLOAD_REQUEST, workPocByUploadData);
}

function* workPromoDuplicateData(action) {
  yield put(setLoadingPromoSubmit(true));
  try {
    yield delay(800);
    yield put(setDuplicateMessage(action.payload));
  } catch (e) {
    //@ts-ignore
    yield put(setDuplicateMessage({ type: PROMO_DUPLICATION_FAILED, message: e.message }));
  } finally {
    yield put(setLoadingPromoSubmit(false));
  }
}

function* sagaPromoDuplicateData() {
  yield takeEvery(PROMO_DUPLICATION_REQUEST, workPromoDuplicateData);
}

function* workResetNotificationData(action) {
  try {
    yield put(setDuplicateMessage(action.payload));
  } catch (e) {
    yield put({ type: RESET_NOTIFICATION_FAILED, message: e.message });
  }
}

function* sagaResetNotificationData() {
  yield takeEvery(RESET_NOTIFICATION, workResetNotificationData);
}

function* workFetchPromoDesignData(action) {
  const { page, pageSize, localeCode, requestPayload } = action.payload;
  let response: Awaited<ReturnType<typeof getPromoDesign>>;

  yield put(setLoadingPromoDesign(true));
  yield put(setReloading(false));
  try {
    response = yield call(getPromoDesign, page, pageSize, requestPayload);
    yield put(checkError(response));
    yield put(setPromoDesign({ ...response.data.data, localeCode }));
  } catch (e) {
    yield put(setToastFromResponse(response));
    yield put({ type: FETCH_PROMO_DESIGN_FAILED, message: e.message });
  } finally {
    yield put(setLoadingPromoDesign(false));
  }
}

function* sagaFetchPromoDesignData() {
  yield takeEvery(FETCH_PROMO_DESIGN, workFetchPromoDesignData);
}

function* workDeleteSingleDesign(action) {
  let response;
  yield put(setDeletedDesignLoading(true));

  try {
    response = yield call(deleteSingleDesign, action.payload.designId);
    yield put(checkError(response));
    yield put(
      setToast({
        showToast: true,
        type: 'success',
        customMessage: action.payload.toastMessage,
      })
    );
    yield put(setReloading(true));
  } catch (e) {
    yield put(setToastFromResponse(response));
    yield put({ type: DELETE_SINGLE_DESIGN_FAILED, message: e.message });
  } finally {
    yield put(setReloading(false));
    yield put(setDeletedDesignLoading(false));
    yield put(setOpenDeleteDialog(false));
  }
}

function* sagaDeleteSingleDesign() {
  yield takeEvery(DELETE_SINGLE_DESIGN, workDeleteSingleDesign);
}

function* workResetToast() {
  try {
    yield put(resetToast());
  } catch (e) {
    yield put({ type: RESET_DISPLAY_TOAST_FAILED, message: e.message });
  }
}

function* sagaResetToast() {
  yield takeEvery(RESET_DISPLAY_TOAST, workResetToast);
}

function* workCheckError(action) {
  const ap = action.payload;

  try {
    if (ap.hasOwnProperty('data')) {
      // checks if empty
      if (!Object.keys(ap.data).length) {
        yield put(setToastFromResponse({ hasError: true, status: 204 }));
      }
    } else {
      yield put(setToastFromResponse(ap));
    }
  } catch (e) {
    yield put({ type: CHECK_ERROR_FAILED, message: e.message });
  }
}

function* sagaCheckError() {
  yield takeEvery(CHECK_ERROR, workCheckError);
}

function* workSetToastFromResponse(action) {
  try {
    yield put(
      setToast({
        showToast: action.payload.hasError,
        type: 'warning',
        status: action.payload.response.status,
        error: action.payload.hasError,
        customMessage: '',
      })
    );
  } catch (e) {
    yield put({ type: SET_TOAST_FROM_RESPONSE_FAILED, message: e.message });
  }
}

function* sagaSetToastFromResponse() {
  yield takeEvery(SET_TOAST_FROM_RESPONSE, workSetToastFromResponse);
}

function* workSetToast(action) {
  try {
    yield put(setToast(action.payload));
  } catch (e) {
    yield put({ type: SET_TOAST_FAILED, message: e.message });
  }
}

function* sagaSetToast() {
  yield takeEvery(SET_TOAST, workSetToast);
}

function* workResetError() {
  try {
    yield put(resetToast());
  } catch (e) {
    yield put({ type: RESET_ERROR_FAILED, message: e.message });
  }
}

function* sagaResetError() {
  yield takeEvery(RESET_ERROR, workResetError);
}

function* workSetStrategyDates(action) {
  try {
    yield put(setStrategyDates(action.payload));
  } catch (e) {
    yield put({ type: SET_STRATEGY_DATES_FAILED, message: e.message });
  }
}

function* sagaSetStrategyDates() {
  yield takeEvery(SET_STRATEGY_DATES, workSetStrategyDates);
}

function* workResetStrategyDates() {
  try {
    yield put(resetStrategyDates());
  } catch (e) {
    yield put({ type: RESET_STRATEGY_DATES_FAILED, message: e.message });
  }
}

function* sagaResetStrategyDates() {
  yield takeEvery(RESET_STRATEGY_DATES, workResetStrategyDates);
}

function* workAddMarkForDownload(action) {
  try {
    yield put(addMarkForDownload(action.payload));
  } catch (e) {
    yield put({ type: ADD_MARK_FOR_DOWNLOAD_FAILED, message: e.message });
  }
}

function* sagaAddMarkForDownload() {
  yield takeEvery(ADD_MARK_FOR_DOWNLOAD, workAddMarkForDownload);
}

function* workRemoveMarkForDownload(action) {
  try {
    yield put(removeMarkForDownload(action.payload));
  } catch (e) {
    yield put({ type: REMOVE_MARK_FOR_DOWNLOAD_FAILED, message: e.message });
  }
}

function* sagaRemoveMarkForDownload() {
  yield takeEvery(REMOVE_MARK_FOR_DOWNLOAD, workRemoveMarkForDownload);
}

function* workClearMarkForDownload() {
  try {
    yield put(clearAllMarkedForDownload());
  } catch (e) {
    yield put({ type: CLEAR_MARK_FOR_DOWNLOAD_FAILED, message: e.message });
  }
}

function* sagaClearMarkForDownload() {
  yield takeEvery(CLEAR_MARK_FOR_DOWNLOAD, workClearMarkForDownload);
}

function* workResetPromoDesign() {
  try {
    yield put(resetPromoDesignData());
    yield put(resetPocFilter());
    yield put(resetBudgetParameters());
    yield put(resetGlobalParameters());
    yield put(resetProducts());
    yield put(resetSkuParameters());
  } catch (e) {
    yield put({ type: RESET_PROMO_DESIGN_FAILED, message: e.message });
  }
}

function* sagaResetPromoDesign() {
  yield takeEvery(RESET_PROMO_DESIGN, workResetPromoDesign);
}

function* workPocUploadFileData(action) {
  let response: Awaited<ReturnType<typeof getPocUploadData>>;
  try {
    response = yield call(getPocUploadData, action.payload.promoDesignId, action.payload.type);
    yield put(setPocUploadFileData(response?.data));
  } catch (e) {
    yield put(setToastFromResponse(response));
    yield put({ type: GET_POC_UPLOAD_FILES_FAILED, message: e.message });
  }
}

function* sagaPocUploadFileData() {
  yield takeEvery(GET_POC_UPLOAD_FILES_REQUEST, workPocUploadFileData);
}

function* workSetSelectedPromoDesign(action) {
  try {
    yield put(setSelectedPromoDesignId(action.payload));
  } catch (e) {
    yield put({ type: SET_SELECTED_PROMO_DESIGN_FAILED, message: e.message });
  }
}

function* sagaSetSelectedPromoDesign() {
  yield takeEvery(SET_SELECTED_PROMO_DESIGN, workSetSelectedPromoDesign);
}

function* workSetPromoPrefix(action) {
  try {
    yield put(setPromoPrefix(action.payload));
  } catch (e) {
    yield put({ type: SET_PROMO_PREFIX_FAILED, message: e.message });
  }
}

function* sagaSetPromoPrefix() {
  yield takeEvery(SET_PROMO_PREFIX, workSetPromoPrefix);
}

function* workSetRewardsDescription(action) {
  try {
    yield put(setRewardsDescription(action.payload));
  } catch (e) {
    yield put({ type: SET_REWARDS_DESCRIPTION_FAILED, message: e.message });
  }
}

function* sagaSetRewardsDescription() {
  yield takeEvery(SET_REWARDS_DESCRIPTION, workSetRewardsDescription);
}

function* workSetTitle(action) {
  try {
    yield put(setTitle(action.payload));
  } catch (e) {
    yield put({ type: SET_TITLE_FAILED, message: e.message });
  }
}

function* sagaSetTitle() {
  yield takeEvery(SET_TITLE, workSetTitle);
}

function* workSetCampaignId(action) {
  try {
    yield put(setCampaignId(action.payload));
  } catch (e) {
    yield put({ type: SET_CAMPAIGN_ID_FAILED, message: e.message });
  }
}

function* sagaSetCampaignId() {
  yield takeEvery(SET_CAMPAIGN_ID, workSetCampaignId);
}

function* workSetMechanicsType(action) {
  try {
    yield put(setMechanicsType(action.payload));
  } catch (e) {
    yield put({ type: SET_MECHANICS_TYPE_FAILED, message: e.message });
  }
}

function* sagaSetMechanicsType() {
  yield takeEvery(SET_MECHANICS_TYPE, workSetMechanicsType);
}

function* workSetMechanicsTypeOption(action) {
  try {
    yield put(setMechanicsTypeOption(action.payload));
  } catch (e) {
    yield put({ type: SET_MECHANICS_TYPE_OPTION_FAILED, message: e.message });
  }
}

function* sagaSetMechanicsTypeOption() {
  yield takeEvery(SET_MECHANICS_TYPE_OPTION, workSetMechanicsTypeOption);
}

function* workSetCoverImage(action) {
  try {
    yield put(setCoverImage(action.payload));
  } catch (e) {
    yield put({ type: SET_COVER_IMAGE_FAILED, message: e.message });
  }
}

function* sagaSetCoverImage() {
  yield takeEvery(SET_COVER_IMAGE, workSetCoverImage);
}

function* workAddSKUProducts(action: IAddEditSKUProducts) {
  try {
    yield put(addSkuProducts(action.payload));
  } catch (e) {
    yield put({ type: ADD_SKU_PRODUCT_FAILED, message: e.message });
  }
}

function* sagaAddSKUProducts() {
  yield takeEvery(ADD_SKU_PRODUCT, workAddSKUProducts);
}

function* workEditSKUProducts(action: IAddEditSKUProducts) {
  try {
    yield put(editSkuProducts(action.payload));
  } catch (e) {
    yield put({ type: EDIT_SKU_PRODUCT_FAILED, message: e.message });
  }
}

function* sagaEditSKUProducts() {
  yield takeEvery(EDIT_SKU_PRODUCT, workEditSKUProducts);
}

function* workRemoveSKUProducts(action: IRemoveSKUProducts) {
  try {
    yield put(removeSkuProducts(action.payload));
  } catch (e) {
    yield put({ type: REMOVE_SKU_PRODUCT_FAILED, message: e.message });
  }
}

function* sagaRemoveSKUProducts() {
  yield takeEvery(REMOVE_SKU_PRODUCT, workRemoveSKUProducts);
}

function* workClearSKUProduct(action) {
  try {
    yield put(clearSkuProducts(action.payload));
  } catch (e) {
    yield put({ type: CLEAR_SKU_PRODUCT_FAILED, message: e.message });
  }
}

function* sagaClearSKUProduct() {
  yield takeEvery(CLEAR_SKU_PRODUCT, workClearSKUProduct);
}

function* workFetchProductFilters(action) {
  let response: Awaited<ReturnType<typeof getProductFilters>>;

  yield put(setLoadingFilters(true));

  try {
    const functionToCall = action.payload?.onlyPermission
      ? getUserProductFilters
      : getProductFilters;
    response = yield call(() => functionToCall({ ...action.payload }));
    yield put(setFilters(response?.data?.filters));
  } catch (e) {
    yield put(setToastFromResponse(response));
    yield put({ type: FETCH_PRODUCT_FILTERS_FAILED, message: e.message });
  } finally {
    yield put(setLoadingFilters(false));
  }
}

function* sagaFetchProductFilters() {
  yield takeEvery(FETCH_PRODUCT_FILTERS, workFetchProductFilters);
}

function* workSetSelectedFilters(action) {
  try {
    yield put(setSelectedFilters(action.payload));
  } catch (e) {
    yield put({ type: SET_SELECTED_FILTERS_FAILED, message: e.message });
  }
}

function* sagaSetSelectedFilters() {
  yield takeEvery(SET_SELECTED_FILTERS, workSetSelectedFilters);
}

function* workResetSelectedFilters(action) {
  try {
    yield put(resetSelectedFilters());
  } catch (e) {
    yield put({ type: RESET_SELECTED_FILTERS_FAILED, message: e.message });
  }
}

function* sagaResetSelectedFilters() {
  yield takeEvery(RESET_SELECTED_FILTERS, workResetSelectedFilters);
}

function* workResetPocValidations() {
  try {
    yield put(resetPocValidations());
  } catch (e) {
    yield put({ type: RESET_POC_VALIDATIONS, message: e.message });
  }
}

function* workDismissPromptForever(action: ISetHasDismissedPromptForever) {
  try {
    yield put(setDismissPromptForever(action.payload));
  } catch (e) {
    yield put({ type: DISMISSED_PROFILE_LINK_FOREVER_FAILED, message: e.message });
  }
}

function* sagaDismissPromptForever() {
  yield takeEvery(DISMISSED_PROFILE_LINK_FOREVER, workDismissPromptForever);
}

function* sagaResetPocValidations() {
  yield takeEvery(RESET_POC_VALIDATIONS, workResetPocValidations);
}

function* workResetAll() {
  try {
    yield put(resetSkuParameters());
    yield put(resetPromoDesignData());
    yield put(resetPromoDesignSlice());
    yield put(resetProducts());
    yield put(resetPocFilter());
    yield put(resetGlobalParameters());
    yield put(resetBudgetParameters());
  } catch (e) {
    yield put({ type: RESET_ALL_FAILED, message: e.message });
  }
}

function* sagaResetAll() {
  yield takeEvery(RESET_ALL, workResetAll);
}

export default function* rootSaga() {
  const sagas = [
    sagaFetchProductFilters,
    sagaFetchAvailableCountries,
    sagaSetSelectedPocsFilterData,
    sagaSetProducts,
    sagaFetchBudgetParameters,
    sagaPocByUploadData,
    sagaPromoDuplicateData,
    sagaResetNotificationData,
    sagaDeleteSingleDesign,
    sagaResetToast,
    sagaFetchPromoDesignData,
    sagaSetToastFromResponse,
    sagaSetToast,
    sagaResetError,
    sagaCheckError,
    sagaSetStrategyDates,
    sagaResetStrategyDates,
    sagaAddMarkForDownload,
    sagaRemoveMarkForDownload,
    sagaClearMarkForDownload,
    sagaPocUploadFileData,
    sagaResetPromoDesign,
    sagaSetSelectedPromoDesign,
    sagaSetPromoPrefix,
    sagaSetTitle,
    sagaSetCampaignId,
    sagaSetMechanicsType,
    sagaSetMechanicsTypeOption,
    sagaAddSKUProducts,
    sagaEditSKUProducts,
    sagaRemoveSKUProducts,
    sagaClearSKUProduct,
    sagaSetSelectedFilters,
    sagaResetSelectedFilters,
    sagaResetPocValidations,
    sagaResetAll,
    sagaDismissPromptForever,
    sagaSetCoverImage,
    sagaSetRewardsDescription,
  ];

  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {}
        }
      })
    )
  );
}
