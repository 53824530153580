import { IActionableErrorAlert, IDateRange } from '~/interfaces';
import { setStrategyDates } from '~/redux/saga/sagaActions';
import { LIFT_ROUTES } from '~/constants';
import { useGetMinDesignDate, useAppDispatch, useAppSelector } from '~/hooks';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { selectGlobalState } from '~/redux/slices';
import { formatDate } from '~/utils';
import { usePreferredLanguageV2 } from 'admin-portal-shared-services';
import { useCallback, useMemo } from 'react';
import convertDateStringToNumber from '~/utils/convertStringDateToNumber';

export const usePromoDesignDateAlerts = (
  dateSelection?: IDateRange,
  personalized = false,
  withCta = true
): {
  errors: IActionableErrorAlert[];
} => {
  const { isPersonalized, dateSelection: dateSelectionFromState } =
    useAppSelector(selectGlobalState);

  const dateRange = dateSelection ?? dateSelectionFromState;

  const { minDate: minDateToBeConverted, isLoading: isLoadingMinDesignDate } = useGetMinDesignDate(
    personalized ?? isPersonalized
  );
  const minDate = convertDateStringToNumber(minDateToBeConverted);

  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { preferredLanguage: localeCode } = usePreferredLanguageV2();

  const modifyDateRange = formatMessage({
    id: 'PROMO_DESIGN_DATE_VALIDATIONS.MODIFY_DATE_RANGE',
    defaultMessage: 'Modify date range',
  });

  const navigateToResetDates = useCallback(
    (start: string, end: string): void => {
      dispatch(
        setStrategyDates({
          startDate: start,
          endDate: end,
        })
      );
      navigate(`${LIFT_ROUTES.DESIGN}?step=0&modifyDates=true`);
    },
    [navigate]
  );

  let errors: IActionableErrorAlert[] = useMemo(() => {
    const alerts: IActionableErrorAlert[] = [];
    const { startDate: startDateToBeConverted, endDate: endDateToBeConverted } = dateRange;
    const startDate = convertDateStringToNumber(startDateToBeConverted);
    const endDate = convertDateStringToNumber(endDateToBeConverted);

    if (!(dateRange.startDate && dateRange.endDate)) {
      alerts.push({
        alertMessage: formatMessage({
          id: 'PROMO_DESIGN_DATE_VALIDATIONS.DATE_RANGE_INVALID',
          defaultMessage:
            'You must provide valid start and end dates to submit this design. Modify date range to adjust dates.',
        }),
        ...(withCta && {
          actions: [
            {
              action: () => {
                errors = [];
                navigateToResetDates('', endDate >= minDate ? dateRange.endDate : '');
              },
              message: modifyDateRange,
            },
          ],
        }),
      });
    }

    if (!isLoadingMinDesignDate && startDate < minDate) {
      alerts.push({
        alertMessage: formatMessage(
          {
            id: 'PROMO_DESIGN_DATE_VALIDATIONS.NO_LONGER_VALID_DATE_RANGE',
            defaultMessage:
              'The selected start date {date} is no longer available. The next available start date is {minDate}. Modify date range to adjust dates.',
          },
          {
            minDate: formatDate(minDateToBeConverted, localeCode),
            date: formatDate(dateRange.startDate, localeCode),
          }
        ),
        ...(withCta && {
          actions: [
            {
              action: () => {
                errors = [];
                navigateToResetDates('', endDate >= minDate ? dateRange.endDate : '');
              },
              message: modifyDateRange,
            },
          ],
        }),
      });
    }
    return alerts;
  }, [dateRange.startDate, dateRange.endDate, minDate, withCta, isLoadingMinDesignDate]);

  return {
    errors,
  };
};
