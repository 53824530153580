import Bff from '~/Api/Bff';
import { useState } from 'react';
import { IPOCParameters, IProductParameters, IPromoPermissionParameters } from '~/interfaces';
import {
  TCreatePromoPermissions,
  TUseUserPromoPermissions,
} from './UsePromoPermissionsService.types';

export const useUserPromoPermissions: TUseUserPromoPermissions = ({ country }) => {
  const [isSaving, setIsSaving] = useState(false);

  const createPromoPermissions: TCreatePromoPermissions = ({
    userId,
    country,
    skuParameters,
    pocParameters,
  }) => {
    setIsSaving(true);
    const url = `v1/user-promo-filters`;
    const options = country ? { headers: { country } } : {};
    return Bff.post<
      {
        id: string;
        skuParameters: IProductParameters;
        pocParameters: IPOCParameters;
      },
      IPromoPermissionParameters
    >({
      url,
      options,
      body: {
        id: userId,
        skuParameters,
        pocParameters,
      },
    })
      .then((response) => {
        return response.data;
      })
      .finally(() => setIsSaving(false));
  };

  return {
    createPromoPermissions,
    isSaving,
  };
};
