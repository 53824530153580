import { ISupportRequest, ISupportRequestResponse } from '~/interfaces';
import Bff from '~/Api/Bff';
import { UseQueryOptions, useQuery, UseQueryResult } from '@tanstack/react-query';
import { useAppDispatch } from '~/hooks';
import { checkError, setToastFromResponse } from '~/redux/saga/sagaActions';
import { AxiosError } from 'axios';

let response: Awaited<ReturnType<typeof getSupportTicketsForPromoDesign>>;

export const getSupportTicketsForPromoDesign = async (
  promoDesignId: string
): Promise<ISupportRequestResponse> => {
  const ticketResponse: any = await Bff.get<ISupportRequestResponse>({
    url: `/v1/support/ticket/promodesign/${promoDesignId}`,
  });

  return ticketResponse;
};

export const useGetSupportTicketsForPromoDesign = (
  promoDesignId: string,
  options?: UseQueryOptions
): UseQueryResult<ISupportRequest[]> => {
  const dispatch = useAppDispatch();
  return useQuery<ISupportRequest[], any, any, any>({
    queryKey: ['getSupportTicketsForPromoDesign', promoDesignId],
    queryFn: async () => {
      response = await getSupportTicketsForPromoDesign(promoDesignId);
      return response?.data;
    },
    onError: (e: AxiosError) => {
      dispatch(setToastFromResponse(response));
    },
    onSuccess: (e) => {
      dispatch(checkError(e));
    },
    cacheTime: 0,
    staleTime: 0,
    ...options,
  });
};
