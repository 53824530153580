import { ReactNode } from 'react';
import { useCountrySelection } from '~/hooks';
import { startFeatureFlagService } from '~/services/FeatureFlagsService/featureFlagsService';

type ServicesWrapper = { optimizelyKey: string; applicationTitle: string; children?: ReactNode };

const ServicesWrapper = ({ optimizelyKey, applicationTitle, children }: ServicesWrapper) => {
  useCountrySelection();

  if (!window.services?.['FeatureToggleOptimizelyV2']?.[applicationTitle]) {
    startFeatureFlagService(applicationTitle, optimizelyKey);
  }

  return <>{children}</>;
};

export default ServicesWrapper;
