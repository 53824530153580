import { FormattedMessage } from 'react-intl';
import { getTestIdAttr } from '~/utils';

export const Cancel = (): JSX.Element => {
  return (
    <FormattedMessage
      {...getTestIdAttr('Cancel')}
      id={'BUTTONS.CANCEL'}
      defaultMessage={'Cancel'}
    />
  );
};
