import React, { MouseEvent } from 'react';
import { Paragraph } from '@hexa-ui/components';
import { Button, Fade, Tooltip } from '@material-ui/core';
import CopyIcon from '~/assets/icons/CopyIcon';
import useStyles, { useTooltipStyles } from './CopyToClipboard.styles';
import { useIntl } from 'react-intl';

interface CopyStatus {
  message: string;
  shouldUpdate: boolean;
}

export interface CopyToClipboardColumnProps {
  text: string;
}

function CopyToClipboard({ text }: CopyToClipboardColumnProps): JSX.Element {
  const { formatMessage } = useIntl();
  const { copyToClipboardButton } = useStyles();
  const tooltipStyle = useTooltipStyles();
  const [copyStatus, setCopyStatus] = React.useState<CopyStatus>({
    message: '',
    shouldUpdate: false,
  });
  const [isTooltipOpen, setIsTooltipOpen] = React.useState<boolean>(false);

  const onCopyText = (traceId: string, event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (isTooltipOpen && !!copyStatus.message) {
      return;
    }
    navigator.clipboard
      .writeText(traceId)
      .then(() => {
        setCopyStatus((prev) => ({
          message: formatMessage({ id: 'COPY_TO_CLIPBOARD.COPIED', defaultMessage: 'Copied!' }),
          shouldUpdate: !prev.shouldUpdate,
        }));
      })
      .catch(() => {
        setCopyStatus((prev) => ({
          message: formatMessage({
            id: 'COPY_TO_CLIPBOARD.ERROR',
            defaultMessage: 'An error occurred while copying to clipboard',
          }),
          shouldUpdate: !prev.shouldUpdate,
        }));
      });
  };

  React.useEffect(() => {
    const controller = new AbortController();
    setIsTooltipOpen(true);
    setTimeout(() => {
      setIsTooltipOpen(false);
    }, 1500);
    return () => {
      controller.abort();
    };
  }, [copyStatus]);

  return (
    <Tooltip
      title={copyStatus.message}
      placement="top"
      open={isTooltipOpen}
      classes={tooltipStyle}
      TransitionComponent={Fade}
      arrow
      id="tooltip-copy"
      PopperProps={{
        disablePortal: true,
      }}
      onMouseDown={(event) => event.preventDefault()}
    >
      <Button
        className={copyToClipboardButton}
        endIcon={<CopyIcon />}
        onClick={(event: MouseEvent<HTMLButtonElement>) => onCopyText(text, event)}
        data-testid="copy-button"
      >
        <Paragraph>{text}</Paragraph>
      </Button>
    </Tooltip>
  );
}

export default CopyToClipboard;
