import { useEffect } from 'react';
import { FileUploader, FileValidated } from '@hexa-ui/components';
import { useField } from 'formik';
import CustomLabel from '~/components/Form/components/CustomLabel/CustomLabel';
import CustomError from '~/components/Form/components/CustomError/CustomError';
import './FileUpload.css';
import { getTestIdAttr } from '~/utils';

export const FileUpload = ({
  label,
  field: { name, value, type, ...fieldProps },
  form,
  required,
  defaultValue,
  hint,
  message,
  browseButtonLabel,
  showLabel,
  onChange,
  onClean,
  showError = true,
  customClasses,
  onDrop,
  ...props
}): JSX.Element => {
  const [, , helpers] = useField({
    ...props,
    name,
  });

  const isValid = (val: FileValidated[]): boolean => val?.[val.length - 1]?.valid;

  useEffect(() => {
    if (isValid(defaultValue)) {
      helpers.setValue(defaultValue);
    }
  }, [defaultValue]);

  const handleChange = (val: FileValidated[], cb): void => {
    helpers.setValue(isValid(val) ? val : []);
    cb(val);
  };
  return (
    <>
      {showLabel && (
        <CustomLabel errors={form?.errors?.[name]} label={label} hint={hint} optional={!required} />
      )}
      <div
        {...getTestIdAttr('FileUpload')}
        className={`${customClasses} ${form?.errors?.[name] && 'fileUpload-error'}`}
      >
        <FileUploader
          buttonIntl={browseButtonLabel}
          message={message}
          onChange={(val) => handleChange(val, onChange)}
          onDrop={(val) => handleChange(val, onDrop)}
          type={type}
          onClean={() => {
            helpers.setValue([]);
            onClean();
          }}
          value={value}
          {...props}
        />
      </div>
      <CustomError errors={form?.errors?.[name]} />
    </>
  );
};
