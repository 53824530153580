import React from 'react';
import { useLocalizedDatetime } from '~/hooks';
import { getTestIdAttr } from '~/utils';
import { TDateTimeWithRelativeHover } from './DateTimeWithRelativeHover.types';

const DateTimeWithRelativeHover: TDateTimeWithRelativeHover = ({ value, children }) => {
  const dt = useLocalizedDatetime(value);
  return (
    <span {...getTestIdAttr('DateTimeWithRelativeHover')} title={dt}>
      {!children ? value : children}
    </span>
  );
};
export default DateTimeWithRelativeHover;
