import Bff from '~/Api/Bff';
import { IPocParametersResponse } from '~/interfaces';
import { AxiosResponse } from 'axios';
import { checkError } from '~/redux/saga/sagaActions';
import { UseQueryOptions, useQuery, UseQueryResult } from '@tanstack/react-query';
import { useAppDispatch } from '~/hooks';
interface IPocParameter {
  parameters: {
    [key: string]: string[];
  };
}

export const getPocParameters = ({
  country,
}: {
  country?: string;
}): Promise<AxiosResponse<IPocParametersResponse>> => {
  const options = country ? { headers: { country } } : {};
  return Bff.get<IPocParametersResponse>({
    url: '/v1/parameters/poc',
    options,
  }).then((response) => {
    return response;
  });
};

export const useGetPocParameters = (
  country: string,
  options?: UseQueryOptions
): UseQueryResult<IPocParameter> => {
  const dispatch = useAppDispatch();
  return useQuery<IPocParameter, any, any, any>({
    queryKey: ['getPocParameters', country],
    queryFn: async () => {
      const response = await getPocParameters({ country });
      return response?.data?.pocParameters?.poc;
    },
    onSuccess: (e) => {
      dispatch(checkError(e));
    },
    staleTime: 0,
    ...options,
  });
};
