export const TAG_GENERAL = {
  ICN: 'icn_{name}',
  ICN_INDEX: 'icn_{name}_{index}',
  ICN_STATUS: 'icn_{status}_{name}',
  ICN_STATUS_INDEX: 'icn_{status}_{name}_{index}',
  IPT: 'ipt_{name}',
  IPT_INDEX: 'ipt_{name}_{index}',
  LBL: 'lbl_{name}',
  LBL_INDEX: 'lbl_{name}_{index}',
  BTN: 'btn_{name}',
  BTN_INDEX: 'btn_{name}_{index}',
  IMG: 'img_{name}',
  IMG_INDEX: 'img_{name}_{index}',
  TBL: 'tbl_{name}',
  TBL_INDEX: 'tbl_{name}_{index}',
};

export const TAG_COMBO_BOX = {
  CBX: 'cbx_{name}',
  OPT: 'opt_{name}',
};

export const TAG_NAMES = {
  GENERAL: {
    CLOSE: 'close',
    STATUS: 'status',
    TABLE: {
      DATA_GRID: 'data_grid',
      TABLE_FILTER: 'table_filter',
    },
    PAGE: {
      PAGE_TITLE: 'page_title',
    },
    COUNTRY_LANGUAGE: {
      COUNTRY_LANGUAGE: 'country_language',
    },
  },
  ACCOUNT: {
    SELECTION: {
      STEP_TITLE: 'step_title',
      STEP_SUBTITLE: 'step_subtitle',
      ACCOUNT_CARD_TITLE: 'account_card_title',
      USER_CARD_TITLE: 'user_card_title',
      GO_BACK: 'go_back',
      SEARCH: 'search',
      CLEAR: 'clear',
      USER_ASSOCIATED_ACCOUNTS: 'user_associated_accounts',
      RESULTS_FOUND: 'results_found',
      SEARCH_FIELD: 'search_field',
      SEARCH_TYPE: 'search_type',
      ACCOUNT_NAME: 'account_name',
      ACCOUNT_ID: 'account_id',
      ACCOUNT_PHONE: 'account_phone',
      ACCOUNT_ICON: 'account_icon',
      STATUS: 'status',
      VENDOR: 'vendor',
      FIND_ACCOUNT: 'find_account',
      GOT_IT: 'got_it',
      TITLE: 'title',
      DESCRIPTION: 'description',
      LINK: 'link',
      VENDOR_ID: 'vendor_id',
      VENDOR_NAME: 'vendor_name',
    },
  },
  USER: {
    USER_NAME: 'user_name',
    USER_EMAIL: 'user_email',
    USER_PHONE: 'user_phone',
    USER_ICON: 'user_icon',
    USER_COPY: 'user_copy',
    USER_STATUS: 'user_status',
    USER_IMAGE: 'user_image',
    SELECTION: {
      STATUS: 'status',
    },
  },
};
