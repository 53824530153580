import Bff from '~/Api/Bff';
import { IPromoDesignRecordResponse, IPromoDesignsResponse } from '@moneycomb/mcomb-types/response';
import { QueriesResults, useQueries, useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { UseQueryOptions, UseQueryResult } from '@tanstack/react-query/build/lib/types';
import { IPromoDesignShort, IPromoDesign } from '~/interfaces';

const DEFAULT_PAGE_SIZE = 20;

const MILISECONDS = 1000;
const SECONDS = 60;
const MINUTES = 60;

const CACHE_TIME = MILISECONDS * SECONDS * MINUTES;

export const getAll = (
  page = 1,
  pageSize = DEFAULT_PAGE_SIZE,
  requestPayload = {}
): Promise<AxiosResponse<IPromoDesignsResponse>> => {
  return Bff.post<typeof requestPayload, IPromoDesignsResponse>({
    url: `/v1/promo-designs/filters?page=${page}&pageSize=${pageSize}`,
    body: {
      ...requestPayload,
    },
  }).then((response) => {
    return response;
  });
};

export const useGetPromoDesign = (
  promoDesignId: string,
  options: UseQueryOptions<IPromoDesign, any, any, any> = {}
): UseQueryResult<IPromoDesign> => {
  return useQuery<IPromoDesign, any, any, any>(
    ['getPromoDesignById', promoDesignId],
    async () => {
      //@ts-ignore
      return await getOne(promoDesignId).then((response) => response.data.data.promoDesign);
    },
    {
      enabled: !!promoDesignId,
      cacheTime: 0,
      ...options,
    }
  );
};

export const useGetPromoDesignsShort = (
  promoDesignIds: string[],
  options: UseQueryOptions<IPromoDesignShort[]> = {}
): QueriesResults<IPromoDesignShort[]> => {
  return useQueries<IPromoDesignShort[]>({
    queries: promoDesignIds.map((promoDesignId) => {
      return {
        queryKey: ['getPromoDesignByIdShort', promoDesignId],
        queryFn: async () => {
          return await getOne(promoDesignId).then((response) => {
            //@ts-ignore
            const data = response.data.data.promoDesign;
            return {
              promoDesignId: data.promoDesignId,
              promoDesignTitleDisplay: data.promoDesignTitleDisplay,
              personalized: data.personalized,
              status: data.status,
              createdAt: data.createdAt,
            };
          });
        },
        enabled: true,
        cacheTime: CACHE_TIME,
        ...options,
      };
    }),
  });
};

export const getOne = (
  promoDesignId: string
): Promise<AxiosResponse<IPromoDesignRecordResponse>> => {
  return Bff.get<IPromoDesignRecordResponse>({
    url: `/v1/promo-designs/${promoDesignId}`,
  }).then((response) => {
    return response;
  });
};

export const deleteOne = (
  promoDesignId: string
): Promise<AxiosResponse<IPromoDesignRecordResponse>> => {
  return Bff.put({
    url: `/v1/promo-designs/${promoDesignId}`,
  }).then((response) => {
    return response;
  });
};
