import { Paragraph } from '@admin-portal-shared-components/typography';
import { X } from '@hexa-ui/icons';
import { getTestIdAttr } from '~/utils';
import './LiftChip.css';
import { TLiftChip } from './LiftChip.types';

const LiftChip: TLiftChip = ({ style, title, closeButton = false, closeButtonFunc }) => {
  return (
    <Paragraph {...getTestIdAttr('LiftChip')} className="lift-chip" style={style}>
      {title}
      {closeButton && (
        <X onClick={closeButtonFunc} className="lift-chip-close-button" size="tiny" />
      )}
    </Paragraph>
  );
};

export default LiftChip;
