import Bff from '~/Api/Bff';
import { IExternalHistory, IExternalHistoryResponse, IPromoDesign } from '~/interfaces';
import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { INVALID_DESIGN } from '~/configs/statuses';

const DEFAULT_PAGE_SIZE = 20;

export const getExternalHistory = (
  promoDesignId: string,
  page = 0,
  pageSize = DEFAULT_PAGE_SIZE
): Promise<IExternalHistoryResponse> => {
  return Bff.get<IExternalHistoryResponse>({
    url: `/v1/promo-designs/${promoDesignId}/external-history?page=${page}&pageSize=${pageSize}`,
  }).then((response) => {
    return response.data;
  });
};

export const useGetExternalHistory = (
  promoDesignId: string,
  page?: number,
  pageSize?: number,
  options?: UseQueryOptions
): UseQueryResult<IExternalHistoryResponse> => {
  return useQuery<any>({
    queryKey: ['getExternalHistory', promoDesignId, page, pageSize],
    queryFn: async () => {
      const response = await getExternalHistory(promoDesignId, page, pageSize);
      return response;
    },
    cacheTime: 0,
    staleTime: 0,
    ...options,
  });
};

export const getFirstExternalHistory = async (
  promoDesignId: string
): Promise<IExternalHistory | null> => {
  const data = await getExternalHistory(promoDesignId, 0, 1);
  if (data.pagination.totalElements === 1) {
    return data?.data?.[0];
  }
  if (data.pagination.totalElements === 0) {
    return null;
  }
  const last = await getExternalHistory(promoDesignId, data.pagination.totalPages - 1, 1);
  return last.data?.[0];
};

export const useGetFirstExternalHistory = (
  promoDesignId: string,
  modalData?: IPromoDesign
): UseQueryResult<IExternalHistory> => {
  return useQuery<IExternalHistory>(
    ['getFirstExternalHistory', promoDesignId],
    async () => {
      return await getFirstExternalHistory(promoDesignId);
    },
    {
      enabled:
        !!promoDesignId && modalData?.status === INVALID_DESIGN && modalData?.personalized === true,
    }
  );
};
