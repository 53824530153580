import { IPercentProps } from '~/interfaces';

const MAX_DIGITS = 2;
export const getFormattedNumber = ({
  value,
  minDigits = 0,
  maxDigits = MAX_DIGITS,
  locale,
}: IPercentProps): string => {
  let formattedValue = value?.toString();
  if (!locale) {
    return formattedValue;
  }
  const option = {
    style: 'decimal',
    minimumFractionDigits: minDigits,
    maximumFractionDigits: maxDigits,
  };
  try {
    const formatter = new Intl.NumberFormat(locale.toString(), option);
    formattedValue = formatter.format(value);
  } catch (e) {
    console.error(e);
  }
  return formattedValue;
};
