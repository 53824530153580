import { useGetFirstExternalHistory } from '~/services/ExternalHistoryService';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Alert } from '@hexa-ui/components';
import { getTestIdAttr, validateExternalHistory } from '~/utils';
import { TAlert, TPromoDesignErrorMessage } from './PromoDesignErrorMessage.types';

const PromoDesignErrorMessage: TPromoDesignErrorMessage = ({ promoDesignId, promoDesignData }) => {
  const { data } = useGetFirstExternalHistory(promoDesignId, promoDesignData);
  const { formatMessage } = useIntl();

  const errorMessage = useMemo<string | undefined>(() => {
    const response = validateExternalHistory(data, formatMessage);
    return response.isValid
      ? undefined
      : typeof response.error === 'string'
      ? response.error
      : JSON.stringify(response.error);
  }, [data, formatMessage]);

  return useMemo<TAlert>(
    () =>
      errorMessage ? (
        <Alert
          {...getTestIdAttr('PromoDesignErrorMessage')}
          message={errorMessage}
          type={'error'}
          css={{
            marginTop: '10px',
            width: '100%',
          }}
        />
      ) : null,
    [errorMessage]
  );
};

export default PromoDesignErrorMessage;
