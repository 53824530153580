import { PRODUCTS_LIST_DELIMITER } from '~/constants';
import { IProduct, ISkusAndParameters, TMechanicType, TMechanicTypeOption } from '~/interfaces';

export const handlePastedProducts = (
  e: ClipboardEvent,
  options: IProduct[],
  selectedValues: IProduct[]
): IProduct[] => {
  const extractPastedValue = e.clipboardData.getData('text');
  const getFormattedValue = (extractPastedValue.split(/[, ]|,|[\n,]+/) || []).filter((item) =>
    item.trim()
  );

  // Needed for some reason formik is defaulting the selectedValues to a string after pasting initially
  if (typeof selectedValues === 'string') {
    selectedValues = [];
  }

  const optionsFiltered = options.filter(
    (product) => !selectedValues.find((storedProduct) => storedProduct.label === product.label)
  );

  const findIndexes = getFormattedValue
    .map((sku) => optionsFiltered.findIndex((product) => product.value === sku))
    .filter((index) => index !== -1);

  const findValues = findIndexes.map((index) => optionsFiltered[index]);

  return [...selectedValues, ...findValues];
};

export const removeLeadingZeros = (sku: string): string => {
  return sku.replace(/^0+/, '');
};

export const formatProductList = (productsArray, shouldRemoveLeadingZeros: boolean): IProduct[] => {
  if (!productsArray) {
    return [];
  }
  return productsArray.map((product): IProduct => {
    return formatProduct(product, shouldRemoveLeadingZeros);
  });
};

export const formatProduct = (
  product: ISkusAndParameters,
  shouldRemoveLeadingZeros: boolean
): IProduct => {
  const sku = shouldRemoveLeadingZeros ? removeLeadingZeros(product?.sku) : product.sku;
  return {
    label: `${sku}${PRODUCTS_LIST_DELIMITER}${product?.productName}`,
    name: product?.productName,
    image: product?.image ?? null,
    value: sku,
  };
};

export const newProductList = (
  storedAddedProducts: IProduct[],
  originalProducts: IProduct[]
): IProduct[] => {
  const storedProductsMap: { [key: string]: boolean } = {};
  for (const storedProduct of storedAddedProducts) {
    storedProductsMap[storedProduct.name] = true;
  }
  return originalProducts.filter((product: IProduct) => !storedProductsMap[product.name]);
};

export const findExistingProductIndex = (
  arrayObj: any,
  isPersonalized: boolean,
  mechanicsType: TMechanicType,
  mechanicsTypeOption: TMechanicTypeOption
): number =>
  arrayObj?.findIndex(
    (x) =>
      x?.isPersonalized === isPersonalized &&
      x?.mechanicsType === mechanicsType &&
      x?.mechanicsTypeOption === mechanicsTypeOption
  );

export const checkProductsExist = (productIndex?: number): boolean =>
  productIndex !== -1 && productIndex !== undefined;
