import React, { useEffect } from 'react';
import CustomLabel from './CustomLabel/CustomLabel';
import CustomError from './CustomError/CustomError';
import { useField } from 'formik';
import { Paragraph, Input } from '@hexa-ui/components';
import { getTestIdAttr } from '~/utils';

export const TextField = ({
  label,
  field: { name, value, ...fieldProps },
  form,
  hint,
  optional = false,
  required,
  onChange,
  placeholder = '',
  enabled,
  defaultValue,
  showLabel = true,
  fieldContainerStyle = {},
  hidden = false,
  characterCounter,
  maxLength,
  autoFocus,
  ...props
}) => {
  const [, , helpers] = useField({
    ...props,
    name,
  });

  useEffect(() => {
    if (defaultValue?.length) {
      helpers.setValue(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [value]);

  return (
    <div
      {...getTestIdAttr('TextField')}
      className="formik-input"
      style={{
        ...(hidden && {
          display: 'none',
        }),
        ...fieldContainerStyle,
      }}
    >
      {showLabel && (
        <CustomLabel errors={form?.errors?.[name]} label={label} hint={hint} optional={!required} />
      )}
      <Input
        {...getTestIdAttr('TextInputField')}
        type={'text'}
        autoComplete={'off'}
        id={name}
        value={value}
        placeholder={placeholder}
        required={required}
        hasError={!!form?.errors?.[name]}
        maxLength={maxLength}
        autoFocus={autoFocus}
        {...fieldProps}
        {...props}
      />
      <div className="error-and-char-counter-container">
        <CustomError errors={form?.errors?.[name]} />
        {characterCounter && (
          <Paragraph className="formik-char-counter">
            {value?.length ?? 0}/{maxLength}
          </Paragraph>
        )}
      </div>
    </div>
  );
};
