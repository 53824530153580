import { NumberSchema } from 'yup';
import { IValidationMessages, IValidationRule } from '~/interfaces';

export const lessThanSpecificValue = (
  validation: NumberSchema,
  rule: IValidationRule,
  fieldName: string,
  validationCallbacks: IValidationMessages
): NumberSchema => {
  validation = validation.when((dependentValue: number, schema: NumberSchema) => {
    if (rule.value) {
      return schema.when(fieldName, (_thisField: number, _innerSchema: NumberSchema) => {
        return schema.max(
          Number(rule.value),
          validationCallbacks.lessThanPreviousValue(fieldName, fieldName)
        );
      });
    }
  });
  return validation;
};
