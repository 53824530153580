import Bff from '~/Api/Bff';
import { useState } from 'react';
import { IProductParameters, ISelectedSKUs } from '~/interfaces';
import {
  TChangeSelectedSKUs,
  TDeleteSelectedSKUs,
  TGetSelectedSKUs,
  TUseSelectedSKUs,
} from './UserSkuPermissionsService.types';

export const useSelectedSKUs: TUseSelectedSKUs = ({ country }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const getSelectedSKUs: TGetSelectedSKUs = (userId, country) => {
    setIsLoading(true);
    const url = '/v1/user-product-filters';
    const options = country ? { headers: { country, userId } } : {};

    return Bff.get<ISelectedSKUs>({
      url,
      options,
    })
      .then((response) => {
        return response.data;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const changeSelectedSKUs: TChangeSelectedSKUs = ({ userId, country, skuParameters }) => {
    setIsSaving(true);
    const url = `v1/user-product-filters`;
    const options = country ? { headers: { country } } : {};
    return Bff.put<
      {
        id: string;
        parameters: IProductParameters;
      },
      ISelectedSKUs
    >({
      url,
      options,
      body: {
        id: userId,
        parameters: skuParameters,
      },
    })
      .then((response) => {
        if (response.hasError) {
          throw new Error(response?.data?.error?.message);
        }
        return response.data;
      })
      .finally(() => setIsSaving(false));
  };

  const deleteSelectedSKUs: TDeleteSelectedSKUs = ({ userId, country }) => {
    setIsSaving(true);
    const url = `v1/user-product-filters`;
    const options = { headers: { ...(country && { country }), userId } };
    return Bff.delete({
      url,
      options,
    })
      .then((response) => {
        if (response.hasError) {
          throw new Error(response?.data?.error?.message);
        }
        return response.data;
      })
      .finally(() => setIsSaving(false));
  };

  return {
    changeSelectedSKUs,
    deleteSelectedSKUs,
    getSelectedSKUs,
    setIsSaving,
    isLoading,
    isSaving,
  };
};
