import React from 'react';
import { Checkbox as HexaCheckbox } from '@hexa-ui/components';
export const Checkbox = ({
  field: { name, value, ...fieldProps },
  label,
  form,
  required,
  customId,
  customClasses,
  disabled,
  onClick,
  ...props
}): JSX.Element => {
  return (
    <HexaCheckbox
      className={customClasses}
      error={form?.errors?.[name]}
      id={customId}
      onClick={onClick}
      value={value}
      label={label}
      disabled={disabled}
      {...fieldProps}
      {...props}
    />
  );
};
