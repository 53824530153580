import { string, StringSchema } from 'yup';
import { ISchemaField, IValidationMessages } from '~/interfaces';

export const validateJson = (
  val: ISchemaField,
  validationCallbacks: IValidationMessages
): StringSchema => {
  let validator = string();
  validator = validator.test(val.name, validationCallbacks.mustBeValidJson(val.label), (value) => {
    try {
      JSON.parse(value);
    } catch (e) {
      return false;
    }
    return true;
  });
  return validator;
};
