import { TMIMEType } from '~/interfaces';

export const getBase64 = (file: File, cb): void => {
  try {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  } catch (e) {
    cb('Error');
  }
};

export async function blobToBase64(blob: Blob, type?: TMIMEType): Promise<FileReader['result']> {
  const myBlob = type ? new Blob([blob], { type }) : blob;
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(myBlob);
  });
}

export const getFileDataFromBase64 = (fileInBase64: string, fileName: string, cb): void => {
  const arr = fileInBase64.split(',');
  const mime = /^data:([a-zA-Z]+\/[a-zA-Z0-9-+.]+);/.exec(arr[0])[1];
  const bstr = atob(arr?.[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n) {
    n--;
    u8arr[n] = bstr.charCodeAt(n);
  }
  const file = new File([u8arr], fileName, { type: mime });
  cb(file);
};
