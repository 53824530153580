import { IChallengeIdResponse } from '~/interfaces';
import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import Bff from '~/Api/Bff';

export const getChallengeIds = (promoDesignId: string): Promise<IChallengeIdResponse> => {
  return Bff.get({
    url: `/v1/promo-design-challenges/${promoDesignId}`,
  }).then((response) => {
    return response.data;
  });
};

export const useGetChallengeIds = (
  promoDesignId: string,
  options?: UseQueryOptions
): UseQueryResult<IChallengeIdResponse> => {
  return useQuery<any, any, IChallengeIdResponse, any>({
    queryKey: ['getChallengeIds', promoDesignId],
    queryFn: async () => {
      return await getChallengeIds(promoDesignId);
    },
    cacheTime: 0,
    staleTime: 0,
    ...options,
  });
};
