import { FormattedMessage, useIntl } from 'react-intl';
import { getTestIdAttr } from '~/utils';
import { TProductFilterLabel, TProductFilterLabelProps } from './ProductFilterLabel.types';

const getI18nProps = (
  context: TProductFilterLabelProps
): {
  id: string;
  defaultMessage: string;
} => {
  const filtersMap = context.countryConfig?.PRODUCT_FILTER;
  const key = filtersMap?.[context.identifier];

  return {
    id: `DESIGN_PAGE.PRODUCTS.PRODUCT_FILTERS.${key}`,
    defaultMessage: context.identifier,
  };
};

export const ProductFilterText = (context: TProductFilterLabelProps): string => {
  const { formatMessage } = useIntl();
  return formatMessage(getI18nProps(context));
};

export const ProductFilterLabel: TProductFilterLabel = (props) => {
  return <FormattedMessage {...getTestIdAttr('ProductFilterLabel')} {...getI18nProps(props)} />;
};
