import { useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';
import { IPagination, TFilter, IPaginationOptions } from '~/interfaces';

export type TSetPagination = (page: number, pageSize: number, filters?: TFilter) => void;

export type TUsePaginationResponse = [IPagination, TSetPagination];

export const PAGE_QUERY_PARAM = 'page';
export const PAGE_SIZE_QUERY_PARAM = 'pageSize';
export const FILTERS_QUERY_PARAM = 'filters';

export const enforceRange = (
  currentValue: number,
  minValue: number = Number.NEGATIVE_INFINITY,
  maxValue: number = Number.POSITIVE_INFINITY
): number => {
  return Math.min(Math.max(currentValue, minValue), maxValue);
};

export const applyOptions = (pagination: IPagination, options?: IPaginationOptions): boolean => {
  if (!options) {
    return false;
  }
  const original = JSON.stringify(pagination);
  pagination.page = enforceRange(pagination.page, options?.minPage);
  pagination.pageSize = enforceRange(
    pagination.pageSize,
    options?.minPageSize,
    options?.maxPageSize
  );
  return JSON.stringify(pagination) !== original;
};

export const createSearchParams = (
  searchParams: URLSearchParams,
  pagination: IPagination,
  options?: IPaginationOptions
): URLSearchParams => {
  const newParams = new URLSearchParams(searchParams);
  applyOptions(pagination, options);
  if (pagination?.page) {
    newParams.set(PAGE_QUERY_PARAM, pagination.page.toString());
  }
  if (pagination?.pageSize) {
    newParams.set(PAGE_SIZE_QUERY_PARAM, pagination.pageSize.toString());
  }
  if (pagination?.filters) {
    newParams.set(FILTERS_QUERY_PARAM, JSON.stringify(pagination.filters));
  }
  return newParams;
};

/*
 Replace the current page URL without modifying the state
 This ensures that the correct parameters are applied,
 and hitting the back button will not take you to the previous state
 Note: this cannot be achieved by simply calling setPagination from within the memo, unless a setTimeout is used
 */
export const replaceUrl = (pagination: IPagination): void => {
  const oldHref = window.location.href;
  const oldUrl = new URL(oldHref);
  const oldSearchParams = oldUrl.searchParams;
  const newParams = createSearchParams(oldSearchParams, pagination);
  const join = oldSearchParams.toString() ? '' : '?';
  const newUrl = `${oldUrl.href.replace(
    oldSearchParams.toString(),
    ''
  )}${join}${newParams.toString()}`;
  window.history.replaceState({ path: newUrl }, '', newUrl);
};

export const usePagination = (
  defaultPagination: IPagination,
  options?: IPaginationOptions
): TUsePaginationResponse => {
  const [searchParams, setSearchParams] = useSearchParams();

  return useMemo<TUsePaginationResponse>(() => {
    const setPagination: TSetPagination = (page, pageSize, filters): void => {
      const pagination: IPagination = {
        page,
        pageSize,
        ...(filters && {
          filters,
        }),
      };
      setSearchParams(createSearchParams(searchParams, pagination, options));
    };

    const pagination: IPagination = {
      ...defaultPagination,
      ...(searchParams.has(PAGE_QUERY_PARAM) && {
        page: Number.parseInt(searchParams.get(PAGE_QUERY_PARAM), 10),
      }),
      ...(searchParams.has(PAGE_SIZE_QUERY_PARAM) && {
        pageSize: Number.parseInt(searchParams.get(PAGE_SIZE_QUERY_PARAM), 10),
      }),
      ...(searchParams.has(FILTERS_QUERY_PARAM) && {
        filters: JSON.parse(searchParams.get(FILTERS_QUERY_PARAM)),
      }),
    };

    if (applyOptions(pagination, options)) {
      replaceUrl(pagination);
    }

    return [pagination, setPagination];
  }, [searchParams]);
};

export default usePagination;
