import { useEffect } from 'react';
import { useField } from 'formik';
import CustomLabel from '~/components/Form/components/CustomLabel/CustomLabel';
import { Select as SelectHexa } from '@hexa-ui/components';
import { Tooltip } from '@admin-portal-shared-components/tooltip';
import './Select.css';
import { getTestIdAttr } from '~/utils';
export const Select = ({
  label,
  field: { name, value, ...fieldProps },
  form,
  required,
  options,
  placeholder,
  dependency,
  hint,
  dependencyPlaceholder,
  onOptionSelect = (value: string) => {},
  defaultValue,
  disabled,
  disableWithStyling,
  ...props
}): JSX.Element => {
  const [, , helpers] = useField({
    ...props,
    name,
  });

  useEffect(() => {
    if (defaultValue?.length) {
      helpers.setValue(defaultValue);
    }
  }, [defaultValue]);

  const dependencyJSX = (
    <div>
      <CustomLabel errors={form?.errors?.[name]} label={label} hint={hint} />
      <div
        className={`dropdown-dependency-placeholder ${
          !!form?.errors?.[name] && 'dropdown-dependency-error-border'
        }`}
      >
        {dependencyPlaceholder}
      </div>
    </div>
  );

  return (
    <div
      {...getTestIdAttr('Select')}
      className={`dropdown-container ${disableWithStyling && 'disable-with-styling'}`}
    >
      {dependency ? (
        dependencyJSX
      ) : (
        <SelectHexa.Root
          disabled={disabled}
          name={name}
          label={label}
          error={form?.errors?.[name]}
          onChange={(val) => {
            helpers.setValue(val);
            onOptionSelect(val);
          }}
          placeholder={placeholder}
          value={value}
          {...props}
        >
          {options?.map((option) => (
            <SelectHexa.Option key={option.value} value={option.value}>
              {option?.hoverLabel?.length ? (
                <Tooltip placement="left" text={option.hoverLabel}>
                  <span>{option.label}</span>
                </Tooltip>
              ) : (
                option?.label
              )}
            </SelectHexa.Option>
          ))}
        </SelectHexa.Root>
      )}
    </div>
  );
};
