export const singlePromoDesignFixture = {
  status: '200 OK',
  data: {
    promoDesign: {
      country: 'BR',
      periodStartDate: '2022-12-13T00:00:00',
      lastUpdatedTimestamp: '2022-12-13T22:09:40.321',
      creatorEmail: 'uzziel.sierra-ext@ab-inbev.com',
      pocInclude: ['4179_376469', '2379_703354'],
      mechanicType: 'DISCOUNT',
      creatorName: 'Uzziel Sierra',
      creatorId: '75dca81a-5651-4a40-91f2-14e477a751ea',
      promoDesignId: '6398f5a2d6c6ea2238d0760a',
      customerFilter: {},
      pocExclude: [],
      periodEndDate: '2022-12-28T00:00:00',
      personalized: false,
      promoDesignTitle: 'Dec 13 2022 Introduce Promotion',
      createdAt: '2022-12-13T21:58:58.011',
      provider: null,
      strategyParameters: [
        {
          strategy: 'INTRODUCE',
          substrategyCodes: ['I1'],
          rules: {
            selectableSubstrategies: 1,
            group: 1,
          },
        },
      ],
      applyLifecycleFiltering: false,
      promoPrefix: 'Personalized-D-BR-SIT-FE',
      status: 'PENDING_DELETION',
      mechanicTypeOption: 'discount_percent',
      promoDesignTitleDisplay: 'Personalized-D-BR-SIT-FE',
    },
  },
};

export const singlePromoDesignPaginatedFixture = {
  data: [
    {
      id: '6398f5a2d6c6ea2238d0760b',
      promoDesignId: '6398f5a2d6c6ea2238d0760a',
      country: 'BR',
      userDescription: '',
      skuLimit: '0',
      productName: null,
      allocation: 0,
      budgetId: 'INI-32',
      maxDiscount: 18,
      maxPromoPrice: 0,
      maxQuantity: 18,
      minDiscount: 18,
      minPromoPrice: 0,
      minQuantity: 12,
      orderAvailability: 18,
      sku: '25429',
      steps: [],
      lastUpdatedTimestamp: '2022-12-13T21:58:58.021',
    },
    {
      id: '6398f5a2d6c6ea2238d0760c',
      promoDesignId: '6398f5a2d6c6ea2238d0760a',
      country: 'BR',
      userDescription: '',
      skuLimit: '0',
      productName: null,
      allocation: 0,
      budgetId: 'INI-32',
      maxDiscount: 18,
      maxPromoPrice: 0,
      maxQuantity: 18,
      minDiscount: 18,
      minPromoPrice: 0,
      minQuantity: 12,
      orderAvailability: 18,
      sku: '25342',
      steps: [],
      lastUpdatedTimestamp: '2022-12-13T21:58:58.021',
    },
  ],
  pagination: {
    page: 0,
    size: 2,
    totalElements: 2,
    totalPages: 1,
  },
};
