import { IProductShort } from '~/interfaces';
import { useQuery } from '@tanstack/react-query';
import { UseQueryOptions, UseQueryResult } from '@tanstack/react-query/build/lib/types';
import { useEffect, useState } from 'react';
import Bff from '~/Api/Bff';
import { SKU_REMOVE_LEADING_ZEROS } from '~/constants';
import { useFeatureToggle, useSelectedCountry } from '~/hooks';
import { removeLeadingZeros } from '~/utils';

type Products = { products: { image: string | null } };

const getProducts = async (): Promise<IProductShort[] & Products> => {
  try {
    const response = await Bff.get({ url: '/v1/products' });
    return response?.data?.products?.products;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const useGetProducts = (options?: UseQueryOptions): UseQueryResult => {
  const selectedCountry = useSelectedCountry();
  const shouldRemoveLeadingZeros = useFeatureToggle(SKU_REMOVE_LEADING_ZEROS, {
    country: selectedCountry,
  });
  const [trimLeadingZeros, setTrimLeadingZeros] = useState<boolean>(false);

  useEffect(() => {
    setTrimLeadingZeros(shouldRemoveLeadingZeros);
  }, [shouldRemoveLeadingZeros]);

  return useQuery({
    queryKey: ['getProducts', selectedCountry],
    queryFn: async () => {
      return await getProducts();
    },
    select: (data: any) => {
      return trimLeadingZeros
        ? data?.map((product) => {
            return { ...product, sku: removeLeadingZeros(product.sku) };
          })
        : data;
    },
    ...options,
  });
};

export default useGetProducts;
