// Specify SKU Params to display N/A

import { SKU_LIMIT } from '~/constants/skuParams';
import { TPromoDesignSkuField, TConfigArrayWithDefault } from '~/interfaces';

export const DEFAULT_SKU_PARAMS_TO_DISPLAY_NA: TPromoDesignSkuField[] = [SKU_LIMIT];

export const SKU_PARAMS_TO_DISPLAY_NA: TConfigArrayWithDefault<TPromoDesignSkuField> = {
  DEFAULT: [...DEFAULT_SKU_PARAMS_TO_DISPLAY_NA],
  BR: [...DEFAULT_SKU_PARAMS_TO_DISPLAY_NA],
};

export const getSkuParamsToDisplayNA = (country: string): TPromoDesignSkuField[] => {
  return SKU_PARAMS_TO_DISPLAY_NA?.[country] ?? SKU_PARAMS_TO_DISPLAY_NA?.DEFAULT;
};
