import { Toast } from '@hexa-ui/components';
import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { resetToast } from '~/redux/saga/sagaActions';
import { useAppDispatch, useAppSelector } from '~/hooks';
import { selectGlobalToast } from '~/redux/slices';
import { IToastData } from '~/interfaces';

const GlobalToasts = (): JSX.Element => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const [toastData, setToastData] = useState<IToastData>({
    type: 'error',
    open: false,
    reset: () => {},
    message: '',
    duration: 0,
  });

  const toast = useAppSelector(selectGlobalToast);
  const reset = () => {
    dispatch(resetToast());
  };

  useEffect(() => {
    const { type, showToast, error, status, duration, customMessage } = toast;
    if (showToast) {
      if (error && !customMessage) {
        setToastData({
          open: showToast,
          message: formatMessage(
            {
              id: 'ERROR_TOAST',
              defaultMessage:
                'Error {errorCode}: Something went wrong with your request. Please contact administrator, or try again later.',
            },
            { errorCode: status || '' }
          ),
          type,
          reset,
          duration,
        });
      } else {
        setToastData({
          open: showToast,
          message: customMessage,
          type,
          reset,
          duration,
        });
      }
    }
  }, [toast]);

  const resetToastState = (): void => {
    toastData?.reset();
    setToastData({ ...toastData, open: false, reset: undefined, message: '', duration: 0 });
  };

  return (
    <Toast.Provider duration={toastData?.duration} swipeDirection="right">
      <Toast.Root
        showCloseButton
        onClose={() => resetToastState()}
        type={toastData.type}
        open={toastData?.open || false}
        actions={[
          {
            action: () => resetToastState(),
            name: formatMessage({ id: 'BUTTONS.DONE', defaultMessage: 'Done' }),
          },
        ]}
        onOpenChange={() => resetToastState()}
        position="top-right"
        message={toastData.message}
      />
    </Toast.Provider>
  );
};

export default GlobalToasts;
