import styled, { css } from 'styled-components';

interface IStatusProps {
  color?: string;
}

interface ITextProps {
  hide?: boolean;
  size?: 'sm' | 'md';
}

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 8px;
`;

export const Text = styled.span<ITextProps>`
  height: 20px;
  color: rgba(0, 0, 0, 0.55);
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 20px;
  ${({ hide }) =>
    hide &&
    css`
      visibility: hidden;
    `}

  ${(props) =>
    props.size === 'md' &&
    css`
      color: rgba(0, 0, 0, 0.55);
      font-size: 16px;
      margin-left: 4px;
    `}
`;

export const StatusColor = styled.div<IStatusProps>`
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background-color: ${(props) => props.color ?? '#000'};
`;
