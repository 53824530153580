import { getDateFormat } from '~/configs/dateFormat';
import { TFormatDateTypes } from '~/interfaces';

const formatDate = (
  date: string,
  localeCode: string,
  useCase: TFormatDateTypes = 'DEFAULT',
  isHideCurrentYear = false
): string => {
  try {
    const value = new Date(date).toLocaleDateString(
      localeCode,
      getDateFormat(localeCode, useCase, isHideCurrentYear)
    );
    if (date && value === 'Invalid Date') {
      throw new Error('Invalid Date');
    }
    return value;
  } catch {
    return date;
  }
};

export default formatDate;
