import { object, array } from 'yup';
import { TValidationType, ISchemaField, IValidationMessages } from '~/interfaces';

export const validateObject = (
  val: ISchemaField,
  validationCallbacks: IValidationMessages
): TValidationType => {
  let validationType = object().shape({
    checked: array(),
  });

  if (val.required) {
    validationType = validationType.test(
      '',
      validationCallbacks.isRequired(val.label),
      // @ts-ignore
      (value) => {
        return value.checked.length;
      }
    );
  }
  return validationType;
};
