import { rest } from 'msw';

const informationWasUpdated = 'Information was updated';
export const baseHandlers = [
  rest.get('/log', (_, res, ctx) => {
    return res(
      ctx.json({
        message: 'Information was logged',
      })
    );
  }),
  rest.delete('/log', (_, res, ctx) => {
    return res(
      ctx.json({
        message: 'Information was deleted',
      })
    );
  }),
  rest.put('/log', (_, res, ctx) => {
    return res(
      ctx.json({
        message: informationWasUpdated,
      })
    );
  }),
  rest.post('/log', (_, res, ctx) => {
    return res(
      ctx.json({
        message: informationWasUpdated,
      })
    );
  }),
  rest.patch('/log', (_, res, ctx) => {
    return res(
      ctx.json({
        message: informationWasUpdated,
      })
    );
  }),
  rest.get('/v1/countries', (_, res, ctx) => {
    return res(
      ctx.json({
        countries: ['BR'],
      })
    );
  }),
  rest.get('/v1/parameters/sku', (_, res, ctx) => {
    return res(ctx.json({}));
  }),
  rest.get('/v1/parameters/poc', (_, res, ctx) => {
    return res(ctx.json({}));
  }),
  rest.get('/v1/parameters/global', (_, res, ctx) => {
    return res(ctx.json({}));
  }),
];
