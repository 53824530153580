import { createSlice } from '@reduxjs/toolkit';
import {
  IAddedProducts,
  IAddEditSKUProducts,
  IBooleanPayloadAction,
  IRemoveSKUProducts,
  ISkusAndParameters,
  TMechanicType,
  TMechanicTypeOption,
} from '~/interfaces';
import {
  findExistingProductIndex,
  findObjectIndex as findObjIndex,
  uniqueObjectsByKey,
} from '~/utils';
import { RootState } from '~/redux/store';

export interface ISKUState {
  parameters: any[];
  addedProducts: IAddedProducts[];
  addedPersonalizedProducts: any[];
  addedNonPersonalizedProducts: any[];
  openDialog: boolean;
}

const initialState: ISKUState = {
  parameters: [],
  addedProducts: [],
  addedPersonalizedProducts: [],
  addedNonPersonalizedProducts: [],
  openDialog: false,
};

const skuSlice = createSlice({
  name: 'skuSelection',
  initialState,
  reducers: {
    setOpenSkuDialog: (state, action: IBooleanPayloadAction): ISKUState => {
      return {
        ...state,
        openDialog: action.payload,
      };
    },
    addSkuProducts: (state, action: IAddEditSKUProducts): ISKUState => {
      const { isPersonalized, mechanicsType, mechanicsTypeOption, products } = action.payload;

      const findAddedProductIndex = findExistingProductIndex(
        state.addedProducts,
        isPersonalized,
        mechanicsType,
        mechanicsTypeOption
      );

      // add onto products if there are already products listed
      if (findAddedProductIndex !== -1 && findAddedProductIndex !== undefined) {
        return {
          ...state,
          addedProducts: [
            ...state.addedProducts.slice(0, findAddedProductIndex),
            {
              ...action.payload,
              products: uniqueObjectsByKey(
                [...state.addedProducts[findAddedProductIndex].products, ...products],
                'sku'
              ),
            },
            ...state.addedProducts.slice(findAddedProductIndex + 1),
          ],
        };
      }

      // add onto products if there are NO products listed.
      return {
        ...state,
        addedProducts: [...state.addedProducts, { ...action.payload }],
      };
    },
    editSkuProducts: (state, action: IAddEditSKUProducts): ISKUState => {
      const { isPersonalized, mechanicsType, mechanicsTypeOption, products, originalProduct } =
        action.payload;
      const currentAddedState = state.addedProducts;
      const findAddedProductIndex = findExistingProductIndex(
        currentAddedState,
        isPersonalized,
        mechanicsType,
        mechanicsTypeOption
      );
      const editedSkuProductIndex = findObjIndex(
        currentAddedState[findAddedProductIndex]?.products,
        'sku',
        originalProduct.sku
      );

      return {
        ...state,
        addedProducts: [
          ...state.addedProducts.slice(0, findAddedProductIndex),
          {
            ...action.payload,
            products: [
              ...state.addedProducts[findAddedProductIndex].products.slice(
                0,
                editedSkuProductIndex
              ),
              ...uniqueObjectsByKey(products, 'sku'),
              ...state.addedProducts[findAddedProductIndex].products.slice(
                editedSkuProductIndex + 1
              ),
            ],
          },
          ...state.addedProducts.slice(findAddedProductIndex + 1),
        ],
      };
    },
    removeSkuProducts: (state, action: IRemoveSKUProducts): ISKUState => {
      const { isPersonalized, mechanicsType, mechanicsTypeOption, product } = action.payload;
      const currentAddedState = state.addedProducts;
      const findAddedProductIndex = findExistingProductIndex(
        currentAddedState,
        isPersonalized,
        mechanicsType,
        mechanicsTypeOption
      );
      const removeSkuProductIndex = findObjIndex<ISkusAndParameters>(
        currentAddedState[findAddedProductIndex]?.products,
        'sku',
        product.sku
      );

      return {
        ...state,
        addedProducts: [
          ...state.addedProducts.slice(0, findAddedProductIndex),
          {
            ...action.payload,
            products: [
              ...state.addedProducts[findAddedProductIndex].products.slice(
                0,
                removeSkuProductIndex
              ),
              ...state.addedProducts[findAddedProductIndex].products.slice(
                removeSkuProductIndex + 1
              ),
            ],
          },
          ...state.addedProducts.slice(findAddedProductIndex + 1),
        ],
      };
    },
    clearSkuProducts: (
      state,
      action: {
        payload: {
          isPersonalized: boolean;
          mechanicsType: TMechanicType;
          mechanicsTypeOption: TMechanicTypeOption;
        };
      }
    ): ISKUState => {
      const { isPersonalized, mechanicsType, mechanicsTypeOption } = action.payload;
      const currentAddedState = state.addedProducts;
      const findAddedProductIndex = findExistingProductIndex(
        currentAddedState,
        isPersonalized,
        mechanicsType,
        mechanicsTypeOption
      );

      return {
        ...state,
        addedProducts: [
          ...state.addedProducts.slice(0, findAddedProductIndex),
          ...state.addedProducts.slice(findAddedProductIndex + 1),
        ],
      };
    },
    resetSkuParameters: (): ISKUState => {
      return {
        ...initialState,
      };
    },
  },
});

export const selectSkuState = (state: RootState) => state.sku;
export const selectAddedProducts = (state: RootState) => state.sku.addedProducts;
export const selectOpenDialog = (state: RootState) => state.sku.openDialog;

export const {
  setOpenSkuDialog,
  resetSkuParameters,
  addSkuProducts,
  editSkuProducts,
  removeSkuProducts,
  clearSkuProducts,
} = skuSlice.actions;
export default skuSlice.reducer;
