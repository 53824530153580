import { LoadingDots, Tooltip } from '@hexa-ui/components';
import TitleItem from '~/components/TitleItem';
import { getTestIdAttr } from '~/utils';
import { ItemTitle, ItemWrapper, StyledDownloadIcon } from './DownloadItem.styles';
import { TDownloadItem } from './DownloadItem.types';

const DownloadItem: TDownloadItem = ({
  text,
  onClick,
  disabled,
  tooltipText,
  isLoading,
  ...titleItemProps
}) => {
  const handleClick = () => {
    !disabled && onClick();
  };

  return (
    <TitleItem
      {...getTestIdAttr('DownloadItem')}
      {...titleItemProps}
      textComponent={
        <ItemWrapper>
          <ItemTitle>{text}</ItemTitle>
          {!isLoading && (
            <Tooltip text={tooltipText}>
              <StyledDownloadIcon
                className={disabled ? 'disabled-download-link' : 'download-link'}
                size="medium"
                onClick={handleClick}
              />
            </Tooltip>
          )}
          {isLoading && <LoadingDots dataTestId="DownloadItem_loading-dots" />}
        </ItemWrapper>
      }
    />
  );
};

export default DownloadItem;
