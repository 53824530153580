import { IPromoMechanic, IPromoMechanicOption } from '~/interfaces';
import { useAppSelector } from '~/hooks/app';
import { selectGlobalState } from '~/redux/slices';
import { useMemo } from 'react';
import { findObjectIndex as findObjIndex } from '@moneycomb/utils';

export const useSelectedMechanicOption = (
  mechanicsData: IPromoMechanic[]
): IPromoMechanicOption => {
  const { mechanicsType: selectedMechanicsType, mechanicsTypeOption: selectedMechanicsTypeOption } =
    useAppSelector(selectGlobalState);
  return useMemo(() => {
    const mechanicTypeIndex = findObjIndex<IPromoMechanic>(
      mechanicsData,
      'type',
      selectedMechanicsType
    );
    const mechanicTypeOptions = mechanicsData?.[mechanicTypeIndex]?.options;
    const mechanicTypeOptionIndex = findObjIndex<IPromoMechanicOption>(
      mechanicTypeOptions,
      'type',
      selectedMechanicsTypeOption
    );
    return mechanicTypeOptions?.[mechanicTypeOptionIndex];
  }, [selectedMechanicsType, selectedMechanicsTypeOption, mechanicsData]);
};
