import Bff from '~/Api/Bff';
import { IUser } from '@moneycomb/mcomb-types';
import { useQuery } from '@tanstack/react-query';
import { UseQueryResult } from '@tanstack/react-query/build/lib/types';

export const getUserById = (search: string, country?: string): Promise<IUser[]> => {
  if (!search) {
    return Promise.resolve([] as IUser[]);
  }
  const url = `v1/datadomain/users?value=${search}`;
  const options = country ? { headers: { country } } : {};
  return Bff.get<IUser[]>({
    url,
    options,
  }).then((response) => {
    return response.data;
  });
};

export const useGetUserById = (search: string, country?: string): UseQueryResult<IUser[], any> => {
  return useQuery<any, any, IUser[], any[]>(
    ['getUserById', country, search],
    async () => {
      return await getUserById(search, country);
    },
    {
      cacheTime: 30,
    }
  );
};
