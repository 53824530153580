export const ADD_NEW_PROMO_BUTTON = 'addNewPromoButton';
export const IS_COUNTRY_ACTIVE = 'isCountryActive';
export const SHOW_ERP_HISTORY_TAB = 'showERPHistoryTabOnPromoDetailsModal';
export const DELETE_PROMO_BUTTON = 'deletePromoButton';
export const PROMO_DESCRIPTION_FIELD = 'promoDescriptionField';
export const DOWNLOAD_PROMO_CSVS_BUTTON = 'downloadPromoCsvsButton';
export const DUPLICATE_PROMO_BUTTON = 'duplicatePromoButton';
export const SHOW_FILTER_VALUE_COUNTS_ON_DESIGN_MODAL_ACCORDION =
  'showFilterValueCountsOnDesignModalAccordion';
export const SHOW_TABLE_IN_DESIGN_MODAL_ACCORDION = 'showTableInDesignModalAccordion';
export const ENABLE_SUPPORT_REQUESTS = 'enable_support_requests';
export const ENABLE_OVERLAPPING_PROMOS_TAB = 'enableOverlappingPromosTab';
export const SHOW_REPORT_ISSUE_LINK = 'showReportIssueLink';
export const SHOW_PROMOTION_DURATION_ON_DESIGN_PAGE_DATE_SELECTION =
  'showPromotionDurationOnDesignPageDateSelection';
export const SHOW_PERSONALIZED_TOGGLE = 'personalizedToggle';
export const SHOW_NON_PERSONALIZED_TOGGLE = 'nonPersonalizedToggle';
export const SHOW_ACTIONS_COLUMN_ON_PROMO_DASHBOARD = 'actionsColumnPromoDashboard';
export const SHOW_CREATED_AT_COLUMN_ON_PROMO_DASHBOARD = 'createdAtColumnPromoDashboard';
export const SHOW_PROMO_DESIGN_TITLE_COLUMN_ON_PROMO_DASHBOARD =
  'promoDesignTitleColumnPromoDashboard';
export const SHOW_PROMO_DESIGN_TITLE_DISPLAY_COLUMN_ON_PROMO_DASHBOARD =
  'promoDesignTitleColumnDisplayPromoDashboard';
export const SHOW_DATE_RANGE_COLUMN_ON_PROMO_DASHBOARD = 'dateRangeColumnPromoDashboard';
export const SHOW_STATUS_COLUMN_ON_PROMO_DASHBOARD = 'statusColumnPromoDashboard';
export const SHOW_CREATOR_NAME_COLUMN_ON_PROMO_DASHBOARD = 'creatorNameColumnPromoDashboard';
export const SHOW_PROMO_DESIGN_ID_COLUMN_ON_PROMO_DASHBOARD = 'promoDesignIdColumnPromoDashboard';
export const SHOW_MECHANIC_TYPE_COLUMN_ON_PROMO_DASHBOARD = 'mechanicTypeColumnPromoDashboard';
export const SKU_REMOVE_LEADING_ZEROS = 'skuRemoveLeadingZeros';
export const PROMO_DETAILS_DESCRIPTION_FIELD_REQUIRED = 'promoDetailsDescriptionRequired';
export const SHOW_SET_USER_NAME_LINK = 'showSetUserNameLink';
export const CAN_CONFIGURE_USER_PERMISSIONS = 'canConfigureUserPermissions';
export const CAN_ACCESS_FEATURE_TOGGLES_PAGE = 'canAccessFeatureTogglesPage';
export const SHOW_SKU_COLUMN_ON_PRODUCT_TABLE = 'skuColumnProductTable';
export const SHOW_PRODUCT_NAME_COLUMN_ON_PRODUCT_TABLE = 'productNameColumnProductTable';
export const SHOW_MIN_QUANTITY_COLUMN_ON_PRODUCT_TABLE = 'minQuantityColumnProductTable';
export const SHOW_MAX_QUANTITY_COLUMN_ON_PRODUCT_TABLE = 'maxQuantityColumnProductTable';
export const SHOW_MIN_DISCOUNT_COLUMN_ON_PRODUCT_TABLE = 'minDiscountColumnProductTable';
export const SHOW_MAX_DISCOUNT_COLUMN_ON_PRODUCT_TABLE = 'maxDiscountColumnProductTable';
export const SHOW_DISCOUNT_COLUMN_ON_PRODUCT_TABLE = 'discountColumnProductTable';
export const SHOW_ALLOCATION_COLUMN_ON_PRODUCT_TABLE = 'allocationColumnProductTable';
export const SHOW_ORDER_AVAILABILITY_COLUMN_ON_PRODUCT_TABLE =
  'orderAvailabilityColumnProductTable';
export const SHOW_SKU_LIMIT_COLUMN_ON_PRODUCT_TABLE = 'skuLimitColumnProductTable';
export const SHOW_BUDGET_ID_COLUMN_ON_PRODUCT_TABLE = 'budgetIdColumnProductTable';
export const SHOW_MAX_POINTS_COLUMN_ON_PRODUCT_TABLE = 'maxPointsColumnProductTable';
export const SHOW_STEP_ID_COLUMN_ON_PRODUCT_TABLE = 'stepIdColumnProductTable';
export const PREVENT_SINGLE_STEP_SUBMIT = 'preventSingleStepSubmit';
export const ENABLE_REVIEW_STEP_CSV_DOWNLOAD = 'enableReviewStepCSVDownload';
export const ENABLE_IMAGE_DIMENSION_VALIDATION = 'enableImageDimensionValidation';
export const SHOW_PROMO_HISTORY_TAB = 'showPromoHistory';
export const SHOW_PROMO_DESIGN_HISTORY_TAB = 'showPromoDesignHistory';
export const ENABLE_ADDITION_TO_VALUE_VALIDATION = 'enableAdditionToValueValidation';
export const ENABLE_MIN_START_DATE_LOGIC = 'enableMinStartDateLogic';
export const PREVENT_DESIGN_SUBMISSION_ON_DATE_ERROR = 'preventPromoDesignCreationOnDateRangeError';
export const ENABLE_CHALLENGE_HISTORY = 'enableChallengeHistory';
export const SHOW_PROMO_HISTORY_FOR_REWARDS_TAB = 'showPromoHistoryForRewards';
export const SHOW_PROMO_DESIGN_HISTORY_FOR_REWARDS_TAB = 'showPromoDesignHistoryForRewards';
export const SHOW_CREATED_AT_ON_FILTER_OPTION = 'showCreatedAtOnFilterOption';
export const SHOW_PROMO_DESIGN_TITLE_ON_FILTER_OPTION = 'showPromoDesignTitleOnFilterOption';
export const SHOW_PROMO_STRATEGY_ON_FILTER_OPTION = 'showPromoStrategyOnFilterOption';
export const SHOW_SUB_STRATEGY_CD_ON_FILTER_OPTION = 'showSubStrategyCdOnFilterOption';
export const SHOW_DATE_RANGE_ON_FILTER_OPTION = 'showDateRangeOnFilterOption';
export const SHOW_STATUS_ON_FILTER_OPTION = 'showStatusOnFilterOption';
export const SHOW_CREATOR_NAME_ON_FILTER_OPTION = 'showCreatorNameOnFilterOption';
export const SHOW_PROMO_DESIGN_ID_ON_FILTER_OPTION = 'showPromoDesignIdOnFilterOption';
export const SHOW_MECHANIC_TYPE_ON_FILTER_OPTION = 'showMechanicTypeOnFilterOption';
export const SHOW_PROMO_ID_ON_FILTER_OPTION = 'showPromoIdOnFilterOption';
export const SHOW_POC_ID_ON_FILTER_OPTION = 'showPocIdOnFilterOption';
export const SHOW_SKUS_ON_FILTER_OPTION = 'showSkuOnFilterOption';
export const SHOW_ERP_ID_ON_FILTER_OPTION = 'showErpIdOnFilterOption';
export const SHOW_POCS_ON_BEES = 'showPocsOnBees';
export const SHOW_POCS_ON_ERP = 'showPocsOnERP';
