/**
 * Use this file to configure the order in which the feilds appear
 * Eventually we will move this to a different data source
 * The code is NOT dependent upon all fields being present in this list
 * If a field is sent from the server that is not in this list, it will simply be added later in the form
 */
import { TConfigArrayWithDefault } from '~/interfaces';

export const COMPLETE = 'COMPLETE';
export const DELETED = 'DELETED';
export const DESIGNED = 'DESIGNED';
export const DRAFT = 'DRAFT';
export const FAILED_KAFKA_SEND = 'FAILED_KAFKA_SEND';
export const FALLBACK = 'FALLBACK';
export const INVALID = 'INVALID';
export const INVALID_DESIGN = 'INVALID_DESIGN';
export const INVALID_PROMO = 'INVALID_PROMO';
export const INVALID_PROMOS = 'INVALID_PROMOS';
export const SYSTEM_ERROR = 'SYSTEM_ERROR';
export const LIVE = 'LIVE';
export const PENDING_ALGORITHM = 'PENDING_ALGORITHM';
export const PENDING_ALGORITHM_DELAYED = 'PENDING_ALGORITHM_DELAYED';
export const PENDING_DELETION = 'PENDING_DELETION';
export const PENDING_MODELING = 'PENDING_MODELING';
export const PROMOS_CREATED = 'PROMOS_CREATED';
export const PROMOS_DELAYED = 'PROMOS_DELAYED';
export const PROMOS_LOADED = 'PROMOS_LOADED';
export const RECEIVED_DS = 'RECEIVED_DS';
export const RECEIVED_ERP = 'RECEIVED_ERP';

export const PROCESSED = 'PROCESSED'; // @deprecated
export const CREATED = 'CREATED'; // @deprecated
export const DELAYED = 'DELAYED'; // @deprecated
export const SUCCESS = 'SUCCESS'; // @deprecated
export const LIVE_WITH_ERRORS = 'LIVE_WITH_ERRORS'; // @deprecated
export const FAILED = 'FAILED'; // @deprecated

export const DEFAULT_STATUSES = [
  COMPLETE,
  DELETED,
  DESIGNED,
  DRAFT,
  FAILED_KAFKA_SEND,
  FALLBACK,
  INVALID,
  INVALID_DESIGN,
  INVALID_PROMO,
  INVALID_PROMOS,
  LIVE,
  PENDING_ALGORITHM,
  PENDING_ALGORITHM_DELAYED,
  PENDING_DELETION,
  PENDING_MODELING,
  PROMOS_CREATED,
  PROMOS_DELAYED,
  PROMOS_LOADED,
  RECEIVED_DS,
  RECEIVED_ERP,
];

export const FILTER_STATUSES: TConfigArrayWithDefault = {
  DEFAULT: [...DEFAULT_STATUSES],
  BR: [...DEFAULT_STATUSES],
};

export const getFilterStatuses = (country?: string): string[] => {
  return FILTER_STATUSES?.[country] ?? FILTER_STATUSES.DEFAULT;
};
