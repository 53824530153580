import React from 'react';
import { Formik, Form, Field } from 'formik';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { startOfMonth, endOfMonth } from 'date-fns';
import { getPromoHistoryCSVDownload } from '~/services/PromoHistoryERPDownload/PromoHistoryERPDownload';

import { SubmitButton, Label, Input } from './styles';

const CalendarForm = () => {
  const currentDate = new Date();
  const currentMonthEnd = endOfMonth(currentDate);

  const handleSubmit = async (values) => {
    const { date } = values;
    const startDate = startOfMonth(date).toISOString();
    const endDate = endOfMonth(date).toISOString();

    const data = await getPromoHistoryCSVDownload(startDate, endDate);

    const fileReader = new FileReader();
    fileReader.onload = () => {
      const downloadUrl = fileReader.result;
      const link = document.createElement('a');
      link.href = downloadUrl as any;
      link.setAttribute('download', 'data.csv');
      document.body.appendChild(link);
      link.click();
      link.remove();
    };
    fileReader.readAsDataURL(data);
  };

  return (
    <Formik
      initialValues={{
        date: currentDate,
      }}
      onSubmit={handleSubmit}
    >
      <Form className="promo-history" style={{ display: 'flex' }}>
        <Label htmlFor="date" style={{ alignSelf: 'center' }}>
          Date:
        </Label>
        <Field name="date">
          {({ field, form }) => (
            <DatePicker
              id="date"
              selected={field.value}
              onChange={(date) => form.setFieldValue(field.name, date)}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              showFullMonthYearPicker
              dropdownMode="select"
              minDate={null}
              maxDate={currentMonthEnd}
              customInput={<Input />}
            />
          )}
        </Field>
        <div>
          <SubmitButton type="submit">Download</SubmitButton>
        </div>
      </Form>
    </Formik>
  );
};

export default CalendarForm;
