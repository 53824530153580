import { Container, Item } from '@admin-portal-shared-components/grid';
import { TextField } from '@material-ui/core';
import { useEffect } from 'react';
import { useField } from 'formik';
import CustomLabel from '~/components/Form/components/CustomLabel/CustomLabel';
import CustomError from '~/components/Form/components/CustomError/CustomError';
import './DatePicker.css';
import { getTestIdAttr } from '~/utils';

const DatePicker = ({
  field: { name, value },
  label,
  defaultValue,
  dateValues,
  hint,
  showLabel = true,
  form,
  setDate,
  minDate,
  maxDate,
  setErrors,
  customClasses,
  ...props
}): JSX.Element => {
  const [, , helpers] = useField({
    ...props,
    name,
  });

  useEffect(() => {
    helpers.setValue(defaultValue !== undefined ? defaultValue : minDate);
  }, []);

  useEffect(() => {
    helpers.setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (form?.errors) {
      setErrors(form?.errors);
    }
  }, [form, value]);

  return (
    <Container
      {...getTestIdAttr('DatePicker')}
      className={`lift-date-picker-container ${customClasses}`}
      type="fluid"
    >
      <Item className="lift-date-picker" xl={6} lg={6} md={6} sm={6} xs={12}>
        {showLabel && <CustomLabel errors={form?.errors?.[name]} label={label} hint={hint} />}
        <TextField
          type="date"
          value={value}
          InputLabelProps={{ shrink: true, className: 'lift-date-picker-label' }}
          className={`lift-date-picker ${!!form?.errors?.[name] && 'date-error'}`}
          onChange={(e) => {
            setDate(e.target.value);
            helpers.setValue(e.target.value);
          }}
          inputProps={{
            min: minDate,
            max: maxDate,
            className: 'lift-date-picker-input',
          }}
          focused={false}
        />
        <CustomError errors={form?.errors?.[name]} />
      </Item>
    </Container>
  );
};

export default DatePicker;
