import styled from 'styled-components';

export const Modal = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
`;

export const ModalContent = styled.div`
  position: relative;
  width: 520px;
  background-color: #fff;
  min-height: 340px;
  border-radius: 8px;
  box-shadow: 1px 1x 1px rgba(0, 0, 0, 0.1);
`;

export const ModalHeader = styled.div`
  padding: 10px;
`;

export const ModalFooter = styled.div`
  padding: 10px;
`;

export const ModalTitle = styled.div`
  margin: 0;
`;

export const ModalBody = styled.div`
  padding: 10px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 12px;

  button {
    border-radius: 6px;
    border: 1px solid #000;
  }
`;
