import { IPromoDesign } from '~/interfaces';
import { useFeatureTogglesResponse } from '~/hooks/useFeatureToggle';
import {
  ENABLE_CHALLENGE_HISTORY,
  ENABLE_OVERLAPPING_PROMOS_TAB,
  ENABLE_SUPPORT_REQUESTS,
  REWARDS_CHALLENGE,
  SHOW_ERP_HISTORY_TAB,
  SHOW_PROMO_DESIGN_HISTORY_TAB,
  SHOW_PROMO_HISTORY_TAB,
  SHOW_PROMO_HISTORY_FOR_REWARDS_TAB,
  SHOW_PROMO_DESIGN_HISTORY_FOR_REWARDS_TAB,
} from '~/constants';
import { useSelectedCountry } from '~/hooks/index';
import { useMemo } from 'react';

type TUseGetPromoDesignTabs = (props: {
  promoDetails?: IPromoDesign;
  overlappingPromoDesignIds?: string[];
}) => {
  promoHistory: boolean;
  promoDesignHistory: boolean;
  erpHistory: boolean;
  challengeHistory: boolean;
  externalHistory: boolean;
  supportRequests: boolean;
  overlappingPromos: boolean;
};

export const useGetPromoDesignTabs: TUseGetPromoDesignTabs = ({
  promoDetails,
  overlappingPromoDesignIds,
}) => {
  const isRewardsChallenge = promoDetails?.mechanicType === REWARDS_CHALLENGE;
  const isPersonalized = !!promoDetails?.personalized;
  const selectedCountry = useSelectedCountry();
  const featureToggles = useFeatureTogglesResponse(
    [
      SHOW_ERP_HISTORY_TAB,
      ENABLE_SUPPORT_REQUESTS,
      ENABLE_CHALLENGE_HISTORY,
      ENABLE_OVERLAPPING_PROMOS_TAB,
      SHOW_PROMO_HISTORY_TAB,
      SHOW_PROMO_DESIGN_HISTORY_TAB,
      SHOW_PROMO_HISTORY_FOR_REWARDS_TAB,
      SHOW_PROMO_DESIGN_HISTORY_FOR_REWARDS_TAB,
    ],
    {
      country: selectedCountry,
    }
  );
  const [showErpHistoryTab] = featureToggles[SHOW_ERP_HISTORY_TAB];
  const [enableOverlappingPromos] = featureToggles[ENABLE_OVERLAPPING_PROMOS_TAB];

  const [enablePromoHistory] = featureToggles[SHOW_PROMO_HISTORY_TAB];
  const [enablePromoDesignHistory] = featureToggles[SHOW_PROMO_DESIGN_HISTORY_TAB];
  const showOverlappingPromos = useMemo<boolean>(() => {
    return enableOverlappingPromos && overlappingPromoDesignIds?.length > 0;
  }, [enableOverlappingPromos, overlappingPromoDesignIds]);

  return useMemo(() => {
    return {
      promoHistory: isRewardsChallenge
        ? enablePromoHistory && featureToggles[SHOW_PROMO_HISTORY_FOR_REWARDS_TAB][0]
        : enablePromoHistory,
      promoDesignHistory: isRewardsChallenge
        ? enablePromoDesignHistory && featureToggles[SHOW_PROMO_DESIGN_HISTORY_FOR_REWARDS_TAB][0]
        : enablePromoDesignHistory,
      erpHistory: !isRewardsChallenge && showErpHistoryTab,
      challengeHistory: isRewardsChallenge && featureToggles[ENABLE_CHALLENGE_HISTORY][0],
      externalHistory: showErpHistoryTab && isPersonalized,
      supportRequests: featureToggles[ENABLE_SUPPORT_REQUESTS][0],
      overlappingPromos: showOverlappingPromos,
    };
  }, [featureToggles, isRewardsChallenge, showOverlappingPromos, promoDetails]);
};

export default useGetPromoDesignTabs;
