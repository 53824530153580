import { CountryFlagIcon } from '~/components';
import { Heading } from '@hexa-ui/components';
import React from 'react';
import { FormattedDisplayName } from 'react-intl';
import { TFlagCountry } from '~/interfaces';

type TSingleCountry = (props: { country: string }) => JSX.Element;

const SingleCountry: TSingleCountry = ({ country }) => {
  if (!country) {
    return null;
  }
  return (
    <>
      <CountryFlagIcon country={country as TFlagCountry} />
      <Heading size="H5" data-testid={'country-name'}>
        <FormattedDisplayName type={'region'} value={country} />
      </Heading>
    </>
  );
};

export default React.memo<TSingleCountry>(SingleCountry);
