import { Input } from '@hexa-ui/components';
import { useField } from 'formik';
import { getTestIdAttr, handlePasteFormik as handlePaste } from '~/utils';
import CustomError from './CustomError/CustomError';
import CustomLabel from './CustomLabel/CustomLabel';

export const NumberField = ({
  label,
  field: { name, value, onBlur, ...fieldProps },
  form,
  required,
  optional = false,
  hint,
  placeholder,
  ...props
}) => {
  const [, , helpers] = useField({
    ...props,
    name,
  });

  return (
    <div {...getTestIdAttr('NumberField')} className="formik-input input-container">
      <CustomLabel errors={form?.errors?.[name]} label={label} hint={hint} optional={!required} />
      <Input
        className="input-field"
        type="number"
        autoComplete={'off'}
        id={name}
        name={name}
        value={value}
        onPaste={(e) => handlePaste(e, helpers, /\d/g)}
        optionalText={''}
        onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
        onChange={(e) => {
          // @ts-ignore
          const numberFind = Number(e.target?.value?.match(/\d/g)?.join(''));
          helpers.setValue(Number.isNaN(numberFind) ? '' : numberFind);
        }}
        required={required}
        hasError={!!form?.errors?.[name]}
        placeholder={placeholder?.length ? placeholder : ''}
        onBlur={onBlur}
        // {...props}
      />
      <CustomError errors={form?.errors?.[name]} />
    </div>
  );
};
