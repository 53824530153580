import React, { FC } from 'react';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import store from '~/redux/store';

const persistor = persistStore(store, null, () => {});

const GlobalStateProvider: FC = ({ children }) => {
  return (
    <Provider store={store}>
      {/* @ts-ignore */}
      <PersistGate persistor={persistor} loading={null}>
        {children}
      </PersistGate>
    </Provider>
  );
};

export default GlobalStateProvider;
