import { FormattedMessage } from 'react-intl';
import React from 'react';
import { getTestIdAttr } from '~/utils';

interface IPocsUploadedMessageProps {
  count?: number;
}
type TPocsUploadedMessage = (props: IPocsUploadedMessageProps) => JSX.Element;

export const PocsUploaded: TPocsUploadedMessage = ({ count }) => {
  return (
    <FormattedMessage
      {...getTestIdAttr('PocsUploaded')}
      id={'POCS_FILTER.UPLOAD.POCS_UPLOAD_COUNT'}
      defaultMessage={'{count, plural, =0 {No POCs} one {1 POC} other {# POCs}} uploaded.'}
      values={{
        count: count ?? 0,
      }}
    />
  );
};
