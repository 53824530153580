import { TConfigNumberWithDefault } from '~/interfaces';

export const DEFAULT_MAX_LENGTH = 200;
export const DEFAULT_MIN_LENGTH = 40;

export const REWARDS_DESCRIPTION_LENGTH: TConfigNumberWithDefault = {
  DEFAULT: DEFAULT_MAX_LENGTH,
};

export const getRewardsDescriptionLength = (country?: string): number => {
  return REWARDS_DESCRIPTION_LENGTH?.[country] ?? REWARDS_DESCRIPTION_LENGTH.DEFAULT;
};
