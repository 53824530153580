import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Paragraph } from '@admin-portal-shared-components/typography';
import { Trash2 } from '@hexa-ui/icons';
import { getTestIdAttr } from '~/utils';

interface IProgressProps {
  label: string;
  isUploaded: boolean;
  updateInterval?: number;
  showItemCount?: boolean;
  countLabel?: string;
  handleTrash: () => void;
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  label: {
    textAlign: 'left',
    margin: '1px',
  },
  labelWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  fileLabel: {
    display: 'flex',
    justifyContent: 'start',
    width: '90%',
  },
  trash: {
    display: 'flex',
    width: '10%',
    color: '#c9201d',
    cursor: 'pointer',
    justifyContent: 'end',
  },
  progressBarColor: {
    backgroundColor: 'black',
  },
});

const ProgressBar = ({
  label,
  isUploaded,
  updateInterval = 500,
  showItemCount = false,
  countLabel = '',
  handleTrash,
}: IProgressProps): JSX.Element => {
  const classes = useStyles();
  const [progress, setProgress] = React.useState<number>(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 100;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, updateInterval);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div {...getTestIdAttr('ProgressBar')} className={classes.root}>
      <div className={classes.labelWrapper}>
        <div className={classes.fileLabel}>
          <Paragraph className={classes.label}>
            {label}
            {showItemCount && (
              <>
                &nbsp;
                <span
                  style={{
                    opacity: 0.5,
                    fontSize: '12px',
                  }}
                >
                  ({countLabel})
                </span>
              </>
            )}
          </Paragraph>
        </div>
        <div className={classes.trash}>
          <Trash2 onClick={handleTrash} />
        </div>
      </div>
      <LinearProgress
        variant="determinate"
        color="primary"
        value={isUploaded ? 100 : progress}
        classes={{
          barColorPrimary: classes.progressBarColor,
        }}
      />
    </div>
  );
};

export default ProgressBar;
