import { getDateFormat } from '~/configs/dateFormat';

export const isValidDeletionDate = (date: string, localeCode: string): boolean => {
  const currentDate = new Date();
  const endDate = new Date(date);
  return (
    currentDate.toLocaleDateString(localeCode, getDateFormat(localeCode)) ===
      endDate.toLocaleDateString(localeCode, getDateFormat(localeCode)) ||
    endDate.getTime() > currentDate.getTime()
  );
};
