import React from 'react';
import Popover from '@material-ui/core/Popover';
import Paper from '@material-ui/core/Paper';
import { getTestIdAttr } from '~/utils';
import { TSimplePopover } from './Popover.types';

const SimplePopover: TSimplePopover = ({
  open,
  anchorEl,
  placement,
  classes,
  content,
  paperStyle,
  onClose,
}) => {
  const id = open ? 'simple-popover' : undefined;

  return (
    <div {...getTestIdAttr('SimplePopover')}>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={placement.anchorOrigin}
        transformOrigin={placement.transformOrigin}
        className={classes.buttonStyle}
      >
        <Paper className={paperStyle}>{content}</Paper>
      </Popover>
    </div>
  );
};
export default SimplePopover;
