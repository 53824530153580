import { CSSProperties, useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Card, Grid, Heading, Paragraph, Select, Table } from '@hexa-ui/components';
import { ArrowDown } from '@hexa-ui/icons';
import {
  useAppSelector,
  usePercentValueFields,
  useSelectedCountry,
  useSkuDetailsKey,
} from '~/hooks';
import {
  LOAD_DEFAULT_NUMBER_OF_PRODUCTS,
  LOAD_NUMBER_OF_PRODUCTS,
  getNumberValueFields,
  PERSONALIZED,
  NON_PERSONALIZED,
} from '~/constants';
import {
  ISkusAndParameters,
  IColumnHeaders,
  TPromoDesignSkuField,
  TSkuParameters,
} from '~/interfaces';

import { FieldDisplay, NumericField, Percent, LiftChip, LoadingSpinner } from '~/components';
import { PRODUCT_NAME, SKU } from '~/constants/skuParams';
import { selectGlobalState } from '~/redux/slices';
import { TSteppedView } from './SteppedView.types';
import './SteppedView.css';
import { getSkuParamsToDisplayNA } from '~/configs/skuParamsToDisplayNA';
import { getTestIdAttr } from '~/utils';

const styles: CSSProperties = {
  fontSize: '14px',
  fontWeight: 500,
  letterSpacing: 0,
  lineHeight: '16px',
  margin: 0,
  marginRight: '8px',
  padding: '8px 16px 8px 12px',
  width: 'fit-content',
  backgroundColor: '#E9F1FF',
};

const keysSkippedPrametersView: TSkuParameters[] = [SKU, PRODUCT_NAME];

const SteppedView: TSteppedView = ({
  displayProducts,
  selectedMechanicType,
  selectedMechanicTypeOption,
  customStyle = {},
  isSelectedPersonalized,
}) => {
  const { formatMessage } = useIntl();
  const selectedCountry = useSelectedCountry();
  const { mechanicsType, isPersonalized } = useAppSelector(selectGlobalState);
  const [products, setProducts] = useState<JSX.Element[]>([]);
  const [loadXProducts, setLoadXProducts] = useState<number>(LOAD_DEFAULT_NUMBER_OF_PRODUCTS);
  const percentValueFields = usePercentValueFields(selectedCountry, selectedMechanicTypeOption);
  const numericValueFields = [...percentValueFields, ...getNumberValueFields()];

  const percentValue = useCallback((value: number): JSX.Element => {
    return <Percent value={value} />;
  }, []);

  const promotionPersonalizedOrNot = useCallback((value, columnKey) => {
    if (!value) {
      return (
        <>
          <FormattedMessage
            id={'PROMOTION_MECHANICS.PERSONALIZED'}
            defaultMessage={'Personalized'}
          />
        </>
      );
    }
    return numericValueFields.includes(columnKey as TPromoDesignSkuField) ? (
      <NumericField
        value={value}
        fieldName={columnKey as TPromoDesignSkuField}
        selectedMechanicTypeOption={selectedMechanicTypeOption}
      />
    ) : (
      value
    );
  }, []);

  const [cols, isLoadingColsResponse] = useSkuDetailsKey(
    isSelectedPersonalized || isPersonalized ? PERSONALIZED : NON_PERSONALIZED,
    selectedMechanicType || mechanicsType
  );

  const showSkuParameter = cols.includes(SKU);
  const showProductName = cols.includes(PRODUCT_NAME);

  const [steppedCols] = useSkuDetailsKey(
    isSelectedPersonalized || isPersonalized ? PERSONALIZED : NON_PERSONALIZED,
    selectedMechanicType || mechanicsType,
    true,
    []
  );

  const formatProductName = useCallback(
    (sku: string, productName: string): string => {
      const product = [];
      showSkuParameter && sku && product.push(sku);
      showProductName && productName && product.push(productName);
      return product.join(' - ');
    },
    [showSkuParameter, showProductName]
  );

  useEffect(() => {
    const temp: JSX.Element[] = displayProducts
      ?.slice(0, LOAD_DEFAULT_NUMBER_OF_PRODUCTS)
      .map((product: ISkusAndParameters) => {
        const displayTitle = formatProductName(product.sku, product.productName);
        return displayTitle ? (
          <LiftChip
            style={styles}
            key={`product-${product.sku}`}
            title={formatProductName(product.sku, product.productName)}
          />
        ) : null;
      });
    setProducts(temp);
  }, [displayProducts, showSkuParameter, showProductName]);

  const productsColumnHeader = (): IColumnHeaders[] => {
    return steppedCols?.map((columnKey: string) => {
      if (columnKey === 'discount' && !!selectedMechanicType) {
        return {
          Header: formatMessage({ id: `PRODUCTS_TABLE.HEADERS.${columnKey}.label` }),
          accessor: 'maxDiscount',
          ...(percentValueFields.includes('maxDiscount') && {
            customRender: (value) => percentValue(value),
          }),
        };
      }
      return {
        Header: formatMessage({ id: `DESIGN_PAGE.PRODUCTS.STEPS_TABLE.${columnKey}` }),
        accessor: columnKey,
        customRender: (value) => promotionPersonalizedOrNot(value, columnKey),
      };
    });
  };

  const emptyHeading = (contentString: string, isEmptyParametersView?: boolean): JSX.Element => {
    return (
      <Card elevated="minimal" border="small" style={{ padding: '8px' }}>
        <div className="empty-parameter-wrapper">
          <Heading
            size="H5"
            className={isEmptyParametersView ? 'empty-parameters-header' : 'empty-products-header'}
          >
            {contentString}
          </Heading>
        </div>
      </Card>
    );
  };

  const loadMore = (): void => {
    const tempProducts: JSX.Element[] = displayProducts
      .slice(products.length, products.length + loadXProducts)
      .map((product: ISkusAndParameters) => (
        <LiftChip
          style={styles}
          key={`product-${product.sku}`}
          title={formatProductName(product.sku, product.productName)}
        />
      ));

    setProducts([...products, ...tempProducts]);
  };

  const renderAddedProducts = (): JSX.Element => {
    if (displayProducts?.length) {
      return (
        <Card elevated="minimal" border="small">
          <div className="added-products-chips">{products}</div>
          {displayProducts?.length > LOAD_DEFAULT_NUMBER_OF_PRODUCTS && (
            <Grid.Container type="fluid" className="load-more-container">
              <Grid.Item className="load-more-item">
                <Button
                  className="load-more-button"
                  onClick={() => loadMore()}
                  variant="secondary"
                  type="button"
                  size="medium"
                  icon={ArrowDown}
                  trailing
                >
                  <FormattedMessage
                    id={'DESIGN_PAGE.PRODUCTS.LOAD_MORE'}
                    defaultMessage={'Load More'}
                  />
                </Button>
              </Grid.Item>
              <Grid.Item className="load-more-select load-more-item">
                <Select.Root
                  defaultValue={LOAD_NUMBER_OF_PRODUCTS[0].toString()}
                  onChange={(value) => setLoadXProducts(parseInt(value))}
                >
                  {LOAD_NUMBER_OF_PRODUCTS.map((loadNumber: number) => {
                    return (
                      <Select.Option key={`step_${loadNumber}`} value={loadNumber.toString()}>
                        <FormattedMessage
                          id={'DESIGN_PAGE.PRODUCTS.LOAD_X_PRODUCTS'}
                          defaultMessage={'Load {loadXProducts, number} products at a time.'}
                          values={{ loadXProducts: loadNumber }}
                        />
                      </Select.Option>
                    );
                  })}
                </Select.Root>
              </Grid.Item>
              <Grid.Item className="load-more-item load-more-show-items-loaded">
                <Paragraph>
                  <FormattedMessage
                    id={'DESIGN_PAGE.PRODUCTS.SHOWING_X_OUT_OF_X_PRODUCTS'}
                    defaultMessage={
                      'Showing {loadedLength, number} of {allProductsLength, number} products'
                    }
                    values={{
                      loadedLength: products.length,
                      allProductsLength: displayProducts?.length,
                    }}
                  />
                </Paragraph>
              </Grid.Item>
            </Grid.Container>
          )}
        </Card>
      );
    }

    return emptyHeading(
      formatMessage({
        id: 'DESIGN_PAGE.PRODUCTS.EMPTY_PRODUCTS',
        defaultMessage: 'Add a product to get started\u2026',
      })
    );
  };

  const parametersView = (): JSX.Element => {
    const elements = cols.filter(
      (item) => !keysSkippedPrametersView.includes(item as TSkuParameters)
    ) as TPromoDesignSkuField[];
    return (
      <Grid.Container type="fluid" className="row-lines">
        {elements?.map((item) => (
          <Grid.Item
            className="stepped-parameter-item"
            key={item}
            lg={3}
            md={3}
            sm={3}
            xl={3}
            xs={3}
          >
            <Heading size="H4" className="heading-layout">
              <FormattedMessage id={`PRODUCTS_TABLE.HEADERS.${item}.label`} defaultMessage={item} />
            </Heading>
            <Paragraph>
              <FieldDisplay
                item={item}
                displayProducts={displayProducts}
                selectedMechanicTypeOption={selectedMechanicTypeOption}
                displayNA={getSkuParamsToDisplayNA(selectedCountry).includes(item)}
                defaultMessage={formatMessage({
                  id: 'DESIGN_PAGE.PRODUCTS.NA',
                  defaultMessage: 'N/A',
                })}
              />
            </Paragraph>
          </Grid.Item>
        ))}
      </Grid.Container>
    );
  };

  const renderAddedParameters = (): JSX.Element => {
    if (displayProducts?.length) {
      return (
        <>
          {!!displayProducts[0]?.steps?.length && (
            <Card
              elevated="minimal"
              border="small"
              style={{ padding: '8px', marginBottom: '16px' }}
            >
              <div className="table-view">
                <Table
                  data={displayProducts[0]?.steps}
                  columns={productsColumnHeader()}
                  pagination={false}
                  emptyMessage={formatMessage({
                    id: 'DESIGN_PAGE.PRODUCTS.PRODUCTS_TABLE_EMPTY',
                    defaultMessage: 'Add a product to get started...',
                  })}
                  tableWidth={'auto'}
                  searchPlaceholder={formatMessage({
                    id: 'DESIGN_PAGE.PRODUCTS.PRODUCTS_SEARCH_PLACEHOLDER',
                    defaultMessage: 'Search name or SKU',
                  })}
                />
              </div>
            </Card>
          )}
          <Card className="stepped-parameter-container" elevated="minimal" border="small">
            {parametersView()}
          </Card>
        </>
      );
    }

    return emptyHeading(
      formatMessage({
        id: 'DESIGN_PAGE.PRODUCTS.EMPTY_PARAMETERS',
        defaultMessage: 'Add Parameters to get started...',
      }),
      true
    );
  };

  const displayAddedProducts = renderAddedProducts();
  const displayAddedParameters = renderAddedParameters();

  if (isLoadingColsResponse) {
    return (
      <LoadingSpinner
        titleMessage={formatMessage({
          id: 'PRODUCTS_TABLE.LOADING',
          defaultMessage: 'Loading products',
        })}
      />
    );
  }

  return (
    <Grid.Container
      {...getTestIdAttr('SteppedView')}
      type="fluid"
      style={{ margin: 0, width: '100%' }}
    >
      <Grid.Item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{ marginTop: '24px', display: 'block', ...customStyle }}
      >
        <Heading size="H3" style={{ paddingBottom: '24px' }}>
          <FormattedMessage id={'DESIGN_PAGE.PRODUCTS.PRODUCTS'} defaultMessage={'Products'} />
        </Heading>
        {displayAddedProducts}
      </Grid.Item>
      <Grid.Item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{ margin: '24px 0', display: 'block' }}
      >
        <Heading size="H3" style={{ paddingBottom: '24px' }}>
          <FormattedMessage id={'DESIGN_PAGE.PRODUCTS.PARAMETERS'} defaultMessage={'Parameters'} />
        </Heading>
        {displayAddedParameters}
      </Grid.Item>
    </Grid.Container>
  );
};

export default SteppedView;
