import { useMemo } from 'react';
import { IDateRange } from '~/interfaces';

import { useGetGlobalConfig } from '~/services';
import { getMaxPromotionEndDate } from '~/utils';

const useGlobalMaxPromotionEndDate = (dateSelection: IDateRange): string => {
  const { data: globalConfigData } = useGetGlobalConfig();

  return useMemo<string>(() => {
    return getMaxPromotionEndDate(
      dateSelection,
      globalConfigData?.max_promo_days,
      globalConfigData?.max_promo_advance
    );
  }, [globalConfigData, dateSelection]);
};

export default useGlobalMaxPromotionEndDate;
