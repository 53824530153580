import Bff from '~/Api/Bff';
import { AxiosResponse } from 'axios';
import { IMechanicsResponse, IPromoMechanic } from '~/interfaces';
import useSelectedCountry from '~/hooks/useSelectedCountry';
import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';
import { useAppDispatch } from '~/hooks';
import { checkError } from '~/redux/saga/sagaActions';

export const getPromoMechanics = (): Promise<AxiosResponse<IMechanicsResponse>> => {
  return Bff.get<IMechanicsResponse>({
    url: '/v1/mechanics',
  }).then((response) => {
    return response;
  });
};

export const useGetPromoMechanics = (
  options?: UseQueryOptions
): UseQueryResult<IPromoMechanic[]> => {
  const selectedCountry = useSelectedCountry();
  const dispatch = useAppDispatch();

  return useQuery<IPromoMechanic[], any, any, any>({
    queryKey: ['getPromoMechanics', selectedCountry],
    queryFn: async () => {
      const response = await getPromoMechanics();
      return response?.data?.mechanics?.mechanics;
    },
    onSuccess: (e) => {
      dispatch(checkError(e));
    },
    ...options,
  });
};
