import { useMemo } from 'react';
import { useAuthenticationService } from 'admin-portal-shared-services';
import { getUserDisplayName } from '~/utils';

export const useIsUserMissingProfileName = (): boolean => {
  const authentication = useAuthenticationService();
  const userFullName = authentication.getUserFullNameB2C();
  return useMemo(() => {
    const fullName = getUserDisplayName(userFullName);
    return !fullName || fullName === 'New User';
  }, [userFullName]);
};
