import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DuplicateDeleteProps, IBooleanPayloadAction, IStringPayloadAction } from '~/interfaces';
import { RootState } from '~/redux/store';

export type IDuplicateDelete = PayloadAction<DuplicateDeleteProps>;

export interface IPromoState {
  promo: any;
  message: string;
  isDuplicateLoading: boolean;
  isDeleteDesignLoading: boolean;
  isSubmitPromoDesignSuccess: boolean;
  isSubmitPromoDesignLoading: boolean;
  openPromoDialog: boolean;
  openDeleteDialog: boolean;
  openDeleteConfirmationDialog: boolean;
  openDuplicateDialog: boolean;
  promoDesignId: string;
}

const initialState: IPromoState = {
  promo: {},
  message: '',
  isDuplicateLoading: false,
  isDeleteDesignLoading: false,
  isSubmitPromoDesignSuccess: false,
  isSubmitPromoDesignLoading: false,
  openPromoDialog: false,
  openDeleteDialog: false,
  openDeleteConfirmationDialog: false,
  openDuplicateDialog: false,
  promoDesignId: '',
};

const promoSlice = createSlice({
  name: 'promo',
  initialState,
  reducers: {
    setSelectedPromoDesignId: (state, action: IStringPayloadAction): IPromoState => {
      return {
        ...state,
        promoDesignId: action.payload,
      };
    },
    setSubmitPromoDesignSuccess: (state, action: IBooleanPayloadAction): IPromoState => {
      return {
        ...state,
        isSubmitPromoDesignSuccess: action.payload,
      };
    },
    setSubmitPromoDesignLoading: (state, action: IBooleanPayloadAction): IPromoState => {
      return {
        ...state,
        isSubmitPromoDesignLoading: action.payload,
      };
    },
    setDeletedDesignLoading: (state, action: IBooleanPayloadAction): IPromoState => {
      return {
        ...state,
        isDeleteDesignLoading: action.payload,
      };
    },
    setOpenPromoDialog: (state, action: IBooleanPayloadAction): IPromoState => {
      return {
        ...state,
        openPromoDialog: action.payload,
      };
    },
    setOpenDeleteDialog: (state, action: IBooleanPayloadAction): IPromoState => {
      return {
        ...state,
        openDeleteDialog: action.payload,
      };
    },
    setOpenDuplicateDialog: (state, action: IBooleanPayloadAction): IPromoState => {
      return {
        ...state,
        openDuplicateDialog: action.payload,
      };
    },
    setDuplicateMessage: (state, action: IDuplicateDelete): IPromoState => {
      return {
        ...state,
        promo: action.payload,
      };
    },
    setLoadingPromoSubmit: (state, action: IBooleanPayloadAction): IPromoState => {
      return {
        ...state,
        isDuplicateLoading: action.payload,
      };
    },
    resetPromoDesignData: (): IPromoState => {
      return {
        ...initialState,
      };
    },
  },
});

export const selectPromoState = (state: RootState) => state.promo;

export const {
  setDeletedDesignLoading,
  setOpenPromoDialog,
  setOpenDeleteDialog,
  setOpenDuplicateDialog,
  setDuplicateMessage,
  setLoadingPromoSubmit,
  setSubmitPromoDesignSuccess,
  setSubmitPromoDesignLoading,
  setSelectedPromoDesignId,
  resetPromoDesignData,
} = promoSlice.actions;
export default promoSlice.reducer;
