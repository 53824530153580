import { usePercentValueFields, useSelectedCountry } from '~/hooks';
import { getNumberValueFields } from '~/constants';
import { Percent, NumberFormatted } from '~/components';
import { TNumericField } from './NumericField.types';
import { getTestIdAttr } from '~/utils';

const NumericField: TNumericField = ({
  value,
  fieldName,
  selectedMechanicTypeOption,
  displayNA = false,
  defaultMessage = '',
}) => {
  const selectedCountry = useSelectedCountry();
  const percentValueFields = usePercentValueFields(selectedCountry, selectedMechanicTypeOption);
  const numberValueFields = getNumberValueFields();

  if (displayNA && !value) {
    return <>{defaultMessage}</>;
  }

  if (percentValueFields.includes(fieldName)) {
    return <Percent {...getTestIdAttr('NumericField')} value={value} />;
  }
  if (numberValueFields.includes(fieldName)) {
    return <NumberFormatted {...getTestIdAttr('NumericField')} value={value} />;
  }
  return <>{value}</>;
};

export default NumericField;
