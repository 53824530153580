import { getApiHost } from '~/services/host/HostService';
import { rest } from 'msw';
import { promoDesignsFixturePage0, promoDesignsFixturePage1 } from '~/mocks/fixtures/promoDesigns';

const hostUrl = getApiHost();

export const fetchPromoDesignsHandler = [
  rest.get(`${hostUrl}/v1/promo-designs/filters`, (req, res, ctx) => {
    const page = req.params.page;
    const pageSize = req.params.pageSize;

    if (page === '0') {
      return res(ctx.json({ ...promoDesignsFixturePage0 }));
    }
    if (page === '1') {
      return res(ctx.json({ ...promoDesignsFixturePage1 }));
    }
    // The below logic combines the two promo designs to mimick showing more items per page
    if (pageSize > '5') {
      const newPageSize =
        promoDesignsFixturePage0.data.pagination.size +
        promoDesignsFixturePage1.data.pagination.size;
      const combinedPromoDesigns = { ...promoDesignsFixturePage0, ...promoDesignsFixturePage1 };
      combinedPromoDesigns.data.pagination.page = 0;
      combinedPromoDesigns.data.pagination.size = newPageSize;
      return res(ctx.json({ ...combinedPromoDesigns }));
    }
  }),
];
