import styled from 'styled-components';

export const ResultContainer = styled.span`
  height: 20px;
  min-height: 20px;
  color: rgba(0, 0, 0, 0.55);
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 20px;
`;
