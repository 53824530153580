import { useMemo } from 'react';
import { useIntl } from 'react-intl';

type TUseFormatStatus = (status?: string) => string;

const useFormatStatus: TUseFormatStatus = (status = '') => {
  const { formatMessage } = useIntl();
  return useMemo<string>(() => {
    return formatMessage({
      id: `STATUSES.PROMOTION.${status || 'FALLBACK'}`,
      defaultMessage: status,
    });
  }, [status]);
};

export default useFormatStatus;
