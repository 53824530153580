import { IconButton } from '@hexa-ui/components';
import { ArrowLeft } from '@hexa-ui/icons';
import React from 'react';
import { Title, TitleContainer } from './styles';
import { TGoBack } from '~/components/GoBack/GoBack.types';
import { getTestIdAttr } from '~/utils';

export const GoBack: TGoBack = ({ title, onClick }) => {
  return (
    <TitleContainer {...getTestIdAttr('GoBack')}>
      <IconButton
        icon={ArrowLeft}
        variant="tertiary"
        onClick={onClick}
        data-testid="go-back-button"
      />
      <Title>{title}</Title>
    </TitleContainer>
  );
};
