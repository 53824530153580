import { useMemo } from 'react';
import { PAGE_SIZE_5 } from '~/constants';
import { IPaginationComponentOptions } from '~/interfaces';

type TUseGetPageSizeOptionsBasedOnData = (
  totalElements: number,
  pagination: IPaginationComponentOptions
) => number[];

export const useGetPageSizeOptionsBasedOnTotalElements: TUseGetPageSizeOptionsBasedOnData = (
  totalElements,
  paginationOptions
) => {
  return useMemo<number[]>(() => {
    if (totalElements) {
      const pageSizeOptions = paginationOptions?.PAGE_SIZE_OPTIONS ?? [PAGE_SIZE_5];
      const expectedRangeIndex = pageSizeOptions?.findIndex((item) => item > totalElements);
      if (expectedRangeIndex === -1) {
        return pageSizeOptions;
      }
      return pageSizeOptions?.slice(0, expectedRangeIndex + 1);
    }
    return [paginationOptions?.PAGE_SIZE_OPTIONS?.[0] ?? PAGE_SIZE_5];
  }, [totalElements]);
};
