import { useFeatureToggle, useFeatureToggleResponse } from './useFeatureToggle';
import { IS_COUNTRY_ACTIVE } from '~/constants/FeatureToggles';
import useSelectedCountry from './useSelectedCountry';
import { TFeatureToggleResponse } from '~/interfaces';

export const useIsCountryActive = (): boolean => {
  const selectedCountry = useSelectedCountry();

  return useFeatureToggle(
    IS_COUNTRY_ACTIVE,
    {
      country: selectedCountry,
    },
    false
  );
};

export const useIsCountryActiveWithLoading = (): TFeatureToggleResponse => {
  const selectedCountry = useSelectedCountry();

  return useFeatureToggleResponse(IS_COUNTRY_ACTIVE, {
    country: selectedCountry,
  });
};
