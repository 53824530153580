import React from 'react';
import { getTestIdAttr } from '~/utils';
import { TTitleWithHover } from './TitleWithHover.types';

const TitleWithHover: TTitleWithHover = ({ value, children }) => {
  return (
    <span {...getTestIdAttr('TitleWithHover')} title={value}>
      {!children ? value : children}
    </span>
  );
};
export default TitleWithHover;
