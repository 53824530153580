import { IRecord } from '~/interfaces';

interface IValid {
  isValid: boolean;
}

export const downloadJSON = (response: IRecord | IRecord[], name: string): IValid => {
  try {
    const JSON_SPACE = 2;
    const json = JSON.stringify(response, null, JSON_SPACE);
    const url = window.URL.createObjectURL(
      new Blob([json], {
        type: 'application/json',
      })
    );
    const hiddenElement = document.createElement('a');
    hiddenElement.href = url;
    hiddenElement.target = '_blank';
    hiddenElement.download = `${name}.json`;
    hiddenElement.click();
    hiddenElement.remove();

    return { isValid: true };
  } catch (e) {
    console.error(e);
    return { isValid: false };
  }
};
