import { BaseSchema, object } from 'yup';
import { usePreferredLanguageV2 } from 'admin-portal-shared-services';
import {
  IFeatureToggleValidation,
  ISchema,
  ISchemaField,
  IValidationMessages,
  TValidationType,
} from '~/interfaces';
import {
  greaterThanDependentValue,
  greaterThanOrEqualToDependentValue,
  greaterThanOrEqualTo,
  greaterThanOrEqualToAllowZero,
  greaterThanOrEqualToSpecificValue,
  greaterThanOrEqualToSpecificDependentValue,
  lessThanOrEqualTo,
  lessThanSpecificValue,
  patternMatch,
  uuid4,
} from '~/components/Form/helpers/rules';
import { getValidationTypeFromComponentType } from '~/components/Form/helpers/getValidationTypeFromComponentType';

const validationMethods: {
  [key: string]: (...any) => TValidationType;
} = {
  greaterThanOrEqualToAllowZero,
  greaterThanOrEqualTo,
  patternMatch,
  greaterThanDependentValue,
  greaterThanOrEqualToDependentValue,
  greaterThanOrEqualToSpecificValue,
  greaterThanOrEqualToSpecificDependentValue,
  lessThanOrEqualTo,
  lessThanSpecificValue,
  uuid4,
};

export const useGetValidationSchema = (
  schema: ISchema,
  validationCallbacks: IValidationMessages,
  featureToggleValidations: IFeatureToggleValidation
): BaseSchema => {
  const { preferredLanguage: localeCode } = usePreferredLanguageV2();

  const validationObject: { [key: string]: TValidationType } = schema.reduce(
    (acc, val: ISchemaField) => {
      let validationType: TValidationType = getValidationTypeFromComponentType(
        val,
        val.componentType,
        validationCallbacks,
        localeCode
      );

      if (validationType) {
        if (val.required) {
          validationType = validationType.required(validationCallbacks.isRequired(val.label));
        } else {
          validationType = validationType.optional();
        }
      }

      return { ...acc, ...(validationType && { [val.name]: validationType }) };
    },
    {}
  );

  /**
   * For reference, this is what it might look like translated with real fields
   * validationObject.skuLimit = Yup.number().when(['maxQuantity'], (maxQuantity, schema) => {
   *     if (maxQuantity) {
   *       return Yup.number().when('skuLimit', (skuLimit) => {
   *         if (skuLimit !== 0) {
   *           return Yup.number().min(maxQuantity, greaterThanOrEqualToAllowZeroCallback('skuLimit', 'maxQuantity'));
   *         }
   *       });
   *     }
   *   });
   */
  for (const fieldName of Object.keys(validationObject)) {
    const schemaFieldConfig: ISchemaField = schema.find((f: ISchemaField) => f.name === fieldName);

    if (schemaFieldConfig?.rules) {
      for (const rule of schemaFieldConfig.rules) {
        if (validationMethods[rule.type]) {
          validationObject[fieldName] = validationMethods[rule.type](
            validationObject[fieldName],
            rule,
            fieldName,
            validationCallbacks,
            featureToggleValidations
          );
        }
      }
    }
  }

  return object().shape(validationObject);
};
