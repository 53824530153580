import {
  ADD_MARK_FOR_DOWNLOAD,
  ADD_SKU_PRODUCT,
  CHECK_ERROR,
  CLEAR_MARK_FOR_DOWNLOAD,
  CLEAR_SKU_PRODUCT,
  DELETE_SINGLE_DESIGN,
  DISMISSED_PROFILE_LINK_FOREVER,
  EDIT_SKU_PRODUCT,
  FETCH_AVAILABLE_COUNTRIES,
  FETCH_BUDGET_UNITS,
  FETCH_PRODUCT_FILTERS,
  FETCH_PROMO_DESIGN,
  GET_POC_UPLOAD_FILES_REQUEST,
  POCS_SELECTED_REQUEST,
  POC_BY_UPLOAD_REQUEST,
  REMOVE_MARK_FOR_DOWNLOAD,
  REMOVE_SKU_PRODUCT,
  RESET_ALL,
  RESET_DISPLAY_TOAST,
  RESET_POC_VALIDATIONS,
  RESET_PROMO_DESIGN,
  RESET_SELECTED_FILTERS,
  SET_CAMPAIGN_ID,
  SET_COVER_IMAGE,
  SET_MECHANICS_TYPE,
  SET_MECHANICS_TYPE_OPTION,
  SET_PROMO_PREFIX,
  SET_REWARDS_DESCRIPTION,
  SET_SELECTED_FILTERS,
  SET_SELECTED_PROMO_DESIGN,
  SET_STRATEGY_DATES,
  SET_TITLE,
  SET_TOAST,
  SET_TOAST_FROM_RESPONSE,
} from './sagaTypes';
import { PayloadAction } from '@reduxjs/toolkit';
import { IDateRange, IImageContent, TMechanicType, TMechanicTypeOption } from '~/interfaces';
import { ISetPocUploadFileDataAction } from '~/redux/slices/pocFilterSlice';

type PayloadFn<T = any> = (payload?: T) => PayloadAction<T>;

const createAction =
  <T = any>(type: string): PayloadFn<T> =>
  (payload) => ({
    type,
    ...(payload && {
      payload,
    }),
  });

export const fetchAvailableCountries = createAction(FETCH_AVAILABLE_COUNTRIES);
export const setSelectedPocsData = (payload) => ({ type: POCS_SELECTED_REQUEST, payload });
export const fetchPocByUploadData = (payload) => ({
  type: POC_BY_UPLOAD_REQUEST,
  payload,
});
export const fetchBudgetParameters = createAction(FETCH_BUDGET_UNITS);
export const deleteSingleDesign = createAction<{ designId: string; toastMessage: string }>(
  DELETE_SINGLE_DESIGN
);
export const resetToast = createAction(RESET_DISPLAY_TOAST);
export const setToastFromResponse = (payload) => ({
  type: SET_TOAST_FROM_RESPONSE,
  payload,
});
export const setToast = (payload) => ({ type: SET_TOAST, payload });
export const checkError = (payload) => ({ type: CHECK_ERROR, payload });
export const fetchPromoDesign = (payload) => ({ type: FETCH_PROMO_DESIGN, payload });
export const setStrategyDates = createAction<IDateRange>(SET_STRATEGY_DATES);
export const addMarkForDownload = createAction<string>(ADD_MARK_FOR_DOWNLOAD);
export const removeMarkForDownload = createAction<number>(REMOVE_MARK_FOR_DOWNLOAD);
export const clearMarkForDownload = createAction(CLEAR_MARK_FOR_DOWNLOAD);
export const resetPromoDesign = createAction(RESET_PROMO_DESIGN);
export const fetchPocUploadFileData = (payload): ISetPocUploadFileDataAction => ({
  type: GET_POC_UPLOAD_FILES_REQUEST,
  payload,
});
export const setSelectedPromoDesign = createAction<string>(SET_SELECTED_PROMO_DESIGN);
export const setPromoPrefix = createAction<string>(SET_PROMO_PREFIX);
export const setMechanicsType = createAction<TMechanicType>(SET_MECHANICS_TYPE);
export const setMechanicsTypeOption = createAction<TMechanicTypeOption>(SET_MECHANICS_TYPE_OPTION);
export const addSkuProducts = (payload) => ({ type: ADD_SKU_PRODUCT, payload });
export const editSkuProducts = (payload) => ({ type: EDIT_SKU_PRODUCT, payload });
export const removeSkuProducts = (payload) => ({ type: REMOVE_SKU_PRODUCT, payload });
export const clearSkuProducts = (payload) => ({ type: CLEAR_SKU_PRODUCT, payload });
export const setSelectedFilters = (payload) => ({ type: SET_SELECTED_FILTERS, payload });
export const resetSelectedFilters = createAction(RESET_SELECTED_FILTERS);
export const fetchProductFilters = (payload?: { country?: string; onlyPermission?: boolean }) => ({
  type: FETCH_PRODUCT_FILTERS,
  payload,
});
export const resetPocValidations = createAction(RESET_POC_VALIDATIONS);
export const resetAll = createAction(RESET_ALL);
export const setDismissedPromptForever = createAction<{
  haveDismissedPromptForever: boolean;
}>(DISMISSED_PROFILE_LINK_FOREVER);
export const setCoverImage = createAction<IImageContent[]>(SET_COVER_IMAGE);
export const setTitle = createAction<string>(SET_TITLE);
export const setCampaignId = createAction<string>(SET_CAMPAIGN_ID);
export const setRewardsDescription = createAction<string>(SET_REWARDS_DESCRIPTION);
