const isAllowedCountry = (countries?: string[], country?: string): boolean => {
  if (!countries?.length) {
    return false;
  }
  if (!country) {
    return false;
  }
  return countries.includes(country);
};

export default isAllowedCountry;
