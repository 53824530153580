import { baseHandlers } from './baseHandlers';
import { productsHandler } from './productsHandler';
import { fetchPromoDesignsHandler } from './promoDesignsHandler';
import {
  fetchSinglePromoDesignHandler,
  fetchSinglePromoDesignPaginatedHandler,
} from './singlePromoDesignHandler';

export const allHandlers = [
  ...baseHandlers,
  ...productsHandler,
  ...fetchPromoDesignsHandler,
  ...fetchSinglePromoDesignHandler,
  ...fetchSinglePromoDesignPaginatedHandler,
];
