import React, { useCallback, useState } from 'react';
import { useGetExternalHistory } from '~/services';
import { Table } from '@hexa-ui/components';
import { FormattedDate, FormattedNumber, useIntl } from 'react-intl';
import { DateTimeWithRelativeHover, Json, Status } from '~/components';
import { IExternalHistory } from '~/interfaces';
import { TExternalHistoryList } from './ExternalHistoryList.types';
import { getTestIdAttr } from '~/utils';
import { PAGE_SIZE_5, PAGINATION_DEFAULT_PROPS } from '~/constants';

const Pagination = PAGINATION_DEFAULT_PROPS;

const ExternalHistoryList: TExternalHistoryList = ({ promoDesignId }) => {
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(PAGE_SIZE_5);

  const { formatMessage } = useIntl();

  const getStatus = useCallback((status: string) => {
    return (
      <>
        <span style={{ display: 'flex', whiteSpace: 'nowrap' }}>
          <Status
            size={'sm'}
            color={status.toUpperCase() === 'SUCCESS' ? 'green' : 'red'}
            status={status.capitalize()}
          />
        </span>
      </>
    );
  }, []);

  const formatDate = useCallback((value) => {
    return (
      <DateTimeWithRelativeHover value={value}>
        <FormattedDate value={`${value}Z`} dateStyle={'medium'} timeStyle={'medium'} />
      </DateTimeWithRelativeHover>
    );
  }, []);

  const formatNumberPocId = useCallback((value: string, row: IExternalHistory) => {
    return row?.externalResponse?.data?.pocIds?.length !== undefined ? (
      <FormattedNumber value={row.externalResponse.data.pocIds.length} />
    ) : null;
  }, []);

  const formatNumberDiscounts = useCallback((value: string, row: IExternalHistory) => {
    return row?.externalResponse?.data?.discounts?.length !== undefined ? (
      <span {...getTestIdAttr('ExternalHistoryListDiscounts')}>
        <FormattedNumber value={row.externalResponse.data.discounts.length} />
      </span>
    ) : null;
  }, []);

  const formatRequestTraceId = useCallback((value: string, row: IExternalHistory) => {
    return (
      <span
        style={{
          whiteSpace: 'nowrap',
        }}
      >
        {row?.requestTraceId ?? null}
      </span>
    );
  }, []);

  const {
    data: externalHistory = {
      data: [],
      pagination: {
        page: 0,
        size: 5,
        totalElements: 0,
        totalPages: 0,
      },
    },
    isLoading,
  } = useGetExternalHistory(promoDesignId, page - 1, pageSize);

  return (
    <div {...getTestIdAttr('ExternalHistoryList')}>
      <Table<IExternalHistory>
        data={externalHistory?.data}
        loading={isLoading}
        loadingMessage={formatMessage({
          id: 'EXTERNAL_HISTORY_TABLE.LOADING',
          defaultMessage: 'Loading External History',
        })}
        cellHeight={'tight'}
        pagination={{
          quantityIndicatorIntl: formatMessage({ id: 'PAGINATION.OF', defaultMessage: 'of' }),
          pageSizeOptionsIntl: (numberOfItems) =>
            formatMessage(
              { id: 'PAGINATION.ITEMS_PER_PAGE', defaultMessage: 'Show {field} items per page' },
              { field: numberOfItems }
            ),
          current: page,
          pageSizeOptions: Pagination.PAGE_SIZE_OPTIONS,
          total: externalHistory?.pagination?.totalElements,
          showPageSizeSelector: true,
          disabled: false,
          onChange: (page: number, pageSize: number) => {
            setPage(page);
            setPageSize(pageSize);
          },
          pageSize,
        }}
        expandable={{
          rowHasExpandFeature: (row: IExternalHistory) => !!row.externalResponse,
          render: (row: IExternalHistory) => {
            return <Json data={row.externalResponse} collapsed={false} />;
          },
        }}
        columns={[
          {
            Header: formatMessage({
              id: 'EXTERNAL_HISTORY_TABLE.fields.STATUS',
              defaultMessage: 'Status',
            }),
            style: {
              maxWidth: '115px',
              minWidth: '115px',
            },
            accessor: 'externalStatus',
            customRender: (value: string) => getStatus(value),
          },
          {
            Header: formatMessage({
              id: 'EXTERNAL_HISTORY_TABLE.fields.DATE',
              defaultMessage: 'Date',
            }),
            style: {
              maxWidth: '275px',
              minWidth: '275px',
            },
            accessor: 'lastUpdatedTimestamp',
            customRender: (value: string) => formatDate(value),
          },
          {
            Header: formatMessage({
              id: 'EXTERNAL_HISTORY_TABLE.fields.PROMO_ID',
              defaultMessage: 'Promo ID',
            }),
            style: {
              maxWidth: '375px',
              minWidth: '375px',
            },
            id: 'externalResponse?.data?.promoId',
            customRender: (value, row) => {
              return row?.externalResponse?.data?.promoId ?? null;
            },
            disableSortBy: true,
          },
          {
            Header: formatMessage({
              id: 'EXTERNAL_HISTORY_TABLE.fields.POCS_COUNT',
              defaultMessage: '# POCs',
            }),
            style: {
              maxWidth: '100px',
              minWidth: '100px',
            },
            id: 'externalResponse?.data?.pocIds?.length',
            customRender: (value, row: IExternalHistory) => formatNumberPocId(value, row),
            disableSortBy: true,
          },
          {
            Header: formatMessage({
              id: 'EXTERNAL_HISTORY_TABLE.fields.DISCOUNTS_COUNT',
              defaultMessage: '# Discounts',
            }),
            style: {
              maxWidth: '120px',
              minWidth: '120px',
            },
            id: 'externalResponse?.data?.discounts?.length',
            customRender: (value, row: IExternalHistory) => formatNumberDiscounts(value, row),
            disableSortBy: true,
          },
          {
            Header: formatMessage({
              id: 'EXTERNAL_HISTORY_TABLE.fields.REQUEST_TRACE_ID',
              defaultMessage: 'Request Trace ID',
            }),
            style: {
              minWidth: '360px',
              whiteSpace: 'nowrap',
            },
            id: 'externalResponse?.requestTraceId',
            customRender: (value, row: IExternalHistory) => formatRequestTraceId(value, row),
            disableSortBy: true,
          },
          {
            Header: '',
            accessor: 'externalResponse',
            style: {
              display: 'none',
            },
            customRender: () => {
              return null;
            },
            disableSortBy: true,
          },
        ]}
      />
    </div>
  );
};

export default ExternalHistoryList;
