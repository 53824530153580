export const FETCH_AVAILABLE_COUNTRIES = 'FETCH_AVAILABLE_COUNTRIES';
export const FETCH_AVAILABLE_COUNTRIES_FAILED = 'FETCH_AVAILABLE_COUNTRIES_FAILED';
export const POCS_SELECTED_REQUEST = 'POCS_SELECTED_REQUEST';
export const POCS_SELECTED_REQUEST_FAILED = 'POCS_SELECTED_REQUEST_FAILED';
export const POCS_IN_SCOPE_REQUEST = 'POCS_IN_SCOPE_REQUEST';
export const POCS_IN_SCOPE_REQUEST_FAILED = 'POCS_IN_SCOPE_REQUEST_FAILED';
export const POC_IN_SCOPE_RESET = 'POC_IN_SCOPE_RESET';
export const POC_IN_SCOPE_RESET_FAILED = 'POC_IN_SCOPE_RESET_FAILED';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_PRODUCTS_FAILED = 'SET_PRODUCTS';
export const FETCH_BUDGET_UNITS = 'FETCH_BUDGET_UNITS';
export const FETCH_BUDGET_UNITS_FAILED = 'FETCH_BUDGET_UNITS_FAILED';
export const POC_BY_UPLOAD_REQUEST = 'POC_BY_UPLOAD_REQUEST';
export const POC_BY_UPLOAD_REQUEST_FAILED = 'POC_BY_UPLOAD_REQUEST_FAILED';
export const PROMO_DUPLICATION_REQUEST = 'PROMO_DUPLICATION_REQUEST';
export const PROMO_DUPLICATION_FAILED = 'PROMO_DUPLICATION_FAILED';
export const RESET_NOTIFICATION = 'RESET_NOTIFICATION';
export const RESET_NOTIFICATION_FAILED = 'RESET_NOTIFICATION_FAILED';
export const DELETE_SINGLE_DESIGN = 'DELETE_SINGLE_DESIGN';
export const DELETE_SINGLE_DESIGN_FAILED = 'DELETE_SINGLE_DESIGN_FAILED';
export const PRODUCT_LIST_REQUEST = 'PRODUCT_LIST_REQUEST';
export const PRODUCT_LIST_FAILED = 'PRODUCT_LIST_FAILED';
export const ALL_PRODUCT_LIST_REQUEST = 'ALL_PRODUCT_LIST_REQUEST';
export const ALL_PRODUCT_LIST_REQUEST_FAILED = 'ALL_PRODUCT_LIST_REQUEST_FAILED';
export const RESET_DISPLAY_TOAST = 'RESET_DISPLAY_TOAST';
export const RESET_DISPLAY_TOAST_FAILED = 'RESET_DISPLAY_TOAST_FAILED';
export const SET_TOAST_FROM_RESPONSE = 'SET_TOAST_FROM_RESPONSE';
export const SET_TOAST_FROM_RESPONSE_FAILED = 'SET_TOAST_FROM_RESPONSE_FAILED';
export const SET_TOAST = 'SET_TOAST';
export const SET_TOAST_FAILED = 'SET_TOAST_FAILED';
export const RESET_ERROR = 'RESET_ERROR';
export const RESET_ERROR_FAILED = 'RESET_ERROR_FAILED';
export const CHECK_ERROR = 'CHECK_ERROR';
export const CHECK_ERROR_FAILED = 'CHECK_ERROR_FAILED';
export const FETCH_PROMO_DESIGN = 'FETCH_PROMO_DESIGN';
export const FETCH_PROMO_DESIGN_FAILED = 'FETCH_PROMO_DESIGN_FAILED';
export const SET_STRATEGY_DATES = 'SET_STRATEGY_DATES';
export const SET_STRATEGY_DATES_FAILED = 'SET_STRATEGY_DATES_FAILED';
export const RESET_STRATEGY_DATES = 'RESET_STRATEGY_DATES';
export const RESET_STRATEGY_DATES_FAILED = 'RESET_STRATEGY_DATES_FAILED';
export const ADD_MARK_FOR_DOWNLOAD = 'ADD_MARK_FOR_DOWNLOAD';
export const ADD_MARK_FOR_DOWNLOAD_FAILED = 'ADD_MARK_FOR_DOWNLOAD';
export const REMOVE_MARK_FOR_DOWNLOAD = 'REMOVE_MARK_FOR_DOWNLOAD';
export const REMOVE_MARK_FOR_DOWNLOAD_FAILED = 'REMOVE_MARK_FOR_DOWNLOAD_FAILED';
export const CLEAR_MARK_FOR_DOWNLOAD = 'CLEAR_MARK_FOR_DOWNLOAD';
export const CLEAR_MARK_FOR_DOWNLOAD_FAILED = 'CLEAR_MARK_FOR_DOWNLOAD_FAILED';
export const GET_POC_UPLOAD_FILES_REQUEST = 'GET_POC_UPLOAD_FILES_REQUEST';
export const GET_POC_UPLOAD_FILES_FAILED = 'GET_POC_UPLOAD_FILES_FAILED';
export const RESET_PROMO_DESIGN = 'RESET_PROMO_DESIGN';
export const RESET_PROMO_DESIGN_FAILED = 'RESET_PROMO_DESIGN_FAILED';
export const SET_SELECTED_PROMO_DESIGN = 'SET_SELECTED_PROMO_DESIGN';
export const SET_SELECTED_PROMO_DESIGN_FAILED = 'SET_SELECTED_PROMO_DESIGN_FAILED';
export const SET_PROMO_PREFIX = 'SET_PROMO_PREFIX';
export const SET_PROMO_PREFIX_FAILED = 'SET_PROMO_PREFIX_FAILED';
export const SET_MECHANICS_TYPE = 'SET_MECHANICS_TYPE';
export const SET_MECHANICS_TYPE_FAILED = 'SET_MECHANICS_TYPE_FAILED';
export const SET_MECHANICS_TYPE_OPTION = 'SET_MECHANICS_TYPE_OPTION';
export const SET_MECHANICS_TYPE_OPTION_FAILED = 'SET_MECHANICS_TYPE_OPTION_FAILED';
export const ADD_SKU_PRODUCT = 'ADD_SKU_PRODUCT';
export const ADD_SKU_PRODUCT_FAILED = 'ADD_SKU_PRODUCT_FAILED';
export const EDIT_SKU_PRODUCT = 'EDIT_SKU_PRODUCT';
export const EDIT_SKU_PRODUCT_FAILED = 'EDIT_SKU_PRODUCT_FAILED';
export const REMOVE_SKU_PRODUCT = 'REMOVE_SKU_PRODUCT';
export const REMOVE_SKU_PRODUCT_FAILED = 'REMOVE_SKU_PRODUCT_FAILED';
export const CLEAR_SKU_PRODUCT = 'CLEAR_SKU_PRODUCT';
export const CLEAR_SKU_PRODUCT_FAILED = 'CLEAR_SKU_PRODUCT_FAILED';
export const FETCH_PRODUCT_FILTERS = 'FETCH_PRODUCT_FILTERS';
export const FETCH_PRODUCT_FILTERS_FAILED = 'FETCH_PRODUCT_FILTERS_FAILED';
export const SET_SELECTED_FILTERS = 'SET_SELECTED_FEATURES';
export const SET_SELECTED_FILTERS_FAILED = 'SET_SELECTED_FEATURES_FAILED';
export const RESET_SELECTED_FILTERS = 'RESET_SELECTED_FEATURES';
export const RESET_SELECTED_FILTERS_FAILED = 'RESET_SELECTED_FEATURES_FAILED';
export const FETCH_PRODUCT_FILTERS_SEARCH = 'FETCH_PRODUCT_FILTERS_SEARCH';
export const FETCH_PRODUCT_FILTERS_SEARCH_FAILED = 'FETCH_PRODUCT_FILTERS_SEARCH_FAILED';
export const SET_PRODUCT_FILTERS_SEARCH = 'SET_PRODUCT_FILTERS_SEARCH';
export const SET_PRODUCT_FILTERS_SEARCH_FAILED = 'SET_PRODUCT_FILTERS_SEARCH_FAILED';
export const RESET_POC_VALIDATIONS = 'RESET_POC_VALIDATIONS';
export const RESET_POC_VALIDATIONS_FAILED = 'RESET_POC_VALIDATIONS_FAILED';
export const RESET_ALL = 'RESET_ALL';
export const RESET_ALL_FAILED = 'RESET_ALL_FAILED';
export const DISMISSED_PROFILE_LINK_FOREVER = 'DISMISSED_PROFILE_LINK_FOREVER';
export const DISMISSED_PROFILE_LINK_FOREVER_FAILED = 'DISMISSED_PROFILE_LINK_FOREVER_FAILED';
export const SET_COVER_IMAGE = 'SET_COVER_IMAGE';
export const SET_COVER_IMAGE_FAILED = 'SET_COVER_IMAGE_FAILED';
export const SET_TITLE = 'SET_TITLE';
export const SET_TITLE_FAILED = 'SET_TITLE_FAILED';
export const SET_CAMPAIGN_ID = 'SET_CAMPAIGN_ID';
export const SET_CAMPAIGN_ID_FAILED = 'SET_CAMPAIGN_ID_FAILED';
export const SET_REWARDS_DESCRIPTION = 'SET_REWARDS_DESCRIPTION';
export const SET_REWARDS_DESCRIPTION_FAILED = 'SET_REWARDS_DESCRIPTION_FAILED';
