import React from 'react';
import { AlertOctagon, Check } from '@hexa-ui/icons';
import { FormattedMessage } from 'react-intl';
import { TPocValidationStatus } from './PocValidationStatus.types';
import { getTestIdAttr } from '~/utils';

const Valid = (): JSX.Element => {
  return (
    <>
      <Check style={{ color: 'green' }} />
      <FormattedMessage id={'POCS_FILTER.UPLOAD.STATUS_VALID'} defaultMessage={'Valid'} />
    </>
  );
};

const Invalid = (): JSX.Element => {
  return (
    <>
      <AlertOctagon style={{ color: 'red' }} />
      <FormattedMessage id={'POCS_FILTER.UPLOAD.STATUS_INVALID'} defaultMessage={'Invalid'} />
    </>
  );
};

export const PocValidationStatus: TPocValidationStatus = ({ isValid }) => {
  return (
    <div
      {...getTestIdAttr('PocValidationStatus')}
      style={{
        display: 'inline-flex',
        alignItems: 'center',
      }}
    >
      {isValid ? <Valid /> : <Invalid />}
    </div>
  );
};
