import { IntlShape } from 'react-intl';
import { IValidationMessages, TValidationPatternId } from '~/interfaces';

export const getFormLabel = (fieldName: string, formatMessage, formId: string): string => {
  return formatMessage({
    id: `FORMS.${formId}.${fieldName}.label`,
    defaultMessage: fieldName,
  });
};

const formatValidationMessages = (intl: IntlShape, formId: string): IValidationMessages => {
  const formatMessage = intl.formatMessage;
  return {
    greaterThanOrEqualToAllowZero: (fieldName: string, fieldName2: string): string => {
      return formatMessage(
        {
          id: 'FIELDS.GREATER_THAN_OR_EQUAL_TO_FIELD_ALLOW_ZERO',
          defaultMessage: `${fieldName} must be 0 or greater than or equal to ${fieldName2}`,
        },
        {
          field: getFormLabel(fieldName, formatMessage, formId),
          field2: getFormLabel(fieldName2, formatMessage, formId),
        }
      );
    },
    greaterThanOrEqualTo: (fieldName: string, fieldName2: string): string => {
      return formatMessage(
        {
          id: 'FIELDS.GREATER_THAN_OR_EQUAL_TO_FIELD',
          defaultMessage: `${fieldName} must be greater than or equal to ${fieldName2}`,
        },
        {
          field: getFormLabel(fieldName, formatMessage, formId),
          field2: getFormLabel(fieldName2, formatMessage, formId),
        }
      );
    },
    greaterThanOrEqualToAdditionalValue: (
      fieldName: string,
      fieldName2: string,
      additionToValue: number
    ): string => {
      return formatMessage(
        {
          id: 'FIELDS.GREATER_THAN_OR_EQUAL_TO_ADDITIONAL_VALUE',
          defaultMessage: `${fieldName} must be equal to ${fieldName2} or be greater than ${fieldName2} by ${additionToValue}`,
        },
        {
          field: getFormLabel(fieldName, formatMessage, formId),
          field2: getFormLabel(fieldName2, formatMessage, formId),
          additionToValue,
        }
      );
    },
    requiresDependentField: (fieldName: string, fieldName2: string): string => {
      return formatMessage(
        {
          id: 'FIELDS.REQUIRES_DEPENDENT_FIELD',
          defaultMessage: `${fieldName} requires ${fieldName2} to contain a value.`,
        },
        {
          field: getFormLabel(fieldName, formatMessage, formId),
          field2: getFormLabel(fieldName2, formatMessage, formId),
        }
      );
    },
    isRequired: (fieldName: string): string => {
      return formatMessage({ id: 'FIELDS.IS_REQUIRED_ERROR_MESSAGE' }, { field: fieldName });
    },
    twoDecimalPoints: (fieldName: string) => {
      return formatMessage(
        { id: 'FIELDS.TWO_DECIMAL_POINTS' },
        {
          field: fieldName,
        }
      );
    },
    patternMatch: (fieldName: string, patternId: TValidationPatternId): string => {
      return formatMessage(
        { id: 'FIELDS.INVALID_FORMAT' },
        {
          field: getFormLabel(fieldName, formatMessage, formId),
          format: formatMessage({ id: `FORMATS.${patternId}`, defaultMessage: '' }),
        }
      );
    },
    lessThanMin: (fieldName: string, minValue: number): string => {
      return formatMessage({ id: 'FIELDS.LESS_THAN_MIN' }, { field: fieldName, minValue });
    },
    greaterThanMax: (fieldName: string, maxValue: any): string => {
      return formatMessage({ id: 'FIELDS.GREATER_THAN_MAX' }, { field: fieldName, maxValue });
    },
    mustHaveSelectedNumberOfItems: (fieldName: string, minValue: number): string => {
      return formatMessage(
        { id: 'FIELDS.MUST_HAVE_SELECTED_NUMBER_OF_ITEMS' },
        { field: fieldName, minValue }
      );
    },
    mustBeANumber: (fieldName: string): string => {
      return formatMessage({ id: 'FIELDS.MUST_BE_A_NUMBER' }, { field: fieldName });
    },
    lessThanMinDate: (fieldName: string, minDate: string): string => {
      return formatMessage({ id: 'FIELDS.PAST_DATE' }, { field: fieldName, minDate });
    },
    lessThanOrEqualTo: (fieldName: string, fieldName2: string): string => {
      return formatMessage(
        {
          id: 'FIELDS.LESS_THAN_OR_EQUAL_TO_FIELD',
          defaultMessage: `${fieldName} must be less than or equal to ${fieldName2}`,
        },
        {
          field: getFormLabel(fieldName, formatMessage, formId),
          field2: getFormLabel(fieldName2, formatMessage, formId),
        }
      );
    },
    lessThanSpecificValue: (fieldName: string, fieldName2: string): string => {
      return formatMessage(
        {
          id: 'FIELDS.LESS_THAN_OR_EQUAL_TO_FIELD',
          defaultMessage: `${fieldName} must be less than or equal to ${fieldName2}`,
        },
        {
          field: getFormLabel(fieldName, formatMessage, formId),
          field2: getFormLabel(fieldName2, formatMessage, formId),
        }
      );
    },
    lessThanPreviousValue: (fieldName: string, fieldName2: string): string => {
      return formatMessage(
        {
          id: 'FIELDS.LESS_THAN_OR_EQUAL_TO_PREVIOUS_FIELD',
          defaultMessage: `${fieldName} must be less than or equal to previous ${fieldName2}`,
        },
        {
          field: getFormLabel(fieldName, formatMessage, formId),
          field2: getFormLabel(fieldName2, formatMessage, formId),
        }
      );
    },
    greaterThanPreviousValue: (fieldName: string, fieldName2: string): string => {
      return formatMessage(
        {
          id: 'FIELDS.GREATER_THAN_PREVIOUS_FIELD',
          defaultMessage: `${fieldName} must be greater than previous ${fieldName2}`,
        },
        {
          field: getFormLabel(fieldName, formatMessage, formId),
          field2: getFormLabel(fieldName2, formatMessage, formId),
        }
      );
    },
    greaterThanEqualToPreviousValue: (fieldName: string, fieldName2: string): string => {
      return formatMessage(
        {
          id: 'FIELDS.GREATER_THAN_EQUAL_TO_PREVIOUS_FIELD',
          defaultMessage: `${fieldName} must be greater than or equal to previous ${fieldName2}`,
        },
        {
          field: getFormLabel(fieldName, formatMessage, formId),
          field2: getFormLabel(fieldName2, formatMessage, formId),
        }
      );
    },
    mustBeValidJson: (fieldName: string): string => {
      return formatMessage(
        {
          id: 'FIELDS.MUST_BE_JSON',
          defaultMessage: '{field} must a valid JSON object.',
        },
        {
          field: fieldName,
        }
      );
    },
  };
};

export default formatValidationMessages;
