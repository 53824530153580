import { TConfigNumberWithDefault } from '~/interfaces';

export const DEFAULT_MAX_LENGTH = 25;

export const PROMO_CAMPAIGN_ID_LENGTH: TConfigNumberWithDefault = {
  DEFAULT: DEFAULT_MAX_LENGTH,
};

export const getPromoCampaignIdLength = (country?: string): number => {
  return PROMO_CAMPAIGN_ID_LENGTH?.[country] ?? PROMO_CAMPAIGN_ID_LENGTH.DEFAULT;
};
