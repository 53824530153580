import { number, NumberSchema } from 'yup';
import { ISchemaField, IValidationMessages } from '~/interfaces';
export const validateFloat = (
  val: ISchemaField,
  validationCallbacks: IValidationMessages
): NumberSchema => {
  let floatValidationType = number().typeError(validationCallbacks.mustBeANumber(val.label));
  floatValidationType = floatValidationType.test(
    '',
    validationCallbacks.twoDecimalPoints(val.label),
    (value) => {
      return value !== undefined
        ? value.toFixed(2) === `${value}` || value.toFixed(1) === `${value}` || value % 1 === 0
        : true;
    }
  );

  if (val?.max !== undefined) {
    floatValidationType = floatValidationType.max(
      val.max,
      validationCallbacks.greaterThanMax(val.label, val.max)
    );
  }
  if (val?.min !== undefined) {
    floatValidationType = floatValidationType.min(
      val.min,
      validationCallbacks.lessThanMin(val.label, val.min)
    );
  }
  return floatValidationType;
};
