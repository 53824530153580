import React from 'react';
import { Button } from '@material-ui/core';
import { Buttons } from '~/components/Messages';
import {
  Modal as ModalDesign,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ButtonContainer,
} from './styles';

import { IModal } from './Modal.types';

const Modal = ({ open, title, children, footer, handleCloseModal }: IModal) => {
  return (
    <>
      {open && (
        <ModalDesign>
          <ModalContent>
            <ModalHeader>
              <ModalTitle>{title}</ModalTitle>
            </ModalHeader>
            <ModalBody>{children}</ModalBody>
            <ModalFooter>{footer}</ModalFooter>
            <ButtonContainer>
              <Button onClick={handleCloseModal}>
                <Buttons.Cancel />
              </Button>
            </ButtonContainer>
          </ModalContent>
        </ModalDesign>
      )}
    </>
  );
};

export default Modal;
