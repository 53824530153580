/**
 * Use this file to configure the order in which the feilds appear
 * Eventually we will move this to a different data source
 * The code is NOT dependent upon all fields being present in this list
 * If a field is sent from the server that is not in this list, it will simply be added later in the form
 */
import { TConfigArrayWithDefault } from '~/interfaces';

export const DEFAULT_STEPPED_DISCOUNT_COLUMNS = [
  'stepId',
  'minQuantity',
  'maxQuantity',
  'discount',
];

export const STEPPED_DISCOUNT_COLUMNS: TConfigArrayWithDefault = {
  DEFAULT: [...DEFAULT_STEPPED_DISCOUNT_COLUMNS],
  BR: [...DEFAULT_STEPPED_DISCOUNT_COLUMNS],
};

export const getSteppedDiscountColumns = (country?: string): string[] => {
  return STEPPED_DISCOUNT_COLUMNS?.[country] ?? STEPPED_DISCOUNT_COLUMNS.DEFAULT;
};
