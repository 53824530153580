import { NumberSchema } from 'yup';
import { IValidationMessages, IValidationRule } from '~/interfaces';

export const lessThanOrEqualTo = (
  validation: NumberSchema,
  rule: IValidationRule,
  fieldName: string,
  validationCallbacks: IValidationMessages
): NumberSchema => {
  // Displays validation messages for if current value is greater than the dependant value.
  // Had to use .test to avoid cyclic errors.
  validation = validation.test(
    '',
    validationCallbacks.lessThanOrEqualTo(fieldName, rule?.field),
    // @ts-ignore
    (value, context) => {
      // @ts-ignore
      const dependentValue = context?.from[0]?.value[rule?.field];
      if (rule?.dependent && dependentValue && value && dependentValue < value) {
        return false;
      }

      return true;
    }
  );

  // Displays validation messages if a dependent value is needed
  validation = validation.test(
    '',
    validationCallbacks.requiresDependentField(fieldName, rule?.field),
    // @ts-ignore
    (value, context) => {
      // @ts-ignore
      const dependentValue = context?.from[0]?.value[rule?.field];
      if (rule?.dependent && !dependentValue && value) {
        return false;
      }

      return true;
    }
  );

  return validation;
};
