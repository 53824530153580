import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import { setupLocalEnv } from './setupLocalEnv';
import App from './App';
import { ErrorBoundary } from '~/components';

if (process.env.NODE_ENV === 'development') {
  setupLocalEnv();
}

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: App,
  errorBoundary(error: Error) {
    return <ErrorBoundary error={error} />;
  },
});

export const { bootstrap, mount, unmount } = reactLifecycles;
