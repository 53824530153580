import ReactJson, { OnCopyProps } from 'react-json-view';
import copy from 'copy-to-clipboard';
import React from 'react';
import { getTestIdAttr } from '~/utils';

type CopyPropsExtended = OnCopyProps;

export const Json = ({ data, collapsed = true }: { data: any; collapsed?: boolean }) => {
  return (
    <>
      {/* @ts-ignore */}
      <ReactJson
        {...getTestIdAttr('Json')}
        src={data}
        collapsed={collapsed}
        name={false}
        quotesOnKeys={false}
        enableClipboard={(copyProps: CopyPropsExtended) => {
          if (typeof copyProps.src === 'string') {
            copy(copyProps.src);
          }
          if (typeof copyProps.src === 'object') {
            copy(JSON.stringify(copyProps.src, null, 4));
          }
        }}
      />
    </>
  );
};
