import { useFeatureToggle } from './useFeatureToggle';
import { PREVENT_SINGLE_STEP_SUBMIT } from '~/constants';
import useSelectedCountry from './useSelectedCountry';

const usePreventSingleStepSubmit = (): boolean => {
  const selectedCountry = useSelectedCountry();
  return useFeatureToggle(PREVENT_SINGLE_STEP_SUBMIT, { country: selectedCountry }, false);
};

export default usePreventSingleStepSubmit;
