import { useFeatureToggle, useFeatureToggleResponse } from './useFeatureToggle';
import { CAN_CONFIGURE_USER_PERMISSIONS } from '~/constants';
import { TFeatureToggleResponse } from '~/interfaces';
import useSelectedCountry from './useSelectedCountry';

const useCanConfigureUserPermissionsResponse = (): TFeatureToggleResponse => {
  const selectedCountry = useSelectedCountry();
  return useFeatureToggleResponse(CAN_CONFIGURE_USER_PERMISSIONS, { country: selectedCountry });
};

const useCanConfigureUserPermissions = (): boolean => {
  const selectedCountry = useSelectedCountry();
  return useFeatureToggle(CAN_CONFIGURE_USER_PERMISSIONS, { country: selectedCountry }, false);
};

export { useCanConfigureUserPermissions, useCanConfigureUserPermissionsResponse };
