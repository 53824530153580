import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import Bff from '~/Api/Bff';
import { TMIMEType } from '~/interfaces';
import { setCoverImage, setToastFromResponse } from '~/redux/saga/sagaActions';
import { blobToBase64 } from '~/utils';

interface IImageParameters {
  url: string;
  type?: TMIMEType;
}

interface ICoverImage {
  imageUrl?: string;
  fileName?: string;
}

export const getImage = async ({
  url,
  type,
}: IImageParameters): Promise<Awaited<ReturnType<typeof blobToBase64>>> => {
  return Bff.get<Blob>({
    url: `/v1/image?url=${encodeURIComponent(url)}`,
    options: {
      responseType: 'blob',
    },
  })
    .then((response) => blobToBase64(response.data, type))
    .then((response) => response);
};

export const useGetImage = (coverImage: ICoverImage) => {
  const dispatch = useDispatch();
  return useMutation(getImage, {
    onError: (e) => dispatch(setToastFromResponse(e)),
    onSuccess: (response) => {
      const payload = [
        {
          errors: [],
          valid: true,
          imageInBase64: response as string,
          file: undefined,
          fileName: coverImage.fileName,
          id: 1,
        },
      ];
      dispatch(setCoverImage(payload));
    },
  });
};
