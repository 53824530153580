import React, { Suspense, CSSProperties } from 'react';
import LoadingSpinner from '~/components/LoadingSpinner';
import { getTestIdAttr } from '~/utils';

interface ILiftLazyLoad {
  children: JSX.Element;
  style?: CSSProperties;
  [key: string]: any;
}

const LiftLazyLoad = ({ children, style, ...props }: ILiftLazyLoad): JSX.Element => {
  return (
    <Suspense
      {...getTestIdAttr('LiftLazyLoad')}
      fallback={
        <div style={style}>
          <LoadingSpinner {...props} />
        </div>
      }
    >
      {children}
    </Suspense>
  );
};

export default LiftLazyLoad;
