import { IPromoDesignsResponse } from '@moneycomb/mcomb-types/response';
import Bff from '~/Api/Bff';
import { AxiosResponse } from 'axios';

export const getBudgetParameters = (): Promise<AxiosResponse<IPromoDesignsResponse>> => {
  return Bff.get({
    url: `/v1/business-units`,
  }).then((response) => {
    return response;
  });
};
