import React from 'react';

export type EnvConfig = {
  name: string;
  ENV: string;
  ENV_TYPE: string;
  optimizelyKey: string;
  enableDevTools?: boolean;
};

const defaultInitialValue: EnvConfig = {
  name: '',
  ENV: '',
  ENV_TYPE: '',
  optimizelyKey: '',
  enableDevTools: false,
};

export const EnvContext = React.createContext(defaultInitialValue);

export const useEnvContext = () => React.useContext(EnvContext);

export const EnvProvider = ({ env, children }: { env: EnvConfig; children: React.ReactNode }) => (
  <EnvContext.Provider value={env}>{children}</EnvContext.Provider>
);
