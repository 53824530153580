export const BASE_PATH = '/moneycomb';

export const LIFT_ROUTES = {
  HOME: `${BASE_PATH}`,
  DESIGN: `${BASE_PATH}/design`,
  SUPPORT: `${BASE_PATH}/support`,
  TOGGLES: `${BASE_PATH}/toggles`,
  ADMIN: `${BASE_PATH}/admin`,
  USER_MANAGEMENT: `${BASE_PATH}/admin/user-management`,
  ADMIN_USERS: `${BASE_PATH}/admin/admin-users`,
  ADMIN_USER: `${BASE_PATH}/admin/admin-users/:userId`,
  CONFIG: `${BASE_PATH}/admin/config`,
};
