import { Tabs } from '@hexa-ui/components';
import React, { forwardRef } from 'react';
import { TGenericTabTrigger } from './TabTrigger.types';
import { getTestIdAttr } from '~/utils';

export const TabTrigger: TGenericTabTrigger = forwardRef((props, _ref) => {
  return (
    <Tabs.Trigger
      {...getTestIdAttr('TabTrigger')}
      value={props.tabValue}
      onClick={() => props.setCurrentTab(props.tabValue)}
      disabled={props.disabled}
      {...(props.style && {
        style: props.style,
      })}
    >
      {props.message}
    </Tabs.Trigger>
  );
});
