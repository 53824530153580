import { TagButton, TagItem } from './FilterChip.styles';
import { TFilterChip } from './FilterChip.types';

const FilterChip: TFilterChip = ({ content, handleDelete }) => {
  return (
    <TagItem data-testid="filter-chip" key={'item'}>
      {content}
      <TagButton type="button" onClick={handleDelete} data-testid={'filter-chip-button'}>
        &times;
      </TagButton>
    </TagItem>
  );
};

export default FilterChip;
