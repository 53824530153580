import { MechanicType } from '~/constants';

export const PRODUCTS_LIST_DELIMITER = ' - ';
export const SKU_PARAMETERS_FORM_ID = 'SKU_PARAMETERS';
export const ADD_PRODUCTS_SEARCH_FORM_ID = 'add-products-search';
export const DISCOUNT = 'DISCOUNT' as MechanicType.DISCOUNT;
export const STEPPED_DISCOUNT = 'STEPPED_DISCOUNT' as MechanicType.STEPPED_DISCOUNT;
export const INTERACTIVE_COMBO = 'INTERACTIVE_COMBO' as MechanicType.INTERACTIVE_COMBO;
export const REWARDS_CHALLENGE = 'REWARDS_CHALLENGE' as MechanicType.REWARDS_CHALLENGE;
export const GREATER_THAN_OR_EQUAL_TO_SPECIFIC_FIELD = 'greaterThanOrEqualToSpecificValue';
export const GREATER_THAN_DEPENDENT_VALUE = 'greaterThanDependentValue';
export const LESS_THAN_SPECIFIC_FIELD = 'lessThanSpecificValue';
export const DISCOUNT_PERCENT = 'discount_percent';
