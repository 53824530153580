import Bff from '~/Api/Bff';
import { ISkuParametersResponse } from '~/interfaces';
import { AxiosResponse } from 'axios';
import { getProductFilters } from './getProductFilters';

export const getUserProductFilters = ({
  country,
}: {
  country?: string;
}): Promise<AxiosResponse<ISkuParametersResponse>> => {
  return Bff.get<ISkuParametersResponse>({
    url: '/v1/parameters/user-product-filters',
    options: {
      headers: {
        ...(country ? { country } : {}),
        ifEmptyReturnAll: true,
      },
    },
  }).then(async (response) => {
    const res = await getProductFilters({ country });
    const allFilters = res?.data?.filters;
    const selectedFilters = response?.data?.parameters;

    const filters = { ...allFilters, ...selectedFilters };
    Object.keys(filters).forEach((key) => {
      const value = filters?.[key];
      if (!value?.length || !Object.values(value)?.length) {
        filters[key] = allFilters?.[key];
      }
    });
    return {
      ...response,
      data: {
        ...response?.data,
        filters,
      },
    };
  });
};
