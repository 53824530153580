import { IERPHistoryResponse } from '~/interfaces';
import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import Bff from '~/Api/Bff';

const DEFAULT_PAGE_SIZE = 20;

export const getErpHistory = (
  promoDesignId: string,
  page = 0,
  pageSize = DEFAULT_PAGE_SIZE
): Promise<IERPHistoryResponse> => {
  return Bff.get<IERPHistoryResponse>({
    url: `/v1/promo-designs/${promoDesignId}/erp-history?page=${page}&pageSize=${pageSize}`,
  }).then((response) => {
    return response.data;
  });
};

export const useGetErpHistory = (
  promoDesignId: string,
  page?: number,
  pageSize?: number,
  options?: UseQueryOptions
): UseQueryResult<IERPHistoryResponse> => {
  return useQuery<any, any, IERPHistoryResponse, any>({
    queryKey: ['getErpHistory', promoDesignId, page, pageSize],
    queryFn: async () => {
      return await getErpHistory(promoDesignId, page, pageSize);
    },
    cacheTime: 0,
    staleTime: 0,
    ...options,
  });
};
