import { useMemo } from 'react';
import * as locale from 'date-fns/locale';
import { useAuthenticationService, usePreferredLanguageV2 } from 'admin-portal-shared-services';

export const removeDash = (value?: string): string | undefined => {
  return value?.replace('-', '');
};
export const getLanguageCode = (value?: string): string | undefined => {
  return value?.split('-')?.[0];
};

export const useUserDateLocale = (): Locale => {
  const authentication = useAuthenticationService();
  const { user_country: userCountry, language: countryLanguage } =
    authentication.getUserCountryAndLanguage();
  const { preferredLanguage, defaultLanguage } = usePreferredLanguageV2();

  return useMemo(() => {
    return (
      locale[removeDash(preferredLanguage)] ??
      locale?.[getLanguageCode(preferredLanguage)] ??
      locale?.[removeDash(defaultLanguage)] ??
      locale?.[getLanguageCode(defaultLanguage)] ??
      locale?.[removeDash(countryLanguage)] ??
      locale?.[`${countryLanguage?.toLowerCase()}${userCountry?.toUpperCase()}`] ??
      locale?.[countryLanguage?.toLowerCase()] ??
      locale['enUS']
    );
  }, [preferredLanguage, defaultLanguage, countryLanguage, userCountry]);
};
