import React, { useEffect } from 'react';
import { Grid, Paragraph } from '@hexa-ui/components';
import CustomLabel from '~/components/Form/components/CustomLabel/CustomLabel';
import CustomError from '~/components/Form/components/CustomError/CustomError';
import { getTestIdAttr } from '~/utils';
import { Field, useField } from 'formik';
import { EmptyCard } from 'supplier-portal-shared-components';
import './CheckboxGroup.css';

export const CheckboxGroup = ({
  field: { name, value, ...fieldProps },
  label,
  showLabel,
  hint,
  form,
  required,
  customId,
  customClasses,
  checkboxOptions,
  emptyClasses,
  emptyHeader,
  currentValues,
  emptySubHeader,
  disabled,
  onClick,
  ...props
}): JSX.Element => {
  const [, , helpers] = useField({
    ...props,
    name,
  });

  useEffect(() => {
    // If checkboxOptions is empty make sure the current value is empty
    // This fixes issues with selecting a checkbox that has a dependency
    // Example:
    // If a substrategy is clicked and a strategy is deselected this ensures
    // that the formik state is emptied.
    if (!checkboxOptions.length) {
      helpers.setValue({ checked: [] });
    } else {
      if (currentValues?.length) {
        helpers.setValue({ checked: currentValues });
      }
    }
  }, [checkboxOptions, currentValues]);

  return (
    <Grid.Container
      {...getTestIdAttr('CheckboxGroup')}
      type="fluid"
      style={{ padding: 0, margin: 0 }}
    >
      <Grid.Item
        style={{ margin: 0 }}
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className={`${form?.errors?.[name] ? 'error-container' : ''}`}
      >
        <CustomLabel errors={form?.errors?.[name]} label={showLabel ? label : ''} hint={hint} />
        <div
          role="group"
          aria-labelledby="checkbox-group"
          style={{ width: '100%' }}
          {...getTestIdAttr('CheckboxGroupList')}
        >
          {!checkboxOptions?.length ? (
            <EmptyCard
              customClasses={emptyClasses ? emptyClasses : ''}
              // Default Message
              description={emptySubHeader || 'No results so far'}
              header={emptyHeader || ''}
            />
          ) : (
            checkboxOptions.map((checkbox, i) => (
              <label
                key={`${checkbox.label}-${checkbox.customId}`}
                className={checkbox.customClasses}
              >
                <Field
                  type="checkbox"
                  name={`${name}.checked`}
                  id={checkbox.customId}
                  onClick={checkbox.onClick}
                  value={checkbox.defaultValue}
                  disabled={checkbox.disabled}
                  {...props}
                />
                <Paragraph>{checkbox.label}</Paragraph>
              </label>
            ))
          )}
        </div>
      </Grid.Item>
      <Grid.Item className="" xs={12} sm={12} md={12} lg={12} xl={12}>
        <CustomError errors={form?.errors?.[name]} />
      </Grid.Item>
    </Grid.Container>
  );
};
