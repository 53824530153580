import React, { useCallback, useState } from 'react';
import { useGetErpHistory } from '~/services/ERPHistoryService';
import { Table, Chip } from '@hexa-ui/components';
import { Trash2 as Delete, AddTag as Add } from '@hexa-ui/icons';
import { FormattedDate, useIntl } from 'react-intl';
import { DateTimeWithRelativeHover, Json, Status } from '~/components';
import { TERPHistoryList } from './ERPHistoryList.types';
import { IERPHistory } from '~/interfaces';
import { getTestIdAttr } from '~/utils';
import { PAGINATION_DEFAULT_PROPS } from '~/constants';

const Pagination = PAGINATION_DEFAULT_PROPS;

const ERPHistoryList: TERPHistoryList = ({ promoDesignId }: { promoDesignId: string }) => {
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(5);

  const { formatMessage } = useIntl();

  const getStatus = useCallback((status: string) => {
    return (
      <Status
        color={status.toUpperCase() === 'SUCCESS' ? 'green' : 'red'}
        status={status.capitalize()}
      />
    );
  }, []);

  const customChip = useCallback(({ value }: { value: string }) => {
    return (
      <>
        <Chip.Root disabled>
          <Chip.Icon icon={value === 'CREATE' ? Add : Delete} />
          <Chip.Label>{value.capitalize()}</Chip.Label>
        </Chip.Root>
      </>
    );
  }, []);

  const formatDate = useCallback((value) => {
    return (
      <DateTimeWithRelativeHover value={value}>
        <FormattedDate value={`${value}Z`} dateStyle={'medium'} timeStyle={'medium'} />
      </DateTimeWithRelativeHover>
    );
  }, []);

  const getTestIdAttrSeqId = (value: string, row: IERPHistory) => {
    return <span {...getTestIdAttr('ERPHistoryListSeqId')}>{value}</span>;
  };

  const {
    data: erpHistory = {
      data: [],
      pagination: {
        page: 0,
        size: 5,
        totalElements: 0,
        totalPages: 0,
      },
    },
    isLoading,
  } = useGetErpHistory(promoDesignId, page - 1, pageSize);

  return (
    <div {...getTestIdAttr('ERPHistoryList')}>
      <Table<IERPHistory>
        data={erpHistory?.data}
        loading={isLoading}
        loadingMessage={formatMessage({
          id: 'ERP_HISTORY_TABLE.LOADING',
          defaultMessage: 'Loading ERP History',
        })}
        fixedHeader={true}
        expandable={{
          rowHasExpandFeature: (row) => !!row?.erpResponse,
          render: (row) => {
            return <Json data={row?.erpResponse} collapsed={false} />;
          },
        }}
        cellHeight={'tight'}
        // search
        pagination={{
          quantityIndicatorIntl: formatMessage({ id: 'PAGINATION.OF', defaultMessage: 'of' }),
          pageSizeOptionsIntl: (numberOfItems) =>
            formatMessage(
              { id: 'PAGINATION.ITEMS_PER_PAGE', defaultMessage: 'Show {field} items per page' },
              { field: numberOfItems }
            ),
          current: page,
          pageSizeOptions: Pagination.PAGE_SIZE_OPTIONS,
          total: erpHistory?.pagination?.totalElements,
          showPageSizeSelector: true,
          disabled: false,
          onChange: (page: number, pageSize: number) => {
            setPage(page);
            setPageSize(pageSize);
          },
          pageSize,
        }}
        columns={[
          {
            Header: formatMessage({
              id: 'ERP_HISTORY_TABLE.fields.ERP_ID',
              defaultMessage: 'ERP ID',
            }),
            accessor: 'erpId',
            style: {
              minWidth: '145px',
              whiteSpace: 'nowrap',
            },
          },
          {
            Header: formatMessage({ id: 'ERP_HISTORY_TABLE.fields.TYPE', defaultMessage: 'Type' }),
            accessor: 'typeOfRequest',
            customRender: (value: string) => customChip({ value }),
          },
          {
            Header: formatMessage({
              id: 'ERP_HISTORY_TABLE.fields.RESULT',
              defaultMessage: 'Result',
            }),
            accessor: 'erpStatus',
            customRender: (value: string) => getStatus(value),
          },
          {
            Header: formatMessage({ id: 'ERP_HISTORY_TABLE.fields.DATE', defaultMessage: 'Date' }),
            accessor: 'lastUpdatedTimestamp',
            style: {
              minWidth: '270px',
            },
            customRender: (value) => formatDate(value),
          },
          {
            Header: formatMessage({
              id: 'ERP_HISTORY_TABLE.fields.PROMO_ID',
              defaultMessage: 'Promo ID',
            }),
            accessor: 'promoID',
            style: {
              minWidth: '375px',
            },
          },
          {
            Header: '',
            accessor: 'erpResponse',
            show: false,
            disableSortBy: true,
            customRender: (value, row) => {
              return null;
            },
          },
          {
            Header: formatMessage({
              id: 'ERP_HISTORY_TABLE.fields.SEQ_ID',
              defaultMessage: 'Sequence ID',
            }),
            accessor: 'seqId',
            customRender: (value, row) => getTestIdAttrSeqId(value, row),
          },
        ]}
      />
    </div>
  );
};

export default ERPHistoryList;
