import {
  PROMOS_CREATED,
  PROMOS_LOADED,
  LIVE,
  COMPLETE,
  PENDING_ALGORITHM_DELAYED,
  PENDING_ALGORITHM,
  PROMOS_DELAYED,
  DELETED,
} from './statuses';
import { TConfigArrayWithDefault } from '~/interfaces';

/**
 * Use this file to configure the order in which the feilds appear
 * Eventually we will move this to a different data source
 * The code is NOT dependent upon all fields being present in this list
 * If a field is sent from the server that is not in this list, it will simply be added later in the form
 */

export const STATUSES_FOR_DUPLICATE: TConfigArrayWithDefault = {
  DEFAULT: [
    PROMOS_CREATED,
    PROMOS_LOADED,
    LIVE,
    COMPLETE,
    PENDING_ALGORITHM_DELAYED,
    PENDING_ALGORITHM,
    PROMOS_DELAYED,
    DELETED,
  ],
};

export const getStatusesForDuplicate = (country?: string): string[] => {
  return STATUSES_FOR_DUPLICATE?.[country] ?? STATUSES_FOR_DUPLICATE.DEFAULT;
};
