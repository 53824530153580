import { createSlice } from '@reduxjs/toolkit';
import {
  IBooleanPayloadAction,
  ICountriesStore,
  ISetAvailableCountriesAction,
  ISetCountryAction,
} from '~/interfaces';
import { RootState } from '~/redux/store';

export type ISetIsLoadingAction = IBooleanPayloadAction;

const initialState: ICountriesStore = {
  countries: [],
  selectedCountry: null,
  isLoading: false,
};

export const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {
    setAvailableCountries: (state, action: ISetAvailableCountriesAction): ICountriesStore => {
      return {
        ...state,
        countries: action.payload.countries,
      };
    },
    setCountry: (state, action: ISetCountryAction): ICountriesStore => {
      return {
        ...state,
        selectedCountry: action.payload.selectedCountry,
      };
    },
    setIsLoading: (state, action: ISetIsLoadingAction): ICountriesStore => {
      return {
        ...state,
        isLoading: action.payload,
      };
    },
  },
});

export const selectAllowedCountries = (state: RootState) => state.countries.countries;
export const selectSelectedCountry = (state: RootState) => state.countries.selectedCountry;
export const selectCountriesState = (state: RootState) => state.countries;

// Action creators are generated for each case reducer function
export const { setAvailableCountries, setCountry, setIsLoading } = countriesSlice.actions;

export default countriesSlice.reducer;
