import useStyles from '~/components/CountrySelector/CountrySelector.styles';
import React from 'react';
import { CountryFlagIcon } from '~/components';
import { FormattedDisplayName } from 'react-intl';
import { TFlagCountry } from '~/interfaces';

const CountryDropdownItem = ({ country }: { country: string }) => {
  const { contentCenter } = useStyles();

  if (!country) {
    return null;
  }

  return (
    <div className={contentCenter}>
      <CountryFlagIcon country={country as TFlagCountry} />
      <FormattedDisplayName type={'region'} value={country} />
    </div>
  );
};

export default React.memo(CountryDropdownItem);
