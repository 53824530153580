export const setupLocalEnv = () => {
  try {
    const { mswWorker } = require('./mocks/mswBrowser.js');
    mswWorker
      .start({
        url: 'http://localhost:8080/mockServiceWorker.js',
      })
      .catch((e) => {
        console.warn(e);
      });
  } catch (e) {
    console.warn(e);
  }
};
