import { getMechanicsUsingDisplayStepped } from '~/configs/mechanicsUsingDisplayStepped';
import { useMemo } from 'react';
import { useAppSelector } from '~/hooks';
import { selectSelectedCountry, selectMechanicsType } from '~/redux/slices';
import { TMechanicType } from '~/interfaces';

const useDisplayStepped = (mechanicsType?: TMechanicType): boolean => {
  const globalMechanicsType = useAppSelector(selectMechanicsType);
  if (!mechanicsType) {
    // Do not put the useSelector in here. useSelector should not be used inside a conditional
    mechanicsType = globalMechanicsType;
  }
  const selectedCountry = useAppSelector(selectSelectedCountry);
  return useMemo(() => {
    return getMechanicsUsingDisplayStepped(selectedCountry).includes(mechanicsType);
  }, [selectedCountry, mechanicsType]);
};

export default useDisplayStepped;
