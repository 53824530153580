import { useGetFirstExternalHistory } from '~/services';
import { useMemo } from 'react';
import { validateExternalHistory } from '~/utils';
import { useIntl } from 'react-intl';
import { IPromoDesign } from '~/interfaces';

export const useGetOverlappingPromoDesignIds = (
  promoDesignId: string,
  modalData: IPromoDesign
): string[] => {
  const { formatMessage } = useIntl();
  const { data: firstExternalHistory } = useGetFirstExternalHistory(promoDesignId, modalData);
  return useMemo<string[]>(() => {
    if (!firstExternalHistory) {
      return [];
    }
    const response = validateExternalHistory(firstExternalHistory, formatMessage);
    return response?.meta?.overlappingPromoDesignIds ?? [];
  }, [firstExternalHistory]);
};
