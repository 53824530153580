import { Action, Alert } from '@hexa-ui/components';
import React, { useEffect, useMemo, useRef } from 'react';
import { getTestIdAttr } from '~/utils';
import { THistoryErrorMessage } from './HistoryErrorMessage.types';

const HistoryErrorMessage: THistoryErrorMessage = ({ message, type, alertActionProps }) => {
  const alertActions = useMemo<
    [firstAction: Action] | [firstAction: Action, secondAction?: Action]
  >(() => {
    const { firstActionName, firstAction, secondActionName, secondAction } = alertActionProps;
    const action = {
      name: firstActionName,
      action: () => {
        firstAction();
      },
    };
    if (secondActionName) {
      return [
        action,
        {
          name: secondActionName,
          action: () => {
            secondAction();
          },
        },
      ];
    }
    return [action];
  }, [alertActionProps]);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
  });

  return (
    <div {...getTestIdAttr('HistoryErrorMessage')} ref={messagesEndRef}>
      <Alert message={message} type={type} actions={alertActions} css={{ width: 'auto' }} />
    </div>
  );
};

export default HistoryErrorMessage;
