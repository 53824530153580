import Bff from '~/Api/Bff';

export const getPromoHistoryCSVDownload = async (startDate, endDate) => {
  const response = await Bff.get({
    url: '/v1/promo-designs/erp-history-download',
    options: {
      headers: {
        startDate,
        endDate,
      },
      responseType: 'blob',
    },
  });

  if (!response.data) {
    throw new Error('Error while retrieving CSV file');
  }

  return response.data;
};
