import { DynamicForm } from './components';
import { FormProps } from '~/interfaces/IForm';
import { forwardRef } from 'react';
import { getTestIdAttr } from '~/utils';

const Form = (
  {
    formId,
    schema,
    onSubmit = () => {},
    setCurrentValues = () => {},
    clearFormOnSubmit,
    initialValues,
    buttonContainerStyles,
    onClose,
    buttonLabel = 'Submit',
    extraButtons,
    showButtons = true,
    ...props
  }: FormProps,
  ref
) => {
  return (
    <DynamicForm
      {...getTestIdAttr('Form')}
      formId={formId}
      schema={schema}
      ref={ref}
      onSubmit={onSubmit}
      setCurrentValues={setCurrentValues}
      initialValues={initialValues}
      onClose={onClose}
      clearFormOnSubmit={clearFormOnSubmit}
      buttonContainerStyles={buttonContainerStyles}
      buttonLabel={buttonLabel}
      extraButtons={extraButtons}
      showButtons={showButtons}
      {...props}
    />
  );
};
export default forwardRef(Form);
