import {
  SKU,
  PRODUCT_NAME,
  MIN_QUANTITY,
  MAX_QUANTITY,
  MIN_DISCOUNT,
  MAX_DISCOUNT,
  DISCOUNT,
  ALLOCATION,
  ORDER_AVAILABILITY,
  SKU_LIMIT,
  BUDGET_ID,
  MAX_POINTS,
  STEP_ID,
} from '~/constants/skuParams';
import {
  SHOW_SKU_COLUMN_ON_PRODUCT_TABLE,
  SHOW_PRODUCT_NAME_COLUMN_ON_PRODUCT_TABLE,
  SHOW_MIN_QUANTITY_COLUMN_ON_PRODUCT_TABLE,
  SHOW_MAX_QUANTITY_COLUMN_ON_PRODUCT_TABLE,
  SHOW_MIN_DISCOUNT_COLUMN_ON_PRODUCT_TABLE,
  SHOW_MAX_DISCOUNT_COLUMN_ON_PRODUCT_TABLE,
  SHOW_DISCOUNT_COLUMN_ON_PRODUCT_TABLE,
  SHOW_ALLOCATION_COLUMN_ON_PRODUCT_TABLE,
  SHOW_ORDER_AVAILABILITY_COLUMN_ON_PRODUCT_TABLE,
  SHOW_SKU_LIMIT_COLUMN_ON_PRODUCT_TABLE,
  SHOW_BUDGET_ID_COLUMN_ON_PRODUCT_TABLE,
  SHOW_MAX_POINTS_COLUMN_ON_PRODUCT_TABLE,
  SHOW_STEP_ID_COLUMN_ON_PRODUCT_TABLE,
} from '~/constants';
import { useMemo } from 'react';
import useSelectedCountry from './useSelectedCountry';
import { useFeatureTogglesResponse } from './useFeatureToggle';
import {
  TFeatureToggleMultiResponse,
  TFeatureToggleResponse,
  TMechanicType,
  TPersonalizationType,
  TPromoDesignSkuField,
} from '~/interfaces';
import { getSkuParamOrders, getSteppedDiscountColumns } from '~/configs';

import { some } from '~/utils';

enum SKU_DETAILS_KEY {
  SKU = 'sku',
  PRODUCT_NAME = 'productName',
  STEP_ID = 'stepId',
  MIN_DISCOUNT = 'minDiscount',
  MAX_DISCOUNT = 'maxDiscount',
  MIN_QUANTITY = 'minQuantity',
  MAX_QUANTITY = 'maxQuantity',
  DISCOUNT = 'discount',
  MAX_POINTS = 'maxPoints',
  ALLOCATION = 'allocation',
  ORDER_AVAILABILITY = 'orderAvailability',
  SKU_LIMIT = 'skuLimit',
  BUDGET_ID = 'budgetId',
}

type SkuDetailResponse = [string[], boolean];
type TFieldToToggleMap = { [key: string]: string };

const featureToggleSkuDetailsKeyMapping = {
  nonPersonalized: {
    [SKU]: SHOW_SKU_COLUMN_ON_PRODUCT_TABLE,
    [PRODUCT_NAME]: SHOW_PRODUCT_NAME_COLUMN_ON_PRODUCT_TABLE,
    [MIN_QUANTITY]: SHOW_MIN_QUANTITY_COLUMN_ON_PRODUCT_TABLE,
    [MAX_QUANTITY]: SHOW_MAX_QUANTITY_COLUMN_ON_PRODUCT_TABLE,
    [DISCOUNT]: SHOW_DISCOUNT_COLUMN_ON_PRODUCT_TABLE,
    [ORDER_AVAILABILITY]: SHOW_ORDER_AVAILABILITY_COLUMN_ON_PRODUCT_TABLE,
    [SKU_LIMIT]: SHOW_SKU_LIMIT_COLUMN_ON_PRODUCT_TABLE,
    [BUDGET_ID]: SHOW_BUDGET_ID_COLUMN_ON_PRODUCT_TABLE,
  },
  personalized: {
    [SKU]: SHOW_SKU_COLUMN_ON_PRODUCT_TABLE,
    [PRODUCT_NAME]: SHOW_PRODUCT_NAME_COLUMN_ON_PRODUCT_TABLE,
    [MIN_DISCOUNT]: SHOW_MIN_DISCOUNT_COLUMN_ON_PRODUCT_TABLE,
    [MAX_DISCOUNT]: SHOW_MAX_DISCOUNT_COLUMN_ON_PRODUCT_TABLE,
    [MIN_QUANTITY]: SHOW_MIN_QUANTITY_COLUMN_ON_PRODUCT_TABLE,
    [MAX_QUANTITY]: SHOW_MAX_QUANTITY_COLUMN_ON_PRODUCT_TABLE,
    [MAX_POINTS]: SHOW_MAX_POINTS_COLUMN_ON_PRODUCT_TABLE,
    [ALLOCATION]: SHOW_ALLOCATION_COLUMN_ON_PRODUCT_TABLE,
    [ORDER_AVAILABILITY]: SHOW_ORDER_AVAILABILITY_COLUMN_ON_PRODUCT_TABLE,
    [SKU_LIMIT]: SHOW_SKU_LIMIT_COLUMN_ON_PRODUCT_TABLE,
    [BUDGET_ID]: SHOW_BUDGET_ID_COLUMN_ON_PRODUCT_TABLE,
  },
} as {
  [key in TPersonalizationType]: {
    [key in SKU_DETAILS_KEY]: string;
  };
};

const featureToggleSteppedKeysMapping = {
  nonPersonalized: {
    [STEP_ID]: SHOW_STEP_ID_COLUMN_ON_PRODUCT_TABLE,
    [MIN_QUANTITY]: SHOW_MIN_QUANTITY_COLUMN_ON_PRODUCT_TABLE,
    [MAX_QUANTITY]: SHOW_MAX_QUANTITY_COLUMN_ON_PRODUCT_TABLE,
    [DISCOUNT]: SHOW_DISCOUNT_COLUMN_ON_PRODUCT_TABLE,
  },
  personalized: {
    [STEP_ID]: SHOW_STEP_ID_COLUMN_ON_PRODUCT_TABLE,
    [MIN_QUANTITY]: SHOW_MIN_QUANTITY_COLUMN_ON_PRODUCT_TABLE,
    [MAX_QUANTITY]: SHOW_MAX_QUANTITY_COLUMN_ON_PRODUCT_TABLE,
    [DISCOUNT]: SHOW_DISCOUNT_COLUMN_ON_PRODUCT_TABLE,
  },
} as {
  [key in TPersonalizationType]: {
    [key in SKU_DETAILS_KEY]: string;
  };
};

const convertFieldToFeatureToggle = (
  field: string,
  personalizedKey: TPersonalizationType,
  isSteppedView: boolean
): string | undefined => {
  const fetureToggleKeys = isSteppedView
    ? featureToggleSteppedKeysMapping
    : featureToggleSkuDetailsKeyMapping;
  return fetureToggleKeys?.[personalizedKey]?.[field];
};

const mapFieldsToFeatureToggles = (
  values: string[],
  personalizedKey: TPersonalizationType,
  isSteppedView: boolean
): TFieldToToggleMap => {
  return values.reduce<TFieldToToggleMap>((previousValue, currentValue) => {
    const featureToggle = convertFieldToFeatureToggle(currentValue, personalizedKey, isSteppedView);
    if (!featureToggle) {
      return {
        ...previousValue,
      };
    }
    return {
      ...previousValue,
      [currentValue]: featureToggle,
    };
  }, {});
};

class FeatureToggleResponseChecker {
  protected selectedCountry;
  protected featureToggleIds;
  protected togglesResponse;
  protected mechanicType;
  protected skippableFields;
  protected isSteppedView;
  constructor(
    selectedCountry: string,
    featureToggleIds: TFieldToToggleMap,
    togglesResponse: TFeatureToggleMultiResponse,
    mechanicType: TMechanicType,
    skippableFields?: string[],
    isSteppedView = false
  ) {
    this.selectedCountry = selectedCountry;
    this.featureToggleIds = featureToggleIds;
    this.togglesResponse = togglesResponse;
    this.mechanicType = mechanicType;
    this.skippableFields = skippableFields;
    this.isSteppedView = isSteppedView;
  }

  getResponse(field: string): TFeatureToggleResponse {
    const toggleResponse = this.togglesResponse?.[this.featureToggleIds?.[field]];
    const skipCheckSkuParamsOrderConfig = this.skippableFields.includes(field);
    const getSkuParams = this.isSteppedView ? getSteppedDiscountColumns : getSkuParamOrders;
    if (
      skipCheckSkuParamsOrderConfig ||
      getSkuParams(this.selectedCountry, this.mechanicType).includes(field as TPromoDesignSkuField)
    ) {
      return toggleResponse;
    }
    return [false, false];
  }
}

export const useSkuDetailsKey = (
  personalizedKey: TPersonalizationType,
  mechanicsType: TMechanicType,
  isSteppedView = false,
  skippableFields: string[] = [SKU, PRODUCT_NAME]
): SkuDetailResponse => {
  const selectedCountry = useSelectedCountry();
  const fields = [
    SKU,
    PRODUCT_NAME,
    STEP_ID,
    MIN_DISCOUNT,
    MAX_DISCOUNT,
    MIN_QUANTITY,
    MAX_QUANTITY,
    DISCOUNT,
    MAX_POINTS,
    ALLOCATION,
    ORDER_AVAILABILITY,
    SKU_LIMIT,
    BUDGET_ID,
  ];
  const featureToggleIds = mapFieldsToFeatureToggles(fields, personalizedKey, isSteppedView);

  const featureTogglesResponse = useFeatureTogglesResponse(Object.values(featureToggleIds), {
    country: selectedCountry,
  });

  const featureToggleChecker = new FeatureToggleResponseChecker(
    selectedCountry,
    featureToggleIds,
    featureTogglesResponse,
    mechanicsType,
    skippableFields,
    isSteppedView
  );

  const skuDetailKeys: string[] = useMemo(() => {
    return fields.filter((field) => !!featureToggleChecker.getResponse(field)?.[0]);
  }, [featureTogglesResponse]);

  const isResponseLoading = () =>
    some(...fields.map((field) => !!featureToggleChecker.getResponse(field)?.[1]));

  return [skuDetailKeys, isResponseLoading()];
};
