import { useIntl } from 'react-intl';
import { useMemo } from 'react';

type TUseGetFormattedStrategyLabels = (selectedStrategies?: string[]) => string[];
export const useGetFormattedStrategyLabels: TUseGetFormattedStrategyLabels = (
  selectedStrategies = []
) => {
  const { formatMessage } = useIntl();
  return useMemo<string[]>(() => {
    return (selectedStrategies || []).map((code) =>
      formatMessage({
        id: `PROMOTION_STRATEGIES.${code}`,
      })
    );
  }, [selectedStrategies]);
};
