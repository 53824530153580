import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IBooleanPayloadAction,
  IDateRange,
  INumberPayloadAction,
  ISingleStrategy,
  IStrategy,
  IStringPayloadAction,
  ISubStrategySelection,
  TMechanicType,
  TMechanicTypeOption,
  ICoverImage,
} from '~/interfaces';
import { RootState } from '~/redux/store';

export type IAddStrategy = PayloadAction<ISingleStrategy>;
export type IRemoveStrategy = INumberPayloadAction;
export type IAddSubStrategy = PayloadAction<ISubStrategySelection>;
export type IRemoveSubStrategiesByStrategies = PayloadAction<ISubStrategySelection[]>;
export type ISetGlobalParameters = PayloadAction<IStrategy>;
export type ICoverAction = PayloadAction<ICoverImage[]>;
export type ISetDateRange = PayloadAction<IDateRange>;
export type ISetHasDismissedPromptForever = PayloadAction<{
  haveDismissedPromptForever: boolean;
}>;
export interface IStrategyState {
  dateSelection: IDateRange;
  isPersonalized: boolean | null;
  isLoading: boolean;
  selectedStrategies: ISingleStrategy[];
  selectedSubStrategies: ISubStrategySelection[];
  promoPrefix: string;
  title: string;
  campaignId: string;
  rewardsDescription: string;
  mechanicsType: TMechanicType;
  mechanicsTypeOption: TMechanicTypeOption;
  haveDismissedPromptForever: boolean;
  coverImage: ICoverImage[];
}

const todaysDate: string = new Date().toISOString().substring(0, 10);

const initialState: IStrategyState = {
  dateSelection: {
    startDate: '',
    endDate: '',
  },
  isPersonalized: null,
  isLoading: false,
  selectedStrategies: [],
  selectedSubStrategies: [],
  promoPrefix: '',
  title: '',
  campaignId: '',
  rewardsDescription: '',
  mechanicsType: '' as TMechanicType,
  mechanicsTypeOption: '' as TMechanicTypeOption,
  haveDismissedPromptForever: false,
  coverImage: [] as ICoverImage[],
};

const strategySlice = createSlice({
  name: 'strategySelection',
  initialState,
  reducers: {
    setMechanicsTypeOption: (state, action: IStringPayloadAction): IStrategyState => {
      return {
        ...state,
        mechanicsTypeOption: action.payload as TMechanicTypeOption,
        isPersonalized: null,
      };
    },
    setMechanicsType: (state, action: IStringPayloadAction): IStrategyState => {
      return {
        ...state,
        mechanicsType: action.payload as TMechanicType,
        mechanicsTypeOption: '' as TMechanicTypeOption,
        isPersonalized: null,
      };
    },
    setPromoPrefix: (state, action: IStringPayloadAction): IStrategyState => {
      return {
        ...state,
        promoPrefix: action.payload,
      };
    },
    setRewardsDescription: (state, action: IStringPayloadAction): IStrategyState => {
      return {
        ...state,
        rewardsDescription: action.payload,
      };
    },
    setTitle: (state, action: IStringPayloadAction): IStrategyState => {
      return {
        ...state,
        title: action.payload,
      };
    },
    setCampaignId: (state, action: IStringPayloadAction): IStrategyState => {
      return {
        ...state,
        campaignId: action.payload,
      };
    },
    setStrategyDates: (state, action: ISetDateRange): IStrategyState => {
      return {
        ...state,
        dateSelection: action.payload,
      };
    },
    setCoverImage: (state, action: ICoverAction): IStrategyState => {
      return {
        ...state,
        coverImage: action.payload,
      };
    },
    resetStrategyDates: (state): IStrategyState => {
      return {
        ...state,
        dateSelection: {
          startDate: todaysDate,
          endDate: todaysDate,
        },
      };
    },
    setIsGlobalLoading: (state, action: IBooleanPayloadAction): IStrategyState => {
      return {
        ...state,
        isLoading: action.payload,
      };
    },
    setPersonalization: (state, action: IBooleanPayloadAction): IStrategyState => {
      return {
        ...state,
        isPersonalized: action.payload,
      };
    },
    addStrategy: (state, action: IAddStrategy): IStrategyState => {
      return {
        ...state,
        selectedStrategies: [...state.selectedStrategies, action.payload],
      };
    },
    removeStrategy: (state, action: IRemoveStrategy): IStrategyState => {
      return {
        ...state,
        selectedStrategies: [
          ...state.selectedStrategies.slice(0, action.payload),
          ...state.selectedStrategies.slice(action.payload + 1),
        ],
      };
    },
    emptySubStrategies: (state): IStrategyState => {
      return {
        ...state,
        selectedSubStrategies: [],
      };
    },
    emptyStrategies: (state): IStrategyState => {
      return {
        ...state,
        selectedStrategies: [],
      };
    },
    addSubStrategy: (state, action: IAddSubStrategy): IStrategyState => {
      return {
        ...state,
        selectedSubStrategies: [...state.selectedSubStrategies, action.payload],
      };
    },
    removeSubStrategy: (state, action: IRemoveStrategy): IStrategyState => {
      return {
        ...state,
        selectedSubStrategies: [
          ...state.selectedSubStrategies.slice(0, action.payload),
          ...state.selectedSubStrategies.slice(action.payload + 1),
        ],
      };
    },
    removeSubStrategiesByStrategies: (
      state,
      action: IRemoveSubStrategiesByStrategies
    ): IStrategyState => {
      return {
        ...state,
        selectedSubStrategies: action.payload,
      };
    },
    setDismissPromptForever: (state, action: ISetHasDismissedPromptForever): IStrategyState => {
      return {
        ...state,
        ...action.payload,
      };
    },
    resetGlobalParameters: (): IStrategyState => {
      return {
        ...initialState,
      };
    },
  },
});

export const selectGlobalState = (state: RootState) => state.global;
export const selectMechanicsType = (state: RootState) => state.global?.mechanicsType;
export const selectMechanicsTypeOption = (state: RootState) => state.global.mechanicsTypeOption;
export const selectSelectedSubStrategies = (state: RootState) => state.global.selectedSubStrategies;
export const selectSelectedStrategies = (state: RootState) => state.global.selectedStrategies;
export const selectIsPersonalized = (state: RootState) => state.global.isPersonalized;
export const selectIsLoading = (state: RootState) => state.global.isLoading;

export const {
  setMechanicsTypeOption,
  setMechanicsType,
  setStrategyDates,
  resetStrategyDates,
  setPersonalization,
  setIsGlobalLoading,
  addStrategy,
  removeStrategy,
  emptySubStrategies,
  emptyStrategies,
  addSubStrategy,
  removeSubStrategy,
  removeSubStrategiesByStrategies,
  resetGlobalParameters,
  setPromoPrefix,
  setRewardsDescription,
  setTitle,
  setCampaignId,
  setDismissPromptForever,
  setCoverImage,
} = strategySlice.actions;
export default strategySlice.reducer;
