import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  styled,
  Typography,
} from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useIntl } from 'react-intl';
import { Table } from '@hexa-ui/components';
import { NumberFormatted } from '~/components';
import './LiftAccordion.css';
import { TLiftAccordion } from './LiftAccordion.types';
import { getTestIdAttr } from '~/utils';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '16px',
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: '20px',
    padding: '0 2px',
  },
}));

const LiftAccordion: TLiftAccordion = ({
  identifier,
  content = [],
  title,
  count,
  children,
  showCount = false,
  showValuesAsTable = false,
}) => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion elevation={0} square {...props} />
  ))(({ theme }) => ({
    width: '100%',
    border: `1px solid ${theme.palette.divider}`,
    minHeight: '52px',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));

  return (
    <div {...getTestIdAttr('LiftAccordion')} style={{ width: '100%' }}>
      {/* @ts-ignore */}
      <Accordion
        expanded={expanded === identifier}
        onChange={handleChange(identifier)}
        data-testid="accordion-container"
      >
        <AccordionSummary
          expandIcon={<ExpandMore className="accordion-icon" />}
          aria-controls={`${identifier}bh-content`}
          id={`${identifier}bh-header`}
          style={{ width: '100%' }}
        >
          <Typography className={classes.title}>
            {title}
            {count !== undefined && !!showCount && (
              <>
                &nbsp;&nbsp;
                <Typography component={'span'} color={'textSecondary'} variant={'subtitle2'}>
                  (<NumberFormatted value={Number(count)} />)
                </Typography>
              </>
            )}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {showValuesAsTable && content.length > 5 ? (
            <>
              <Table
                pagination={{
                  quantityIndicatorIntl: formatMessage({
                    id: 'PAGINATION.OF',
                    defaultMessage: 'of',
                  }),
                  pageSizeOptionsIntl: (numberOfItems) =>
                    formatMessage(
                      {
                        id: 'PAGINATION.ITEMS_PER_PAGE',
                        defaultMessage: 'Show {field} items per page',
                      },
                      { field: numberOfItems }
                    ),
                  pageSize: 5,
                }}
                tableHeight={'240px'}
                fixedHeader={true}
                cellHeight={'tight'}
                search={content.length > 20}
                columns={[
                  {
                    Header: title,
                    accessor: 'data',
                  },
                ]}
                data={content.map((item) => ({ data: item }))}
              />
            </>
          ) : (
            <Typography className="accordion-content">
              {content.map((item) => item).join(', ')}
            </Typography>
          )}
          {children}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default LiftAccordion;
