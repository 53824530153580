import { IFile } from '~/interfaces';
import { unique } from '~/utils';

export const hasDuplicateFile = (meta, files): boolean => {
  return files.some((file: IFile) => {
    return file.name === meta.name && file.size === meta.size;
  });
};

export const getRows = (x: ProgressEvent<FileReader>): string[] => {
  const target = x.currentTarget as FileReader;
  const result = target.result.toString();
  const currentValue = result.trim();
  return currentValue.split(/\r?\n/);
};

const FileHandler = async (
  file,
  files,
  formatMessage
): Promise<{
  fileSerialized: IFile;
  values: string[];
}> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(file);
    let isSingleColumn = false;
    reader.onload = (x: ProgressEvent<FileReader>) => {
      const rows = getRows(x);

      isSingleColumn = rows[0].split(',').length === 1;
      let isValidPocValues = true;
      if (isSingleColumn) {
        isValidPocValues = rows.map((row: string) => row.trim()).every((row: string) => !!row);
      }

      if (file.type === 'text/csv' && isSingleColumn && isValidPocValues) {
        if (
          !hasDuplicateFile(
            {
              name: file.name,
              size: file.size,
              content: rows,
            },
            files
          )
        ) {
          const fileSerialized = {
            path: file.path,
            name: file.name,
            size: file.size,
            lastModified: file.lastModified,
            content: rows,
          } as IFile;

          const uploadContent = rows.filter((item) => !!item);
          const uniquePocsInThisFile = unique(uploadContent);
          return resolve({
            fileSerialized,
            values: uniquePocsInThisFile as [],
          });
        } else {
          return reject({
            message: formatMessage(
              { id: 'POCS_FILTER.UPLOAD.DUPLICATE_FILE' },
              {
                fileName: file.name,
              }
            ),
            type: 'warning',
          });
        }
      } else {
        return reject({
          message: formatMessage(
            { id: 'POCS_FILTER.UPLOAD.FORMAT_ERROR_MESSAGE' },
            {
              fileName: file.name,
            }
          ),
          type: 'error',
        });
      }
    };
  });
};

export default FileHandler;
