import { NumberSchema, number } from 'yup';
import { ISchemaField, IValidationMessages } from '~/interfaces';

export const validatePercent = (
  val: ISchemaField,
  validationCallbacks: IValidationMessages
): NumberSchema => {
  let percentValidationType = number()
    .typeError(validationCallbacks.mustBeANumber(val.label))
    .min(0, validationCallbacks.lessThanMin(val.label, 0));

  if (val.max) {
    percentValidationType = percentValidationType.max(
      val.max,
      validationCallbacks.greaterThanMax(val.label, val.max)
    );
  } else {
    // Default Max 100
    percentValidationType = percentValidationType.max(
      100,
      validationCallbacks.greaterThanMax(val.label, 100)
    );
  }
  return percentValidationType;
};
