import { rest } from 'msw';
import { getApiHost } from '~/services/host/HostService';
import { productsFixture } from '~/mocks/fixtures/products';

const hostUrl = getApiHost();

export const productsHandler = [
  rest.get(`${hostUrl}/v1/products`, (_, res, ctx) => {
    return res(ctx.json({ ...productsFixture }));
  }),
];
