import {
  ACTIONS,
  CREATED_AT,
  PROMO_DESIGN_TITLE,
  DATE_RANGE,
  STATUS,
  CREATOR_NAME,
  PROMO_DESIGN_ID,
  MECHANIC_TYPE,
  PROMO_DESIGN_TITLE_DISPLAY,
} from '~/configs/promoListColumns';
import {
  SHOW_ACTIONS_COLUMN_ON_PROMO_DASHBOARD,
  SHOW_CREATED_AT_COLUMN_ON_PROMO_DASHBOARD,
  SHOW_PROMO_DESIGN_TITLE_COLUMN_ON_PROMO_DASHBOARD,
  SHOW_PROMO_DESIGN_TITLE_DISPLAY_COLUMN_ON_PROMO_DASHBOARD,
  SHOW_DATE_RANGE_COLUMN_ON_PROMO_DASHBOARD,
  SHOW_STATUS_COLUMN_ON_PROMO_DASHBOARD,
  SHOW_CREATOR_NAME_COLUMN_ON_PROMO_DASHBOARD,
  SHOW_PROMO_DESIGN_ID_COLUMN_ON_PROMO_DASHBOARD,
  SHOW_MECHANIC_TYPE_COLUMN_ON_PROMO_DASHBOARD,
} from '~/constants';
import useSelectedCountry from './useSelectedCountry';
import { useFeatureTogglesResponse } from './useFeatureToggle';
import { useMemo } from 'react';

enum PROMO_LIST_COLUMNS {
  ACTIONS = 'actions',
  CREATED_AT = 'createdAt',
  PROMO_DESIGN_TITLE = 'promoDesignTitle',
  PROMO_DESIGN_TITLE_DISPLAY = 'promoDesignTitleDisplay',
  DATE_RANGE = 'dateRange',
  STATUS = 'status',
  CREATOR_NAME = 'creatorName',
  PROMO_DESIGN_ID = 'promoDesignId',
  MECHANIC_TYPE = 'mechanicType',
}

const featureToggleColumnMapping = {
  [ACTIONS]: SHOW_ACTIONS_COLUMN_ON_PROMO_DASHBOARD,
  [CREATED_AT]: SHOW_CREATED_AT_COLUMN_ON_PROMO_DASHBOARD,
  [PROMO_DESIGN_TITLE]: SHOW_PROMO_DESIGN_TITLE_COLUMN_ON_PROMO_DASHBOARD,
  [PROMO_DESIGN_TITLE_DISPLAY]: SHOW_PROMO_DESIGN_TITLE_DISPLAY_COLUMN_ON_PROMO_DASHBOARD,
  [DATE_RANGE]: SHOW_DATE_RANGE_COLUMN_ON_PROMO_DASHBOARD,
  [STATUS]: SHOW_STATUS_COLUMN_ON_PROMO_DASHBOARD,
  [CREATOR_NAME]: SHOW_CREATOR_NAME_COLUMN_ON_PROMO_DASHBOARD,
  [PROMO_DESIGN_ID]: SHOW_PROMO_DESIGN_ID_COLUMN_ON_PROMO_DASHBOARD,
  [MECHANIC_TYPE]: SHOW_MECHANIC_TYPE_COLUMN_ON_PROMO_DASHBOARD,
} as {
  [key in PROMO_LIST_COLUMNS]: string;
};

export const usePromoListColumnsV2 = (): string[] => {
  const selectedCountry = useSelectedCountry();

  const featureTogglesResponse = useFeatureTogglesResponse(
    Object.values(featureToggleColumnMapping),
    { country: selectedCountry }
  );

  return useMemo<string[]>(() => {
    return Object.entries(featureToggleColumnMapping)
      .filter(([, featureToggle]) => {
        return !!featureTogglesResponse?.[featureToggle]?.[0];
      })
      .map(([potentialCol]) => potentialCol);
  }, [selectedCountry, JSON.stringify(featureTogglesResponse)]);
};
