import { FormattedMessage } from 'react-intl';
import { getTestIdAttr } from '~/utils';

export const Delete = (): JSX.Element => {
  return (
    <FormattedMessage
      {...getTestIdAttr('Delete')}
      id={'BUTTONS.DELETE'}
      defaultMessage={'Delete'}
    />
  );
};
