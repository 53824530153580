import { FormattedNumber } from 'react-intl';
import { getTestIdAttr } from '~/utils';
import { TNumberFormatted } from './Number.types';

const NumberFormatted: TNumberFormatted = ({ value, minDigits = 0, maxDigits = 2 }) => {
  return (
    <FormattedNumber
      {...getTestIdAttr('Number')}
      value={value}
      style={'decimal'}
      maximumFractionDigits={maxDigits}
      minimumFractionDigits={minDigits}
    />
  );
};

export default NumberFormatted;
