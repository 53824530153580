import { AlertOctagon } from '@hexa-ui/icons';
import './CustomError.css';
import { getTestIdAttr } from '~/utils';

interface ICustomLabelProps {
  errors?: string;
}

const CustomError = ({ errors }: ICustomLabelProps): JSX.Element => {
  return (
    <div
      {...getTestIdAttr('CustomError')}
      style={{ display: 'flex', flexDirection: 'column' }}
      className={`${!!errors && 'custom-formik-error-styles'}`}
    >
      {!!errors && (
        <span className="custom-formik-error">
          <AlertOctagon /> {errors}
        </span>
      )}
    </div>
  );
};

export default CustomError;
