import { ListItem } from '@hexa-ui/components';
import { User } from '@hexa-ui/icons';
import styled, { css } from 'styled-components';

interface ICustomerText {
  overshadow: string;
}

export const StyledForm = styled.div`
  .formik-button-container {
    margin-top: 10px;
  }
`;

export const StyledListItem = styled(ListItem)`
  &:hover {
    .categoryStatus {
      visibility: visible;
    }
  }
`;

export const ListContainer = styled.div`
  margin-top: 8px;
  max-width: 722px;
  min-height: 100px;
`;

export const Container = styled.div`
  min-height: 100px;
`;

export const CustomerContainer = styled.div<ICustomerText>`
  margin-left: 16px;
  ${({ overshadow }) =>
    overshadow === 'true' &&
    css`
      span {
        color: rgba(0, 0, 0, 0.3);
      }
    `}
`;

export const CustomerPhone = styled.span`
  margin-left: 6px;
  margin-right: 18px;
`;

export const CustomerEmail = styled.span`
  margin-left: 6px;
`;

export const Contact = styled.div`
  display: flex;
  align-items: center;
`;

export const CustomerName = styled.span`
  color: #212121;
  font-family: Work Sans, San Francisco, Roboto, Segoe UI, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
`;

export const CustomerHeader = styled.div`
  display: flex;
`;

export const CustomerContacts = styled.div`
  color: rgba(0, 0, 0, 0.55);
  font-family: Work Sans, San Francisco, Roboto, Segoe UI, Helvetica, sans-serif;
  font-size: 14px;
  letter-spacing: 0.1px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const CustomerIcon = styled(User)`
  color: #00a83b;
`;

export const List = styled.ul`
  list-style: none;
  margin: 8px 8px 0px 0px;
  padding: 0px 10px 0px 0px;
  height: inherit;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    border: none;
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #dfdede;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const ResultContainer = styled.div`
  display: block;
  margin: 8px 0px 0px 16px;
`;
