import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { getApiHost } from '~/services/host/HostService';
import { useAuthenticationService } from 'admin-portal-shared-services';
import store from '~/redux/store';

export interface IRequest<T = any> {
  url: string;
  body?: T;
  cancelToken?: any;
  options?: AxiosRequestConfig;
}

const initialAxios = Axios.create({
  baseURL: getApiHost(),
});

// eslint-disable-next-line react-hooks/rules-of-hooks
const authentication = useAuthenticationService();
const { user_country: userCountry } = authentication.getUserCountryAndLanguage();
authentication.enhancedAxios(initialAxios);

(initialAxios as any).interceptors.request.use(
  (config: AxiosRequestConfig) => {
    config.headers = {
      ...config.headers,
      requestTraceId: uuidv4(),
      country: store.getState().countries.selectedCountry ?? userCountry,
    };
    return config;
  },
  (error) => Promise.reject(error)
);

const Api = {
  post: <TRequest = any, TResponse = any>({
    url,
    body,
    cancelToken,
    options = {},
  }: IRequest<TRequest>): Promise<AxiosResponse<TResponse>> =>
    initialAxios.post(url, body, { ...options, cancelToken }).catch((error) => ({
      hasError: true,
      ...error,
    })),

  delete: ({ url, options = {} }: IRequest): Promise<any> =>
    initialAxios.delete(url, options).catch((error) => ({ hasError: true, ...error })),

  get: <T = any>({ url, options }: IRequest): Promise<AxiosResponse<T>> =>
    initialAxios.get(url, options).catch((error) => ({ hasError: true, ...error })),

  put: <TRequest = any, TResponse = any>({
    url,
    body,
    options = {},
  }: IRequest<TRequest>): Promise<AxiosResponse<TResponse>> =>
    initialAxios.put(url, body, options).catch((error) => ({
      hasError: true,
      ...error,
    })),

  patch: <T>({ url, body, options = {} }: IRequest): Promise<AxiosResponse<T>> =>
    initialAxios.patch(url, body, options).catch((error) => ({
      hasError: true,
      ...error,
    })),
};

export default Api;
