import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TToastType } from '~/interfaces';
import { RootState } from '~/redux/store';

export interface IToastProps {
  showToast: boolean;
  type: TToastType;
  status: number;
  error: boolean;
  customMessage: string;
  duration: number;
}

export interface IToastState {
  toast: IToastProps;
}

export type ISetToastAction = PayloadAction<{
  showToast: boolean;
  type: TToastType;
  status?: number;
  error?: boolean;
  customMessage?: string;
  duration?: number;
}>;

const initialState: IToastState = {
  toast: {
    showToast: false,
    type: 'warning' as TToastType,
    status: 200,
    error: false,
    customMessage: '',
    duration: 3000,
  },
};

const globalToastSlice = createSlice({
  name: 'globalToastHandler',
  initialState,
  reducers: {
    setToast: (state, action: ISetToastAction): IToastState => {
      return {
        toast: {
          ...state.toast,
          ...action.payload,
        },
      };
    },

    resetToast: (): IToastState => {
      return initialState;
    },
  },
});

export const selectGlobalToast = (state: RootState) => state.globalToast.toast;

export const { setToast, resetToast } = globalToastSlice.actions;
export default globalToastSlice.reducer;
