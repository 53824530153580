import { ENABLE_ADDITION_TO_VALUE_VALIDATION } from '~/constants/FeatureToggles';
import useSelectedCountry from './useSelectedCountry';
import { useFeatureTogglesResponse } from './useFeatureToggle';
import { useMemo } from 'react';
import { IFeatureToggleValidation } from '~/interfaces';

const isAdditionToValueValidationEnabled = 'isAdditionToValueValidationEnabled';

enum VALIDATION_FEATURE_TOGGLES {
  isAdditionToValueValidationEnabled = 'isAdditionToValueValidationEnabled',
}

export const featureToggleColumnMapping = {
  [isAdditionToValueValidationEnabled]: ENABLE_ADDITION_TO_VALUE_VALIDATION,
} as {
  [key in VALIDATION_FEATURE_TOGGLES]: string;
};

export const useValidationFeatureToggles = (): IFeatureToggleValidation => {
  const selectedCountry = useSelectedCountry();

  const featureTogglesResponse = useFeatureTogglesResponse(
    Object.values(featureToggleColumnMapping),
    { country: selectedCountry }
  );

  return useMemo<IFeatureToggleValidation>(() => {
    return Object.entries(featureToggleColumnMapping)
      .map((entry) => {
        return { [entry[0]]: featureTogglesResponse?.[entry[1]]?.[0] };
      })
      .reduce((obj, item) => {
        const key = Object.keys(item)?.[0];
        obj[key] = Object.values(item)?.[0] || false;
        return obj;
      }, {});
  }, [selectedCountry, JSON.stringify(featureTogglesResponse)]);
};
