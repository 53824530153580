import { AlertTriangle, Checkmark2 } from '@hexa-ui/icons';
import React from 'react';
import { getTestIdAttr } from '~/utils';
import { PocValidationIconComponent } from './PocValidationIcon.types';

export const PocValidationIcon: PocValidationIconComponent = ({
  invalidPocs = 0,
  uploadPocValues = 0,
  isValidating = true,
}) => {
  if (isValidating) {
    return null;
  }

  if (invalidPocs) {
    return (
      <>
        <AlertTriangle
          {...getTestIdAttr('PocValidationIcon')}
          fill={'gold'}
          style={{
            color: 'gold',
          }}
        />
      </>
    );
  }

  if (uploadPocValues) {
    return (
      <>
        <Checkmark2
          {...getTestIdAttr('PocValidationIcon')}
          fill={'green'}
          style={{
            color: 'green',
          }}
        />
      </>
    );
  }
  return null;
};
