import React, { useEffect, useState } from 'react';
import CustomLabel from '~/components/Form/components/CustomLabel/CustomLabel';
import CustomError from '~/components/Form/components/CustomError/CustomError';
import { Autocomplete } from '@material-ui/lab';
import { Chip, TextField } from '@material-ui/core';
import { useField } from 'formik';
import { makeStyles } from '@material-ui/styles';
import { getTestIdAttr, unique } from '~/utils';
import './TextWithTags.css';

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: '4px',
    '&.Mui-focused fieldset': {
      border: 'none',
    },
  },
  inputRoot: {
    width: '100%',
    borderRadius: '8px',
    backgroundColor: '#ffffff',
    minHeight: '48px',
    maxHeight: '200px',
    bordercColor: 'rgba(0, 0, 0, 0.15)',
    '&.Mui-focused': {
      border: '1px solid black',
    },
    overflow: 'auto',
    padding: '4px 65px 4px 4px !important',
  },
  tags: {
    borderRadius: '16px',
    backgroundColor: '#E9F1FF',
    fontFamily: 'Work Sans',
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: '16px',
    padding: '8px 8px 8px 0',
  },
  errorTag: {
    backgroundColor: 'rgb(201, 32, 29);',
    color: 'white',
  },
  fieldFocus: {
    '&.Mui-focused': {
      display: 'none',
    },
  },
}));

export const TextWithTags = ({
  label,
  field: { name, value, ...fieldProps },
  form,
  hint,
  optional = false,
  required,
  onChange,
  placeholder,
  submitTagOnKeyPress,
  showLabel = true,
  // enabled,
  fieldContainerStyle = {},
  hidden = false,
  validationinfo,
  ...props
}) => {
  const [inputVal, setInputVal] = useState<string>('');
  const classes = useStyles();

  const [, , helpers] = useField({
    ...props,
    name,
  });

  useEffect(() => {
    if (typeof value === 'string' && value.length) {
      helpers.setValue([value]);
    }
  }, []);

  useEffect(() => {
    setInputVal('');
    if (onChange) {
      onChange(value);
    }
  }, [value]);

  return (
    <div
      {...getTestIdAttr('TextWithTags')}
      className={`formik-input text-with-tags-container ${form?.errors?.[name] && 'error-border'}`}
      style={{
        ...(hidden && {
          display: 'none',
        }),
        ...fieldContainerStyle,
      }}
    >
      {showLabel && (
        <CustomLabel errors={form?.errors?.[name]} label={label} hint={hint} optional={!required} />
      )}
      <div className="text-with-tags-autocomplete">
        <Autocomplete
          multiple
          freeSolo
          options={[]}
          id={name}
          value={value}
          style={{ width: '100%' }}
          classes={{
            root: classes.root,
            inputRoot: classes.inputRoot,
            tag: classes.tags,
          }}
          inputValue={inputVal}
          onPaste={(e) => {
            e.preventDefault();
            const pastedValue = e.clipboardData.getData('text');
            const doesValIncludeDelimiter = [];

            submitTagOnKeyPress?.forEach(
              (delim) => pastedValue.includes(delim) && doesValIncludeDelimiter.push(delim)
            );

            if (doesValIncludeDelimiter.length) {
              const formatRegex = doesValIncludeDelimiter
                .map((delim) => {
                  if (delim === '\n' || delim === '\t') {
                    // Change single backslash into double back splash
                    // Needed to format our regex correctly.
                    return `[${delim.split('\\').join('\\\\')}]`;
                  } else {
                    return `[\\${delim}]`;
                  }
                })
                .join('|');
              const regexPattern = new RegExp(formatRegex + '+', 'g');
              const tempPastedVal = pastedValue.split(regexPattern);
              const sanitizeVal = tempPastedVal
                .map((val) => val.trim())
                .filter((val) => val.length);
              helpers.setValue(unique([...value, ...sanitizeVal]));
            } else {
              helpers.setValue(unique([...value, pastedValue]));
            }
          }}
          onInputChange={(e, v, r) => {
            if (r === 'clear') {
              helpers.setValue(unique([]));
            }

            if (e) {
              // @ts-ignore
              const val = e.target.value;
              const doesValIncludeDelimiter = [];

              submitTagOnKeyPress?.forEach(
                (delim) => val?.includes(delim) && doesValIncludeDelimiter.push(delim)
              );
              // Clear state when a delimiter in inputVal is present. We add value state in TextField.
              if (doesValIncludeDelimiter.length) {
                setInputVal('');
                // Set state if there are no delimiters presen
              } else if (val) {
                setInputVal(val);
                // Set state to empty string if input is empty
              } else {
                setInputVal('');
              }
            }
          }}
          onBlur={(e) => {
            // @ts-ignore
            const inputValue = e.target?.value?.trim();
            if (inputValue.length) {
              helpers.setValue(unique([...value, inputValue]));
            }
          }}
          onChange={(e, newvalue) => helpers.setValue(newvalue)}
          renderTags={(value, getTagProps) => {
            let tempVal = value;
            if (typeof tempVal === 'string') {
              tempVal = [tempVal];
            }
            return (
              <>
                {tempVal.map((option, index) => {
                  const isTagInvalid =
                    validationinfo.fields[name]?.invalidValues?.includes(option) || false;
                  return (
                    <Chip
                      key={`text-tags-${option}`}
                      classes={{
                        root: isTagInvalid && classes.errorTag,
                        deleteIcon: isTagInvalid && classes.errorTag,
                      }}
                      {...getTagProps({ index })}
                      label={option}
                      onDelete={() => {
                        const slicedVal = [...tempVal.slice(0, index), ...tempVal.slice(index + 1)];
                        helpers.setValue(slicedVal);
                      }}
                    />
                  );
                })}
              </>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...getTestIdAttr('TextWithTagsTextField')}
              {...params}
              variant="outlined"
              label=""
              className="text-with-tags-autocomplete-input"
              classes={{
                root: classes.fieldFocus,
              }}
              placeholder={value?.length ? '' : placeholder}
              // Once a delimiter is detected, we add the value to formik state
              onKeyDown={(e) => {
                const keyPressed = e?.key;
                // @ts-ignore
                const val = e?.target?.value;
                if (submitTagOnKeyPress?.includes(keyPressed) && val) {
                  const tempVal = val.split(',');
                  helpers.setValue(unique([...value, ...tempVal]));
                }
                if (keyPressed === 'Enter' && val) {
                  helpers.setValue(unique([...value, val]));
                }
              }}
            />
          )}
          {...props}
        />
      </div>
      <CustomError errors={form?.errors?.[name]} />
    </div>
  );
};
