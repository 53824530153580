import { ISchema, ISchemaField } from '~/interfaces';

export const getDefaultValues = (
  schema: ISchema
): {
  [key: string]: any;
} => {
  return schema
    .filter((val: ISchemaField) => !val.condition)
    .reduce((acc: { [key: string]: any }, val: ISchemaField) => {
      let defaultValue = val.default;
      if (!defaultValue) {
        switch (val.componentType) {
          case 'text':
          case 'textarea':
          case 'select':
          case 'radioGroup':
            defaultValue = '';
            break;
          case 'checkbox':
            defaultValue = false;
            break;
          case 'checkboxgroup':
            defaultValue = { checked: [] };
            break;
          default:
            defaultValue = '';
        }
      }

      return {
        ...acc,
        [val.name]: val.defaultValue || defaultValue,
      };
    }, {});
};
