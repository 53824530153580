import { ClipboardEvent } from 'react';

const handlePaste = (e: ClipboardEvent, helpers, regMatch) => {
  e.preventDefault();

  const extractPastedValue = e.clipboardData.getData('text');
  const getFormattedValue = (extractPastedValue?.split(/[, ]|,|[\n,]+/) || [])
    .filter((item) => item.trim())
    ?.join('');
  const numberValues = getFormattedValue.match(regMatch)?.join('');
  if (numberValues !== undefined) {
    helpers.setValue(numberValues);
  }
};

export default handlePaste;
