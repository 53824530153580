import { formatRelative } from 'date-fns';
import React from 'react';
import { TFormatRelative } from './FormatRelative.types';

export const FormatRelative: TFormatRelative = ({ value, userLocale }) => {
  return (
    <>
      {formatRelative(new Date(value), new Date(), {
        locale: userLocale,
      })}
    </>
  );
};
