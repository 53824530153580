import Bff from '~/Api/Bff';
import { IPromoDesignsResponse } from '@moneycomb/mcomb-types/response';
import { AxiosResponse, AxiosError } from 'axios';
import { useMutation } from '@tanstack/react-query';
import { checkError, setToastFromResponse } from '~/redux/saga/sagaActions';
import { useAppDispatch } from '~/hooks';
import { downloadFromAPI } from '~/utils';
import { STATUS_200 } from '~/constants';

export const downloadPromo = (payload: string[]): Promise<AxiosResponse<IPromoDesignsResponse>> => {
  return Bff.post<
    {
      promoDesignIds: string[];
    },
    IPromoDesignsResponse
  >({
    url: `/v1/promo-designs/download`,
    body: {
      promoDesignIds: payload,
    },
  }).then((response) => response);
};

export const useDownloadPromo = () => {
  const dispatch = useAppDispatch();
  return useMutation(downloadPromo, {
    onError: (e: AxiosError) => dispatch(setToastFromResponse(e)),
    onSuccess: (response) => {
      const { status, data, response: downloadResponse } = response || {};
      if (status === STATUS_200 || downloadResponse?.status === STATUS_200) {
        downloadFromAPI(data, 'PromoDesigns');
      } else {
        dispatch(checkError(response));
      }
    },
  });
};
