import formatDate from './formatDate';
import { TFormatDateTypes } from '~/interfaces';

const isSameYear = (startDate: string, endDate: string): boolean => {
  return (
    new Date(`${startDate}T00:00:00.000Z`).getUTCFullYear() ===
    new Date(`${endDate}T00:00:00.000Z`).getUTCFullYear()
  );
};
const getDateRange = (
  startDate: string,
  endDate: string,
  localeCode: string,
  useCase: TFormatDateTypes = 'DEFAULT',
  isHideCurrentYear = false
): string => {
  const isSelectedDateInCurrentYear = (date: string): boolean =>
    new Date(date)?.getFullYear() === new Date()?.getFullYear();

  const bothInSameYear = isSameYear(startDate, endDate);

  const hideStartDateYear =
    bothInSameYear || (isHideCurrentYear && isSelectedDateInCurrentYear(startDate));
  const hideEndDateYear = bothInSameYear
    ? isHideCurrentYear && isSelectedDateInCurrentYear(endDate)
    : false;

  const formattedStartDate = formatDate(startDate, localeCode, useCase, hideStartDateYear);
  const formattedEndDate = formatDate(endDate, localeCode, useCase, hideEndDateYear);
  return `${formattedStartDate} - ${formattedEndDate}`;
};

export default getDateRange;
