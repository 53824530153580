import { Container, Item } from '@admin-portal-shared-components/grid';
import { Heading } from '@admin-portal-shared-components/typography';
import React, { useState } from 'react';
import { FormattedDisplayName, FormattedMessage } from 'react-intl';
import {
  CountryFlagIcon,
  CountrySelector,
  RequestSupportModal,
  SetUserProfileOption,
} from '~/components';
import { getTestIdAttr } from '~/utils';
import { useInitializeSidebar, useSelectedCountry } from '~/hooks';
import { TFlagCountry } from '~/interfaces';
import { TCommonLayoutComponent } from '~/components/CommonTopLayout/CommonTopLayout.types';
import './CommonTopLayout.css';

const CommonLayout: TCommonLayoutComponent = ({
  formatMessageId,
  children,
  isCountrySelectionReadOnly,
}) => {
  const selectedCountry = useSelectedCountry();

  const [supportModalOpen, setSupportModalOpen] = useState<boolean>(false);
  useInitializeSidebar({
    openSupportModal: setSupportModalOpen,
  });

  return (
    <>
      <SetUserProfileOption />
      <div {...getTestIdAttr('CommonLayout')} className="common-layout-wrapper">
        <Container sidebar type="fluid" id="common-container">
          {isCountrySelectionReadOnly && (
            <Item
              {...getTestIdAttr('CountrySelectionReadOnly')}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="country-read-wrapper"
            >
              <div className="country-subwrapper">
                <CountryFlagIcon country={selectedCountry as TFlagCountry} />
                <Heading size="H5">
                  <FormattedDisplayName type={'region'} value={selectedCountry} />
                </Heading>
              </div>
            </Item>
          )}
          <Item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Heading size={'H2'} className="align-center" data-testid="page-header">
              <FormattedMessage id={formatMessageId} />
            </Heading>
          </Item>
          {!isCountrySelectionReadOnly && (
            <Item
              {...getTestIdAttr('CountrySelectionWrapper')}
              xs={6}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              className="country-selection-wrapper"
            >
              <CountrySelector />
            </Item>
          )}
          {children && (
            <Item xs={6} sm={6} md={6} lg={6} xl={6} className="child-wrapper">
              {!!selectedCountry && children}
            </Item>
          )}
          <RequestSupportModal
            open={supportModalOpen}
            setOpen={setSupportModalOpen}
            promoDesign={null}
          />
        </Container>
      </div>
    </>
  );
};
export default React.memo<TCommonLayoutComponent>(CommonLayout);
