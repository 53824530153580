import { formatDuration, intervalToDuration, setHours, differenceInDays } from 'date-fns';

export const customFormatDuration = (start: Date, end: Date, userLocale: Locale): string => {
  try {
    const newStart = setHours(new Date(start), 0);
    const newEnd = setHours(new Date(end), 0);

    const durations = intervalToDuration({ start: newStart, end: newEnd });
    return formatDuration(durations, {
      zero: false,
      locale: userLocale,
    });
  } catch (e) {
    return '';
  }
};

export const calculateDaysBetween = (start: Date, end: Date): number => {
  try {
    const newStart = setHours(new Date(start), 0);
    const newEnd = setHours(new Date(end), 0);

    return differenceInDays(newEnd, newStart);
  } catch (e) {
    return null;
  }
};
