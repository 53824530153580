export const promoDesignsFixturePage0 = {
  status: '200 OK',
  data: {
    data: [
      {
        promoDesignId: '6399e397826bd109d9471395',
        promoDesignTitle: 'Dec 14 2022 Introduce Promotion',
        promoPrefix: '',
        country: 'BR',
        creatorEmail: 'dhiego.bastosdemoura-ext@ab-inbev.com',
        creatorId: '46555026-d8f1-4b59-9362-fb28f3928bf4',
        creatorName: 'New User',
        status: 'DESIGNED',
        createdAt: '2022-12-14T14:54:15.391',
        periodStartDate: '2022-12-14T00:00:00',
        periodEndDate: '2023-01-07T00:00:00',
        lastUpdatedTimestamp: '2022-12-14T14:54:15.469',
        mechanicType: 'DISCOUNT',
        mechanicTypeOption: 'discount_percent',
        promoDesignTitleDisplay: 'Dec 14 2022 Introduce Promotion',
      },
      {
        promoDesignId: '6399e3725b8a337d8fad4301',
        promoDesignTitle: 'Dec 14 2022 Sustain Promotion',
        promoPrefix: '',
        country: 'BR',
        creatorEmail: 'dhiego.bastosdemoura-ext@ab-inbev.com',
        creatorId: '46555026-d8f1-4b59-9362-fb28f3928bf4',
        creatorName: 'New User',
        status: 'DESIGNED',
        createdAt: '2022-12-14T14:53:38.901',
        periodStartDate: '2023-01-01T00:00:00',
        periodEndDate: '2023-01-08T00:00:00',
        lastUpdatedTimestamp: '2022-12-14T14:53:39.045',
        mechanicType: 'DISCOUNT',
        mechanicTypeOption: 'discount_percent',
        promoDesignTitleDisplay: 'Dec 14 2022 Sustain Promotion',
      },
      {
        promoDesignId: '639939f9d6c6ea2238d07618',
        promoDesignTitle: 'Dec 14 2022 Introduce Promotion',
        promoPrefix: '',
        country: 'BR',
        creatorEmail: 'Allen.Gonzales-ext@ab-Inbev.com',
        creatorId: '1e1c907f-97df-4dc3-af1c-1f24b39ad583',
        creatorName: 'Allen Gonzales',
        status: 'DESIGNED',
        createdAt: '2022-12-14T02:50:33.635',
        periodStartDate: '2022-12-14T00:00:00',
        periodEndDate: '2022-12-14T00:00:00',
        lastUpdatedTimestamp: '2022-12-14T02:50:33.656',
        mechanicType: 'DISCOUNT',
        mechanicTypeOption: 'discount_percent',
        promoDesignTitleDisplay: 'Dec 14 2022 Introduce Promotion',
      },
      {
        promoDesignId: '639938270280b5339d8f218e',
        promoDesignTitle: 'Dec 14 2022 Introduce Promotion',
        promoPrefix: '',
        country: 'BR',
        creatorEmail: 'Allen.Gonzales-ext@ab-Inbev.com',
        creatorId: '1e1c907f-97df-4dc3-af1c-1f24b39ad583',
        creatorName: 'Allen Gonzales',
        status: 'DESIGNED',
        createdAt: '2022-12-14T02:42:47.686',
        periodStartDate: '2022-12-14T00:00:00',
        periodEndDate: '2022-12-14T00:00:00',
        lastUpdatedTimestamp: '2022-12-14T02:42:47.838',
        mechanicType: 'DISCOUNT',
        mechanicTypeOption: 'discount_percent',
        promoDesignTitleDisplay: 'Dec 14 2022 Introduce Promotion',
      },
      {
        promoDesignId: '63990c0c5b8a337d8fad42fe',
        promoDesignTitle: 'Dec 13 2022 Introduce Promotion',
        promoPrefix: '',
        country: 'BR',
        creatorEmail: 'Hemlata.Yadav-ext@ab-inbev.com',
        creatorId: '6681ac49-31ad-45e7-9e3c-73a424f520a9',
        creatorName: 'New User',
        status: 'COMPLETE',
        createdAt: '2022-12-13T23:34:36.914',
        periodStartDate: '2022-12-13T00:00:00',
        periodEndDate: '2022-12-13T00:00:00',
        lastUpdatedTimestamp: '2022-12-14T01:01:03.305',
        mechanicType: 'DISCOUNT',
        mechanicTypeOption: 'discount_percent',
        promoDesignTitleDisplay: 'Dec 13 2022 Introduce Promotion',
      },
    ],
    pagination: {
      page: 0,
      size: 5,
      totalElements: 3353,
      totalPages: 671,
    },
  },
};

export const promoDesignsFixturePage1 = {
  status: '200 OK',
  data: {
    data: [
      {
        promoDesignId: '6398f5a2d6c6ea2238d0760a',
        promoDesignTitle: 'Dec 13 2022 Introduce Promotion',
        promoPrefix: 'Personalized-D-BR-SIT-FE',
        country: 'BR',
        creatorEmail: 'uzziel.sierra-ext@ab-inbev.com',
        creatorId: '75dca81a-5651-4a40-91f2-14e477a751ea',
        creatorName: 'Uzziel Sierra',
        status: 'PENDING_DELETION',
        createdAt: '2022-12-13T21:58:58.011',
        periodStartDate: '2022-12-13T00:00:00',
        periodEndDate: '2022-12-28T00:00:00',
        lastUpdatedTimestamp: '2022-12-13T22:09:40.321',
        mechanicType: 'DISCOUNT',
        mechanicTypeOption: 'discount_percent',
        promoDesignTitleDisplay: 'Personalized-D-BR-SIT-FE',
      },
      {
        promoDesignId: '6398f5a1826bd109d9471388',
        promoDesignTitle: 'Dec 13 2022 Introduce Promotion',
        promoPrefix: 'Personalized-D-BR-SIT-FE',
        country: 'BR',
        creatorEmail: 'uzziel.sierra-ext@ab-inbev.com',
        creatorId: '75dca81a-5651-4a40-91f2-14e477a751ea',
        creatorName: 'Uzziel Sierra',
        status: 'PENDING_DELETION',
        createdAt: '2022-12-13T21:58:57.254',
        periodStartDate: '2022-12-13T00:00:00',
        periodEndDate: '2022-12-28T00:00:00',
        lastUpdatedTimestamp: '2022-12-13T22:09:44.238',
        mechanicType: 'DISCOUNT',
        mechanicTypeOption: 'discount_percent',
        promoDesignTitleDisplay: 'Personalized-D-BR-SIT-FE',
      },
      {
        promoDesignId: '6398f58562e53f2a658e5ba4',
        promoDesignTitle: 'Dec 13 2022 Introduce Promotion',
        promoPrefix: 'Personalized-D-BR-SIT-FE',
        country: 'BR',
        creatorEmail: 'uzziel.sierra-ext@ab-inbev.com',
        creatorId: '75dca81a-5651-4a40-91f2-14e477a751ea',
        creatorName: 'Uzziel Sierra',
        status: 'PENDING_DELETION',
        createdAt: '2022-12-13T21:58:29.497',
        periodStartDate: '2022-12-13T00:00:00',
        periodEndDate: '2022-12-28T00:00:00',
        lastUpdatedTimestamp: '2022-12-13T22:09:47.856',
        mechanicType: 'DISCOUNT',
        mechanicTypeOption: 'discount_percent',
        promoDesignTitleDisplay: 'Personalized-D-BR-SIT-FE',
      },
      {
        promoDesignId: '6398f581826bd109d9471383',
        promoDesignTitle: 'Dec 13 2022 Introduce Promotion',
        promoPrefix: 'Personalized-D-BR-SIT-FE',
        country: 'BR',
        creatorEmail: 'uzziel.sierra-ext@ab-inbev.com',
        creatorId: '75dca81a-5651-4a40-91f2-14e477a751ea',
        creatorName: 'Uzziel Sierra',
        status: 'PENDING_DELETION',
        createdAt: '2022-12-13T21:58:25.433',
        periodStartDate: '2022-12-13T00:00:00',
        periodEndDate: '2022-12-28T00:00:00',
        lastUpdatedTimestamp: '2022-12-13T22:09:51.494',
        mechanicType: 'DISCOUNT',
        mechanicTypeOption: 'discount_percent',
        promoDesignTitleDisplay: 'Personalized-D-BR-SIT-FE',
      },
      {
        promoDesignId: '6398f5807cd05555fa4abcf1',
        promoDesignTitle: 'Dec 13 2022 Introduce Promotion',
        promoPrefix: 'Personalized-D-BR-SIT-FE',
        country: 'BR',
        creatorEmail: 'uzziel.sierra-ext@ab-inbev.com',
        creatorId: '75dca81a-5651-4a40-91f2-14e477a751ea',
        creatorName: 'Uzziel Sierra',
        status: 'LIVE',
        createdAt: '2022-12-13T21:58:24.681',
        periodStartDate: '2022-12-13T00:00:00',
        periodEndDate: '2022-12-28T00:00:00',
        lastUpdatedTimestamp: '2022-12-14T01:01:02.934',
        mechanicType: 'DISCOUNT',
        mechanicTypeOption: 'discount_percent',
        promoDesignTitleDisplay: 'Personalized-D-BR-SIT-FE',
      },
    ],
    pagination: {
      page: 1,
      size: 5,
      totalElements: 3353,
      totalPages: 671,
    },
  },
};
