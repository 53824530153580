/**
 * Use this file to configure the order in which the fields appear
 * Eventually we will move this to a different data source
 * The code is NOT dependent upon all fields being present in this list
 * If a field is sent from the server that is not in this list, it will simply be added later in the form
 */
import {
  TMechanicType,
  TPromoDesignSkuField,
  TConfigArrayWithDefault,
  TConfigObjectWithDefault,
} from '~/interfaces';

import {
  MIN_QUANTITY,
  MAX_QUANTITY,
  DISCOUNT,
  MIN_PRICE,
  MAX_PRICE,
  MIN_DISCOUNT,
  MAX_DISCOUNT,
  ALLOCATION,
  ORDER_AVAILABILITY,
  SKU_LIMIT,
  BUDGET_ID,
  MAX_POINTS,
} from '~/constants/skuParams';

export const DEFAULT_SKU_PARAMS_ORDER_STEPPED: TPromoDesignSkuField[] = [
  MIN_QUANTITY,
  MAX_QUANTITY,
  DISCOUNT,
];

export const SKU_PARAMS_ORDER_STEPPED: TConfigArrayWithDefault<TPromoDesignSkuField> = {
  DEFAULT: [...DEFAULT_SKU_PARAMS_ORDER_STEPPED],
  BR: [...DEFAULT_SKU_PARAMS_ORDER_STEPPED],
};

const SKU_PARAMS_ORDER_DEFAULT: TPromoDesignSkuField[] = [
  MIN_PRICE,
  MAX_PRICE,
  MIN_DISCOUNT,
  MAX_DISCOUNT,
  MIN_QUANTITY,
  MAX_QUANTITY,
  DISCOUNT,
  ALLOCATION,
  ORDER_AVAILABILITY,
  SKU_LIMIT,
  BUDGET_ID,
];

export const SKU_PARAMS_ORDER: TConfigObjectWithDefault<TMechanicType, TPromoDesignSkuField> = {
  BR: {
    STEPPED_DISCOUNT: [ALLOCATION, ORDER_AVAILABILITY, SKU_LIMIT, BUDGET_ID],
    INTERACTIVE_COMBO: SKU_PARAMS_ORDER_DEFAULT,
    DEFAULT: SKU_PARAMS_ORDER_DEFAULT,
  },
  AR: {
    STEPPED_DISCOUNT: [ALLOCATION, BUDGET_ID],
    INTERACTIVE_COMBO: SKU_PARAMS_ORDER_DEFAULT,
    DEFAULT: SKU_PARAMS_ORDER_DEFAULT,
  },
  CO: {
    REWARDS_CHALLENGE: [
      MIN_DISCOUNT,
      MAX_DISCOUNT,
      MIN_QUANTITY,
      MAX_QUANTITY,
      MAX_POINTS,
      ALLOCATION,
    ],
    DISCOUNT: [
      MIN_DISCOUNT,
      MAX_DISCOUNT,
      MIN_QUANTITY,
      MAX_QUANTITY,
      ALLOCATION,
      ORDER_AVAILABILITY,
      SKU_LIMIT,
      DISCOUNT,
    ],
    DEFAULT: SKU_PARAMS_ORDER_DEFAULT,
  },
  DEFAULT: {
    DEFAULT: SKU_PARAMS_ORDER_DEFAULT,
  },
};

export const getSkuParamOrders = (
  country: string,
  mechanicType: TMechanicType
): TPromoDesignSkuField[] => {
  return SKU_PARAMS_ORDER?.[country]?.[mechanicType] ?? SKU_PARAMS_ORDER?.DEFAULT?.DEFAULT;
};

export const getSkuParamOrdersStepped = (country: string): TPromoDesignSkuField[] => {
  return SKU_PARAMS_ORDER_STEPPED?.[country] ?? SKU_PARAMS_ORDER_STEPPED?.DEFAULT;
};
