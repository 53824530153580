import { useMutation } from '@tanstack/react-query';
import Bff from '~/Api/Bff';
import { ICreateSupportTicketGeneric } from '~/interfaces';
import { StoreState } from '~/redux/store';
interface TCreateSupportTicketGeneric {
  summary: ICreateSupportTicketGeneric['summary'];
  description: ICreateSupportTicketGeneric['description'];
  state?: StoreState;
  reactQuery?: ICreateSupportTicketGeneric['reactQuery'];
}

export const createSupportTicketGeneric = async ({
  summary,
  description,
  state,
  reactQuery,
}: TCreateSupportTicketGeneric): Promise<any> => {
  const response = await Bff.post<{
    summary: string;
    description: string;
    state?: typeof state;
    reactQuery?: typeof reactQuery;
  }>({
    url: `/v1/support/ticket/generic`,
    body: {
      description,
      summary,
      state,
      reactQuery,
    } as ICreateSupportTicketGeneric,
  });

  return response;
};

export const useCreateSupportTicketGeneric = () => useMutation(createSupportTicketGeneric, {});
