import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { LIFT_ROUTES } from '~/constants';

import LiftLazyLoad from './components/Form/components/LiftLazyLoad/LiftLazyLoad';

const DesignPage = lazy(() => import('./pages/DesignPage'));
const Homepage = lazy(() => import('./pages/Homepage/Homepage'));
const SupportPage = lazy(() => import('./pages/SupportPage/SupportPage'));
const UserManagementPage = lazy(() => import('./pages/UserManagementPage'));
const TogglesPage = lazy(() => import('./pages/TogglesPage/TogglesPage'));
const AdminPage = lazy(() => import('./pages/AdminPage/AdminPage'));

const Router = (): JSX.Element => {
  return (
    <LiftLazyLoad
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '150px',
      }}
    >
      <Routes>
        <Route path={LIFT_ROUTES.HOME} element={<Homepage />} />
        <Route path={LIFT_ROUTES.DESIGN} element={<DesignPage />} />
        <Route path={LIFT_ROUTES.SUPPORT} element={<SupportPage />} />
        <Route path={LIFT_ROUTES.USER_MANAGEMENT} element={<UserManagementPage />} />
        <Route path={LIFT_ROUTES.TOGGLES} element={<TogglesPage />} />
        <Route path={LIFT_ROUTES.ADMIN} element={<AdminPage />} />
      </Routes>
    </LiftLazyLoad>
  );
};
export default Router;
