import { usePreferredLanguageV2 } from 'admin-portal-shared-services';
import formatValidationMessages from '~/utils/formatValidationMessages';
import { useIntl } from 'react-intl';
import { validateDatePicker } from '~/components/Form/helpers/validators';
import { every, getMaxPromotionStartDate } from '~/utils';
import { useAppSelector } from '~/hooks/app';
import { selectGlobalState } from '~/redux/slices';
import { useGetMinDesignDate } from '~/hooks/useGetMinDesignDate';
import { useGlobalMaxPromotionEndDate } from '~/hooks/index';
import { IDateRange } from '~/interfaces';
import { DateSchema } from 'yup';
import convertDateStringToNumber from '~/utils/convertStringDateToNumber';

export type TUseIsDateRangeValid = (props: { formId: string }) => {
  isValid: boolean;
  errors: string[];
};

export const hasStartDate = (dateSelection: IDateRange): boolean => {
  return !!dateSelection.startDate;
};
export const hasEndDate = (dateSelection: IDateRange): boolean => {
  return !!dateSelection.endDate;
};

export const hasNoErrors = (errors: string[] = []): boolean => {
  return !Array.isArray(errors) || errors.length === 0;
};

export const hasValidStartDate = (dateSelection: IDateRange, minDate: string): boolean => {
  return (
    hasStartDate(dateSelection) &&
    convertDateStringToNumber(dateSelection.startDate) >= convertDateStringToNumber(minDate)
  );
};

export const isEndDateOnOrAfterStartDate = (dateSelection: IDateRange): boolean => {
  return (
    convertDateStringToNumber(dateSelection.endDate) >=
    convertDateStringToNumber(dateSelection.startDate)
  );
};

export const hasValidEndDate = (dateSelection: IDateRange, minDate: string): boolean => {
  return (
    hasEndDate(dateSelection) &&
    convertDateStringToNumber(dateSelection.endDate) >= convertDateStringToNumber(minDate)
  );
};

export const appendErrors = (validator: DateSchema, value?: string, errors = []): void => {
  try {
    validator.validateSync(value || undefined);
  } catch (e) {
    errors.push(...e.errors);
  }
};

export const useIsDateRangeValid: TUseIsDateRangeValid = ({ formId }) => {
  const { dateSelection } = useAppSelector(selectGlobalState);
  const { minDate } = useGetMinDesignDate();
  const { preferredLanguage: localeCode } = usePreferredLanguageV2();
  const validationCallbacks = formatValidationMessages(useIntl(), formId);
  const maxEndDate = useGlobalMaxPromotionEndDate(dateSelection);
  const { formatMessage } = useIntl();

  const [startDateValidator, endDateValidator]: DateSchema[] = [
    {
      minDate,
      maxDate: getMaxPromotionStartDate(),
      name: 'startDate',
      label: formatMessage({
        id: 'DATE_PICKER_LABELS.START_DATE',
        defaultMessage: 'Start date',
      }),
    },
    {
      minDate: dateSelection.startDate,
      maxDate: maxEndDate,
      name: 'endDate',
      label: formatMessage({
        id: 'DATE_PICKER_LABELS.END_DATE',
        defaultMessage: 'End date',
      }),
    },
  ].map((values) => {
    let validator = validateDatePicker(
      {
        minDate: values.minDate,
        maxDate: values.maxDate,
        componentType: 'datePicker',
        name: values.name,
        onOptionSelect(_value: any): void {},
        options: undefined,
        type: undefined,
        required: true,
        label: values.label,
      },
      validationCallbacks,
      localeCode
    );
    validator = validator.required(validationCallbacks.isRequired(values.label));
    return validator;
  });

  const errors = [];
  appendErrors(startDateValidator, dateSelection.startDate, errors);
  appendErrors(endDateValidator, dateSelection.endDate, errors);

  const isValid = every(
    hasNoErrors(errors),
    hasValidStartDate(dateSelection, minDate),
    hasValidEndDate(dateSelection, minDate),
    isEndDateOnOrAfterStartDate(dateSelection)
  );

  return {
    isValid,
    errors,
  };
};

export default useIsDateRangeValid;
