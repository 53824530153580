import React from 'react';
import { getLinkToJiraIssue, getTestIdAttr } from '~/utils';

export const HrefToJiraIssue = ({ value }: { value: string }): JSX.Element => {
  return (
    <>
      <a {...getTestIdAttr('HrefToJiraIssue')} href={getLinkToJiraIssue(value)} target={'_blank'}>
        <b>{value}</b>
      </a>
    </>
  );
};
