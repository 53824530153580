import { useFeatureTogglesResponse, useSelectedCountry } from '~/hooks';
import { SHOW_NON_PERSONALIZED_TOGGLE, SHOW_PERSONALIZED_TOGGLE } from '~/constants';
import { useMemo } from 'react';

export const usePersonalizationToggles = (): {
  isLoadingFeatureToggles: boolean;
  isPersonalizedAllowed: boolean;
  isNonPersonalizedAllowed: boolean;
} => {
  const selectedCountry = useSelectedCountry();
  const featureToggles = useFeatureTogglesResponse(
    [SHOW_PERSONALIZED_TOGGLE, SHOW_NON_PERSONALIZED_TOGGLE],
    {
      country: selectedCountry,
    }
  );

  const [showPersonalizedToggle, loadingShowPersonalizedToggle] =
    featureToggles[SHOW_PERSONALIZED_TOGGLE];

  const [showNonPersonalizedToggle, loadingShowNonPersonalizedToggle] =
    featureToggles[SHOW_NON_PERSONALIZED_TOGGLE];

  const isLoadingFeatureToggles: boolean = useMemo(() => {
    return loadingShowPersonalizedToggle || loadingShowNonPersonalizedToggle;
  }, [loadingShowPersonalizedToggle, loadingShowNonPersonalizedToggle]);

  return useMemo(() => {
    return {
      isLoadingFeatureToggles,
      isPersonalizedAllowed: showPersonalizedToggle,
      isNonPersonalizedAllowed: showNonPersonalizedToggle,
    };
  }, [isLoadingFeatureToggles, showPersonalizedToggle, showNonPersonalizedToggle]);
};
