import { useMutation } from '@tanstack/react-query';
import Bff from '~/Api/Bff';
import { StoreState } from '~/redux/store';

interface ICreateSupportTicketPromoDesign {
  promoDesignId: string;
  description: string;
  state?: StoreState;
  reactQuery?: any;
}

export const createSupportTicketPromoDesign = async ({
  promoDesignId,
  description,
  state = undefined,
  reactQuery = undefined,
}: ICreateSupportTicketPromoDesign): Promise<any> => {
  const response = await Bff.post<{
    description: string;
    state: typeof state;
    reactQuery: typeof reactQuery;
  }>({
    url: `/v1/support/ticket/promodesign/${promoDesignId}`,
    body: {
      description,
      state,
      reactQuery,
    },
  });

  return response;
};

export const useCreateSupportTicketPromoDesign = () =>
  useMutation(createSupportTicketPromoDesign, {});
