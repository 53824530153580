import { FormattedMessage, useIntl } from 'react-intl';
import { getTestIdAttr } from '~/utils';
import { TPocFilterLabel, TPocFilterLabelProps } from './PocFilterLabel.types';

const getI18nProps = (
  context: TPocFilterLabelProps
): {
  id: string;
  defaultMessage: string;
} => {
  const filtersMap = context.countryConfig?.POC_FILTER;
  const key = filtersMap?.[context.identifier];
  return {
    id: `POC_FILTERS.${key}`,
    defaultMessage: context.identifier,
  };
};

export const PocFilterText = (context: TPocFilterLabelProps): string => {
  const { formatMessage } = useIntl();
  return formatMessage(getI18nProps(context));
};

export const PocFilterLabel: TPocFilterLabel = (props) => {
  return <FormattedMessage {...getTestIdAttr('PocFilterLabel')} {...getI18nProps(props)} />;
};
