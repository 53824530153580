import { Button } from '@hexa-ui/components';
import { Field, Formik } from 'formik';
import { useIntl } from 'react-intl';
import { getDefaultValues, getInitialValues } from '~/components/Form/helpers';
import {
  Checkbox,
  CheckboxGroup,
  ConditionalField,
  MultiSelect,
  NumberField,
  RadioGroup,
  Select,
  FloatField,
  TextArea,
  DatePicker,
  TextField,
  TextWithTags,
  FileUpload,
} from './index';
import { formatValidationMessages } from '~/utils';
import { forwardRef } from 'react';
import DynamicFormContext from './DynamicFormContext';
import { useGetValidationSchema, useValidationFeatureToggles } from '~/hooks';
import { Buttons } from '~/components/Messages';
import './DynamicForm.css';
import { TForm } from '~/components/Form/Form.types';

const components = [
  { componentType: 'text', component: TextField },
  { componentType: 'textWithTags', component: TextWithTags },
  { componentType: 'textarea', component: TextArea },
  { componentType: 'select', component: Select },
  { componentType: 'multiselect', component: MultiSelect },
  { componentType: 'checkbox', component: Checkbox },
  { componentType: 'checkboxgroup', component: CheckboxGroup },
  { componentType: 'radioGroup', component: RadioGroup },
  { componentType: 'percent', component: FloatField },
  { componentType: 'float', component: FloatField },
  { componentType: 'datePicker', component: DatePicker },
  { componentType: 'number', component: NumberField },
  { componentType: 'fileUpload', component: FileUpload },
];

const DynamicForm: TForm = (
  {
    formId,
    schema,
    onSubmit,
    getCurrentValues,
    initialValues,
    buttonContainerStyles,
    clearFormOnSubmit,
    onClose,
    buttonLabel,
    variant = 'primary',
    validateOnMount = false,
    validateOnBlur = false,
    validateOnChange = false,
    extraButtons,
    showButtons,
    ...props
  },
  ref
) => {
  const defaultValues = getDefaultValues(schema);
  const validationSchema = useGetValidationSchema(
    schema,
    formatValidationMessages(useIntl(), formId),
    useValidationFeatureToggles()
  );

  return (
    <Formik
      initialValues={getInitialValues(defaultValues, initialValues)}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        onSubmit(values, resetForm);
        clearFormOnSubmit && resetForm(values);
      }}
      validateOnMount={validateOnMount}
      validateOnBlur={false}
      validateOnChange={validateOnChange}
      {...props}
    >
      {({ handleSubmit, setFieldValue, setFieldTouched, values }) => {
        return (
          <form
            className="formik-form"
            id={formId}
            onSubmit={handleSubmit}
            noValidate
            autoComplete="off"
          >
            {schema.map(({ componentType, condition, ...fieldSchema }) => {
              if (!components.some((component) => component.componentType === componentType)) {
                return null;
              }

              const Component = components.find(
                (component) => component.componentType === componentType
              ).component;

              if (condition) {
                return (
                  <ConditionalField
                    key={fieldSchema.name}
                    show={
                      condition.operator === '='
                        ? values[condition.key] === condition.value
                        : values[condition.key] !== condition.value
                    }
                    onCollapse={() => {
                      setFieldValue(fieldSchema.name, defaultValues[fieldSchema.name]);
                      setFieldTouched(fieldSchema.name, false);
                    }}
                    onShow={() => {
                      setFieldValue(fieldSchema.name, defaultValues[fieldSchema.name]);
                    }}
                  >
                    <Field component={Component} {...fieldSchema} />
                  </ConditionalField>
                );
              }

              return (
                <Field
                  key={fieldSchema.name}
                  component={Component}
                  {...fieldSchema}
                  validationinfo={validationSchema}
                />
              );
            })}

            <div
              className="formik-button-container"
              style={!showButtons ? { display: 'none' } : { ...(buttonContainerStyles ?? {}) }}
            >
              <Button id={`${formId}-submit`} type="submit" variant={variant} elevated>
                {buttonLabel ?? <Buttons.Submit />}
              </Button>
              {extraButtons}
            </div>
            <DynamicFormContext values={values} getCurrentValues={getCurrentValues} ref={ref} />
          </form>
        );
      }}
    </Formik>
  );
};

export default forwardRef(DynamicForm);
