import { useGetFirstExternalHistory, useGetPromoDesignsShort } from '~/services';
import { useMemo } from 'react';
import { validateExternalHistory } from '~/utils';
import { useIntl } from 'react-intl';
import {
  IOverlappingPromoDetail,
  IOverlappingPromoDetailData,
  IExternalHistoryValidationResponse,
  IPromoDesign,
} from '~/interfaces';
import { ExternalHistoryValidator } from '@moneycomb/utils';

export const useGetOverlappingPromoDetails = (
  promoDesignId: string,
  modalData: IPromoDesign
): IOverlappingPromoDetailData => {
  const { formatMessage } = useIntl();
  const { data: firstExternalHistory } = useGetFirstExternalHistory(promoDesignId, modalData);
  const validationResponse = useMemo<IExternalHistoryValidationResponse>(() => {
    if (!firstExternalHistory) {
      return undefined;
    }
    return validateExternalHistory(firstExternalHistory, formatMessage);
  }, [firstExternalHistory]);

  const overlappingPromoDesignIds = useMemo<string[]>(() => {
    return validationResponse?.meta?.overlappingPromoDesignIds ?? [];
  }, [validationResponse]);

  const overlappingPromoDetails = useMemo<IOverlappingPromoDetail[]>(() => {
    return validationResponse?.meta?.overlappingPromoDetails ?? [];
  }, [validationResponse]);

  const results = useGetPromoDesignsShort(overlappingPromoDesignIds);

  const promoDesignsById = useMemo<{ [key: string]: IPromoDesign }>(() => {
    const promoDesigns = {};
    for (const promoDesignId of overlappingPromoDesignIds) {
      promoDesigns[promoDesignId] = results.find((result) => {
        // @ts-ignore
        return result?.data?.promoDesignId === promoDesignId;
      })?.data;
    }
    return promoDesigns;
  }, [results]);

  return useMemo<IOverlappingPromoDetailData>(() => {
    const data = {};
    for (const overlappingDetail of overlappingPromoDetails) {
      const NUMBER_OD_ZEROS = 8;
      const promoDesignId = ExternalHistoryValidator.convertSpecIdToPromoDesignId(
        overlappingDetail.overlapping_promo_spec_id,
        NUMBER_OD_ZEROS
      );
      if (promoDesignsById?.[promoDesignId]) {
        if (!data[promoDesignId]) {
          data[promoDesignId] = {
            design: promoDesignsById?.[promoDesignId],
            overlap: [],
          };
        }
        data[promoDesignId].overlap.push(overlappingDetail);
      }
    }
    return data;
  }, [promoDesignsById, overlappingPromoDetails]);
};
