import Bff from '~/Api/Bff';
import { ICreatePromoDesignRequest, ISaveNonPersonalizedPromoResponse } from '~/interfaces';
import { AxiosResponse } from 'axios';

export const createPromoDesign = (
  payload: ICreatePromoDesignRequest
): Promise<AxiosResponse<ISaveNonPersonalizedPromoResponse>> => {
  return Bff.post<ICreatePromoDesignRequest, ISaveNonPersonalizedPromoResponse>({
    url: `v1/promo-designs`,
    body: payload,
  }).then((response) => {
    return response;
  });
};
