import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { CountryDropdownItem } from '~/components/CountrySelector/components';
import useStyles from '~/components/CountrySelector/CountrySelector.styles';
import React from 'react';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';
import { getTestIdAttr } from '~/utils';

type TCountryDropdown = (props: {
  fullWidth?: boolean;
  selectedCountry: string;
  handleSelection: SelectInputProps['onChange'];
  countries: string[];
}) => JSX.Element;

const CountryDropdown: TCountryDropdown = ({
  fullWidth,
  selectedCountry,
  handleSelection,
  countries,
}) => {
  const classes = useStyles();

  return (
    <Select
      {...getTestIdAttr('CountryDropdown')}
      labelId="country"
      id="select-country"
      value={selectedCountry ?? ''}
      onChange={handleSelection}
      variant={'outlined'}
      classes={{
        root: classes.root,
      }}
      className={`${classes.outline} ${fullWidth && classes.fullWidth}`}
    >
      {countries.map((country) => (
        <MenuItem value={country} key={country}>
          <CountryDropdownItem country={country} />
        </MenuItem>
      ))}
    </Select>
  );
};

export default React.memo<TCountryDropdown>(CountryDropdown);
