import { Persister } from '@tanstack/react-query-persist-client';
import { createAsyncStoragePersister } from '@tanstack/query-async-storage-persister';
import storage from '~/redux/storage';
import { compress, decompress } from 'lz-string';

/**
 * Creates an Indexed DB persister
 * @see https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API
 */
export function createIDBPersister(idbValidKey: IDBValidKey = 'reactQuery'): Persister {
  const liftStorage = storage('lift');

  return createAsyncStoragePersister({
    storage: liftStorage,
    key: idbValidKey as string,
    throttleTime: 5000,
    serialize: (data) => {
      return compress(JSON.stringify(data));
    },
    deserialize: (data) => JSON.parse(decompress(data)),
  });
}
