import { STEPPED_DISCOUNT, INTERACTIVE_COMBO, REWARDS_CHALLENGE } from '~/constants';
import { TMechanicType, TConfigArrayWithDefault } from '~/interfaces';

export const MECHANICS_USING_DISPLAY_STEPPED: TConfigArrayWithDefault<TMechanicType> = {
  DEFAULT: [STEPPED_DISCOUNT, INTERACTIVE_COMBO, REWARDS_CHALLENGE],
  // Add a country override here
};

export const getMechanicsUsingDisplayStepped = (country: string): TMechanicType[] => {
  return MECHANICS_USING_DISPLAY_STEPPED?.[country] ?? MECHANICS_USING_DISPLAY_STEPPED.DEFAULT;
};
