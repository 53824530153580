import { NumberSchema } from 'yup';
import { IFeatureToggleValidation, IValidationMessages, IValidationRule } from '~/interfaces';

export const greaterThanOrEqualTo = (
  validation: NumberSchema,
  rule: IValidationRule,
  fieldName: string,
  validationCallbacks: IValidationMessages,
  featureToggleValidations: IFeatureToggleValidation
): NumberSchema => {
  validation = validation.when([rule.field], (dependentValue: number, schema: NumberSchema) => {
    if (dependentValue) {
      if (rule?.additionToValue && featureToggleValidations?.isAdditionToValueValidationEnabled) {
        return schema.when(fieldName, (_thisField: number, _innerSchema: NumberSchema) => {
          if (_thisField !== dependentValue) {
            return schema.min(
              dependentValue + rule?.additionToValue,
              validationCallbacks.greaterThanOrEqualToAdditionalValue(
                fieldName,
                rule?.field,
                rule?.additionToValue
              )
            );
          }
        });
      }

      return schema.when(fieldName, (_thisField: number, _innerSchema: NumberSchema) => {
        return schema.min(
          dependentValue,
          validationCallbacks.greaterThanOrEqualTo(fieldName, rule.field)
        );
      });
    } else {
      return schema.when(fieldName, (thisField) => {
        if (thisField !== 0 && rule?.dependent) {
          return schema.min(
            dependentValue,
            validationCallbacks.requiresDependentField(fieldName, rule.field)
          );
        }
      });
    }
  });
  return validation;
};
