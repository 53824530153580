import {
  DESIGNED,
  INVALID_PROMO,
  PROMOS_CREATED,
  PROMOS_LOADED,
  LIVE,
  INVALID_PROMOS,
  PENDING_ALGORITHM,
  PENDING_ALGORITHM_DELAYED,
  FAILED_KAFKA_SEND,
  PROMOS_DELAYED,
} from './statuses';
import { TConfigArrayWithDefault } from '~/interfaces';
/**
 * Use this file to configure the order in which the feilds appear
 * Eventually we will move this to a different data source
 * The code is NOT dependent upon all fields being present in this list
 * If a field is sent from the server that is not in this list, it will simply be added later in the form
 */

export const STATUSES_FOR_DELETE: TConfigArrayWithDefault = {
  DEFAULT: [
    DESIGNED,
    INVALID_PROMO,
    PROMOS_CREATED,
    PROMOS_LOADED,
    LIVE,
    INVALID_PROMOS,
    PENDING_ALGORITHM,
    PENDING_ALGORITHM_DELAYED,
    FAILED_KAFKA_SEND,
    PROMOS_DELAYED,
  ],
};

export const getStatusesForDelete = (country?: string): string[] => {
  return STATUSES_FOR_DELETE?.[country] ?? STATUSES_FOR_DELETE.DEFAULT;
};
