import { TypeToast, useAuthenticationService, useToast } from 'admin-portal-shared-services';
import { useEffect } from 'react';
import { fetchAvailableCountries } from '~/redux/saga/sagaActions';
import { selectCountriesState, setCountry } from '~/redux/slices';
import getCountryName from '~/i18n/getCountryName';
import { useIntl } from 'react-intl';
import { isAllowedCountry } from '~/utils';
import { useAppDispatch, useAppSelector } from '~/hooks';

export const getPreferredCountry = (
  countries?: string[],
  selectedCountryGlobal?: string,
  userCountry?: string
): string | null => {
  if (countries !== null && countries !== undefined) {
    // Always prefer the selected global country if it's in the allowed list of countries
    if (isAllowedCountry(countries, selectedCountryGlobal)) {
      return selectedCountryGlobal;
    }
    // Secondarily prefer the user's country if it's allowed
    if (isAllowedCountry(countries, userCountry)) {
      return userCountry;
    }
    if (countries?.length) {
      return countries[0];
    }
  }
  return null;
};

const useCountrySelection = (countryFromSelection?: string) => {
  const dispatch = useAppDispatch();
  const authentication = useAuthenticationService();
  const notificationService = useToast();
  const { formatMessage } = useIntl();
  const { user_country: userCountry, language } = authentication.getUserCountryAndLanguage();
  const {
    countries,
    selectedCountry: selectedCountryGlobal,
    isLoading,
  } = useAppSelector(selectCountriesState);

  useEffect(() => {
    dispatch(fetchAvailableCountries());
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-console
  }, [selectedCountryGlobal]);

  useEffect(() => {
    if (countries !== null && countries !== undefined) {
      const preferredCountry = getPreferredCountry(countries, selectedCountryGlobal, userCountry);

      if (selectedCountryGlobal && !isAllowedCountry(countries, selectedCountryGlobal)) {
        notificationService.notify({
          type: TypeToast.ERROR,
          message: formatMessage(
            {
              id: `MESSAGES.COUNTRY_ACCESS_DENIED`,
              defaultMessage: 'You do not have permission to use {country} on BEES Lift',
            },
            {
              country: getCountryName(selectedCountryGlobal, language ?? 'en'),
            }
          ),
        });
        if (preferredCountry) {
          dispatch(setCountry({ selectedCountry: preferredCountry }));
        }
      } else {
        if (preferredCountry && selectedCountryGlobal !== preferredCountry) {
          console.log('change country');
          dispatch(setCountry({ selectedCountry: preferredCountry }));
        }
      }
    }
  }, [countries, dispatch]);

  useEffect(() => {
    if (countryFromSelection) {
      if (countries.includes(countryFromSelection)) {
        dispatch(setCountry({ selectedCountry: countryFromSelection }));
      } else {
        // eslint-disable-next-line no-console
        console.error('temporary: failure, no countries from BFF match selection');
      }
    }
  }, [countryFromSelection]);

  return { selectedCountryGlobal, countries, isLoading };
};

export default useCountrySelection;
