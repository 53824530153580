export const SKU = 'sku';
export const PRODUCT_NAME = 'productName';
export const MIN_QUANTITY = 'minQuantity';
export const MAX_QUANTITY = 'maxQuantity';
export const MIN_DISCOUNT = 'minDiscount';
export const MAX_DISCOUNT = 'maxDiscount';
export const DISCOUNT = 'discount';
export const ALLOCATION = 'allocation';
export const ORDER_AVAILABILITY = 'orderAvailability';
export const SKU_LIMIT = 'skuLimit';
export const BUDGET_ID = 'budgetId';
export const MIN_PRICE = 'minPrice';
export const MAX_PRICE = 'maxPrice';
export const MAX_POINTS = 'maxPoints';
export const STEP_ID = 'stepId';
