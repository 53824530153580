import { IProductSearch, IProductsFilterResponse } from '~/interfaces';
import { useQuery } from '@tanstack/react-query';
import { UseQueryOptions, UseQueryResult } from '@tanstack/react-query/build/lib/types';
import { useSelectedCountry } from '~/hooks';
import Bff from '~/Api/Bff';
import { AxiosResponse } from 'axios';

const getProductFilters = ({
  country,
}: {
  country?: string;
}): Promise<AxiosResponse<IProductsFilterResponse>> => {
  const options = country ? { headers: { country } } : {};
  return Bff.get<IProductsFilterResponse>({
    url: '/v3/products/filter',
    options,
  }).then((response) => {
    return response;
  });
};

const getProductFiltersSearch = async ({
  mechanicType,
  productFilters,
}: IProductSearch): Promise<IProductsFilterResponse> => {
  try {
    const data = await Bff.post<typeof productFilters, IProductsFilterResponse>({
      url: `/v3/products/search?mechanicType=${mechanicType}`,
      body: { ...productFilters },
    });
    return data?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const useGetProductFiltersSearch = (
  payload: IProductSearch,
  options?: UseQueryOptions
): UseQueryResult => {
  const selectedCountry = useSelectedCountry();
  return useQuery(
    ['getProductFiltersSearch', selectedCountry, payload],
    async () => {
      return await getProductFiltersSearch(payload);
    },
    { ...options }
  );
};

export { useGetProductFiltersSearch, getProductFilters };
