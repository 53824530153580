import { FormattedMessage } from 'react-intl';
import './CustomLabel.css';
import { getTestIdAttr } from '~/utils';
interface ICustomLabelProps {
  errors?: string;
  label: string;
  hint?: string;
  optional?: boolean;
}

const CustomLabel = ({ errors, label, hint, optional }: ICustomLabelProps): JSX.Element => {
  return (
    <div
      {...getTestIdAttr('CustomLabel')}
      className={`${errors ? 'custom-formik-label-error-styles ' : ''}custom-label-container`}
    >
      <label className={`custom-formik-label`}>
        {label}{' '}
        {!!optional && (
          <span className="custom-formik-optional-text">
            <FormattedMessage id={'FIELDS.OPTIONAL'} defaultMessage={'(Optional)'} />
          </span>
        )}
      </label>
      <span className="custom-formik-hint">{hint}</span>
    </div>
  );
};

export default CustomLabel;
