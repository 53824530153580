import { createSlice } from '@reduxjs/toolkit';
import {
  IBooleanPayloadAction,
  IProductPayload,
  ISelectedFilters,
  ISelectedFiltersPayload,
  ISkusAndParameters,
} from '~/interfaces';
import { RootState } from '~/redux/store';

export interface IProductsState {
  products: ISkusAndParameters[];
  productsFiltered: any;
  selectedFilters: ISelectedFilters;
  filters: {
    [key: string]: string[];
  };
  loadingFilters: boolean;
  loadingProducts: boolean;
}

const initialState: IProductsState = {
  products: [],
  productsFiltered: [],
  selectedFilters: {},
  filters: {},
  loadingFilters: false,
  loadingProducts: false,
};

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setProducts: (state, action: IProductPayload): IProductsState => {
      return {
        ...state,
        products: action.payload.products,
      };
    },
    setSelectedFilters: (state, action: ISelectedFiltersPayload): IProductsState => {
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          ...action.payload,
        },
      };
    },
    resetSelectedFilters: (state): IProductsState => {
      return {
        ...state,
        selectedFilters: initialState.selectedFilters,
      };
    },
    setFilters: (state, action: ISelectedFiltersPayload): IProductsState => {
      return {
        ...state,
        filters: action.payload,
      };
    },
    setLoadingFilters: (state, action: IBooleanPayloadAction): IProductsState => {
      return {
        ...state,
        loadingFilters: action.payload,
      };
    },
    resetProducts: () => {
      return {
        ...initialState,
      };
    },
  },
});

export const selectProductsState = (state: RootState) => state.products;

export const {
  setProducts,
  setSelectedFilters,
  setFilters,
  setLoadingFilters,
  resetSelectedFilters,
  resetProducts,
} = productsSlice.actions;
export default productsSlice.reducer;
