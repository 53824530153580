import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IPocListResponse,
  IStringArrayPayloadAction,
  IUploadFilesListWithContent,
} from '~/interfaces';
import { RootState } from '~/redux/store';
import { POC_EXCLUDE, POC_INCLUDE } from '~/constants';

export interface IPocFilterState {
  selectedPocs: {
    [key: string]: string[];
  };
  uploadExcludedPocFiles: any[];
  uploadExcludedPocValues: any[];
  uploadFilesList: IUploadFilesListWithContent[];
  uploadExcludedPocFilesList: IUploadFilesListWithContent[];
  uploadPocContent: string[];
  uploadExcludedPocContent: string[];
  applyLifecycle: boolean;
  selectedPocsCount: number;
  activeTab: number;
  invalidPocs: string[];
  validPocs: string[];
}

export type ISetPocUploadFileDataAction = PayloadAction<IPocListResponse[]>;

const initialState: IPocFilterState = {
  selectedPocs: {},
  uploadExcludedPocFiles: [],
  uploadExcludedPocValues: [],
  uploadExcludedPocFilesList: [],
  uploadExcludedPocContent: [],
  uploadFilesList: [],
  uploadPocContent: [],
  applyLifecycle: true,
  selectedPocsCount: 0,
  activeTab: 0,
  invalidPocs: [],
  validPocs: [],
};

const pocFilterSlice = createSlice({
  name: 'pocFilter',
  initialState,
  reducers: {
    resetPocValidations: (state): IPocFilterState => {
      return {
        ...state,
        invalidPocs: initialState.invalidPocs,
        validPocs: initialState.validPocs,
      };
    },
    setValidPocs: (state, action: IStringArrayPayloadAction): IPocFilterState => {
      return {
        ...state,
        validPocs: action.payload,
      };
    },
    setInvalidPocs: (state, action: IStringArrayPayloadAction): IPocFilterState => {
      return {
        ...state,
        invalidPocs: action.payload,
      };
    },
    setSelectedPocsFilter: (state, action): IPocFilterState => {
      const {
        selectedPocs,
        uploadExcludedPocFiles = [],
        uploadExcludedPocValues = [],
        selectedPocsCount,
        activeTab = 0,
      } = action.payload;
      return {
        ...state,
        selectedPocs,
        selectedPocsCount,
        uploadExcludedPocFilesList: uploadExcludedPocFiles,
        uploadExcludedPocContent: uploadExcludedPocValues,
        activeTab,
      };
    },
    setPocByUploadData: (state, action): IPocFilterState => {
      const {
        uploadPocValues = [],
        uploadFiles = [],
        applyLifecycle,
        activeTab = 0,
      } = action.payload;
      return {
        ...state,
        uploadFilesList: uploadFiles,
        uploadPocContent: uploadPocValues,
        applyLifecycle,
        activeTab,
      };
    },
    setPocUploadFileData: (state, action: ISetPocUploadFileDataAction): IPocFilterState => {
      const formattedData: IUploadFilesListWithContent[] = (action.payload || []).map((item) => {
        return {
          ...item,
          content: item?.pocIds,
        };
      });
      return {
        ...state,
        uploadFilesList: (formattedData || []).filter((item) => item.pocListType === POC_INCLUDE),
        uploadExcludedPocFilesList: (formattedData || []).filter(
          (item) => item.pocListType === POC_EXCLUDE
        ),
      };
    },
    resetPocFilter: (): IPocFilterState => {
      return {
        ...initialState,
      };
    },
  },
});

export const selectPocFilterState = (state: RootState) => state.pocFilter;

export const {
  resetPocValidations,
  setValidPocs,
  setInvalidPocs,
  setSelectedPocsFilter,
  setPocByUploadData,
  resetPocFilter,
  setPocUploadFileData,
} = pocFilterSlice.actions;
export default pocFilterSlice.reducer;
