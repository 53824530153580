import { TConfigNumberWithDefault } from '~/interfaces';

export const DEFAULT_MAX_LENGTH = 100;

export const PROMO_TITLE_LENGTH: TConfigNumberWithDefault = {
  DEFAULT: DEFAULT_MAX_LENGTH,
};

export const getPromoTitleLength = (country?: string): number => {
  return PROMO_TITLE_LENGTH?.[country] ?? PROMO_TITLE_LENGTH.DEFAULT;
};
