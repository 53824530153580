export const downloadFromAPI = (response: any, name: string) => {
  const url = window.URL.createObjectURL(
    new Blob([response], {
      type: 'text/csv;charset=utf-8;',
    })
  );
  const hiddenElement = document.createElement('a');
  hiddenElement.href = url;
  hiddenElement.target = '_blank';
  hiddenElement.download = `${name}.csv`;
  hiddenElement.click();
  hiddenElement.remove();
};
