import { decompress } from 'lz-string';

export const getReactQueryData = async (): Promise<any> => {
  let reactQueryData;
  try {
    reactQueryData = await window?.LiftStorage?.['lift']
      ?.getItem('reactQuery')
      .then((value: string) => {
        try {
          return JSON.parse(decompress(value));
        } catch (e) {
          console.error('Could not parse react query data');
        }
      })
      .catch((e) => {
        console.error('Could not get react query data', e);
        return undefined;
      });
  } catch (e) {
    console.error('Could not get react query data');
  }
  return reactQueryData;
};
