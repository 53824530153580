import { FormattedMessage } from 'react-intl';
import { getTestIdAttr } from '~/utils';

export const Duplicate = (): JSX.Element => {
  return (
    <FormattedMessage
      {...getTestIdAttr('Duplicate')}
      id={'BUTTONS.DUPLICATE'}
      defaultMessage={'Duplicate'}
    />
  );
};
