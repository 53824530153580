import Bff from '~/Api/Bff';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useSelectedCountry, useFeatureToggle } from '~/hooks';
import { SKU_REMOVE_LEADING_ZEROS } from '~/constants';
import { useEffect, useState } from 'react';
import { removeLeadingZeros } from '~/utils';
import { UseQueryResult } from '@tanstack/react-query/build/lib/types';
import { IPromoDesignSkusResponse } from '~/interfaces';

type IPromoDesignPayload = {
  promoDesignId: string;
  currentPage: number;
  pageSize: number;
  products?: any;
};

const getSkuListById = async (payload: IPromoDesignPayload): Promise<IPromoDesignSkusResponse> => {
  const { promoDesignId, currentPage, pageSize } = payload;
  const currPageFormattedToMatchBackend = currentPage !== 0 ? currentPage - 1 : 0;
  try {
    const response = await Bff.get<IPromoDesignSkusResponse>({
      url: `/v2/promo-design-skus/${promoDesignId}?page=${currPageFormattedToMatchBackend}&pageSize=${pageSize}`,
    });
    const productsFromResponse = response?.data?.data;
    const paginationFromResponse = response?.data?.pagination;
    return { data: productsFromResponse, pagination: paginationFromResponse };
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const useGetSkuListById = (
  payload: IPromoDesignPayload,
  options?: UseQueryOptions
): UseQueryResult<IPromoDesignSkusResponse, any> => {
  const selectedCountry = useSelectedCountry();
  const shouldTrimLeadingZerosToggle = useFeatureToggle(SKU_REMOVE_LEADING_ZEROS, {
    country: selectedCountry,
  });
  const [shouldTrimLeadingZeros, setShouldTrimLeadingZeros] = useState<boolean>(false);

  useEffect(() => {
    setShouldTrimLeadingZeros(shouldTrimLeadingZerosToggle);
  }, [shouldTrimLeadingZerosToggle]);

  const { promoDesignId, currentPage, pageSize, products } = payload;
  return useQuery<any, any, any, any>({
    queryKey: [
      'getSkuListById',
      selectedCountry,
      promoDesignId,
      currentPage,
      pageSize,
      shouldTrimLeadingZeros,
    ],
    queryFn: async () => await getSkuListById(payload),
    select: (responseData: any) => {
      const responseDataDetails = responseData?.data;
      const formatData = responseDataDetails?.map((productDetails) => {
        const productDetailsSku = shouldTrimLeadingZeros
          ? removeLeadingZeros(productDetails?.sku)
          : productDetails?.sku;
        const foundProduct = products?.find((product) => {
          const sku = shouldTrimLeadingZeros ? removeLeadingZeros(product?.sku) : product?.sku;
          return sku === productDetailsSku;
        });
        const productName = foundProduct?.productName ?? productDetails?.sku;
        return {
          ...productDetails,
          productName,
        };
      });

      return { ...responseData, data: formatData };
    },
    enabled: !!promoDesignId,
    cacheTime: 0,
    staleTime: 0,
    ...options,
  });
};
