import { useMemo } from 'react';
import { useIntl } from 'react-intl';

export const useGetFormattedSubstrategyLabels = (
  selectedSubStrategies: string[] = []
): string[] => {
  const { formatMessage } = useIntl();
  return useMemo<string[]>(() => {
    return selectedSubStrategies.map(
      (code) =>
        `(${code}) \u00A0${formatMessage({
          id: `PROMOTION_STRATEGIES.SUB_STRATEGIES.${code}`,
        })}`
    );
  }, [selectedSubStrategies]);
};
