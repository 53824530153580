import styled, { css } from 'styled-components';
import { TVariant } from '~/components/Dropdown/Dropdown.types';

interface IStylesProps {
  readonly variant?: TVariant;
  readonly isVisible?: boolean;
}

export const Container = styled.div<IStylesProps>`
  position: relative;
  width: 100%;
  ${(props) =>
    props.variant === 'small' &&
    css`
      max-width: 170px;
    `}
`;

export const Label = styled.label`
  color: rgba(0, 0, 0, 0.9);
  font-family: Work Sans;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: 20px;
  display: inline-block;
  margin-bottom: 4px;
`;

export const Select = styled.div<IStylesProps>`
  background-color: #fff;
  font-family: Work Sans;
  font-weight: 500;
  border: 1px solid rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  column-gap: 12px;

  cursor: pointer;
  width: inherit;

  ${(props) =>
    props.variant === 'large' &&
    css`
      font-size: 16px;
      line-height: 24px;
      border-radius: 8px;
      padding: 12px 16px;
    `}

  ${(props) =>
    props.variant === 'medium' &&
    css`
      font-size: 16px;
      border-radius: 8px;
      padding: 12px 16px;
      height: 40px;
    `}

  ${(props) =>
    props.variant === 'small' &&
    css`
      font-size: 14px;
      line-height: 16px;
      border-radius: 16px;
      padding: 8px 16px;
    `}

  ${(props) =>
    props.variant === 'small-x' &&
    css`
      font-size: 14px;
      line-height: 16px;
      border-radius: 16px;
      padding: 8px 16px;
    `}

  ${(props) =>
    props.isVisible &&
    css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `}
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const CurrentItem = styled.span`
  user-select: none;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  width: inherit;
`;

export const List = styled.ul<IStylesProps>`
  width: inherit;
  z-index: 99;
  position: absolute;
  list-style-type: none;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  cursor: pointer;
  visibility: hidden;
  overflow-y: auto;
  max-height: 490px;

  ${(props) =>
    props.isVisible &&
    css`
      display: block;
      visibility: visible;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 16px;
    `}

  > * {
    &:last-child {
      border-bottom: none;
      ${(props) =>
        props.variant === 'large' &&
        css`
          border-bottom-right-radius: 8px;
          border-bottom-left-radius: 8px;
        `}

      ${(props) =>
        props.variant === 'medium' &&
        css`
          border-bottom-left-radius: 8px;
        `}

      ${(props) =>
        props.variant === 'small' &&
        css`
          border-bottom-left-radius: 16px;
        `}

      ${(props) =>
        props.variant === 'small-x' &&
        css`
          border-bottom-left-radius: 16px;
        `}
    }
  }
`;

export const GrayContainer = styled.div`
  background-color: #0000001a;
`;

export const CustomOption = styled.div<IStylesProps>`
  z-index: 99;
  background-color: #fff;
  font-family: Work Sans;
  font-weight: 500;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-top: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &:hover {
    background-color: #f2f2f2;
    font-weight: 500;
  }

  ${(props) =>
    props.isVisible &&
    css`
      display: block;
      visibility: visible;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    `}

  ${(props) =>
    props.variant === 'large' &&
    css`
      font-size: 16px;
      line-height: 24px;
      padding: 12px 16px;
    `}

  ${(props) =>
    props.variant === 'medium' &&
    css`
      font-size: 16px;
      line-height: 24px;
      padding: 8px 16px;
    `}

  ${(props) =>
    props.variant === 'small' &&
    css`
      font-size: 14px;
      line-height: 16px;
      padding: 8px 16px;
    `}

    ${(props) =>
    props.variant === 'small-x' &&
    css`
      font-size: 14px;
      line-height: 16px;
      padding: 8px 16px;
    `}
`;
