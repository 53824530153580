import { StatusIndicator } from 'supplier-portal-shared-components';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { TPromotionStatus } from './PromoStatus.types';
import {
  DESIGNED,
  PENDING_MODELING,
  PROCESSED,
  CREATED,
  DELAYED,
  PROMOS_CREATED,
  RECEIVED_ERP,
  RECEIVED_DS,
  INVALID,
  INVALID_DESIGN,
  INVALID_PROMO,
  COMPLETE,
  LIVE,
  SUCCESS,
  DELETED,
  LIVE_WITH_ERRORS,
  FAILED,
  INVALID_PROMOS,
  SYSTEM_ERROR,
  DRAFT,
} from '~/configs/statuses';

const PromotionStatus: TPromotionStatus = ({ status }) => {
  const { formatMessage } = useIntl();
  const statusFormatted = useMemo<string>(
    () =>
      formatMessage({
        id: `STATUSES.PROMOTION.${status}`,
        defaultMessage: status,
      }),
    [status]
  );

  const color = useMemo<string>(() => {
    switch (status) {
      case DESIGNED:
      case PENDING_MODELING:
      case PROCESSED:
      case CREATED:
      case DELAYED:
      case PROMOS_CREATED:
        return '#F6B244';
      case RECEIVED_ERP:
        return '#7C6BAE';
      case RECEIVED_DS:
        return '#047AF1';
      case INVALID:
      case INVALID_DESIGN:
      case INVALID_PROMO:
        return '#b63e3e';
      case COMPLETE:
      case LIVE:
      case SUCCESS:
        return 'green';
      case DELETED:
      case LIVE_WITH_ERRORS:
      case FAILED:
      case INVALID_PROMOS:
      case SYSTEM_ERROR:
        return '#FF1414';
      case DRAFT:
        return 'grey';
      default:
        return '#FFB042';
    }
  }, [status]);

  // @ts-ignore next-line
  return <StatusIndicator color={color} status={statusFormatted} />;
};

export default PromotionStatus;
