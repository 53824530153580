import { isNotEmptyObj } from '~/utils';
import { IRecord } from '~/interfaces';

export const isAllSelected = (selectedPocsList: IRecord, completeList: IRecord): boolean => {
  if (!isNotEmptyObj(selectedPocsList) && !isNotEmptyObj(completeList)) {
    return false;
  }
  return isNotEmptyObj(completeList)
    ? Object.keys(completeList).every(
        (key: string) => selectedPocsList[key]?.length === completeList[key]?.length
      )
    : false;
};
