import { array, ArraySchema } from 'yup';
import { ISchemaField, IValidationMessages } from '~/interfaces';

export const validateFileUpload = (
  val: ISchemaField,
  validationCallbacks: IValidationMessages
): ArraySchema<any> | null => {
  if (val?.required) {
    return array().min(1, validationCallbacks.isRequired(val.label));
  }
  return null;
};
