import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useFormikContext } from 'formik';

interface IErrors {
  [key: string]: string;
}

const DynamicFormContext = ({ values, getCurrentValues }, ref) => {
  const formikState = useFormikContext();

  useEffect(() => {
    if (getCurrentValues) {
      getCurrentValues(values);
    }
  }, [values]);

  useImperativeHandle(ref, () => ({
    handleSubmit: (): void => formikState.handleSubmit(),
    handleReset: () => formikState.handleReset(),
    getErrors: async (): Promise<IErrors> => {
      return await formikState.validateForm(formikState.values);
    },
    values: formikState.values,
    validateForm: () => formikState.validateForm(formikState.values),
    isValid: async (): Promise<boolean> => {
      const response = await formikState.validateForm(formikState.values);
      return !Object.keys(response)?.length;
    },
    dirty: formikState.dirty,
    status: formikState.status,
  }));

  return null;
};
export default forwardRef(DynamicFormContext);
