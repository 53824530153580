import { Table } from '@hexa-ui/components';
import { useMemo, useState } from 'react';
import { FormattedDate, useIntl } from 'react-intl';
import { IColumnHeaders, IPaginationComponentOptions, IRecord, TAlertType } from '~/interfaces';
import { HistoryErrorMessage, StatusCodeIcon } from '~/components';
import { downloadJSON, getTestIdAttr } from '~/utils';
import { setToast } from '~/redux/saga/sagaActions';
import { useAppDispatch, useGetPageSizeOptionsBasedOnTotalElements } from '~/hooks';
import { defaultHistoryTableColumns, PAGE_SIZE_10, PAGE_SIZE_20, PAGE_SIZE_5 } from '~/constants';
import { TCommonHistory } from './HistoryTable.types';
import './HistoryTable.css';

const PaginationSettings: IPaginationComponentOptions = {
  PAGE_SIZE_OPTIONS: [PAGE_SIZE_5, PAGE_SIZE_10, PAGE_SIZE_20],
};

export const CommonHistory: TCommonHistory = ({
  columns,
  data,
  onRowClick,
  emptyTableMessage,
  isLoading,
  loadingMessage,
  paginationProps,
  alertMessage,
  showAlert,
  setShowAlert,
  downloadFileName,
  hidePagination,
  page,
  setPage,
  pageSize,
  setPageSize,
  alertTypeProp,
  hideDefaultColumn,
}): JSX.Element => {
  const { formatMessage } = useIntl();

  const [selectedRow, setSelectedRow] = useState<IRecord>({});
  const dispatch = useAppDispatch();

  const setDefaultColumns = (): IColumnHeaders<typeof columns>[] => {
    return defaultHistoryTableColumns.map((col) => {
      const headerObj = {
        Header: formatMessage({ id: `PROMO_HISTORY_TABLE.HEADERS.${col}`, defaultMessage: col }),
        accessor: col,
      };
      switch (col) {
        case 'message':
          headerObj['style'] = { width: '5%' };
          headerObj['accessor'] = 'message.message';
          headerObj['customRender'] = (value, rowData) => {
            return value ? <StatusCodeIcon statusCode={rowData?.message?.status} /> : null;
          };
          break;
        case 'lastUpdatedTimestamp':
          headerObj['style'] = { width: '25%' };
          headerObj['customRender'] = (value) => {
            return <FormattedDate value={`${value}Z`} dateStyle={'medium'} timeStyle={'short'} />;
          };
          break;
        default:
          break;
      }

      return headerObj;
    });
  };

  const formattedColumns = useMemo<IColumnHeaders<typeof columns>[]>(() => {
    let formatColumns = [];

    if (!hideDefaultColumn) {
      formatColumns = setDefaultColumns();
    }
    return [...formatColumns, ...columns].map((item) => {
      return {
        style: {
          width: '20%',
        },
        ...item,
      };
    });
  }, [columns, hideDefaultColumn]);

  const handleClose = (): void => {
    setShowAlert(false);
  };

  const handleDownload = (): void => {
    const downloadValid = downloadJSON(selectedRow?.message?.additional, downloadFileName);

    if (!downloadValid.isValid) {
      dispatch(
        setToast({
          showToast: true,
          type: 'error',
          error: true,
          customMessage: formatMessage({
            id: 'COMMON_HISTORY.DOWNLOAD_JSON_FAILED',
            defaultMessage:
              'An error occurred while downloading this message. Please try again, or submit a support request.',
          }),
        })
      );
    }
  };

  const onRowSelect = (rowData, rowIndex: number): void => {
    onRowClick(rowData, rowIndex);
    setSelectedRow(rowData);
  };

  const pageSizeOptions = useGetPageSizeOptionsBasedOnTotalElements(
    paginationProps?.totalSize,
    PaginationSettings
  );

  const alertType = useMemo<TAlertType>(() => {
    const { status } = selectedRow?.message || {};
    switch (Number(status)) {
      case 200:
      case 201:
      case 202:
      case 203:
      case 204:
        return 'info';
      default:
        return 'error';
    }
  }, [selectedRow]);

  return (
    <div {...getTestIdAttr('CommonHistory')}>
      <Table<IRecord>
        {...getTestIdAttr('CommonHistory')}
        columns={formattedColumns}
        data={data}
        emptyMessage={emptyTableMessage}
        loading={isLoading}
        loadingMessage={loadingMessage}
        fixedHeader={true}
        pagination={
          !hidePagination
            ? {
                quantityIndicatorIntl: formatMessage({ id: 'PAGINATION.OF', defaultMessage: 'of' }),
                pageSizeOptionsIntl: (numberOfItems) =>
                  formatMessage(
                    {
                      id: 'PAGINATION.ITEMS_PER_PAGE',
                      defaultMessage: 'Show {field} items per page',
                    },
                    { field: numberOfItems }
                  ),
                current: page + 1,
                showPageSizeSelector: true,
                onChange: (page: number, pageSize: number) => {
                  setPage(page - 1);
                  setPageSize(pageSize);
                },
                total: paginationProps?.totalSize || 1,
                disabled: false,
                pageSize,
                pageSizeOptions,
              }
            : false
        }
        onRow={(rowData, rowIndex: number) => {
          return {
            onClick: () => onRowSelect(rowData, rowIndex),
          };
        }}
      />
      {showAlert && (
        <div className={`${alertTypeProp || alertType}-history-message`} id="history-message">
          <HistoryErrorMessage
            message={alertMessage}
            type={alertTypeProp || alertType}
            alertActionProps={{
              firstActionName: formatMessage({ id: 'BUTTONS.ClOSE', defaultMessage: 'Close' }),
              secondActionName:
                selectedRow?.message?.additional?.length &&
                formatMessage({
                  id: 'COMMON_HISTORY.DOWNLOAD_FULL_MESSAGE',
                  defaultMessage: 'Download full message',
                }),
              firstAction: handleClose,
              secondAction: selectedRow?.message?.additional?.length && handleDownload,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default CommonHistory;
