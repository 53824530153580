import {
  Add,
  Apply,
  Back,
  Cancel,
  Close,
  Confirm,
  Delete,
  Done,
  Duplicate,
  Next,
  Save,
  Submit,
} from './Button';
import {
  Active,
  AlmostThere,
  Description,
  Disabled,
  Email,
  Enabled,
  Inactive,
  Loading,
  Name,
  Search,
} from './CommonMessages';
export { PocsSelected } from './PocsSelected';
export { PocsUploaded } from './PocsUploaded';
export const Buttons = {
  Add,
  Apply,
  Back,
  Cancel,
  Close,
  Confirm,
  Delete,
  Done,
  Duplicate,
  Next,
  Save,
  Submit,
};
export const CommonMessages = {
  Active,
  AlmostThere,
  Description,
  Disabled,
  Email,
  Enabled,
  Inactive,
  Loading,
  Name,
  Search,
};
