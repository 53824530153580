import { Button, Chip } from '@hexa-ui/components';
import { Search } from '@hexa-ui/icons';
import styled, { css } from 'styled-components';

interface IInputContainerProps {
  containerWidth?: string;
  transparent?: boolean;
}

interface IButtonContainerProps {
  buttonMargin?: boolean;
}

interface IStyledSearchProps {
  leftmargin?: string;
}

export const ValuesContainer = styled.div`
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  column-gap: 4px;
  row-gap: 4px;
`;

export const ChipContainer = styled(Chip.Root)`
  text-overflow: ellipsis;
  max-width: 100%;
`;

export const Container = styled.div`
  flex: 1;
`;

export const InputArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1;
  column-gap: 4px;
  overflow: hidden;
`;

export const InputValuesContainer = styled.div`
  display: flex;
  column-gap: 4px;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 4px;
`;

export const StyledSearchIcon = styled(Search)<IStyledSearchProps>`
  margin-top: 4px;
  ${({ leftmargin }) =>
    leftmargin &&
    css`
      margin-left: ${leftmargin};
    `}
`;

export const ButtonText = styled.span`
  font-weight: 500;
`;

export const SearchButton = styled(Button)<IButtonContainerProps>`
  width: 100px;
`;

export const FormContainer = styled.div`
  min-height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 8px;
  row-gap: 16px;
  flex-wrap: wrap;
  width: 100%;
`;

export const InputContainer = styled.div<IInputContainerProps>`
  padding: 8px;
  border-radius: 24px;
  background-color: ${({ transparent }) => (transparent ? 'transparent' : 'rgba(0, 0, 0, 0.05)')};
  display: flex;
  align-items: center;
  width: ${({ containerWidth }) => containerWidth ?? '100%'};
  height: fit-content;
  flex-wrap: wrap;
  flex: 1;
  row-gap: 4px;
`;

export const StyledInput = styled.input`
  background: transparent;
  outline: none;
  height: 32px;
  width: 100%;
  text-overflow: ellipsis;
  color: #000000;
  font-family: 'Work Sans';
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  border: none;
  text-overflow: ellipsis;
`;
