import { createSlice } from '@reduxjs/toolkit';
import { getDateRange } from '~/utils';
import { IBooleanPayloadAction, INumberPayloadAction, IPromoDesign } from '~/interfaces';
import { RootState } from '~/redux/store';

export interface IPromotionDesignState {
  markedForDownload: string[];
  promoDesign: IPromoDesign[];
  loadingPromoDesign: boolean;
  reload: boolean;
  pagination: {
    totalSize: number;
  };
}
interface IPromotionListTableData {
  type: string;
  payload: string;
}

export type IRemoveIndex = INumberPayloadAction;

const initialState: IPromotionDesignState = {
  markedForDownload: [],
  reload: false,
  promoDesign: [],
  loadingPromoDesign: false,
  pagination: {
    totalSize: 0,
  },
};

const formattedDate = (date) => {
  return date?.substring(0, 10);
};

const promotionDesignSlice = createSlice({
  name: 'promoDesign',
  initialState,
  reducers: {
    setLoadingPromoDesign: (state, action: IBooleanPayloadAction): IPromotionDesignState => {
      return {
        ...state,
        loadingPromoDesign: action.payload,
      };
    },
    setPromoDesign: (state, action): IPromotionDesignState => {
      return {
        ...state,
        promoDesign: action.payload.data.map((design) => {
          design.dateRange = getDateRange(
            formattedDate(design?.periodStartDate),
            formattedDate(design?.periodEndDate),
            action.payload.localeCode,
            'PROMOTION_LIST',
            true // To hide year for current year.
          );
          return design;
        }),
        pagination: {
          totalSize: action?.payload?.pagination?.totalElements || 0,
        },
      };
    },
    addMarkForDownload: (state, action: IPromotionListTableData): IPromotionDesignState => {
      return {
        ...state,
        markedForDownload: [...state.markedForDownload, action.payload],
      };
    },
    removeMarkForDownload: (state, action: IRemoveIndex): IPromotionDesignState => {
      return {
        ...state,
        markedForDownload: [
          ...state.markedForDownload.slice(0, action.payload),
          ...state.markedForDownload.slice(action.payload + 1),
        ],
      };
    },
    setReloading: (state, action: IBooleanPayloadAction): IPromotionDesignState => {
      return {
        ...state,
        reload: action.payload,
      };
    },
    clearAllMarkedForDownload: (state) => {
      return {
        ...state,
        markedForDownload: [],
      };
    },
    resetPromoDesignSlice: () => {
      return {
        ...initialState,
      };
    },
  },
});

export const selectPromoDesignState = (state: RootState) => state.promoDesign;

export const {
  setLoadingPromoDesign,
  setPromoDesign,
  addMarkForDownload,
  removeMarkForDownload,
  clearAllMarkedForDownload,
  setReloading,
  resetPromoDesignSlice,
} = promotionDesignSlice.actions;
export default promotionDesignSlice.reducer;
