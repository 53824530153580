import { NumberSchema } from 'yup';
import { IValidationMessages, IValidationRule } from '~/interfaces';

export const greaterThanOrEqualToSpecificDependentValue = (
  validation: NumberSchema,
  rule: IValidationRule,
  fieldName: string,
  validationCallbacks: IValidationMessages
): NumberSchema => {
  validation = validation.when((dependentValue: number, schema: NumberSchema) => {
    if (rule?.value) {
      return schema.when(fieldName, (_thisField: number, _innerSchema: NumberSchema) => {
        return schema.min(
          Number(rule?.value),
          validationCallbacks.greaterThanOrEqualTo(fieldName, rule?.field)
        );
      });
    }

    if (rule?.dependent && !rule?.disabled) {
      return schema.when(fieldName, (thisField) => {
        if (thisField !== 0 && rule?.value) {
          return schema.required(
            validationCallbacks.requiresDependentField(fieldName, rule?.field)
          );
        }
      });
    }
  });
  return validation;
};
