import { number } from 'yup';
import { TValidationType, ISchemaField, IValidationMessages } from '~/interfaces';

export const validateNumber = (
  val: ISchemaField,
  validationCallbacks: IValidationMessages
): TValidationType => {
  let numberValidationType = number().typeError(validationCallbacks.mustBeANumber(val.label));
  if (val?.max !== undefined) {
    numberValidationType = numberValidationType.max(
      val.max,
      validationCallbacks.greaterThanMax(val.label, val.max)
    );
  }
  if (val?.min !== undefined) {
    numberValidationType = numberValidationType.min(
      val.min,
      validationCallbacks.lessThanMin(val.label, val.min)
    );
  }
  return numberValidationType;
};
