import { Theme } from '@admin-portal-shared-components/types';
import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    copyToClipboardButton: {
      textTransform: 'none',
      color: theme.bzColorSemanticInfoText,
      marginLeft: '-8px',
      '& svg:first-child': {
        fontSize: '0.75rem',
        visibility: 'hidden',
      },
      '&:hover': {
        backgroundColor: theme.bzColorSemanticInfoBackground,
        '& p': {
          color: theme.bzColorSemanticInfoText,
        },
        '& svg': {
          visibility: 'visible',
        },
      },
      '& p': {
        margin: 0,
        lineHeight: '1.125rem',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        display: '-webkit-box',
        overflow: 'hidden',
      },
    },
  })
);

export const useTooltipStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltip: {
      fontFamily: "'Work Sans', 'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica', sans-serif",
      fontSize: theme.bzFontSizeSmall,
      backgroundColor: 'black',
      fontWeight: 400,
    },
    arrow: {
      color: 'black',
    },
    tooltipPlacementTop: {
      margin: '8px 0',
      '& $arrow': {
        transform: 'translateY(-1px)',
      },
    },
    tooltipPlacementBottom: {
      margin: '8px 0',
      '& $arrow': {
        transform: 'translateY(1px)',
      },
    },
    tooltipPlacementRight: {
      margin: '0 8px',
      '& $arrow': {
        transform: 'translateX(1px)',
      },
    },
    tooltipPlacementLeft: {
      margin: '0 8px',
      '& $arrow': {
        transform: 'translateX(-1px)',
      },
    },
  })
);
