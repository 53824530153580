// example found on SteppedView.tsx
export const LOAD_DEFAULT_NUMBER_OF_PRODUCTS = 100;
const PRODUCTS_100 = 100;
const PRODUCTS_200 = 200;
const PRODUCTS_300 = 300;
const PRODUCTS_400 = 400;
const PRODUCTS_500 = 500;
export const LOAD_NUMBER_OF_PRODUCTS = [
  PRODUCTS_100,
  PRODUCTS_200,
  PRODUCTS_300,
  PRODUCTS_400,
  PRODUCTS_500,
];
