import { TAG_GENERAL, TAG_NAMES } from '~/constants';
import React from 'react';
import { ResultContainer } from './styles';
import { concatString, getTestIdAttr } from '~/utils';
import { TResultsIndicator } from './ResultsIndicator.types';

const { RESULTS_FOUND } = TAG_NAMES.ACCOUNT.SELECTION;

export const ResultsIndicator: TResultsIndicator = ({
  quantity = 0,
  pluralLabel,
  singularLabel,
}) => {
  if (quantity === 1) {
    return (
      <ResultContainer
        {...getTestIdAttr('ResultsIndicator')}
        id={concatString(TAG_GENERAL.LBL, {
          name: RESULTS_FOUND,
        })}
      >
        {singularLabel}
      </ResultContainer>
    );
  }

  return (
    <ResultContainer
      {...getTestIdAttr('ResultsIndicator')}
      id={concatString(TAG_GENERAL.LBL, {
        name: RESULTS_FOUND,
      })}
    >
      {pluralLabel}
    </ResultContainer>
  );
};
