import DesignTokensProvider from '@admin-portal-shared-components/design-tokens-provider';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter } from 'react-router-dom';
import { EnvConfig, EnvProvider, GlobalToasts } from '~/components';
import { APPLICATION_TITLE } from '~/constants';
import { IntlProvider } from '~/i18n';
import Router from '~/Routes';
import { GlobalStoreWrapper, ServicesWrapper } from '~/wrappers';
import { QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { createIDBPersister } from '~/redux/Persister';
import '~/App.css';
import { ConfigProvider } from '~/provider';

Object.defineProperty(String.prototype, 'capitalize', {
  value() {
    return this.charAt(0).toUpperCase() + this.slice(1).toLowerCase();
  },
  enumerable: false,
});

export const theme = createTheme({
  palette: {
    text: {
      primary: 'rgba(0, 0, 0, 0.9)',
      secondary: 'rgba(0, 0, 0, 0.55)',
    },
  },
  typography: {
    fontFamily: 'Barlow, sans-serif',
  },
});
const FOUR_HOURS = 14400000;
const FIFTEEN_MINUTES = 900000;
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: FOUR_HOURS,
      staleTime: FIFTEEN_MINUTES,
      retry: true,
      refetchOnWindowFocus: false,
    },
  },
});
export const persister = createIDBPersister();

export default function App(props: EnvConfig) {
  const { enableDevTools } = props;
  return (
    <>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
      <link
        href="https://fonts.googleapis.com/css2?family=Barlow:wght@100;400;600&display=swap"
        rel="stylesheet"
      />
      <EnvProvider env={props}>
        <IntlProvider>
          <GlobalStoreWrapper>
            <ServicesWrapper
              applicationTitle={APPLICATION_TITLE}
              optimizelyKey={props.optimizelyKey}
            >
              <DesignTokensProvider>
                <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
                  {enableDevTools && <ReactQueryDevtools />}
                  <BrowserRouter>
                    <ThemeProvider theme={theme}>
                      <GlobalToasts />
                      <ConfigProvider>
                        <Router />
                      </ConfigProvider>
                    </ThemeProvider>
                  </BrowserRouter>
                </PersistQueryClientProvider>
              </DesignTokensProvider>
            </ServicesWrapper>
          </GlobalStoreWrapper>
        </IntlProvider>
      </EnvProvider>
    </>
  );
}
