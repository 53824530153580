import { useMemo } from 'react';
import { useFeatureToggleResponse, useSelectedCountry } from '~/hooks';
import { getStatusesForDuplicate } from '~/configs/statusesForDuplicate';
import { DUPLICATE_PROMO_BUTTON } from '~/constants';
import { IPromoDesign } from '~/interfaces';
import { every } from '~/utils';

export const useCanDuplicatePromo = (promoDesign: IPromoDesign): boolean => {
  const selectedCountry = useSelectedCountry();

  const isAllowedStatus = useMemo<boolean>(() => {
    return getStatusesForDuplicate(selectedCountry).includes(promoDesign?.status);
  }, [selectedCountry, promoDesign?.status]);

  const [isDuplicateFeatureToggleEnabled, isLoadingDuplicatePromoButtonToggle] =
    useFeatureToggleResponse(DUPLICATE_PROMO_BUTTON, { country: selectedCountry });

  return every(
    isAllowedStatus,
    isDuplicateFeatureToggleEnabled,
    !isLoadingDuplicatePromoButtonToggle
  );
};
