import { TMechanicTypeOption, IPercentValues, TPromoDesignSkuField } from '~/interfaces';

const PERCENT_VALUE_FIELDS: IPercentValues = {
  default: {
    discount_percent: ['allocation', 'maxDiscount', 'minDiscount', 'discount'],
    price_point: ['allocation'],
    purchase_multiple_volume_fixed: ['allocation', 'minDiscount', 'maxDiscount'],
  },
};

export const getPercentValueFields = (
  country?: string,
  mechanicTypeOption?: TMechanicTypeOption
): TPromoDesignSkuField[] => {
  let values;
  if (!country) {
    values = PERCENT_VALUE_FIELDS.default;
  } else {
    values = PERCENT_VALUE_FIELDS?.[country] ?? PERCENT_VALUE_FIELDS.default;
  }

  if (mechanicTypeOption) {
    return values[mechanicTypeOption];
  }
  return values['discount_percent'];
};

export const getNumberValueFields = (): TPromoDesignSkuField[] => {
  return ['orderAvailability', 'skuLimit', 'minQuantity', 'maxQuantity'];
};
