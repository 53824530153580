import { TFormatDateTypes, IDateFormat } from '~/interfaces';

const dateFormats: IDateFormat = {
  DEFAULT: {
    default: { year: 'numeric', month: 'short', day: '2-digit', timeZone: 'UTC' },
    'en-US': { year: 'numeric', month: 'short', day: '2-digit', timeZone: 'UTC' },
    'pt-BR': { year: 'numeric', month: 'short', day: '2-digit', timeZone: 'UTC' },
  },
  PROMOTION_LIST: {
    default: { year: 'numeric', month: 'short', day: '2-digit', timeZone: 'UTC' },
    'en-US': { year: 'numeric', month: 'short', day: '2-digit', timeZone: 'UTC' },
    'pt-BR': { year: 'numeric', month: 'short', day: '2-digit', timeZone: 'UTC' },
  },
  DATE_TIME: {
    default: {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
};

export const getDateFormat = (
  localeCode: string,
  useCase?: TFormatDateTypes,
  isHideCurrentYear = false
): Intl.DateTimeFormatOptions => {
  const selectedDateFormat =
    dateFormats?.[useCase]?.[localeCode] ??
    dateFormats[useCase]?.default ??
    dateFormats.DEFAULT?.[localeCode] ??
    dateFormats.DEFAULT.default;
  if (isHideCurrentYear) {
    const { year, ...dateProperty } = selectedDateFormat;
    return dateProperty;
  }
  return selectedDateFormat;
};

// Date used to limit date picker max date.
export const MAX_DATE = '2100-12-31';

export default dateFormats;
