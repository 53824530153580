import {
  IExternalHistory,
  IExternalHistoryValidationResponse,
  IOverlappingPromoDetail,
} from '~/interfaces';
import { IntlFormatters } from 'react-intl';
import { ExternalHistoryValidator } from '@moneycomb/utils';

export const getOverlapData = (data: IExternalHistory): IOverlappingPromoDetail[] => {
  return data.externalResponse.additional;
};

type ValidationStrategy = {
  validator: (data: IExternalHistory) => boolean;
  errorMessage: (
    formatMessage: IntlFormatters['formatMessage'],
    data: IExternalHistory
  ) => string | { id: string; defaultMessage: string; values?: any };
};

const validationStrategies: ValidationStrategy[] = [
  {
    validator: ExternalHistoryValidator.hasSteppedDiscountSustainIncompatibility,
    errorMessage: (formatMessage) =>
      formatMessage({
        id: 'ERROR_MESSAGES.NO_SUSTAIN_WITH_STEPPED_DISCOUNT',
        defaultMessage: 'Personalized stepped discounts with strategy "Sustain" are not supported.',
      }),
  },
  {
    validator: ExternalHistoryValidator.hasInteractiveComboGenerateDemandIncompatibility,
    errorMessage: (formatMessage) =>
      formatMessage({
        id: 'ERROR_MESSAGES.NO_GENERATE_DEMAND_WITH_INTERACTIVE_COMBO',
        defaultMessage:
          'Personalized interactive combos with strategy "Generate Demand" are not supported.',
      }),
  },
  {
    validator: ExternalHistoryValidator.hasSteppedDiscountOperationsIncompatibility,
    errorMessage: (formatMessage) =>
      formatMessage({
        id: 'ERROR_MESSAGES.NO_OPERATIONS_WITH_STEPPED_DISCOUNT',
        defaultMessage:
          'Personalized stepped discounts with strategy "Operations" are not supported.',
      }),
  },
  {
    validator: ExternalHistoryValidator.hasRewardsChallengeOperationsIncompatibility,
    errorMessage: (formatMessage) =>
      formatMessage({
        id: 'ERROR_MESSAGES.NO_OPERATIONS_WITH_REWARDS_CHALLENGE',
        defaultMessage:
          'Personalized rewards challenges with strategy "Operations" are not supported.',
      }),
  },
  {
    validator: ExternalHistoryValidator.hasSteppedDiscountIntroduceIncompatibility,
    errorMessage: (formatMessage) =>
      formatMessage({
        id: 'ERROR_MESSAGES.NO_INTRODUCE_WITH_STEPPED_DISCOUNT',
        defaultMessage:
          'Personalized stepped discounts with strategy "Introduce" are not supported.',
      }),
  },
  {
    validator: ExternalHistoryValidator.doesOverlap,
    errorMessage: (formatMessage, data) => {
      const overlapping = ExternalHistoryValidator.getOverlappingPromoDesignIds(data);
      return formatMessage(
        {
          id: 'ERROR_MESSAGES.OVERLAPPING_PROMO_DESIGN',
          defaultMessage:
            '{count, plural, one {This promo design overlaps with promo design {promoDesignId}} other {This promo design overlaps with # other promo designs}}',
        },
        {
          count: overlapping.length,
          promoDesignId: overlapping.length === 1 ? overlapping[0] : '',
        }
      );
    },
  },
  {
    validator: ExternalHistoryValidator.hasDistinctBudgetIdError,
    errorMessage: (formatMessage) =>
      formatMessage({
        id: 'ERROR_MESSAGES.DISTINCT_BUDGET_ID_ERROR',
        defaultMessage: 'There should only be one distinct Budget ID across all SKUs.',
      }),
  },
  {
    validator: ExternalHistoryValidator.hasInvalidSkusMessage,
    errorMessage: (formatMessage, data) => {
      const invalidSkus = ExternalHistoryValidator.getInvalidSkus(data);
      return formatMessage(
        {
          id: 'ERROR_MESSAGES.INVALID_SKUS_ERROR',
          defaultMessage:
            '{count, plural, one {This promo design contains the following invalid SKU: {sku}} other {This promo design contains # invalid SKUs: {sku}}}',
        },
        {
          count: invalidSkus.length,
          sku: invalidSkus.join(', '),
        }
      );
    },
  },
];

export const validateExternalHistory = (
  data: IExternalHistory,
  formatMessage: IntlFormatters['formatMessage']
): IExternalHistoryValidationResponse => {
  const response: IExternalHistoryValidationResponse = { isValid: true };

  for (const strategy of validationStrategies) {
    if (strategy.validator(data)) {
      response.error = strategy.errorMessage(formatMessage, data);
      break;
    }
  }

  if (ExternalHistoryValidator.hasFailed(data)) {
    response.isValid = false;
  } else {
    console.error('Unhandled error in external history validator');
  }

  return response;
};
