import styled from 'styled-components';

export const SubmitButton = styled.button`
  outline: none;
  background-color: #fff;
  font-size: 16px;
  border-radius: 6px;
  margin-left: 6px;
  padding: 6px;
  cursor: pointer;
`;

export const Label = styled.label`
  font-size: 18px;
  margin-right: 6px;
`;

export const Input = styled.input`
  padding: 8px;
  font-size: 16px;
`;
