import { APPLICATION_TITLE } from '~/constants';
import { useAuthenticationService, useFeatureToggleServiceV2 } from 'admin-portal-shared-services';
import { useEffect, useState } from 'react';
import {
  IOptimizelyAudienceFields,
  TFeatureToggleMultiResponse,
  TFeatureToggleResponse,
  TFeatureToggleUserInfo,
} from '~/interfaces';

const hasSameInfo = (objectA: TFeatureToggleUserInfo, objectB: TFeatureToggleUserInfo): boolean => {
  const keys = Object.keys(objectA);

  const comparedKeys = keys.map((key) => {
    return objectA[key] === objectB[key];
  });
  return !comparedKeys.includes(false);
};

function useFeatureToggleV2(
  featureName: string,
  projectName: string,
  audience: IOptimizelyAudienceFields
): TFeatureToggleResponse {
  const [response, setResponse] = useState<TFeatureToggleResponse>([undefined, true]);
  const service = useFeatureToggleServiceV2(projectName);
  const authentication = useAuthenticationService();
  const store = service.getStore();
  const zone = audience?.country ?? authentication.getCountryB2C();
  const userId = authentication.getUserId();
  const country = zone.toUpperCase();

  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (!isReady) {
      service.onReady().then(() => {
        setIsReady(true);
      });
    }
  }, [isReady]);

  useEffect(() => {
    if (!isReady) {
      return;
    }
    getToggleStatus().then((status) => {
      store.getState()[featureName] = {
        userId,
        country,
        value: status,
      };
      setResponse([status, false]);
    });
  }, [featureName, userId, country, isReady]);

  async function getToggleStatus() {
    const state = store.getState();
    const featureToggle = state?.[featureName];
    if (featureToggle?.userId === userId && featureToggle?.country === country) {
      return Promise.resolve(featureToggle.value);
    } else {
      return service.isFeatureEnabledAudiences(featureName, userId, {
        userId,
        country,
      });
    }
  }

  return response;
}

function useFeatureTogglesV2(
  featureNames: string[],
  projectName: string,
  audience: IOptimizelyAudienceFields
): TFeatureToggleMultiResponse {
  const initial: TFeatureToggleMultiResponse = {};
  featureNames.forEach((name) => {
    initial[name] = [undefined, true];
  });
  const [response, setResponse] = useState<TFeatureToggleMultiResponse>(initial);
  const service = useFeatureToggleServiceV2(projectName);
  const authentication = useAuthenticationService();
  const store = service.getStore();
  const state = store.getState();

  const zone = audience?.country ?? authentication.getCountryB2C();
  const userId = authentication.getUserId();
  const country = zone.toUpperCase();

  const isReady = service.getIsReady();

  const getStoredToggles = (): { [key: string]: boolean | null } => {
    const map = {};
    for (const featureName of featureNames) {
      if (state[featureName]) {
        const featureToggle = state[featureName];
        map[featureName] = hasSameInfo(
          { userId: featureToggle.userId, country: featureToggle.country },
          { userId, country }
        )
          ? featureToggle.value
          : null;
      } else {
        map[featureName] = null;
      }
    }
    return map;
  };

  const updateToggleStatus = async () => {
    const storedToggles = getStoredToggles();
    const map: TFeatureToggleMultiResponse = await Promise.all<TFeatureToggleMultiResponse>(
      featureNames.map((featureName) => {
        const storedToggle = storedToggles[featureName];
        if (storedToggle !== null) {
          return Promise.resolve({ [featureName]: [storedToggle, false] });
        } else {
          return service
            .isFeatureEnabledAudiences(featureName, userId, {
              userId,
              country,
            })
            .then((status) => {
              return { [featureName]: [status, false] };
            });
        }
      })
    ).then((responses) => {
      const map = {};
      for (const res of responses) {
        const featureName = Object.keys(res)[0];
        map[featureName] = res[featureName];
      }
      return map;
    });
    setResponse(map);
  };

  const deps = featureNames.map((name) => state[name]);
  useEffect(() => {
    const map = {
      ...response,
    };

    for (const [featureName, [featureToggleStatus, isLoading]] of Object.entries(map)) {
      if (isLoading === false && state[featureName]) {
        const isSameCall = hasSameInfo(
          { userId, country },
          {
            userId: state[featureName].userId,
            country: state[featureName].country,
          }
        );

        if (isSameCall && featureToggleStatus !== state[featureName].value) {
          map[featureName] = [state[featureName].value, false];
        }
      }
    }
    setResponse(map);
  }, deps);

  useEffect(() => {
    if (isReady) {
      updateToggleStatus();
    } else {
      service.onReady().then(() => {
        updateToggleStatus();
      });
    }
  }, []);

  useEffect(() => {
    if (country && isReady) {
      updateToggleStatus();
    }
  }, [country, isReady]);

  return response;
}

export const useFeatureToggle = (
  toggleName: string,
  audience: IOptimizelyAudienceFields,
  defaultValue = false
): boolean => {
  return useFeatureToggleV2(toggleName, APPLICATION_TITLE, audience)?.[0] ?? defaultValue;
};

export const useFeatureToggleResponse = (
  toggleName: string,
  audience: IOptimizelyAudienceFields
): TFeatureToggleResponse => {
  return useFeatureToggleV2(toggleName, APPLICATION_TITLE, audience);
};

export const useFeatureTogglesResponse = (
  toggleNames: string[],
  audience: IOptimizelyAudienceFields
): TFeatureToggleMultiResponse => {
  return useFeatureTogglesV2(toggleNames, APPLICATION_TITLE, audience);
};
