import { useEffect, useRef, useState } from 'react';
import { IUser } from '~/interfaces';
import { useGetUsersByIds } from '~/services';
import { useIntl } from 'react-intl';
import {
  Container,
  CustomerContacts,
  CustomerContainer,
  CustomerEmail,
  CustomerHeader,
  CustomerIcon,
  CustomerName,
  List,
  ListContainer,
  ResultContainer,
  StyledForm,
  StyledListItem,
} from './styles';
import { TAG_GENERAL, TAG_NAMES } from '~/constants';
import { Mail } from '@hexa-ui/icons';
import { concatString, formatText, getTestIdAttr } from '~/utils';
import { EnabledDisabled, Form, LoadingSpinner, ResultsIndicator } from '~/components';
import { useCanReadFromIdentity } from '~/hooks';
import { TFindUser } from '~/components/FindUser/FindUser.types';

const { USER_NAME, USER_EMAIL, USER_ICON } = TAG_NAMES.USER;
const { STATUS } = TAG_NAMES.ACCOUNT.SELECTION;

const FindUser: TFindUser = ({ onClick = (_user) => {}, users }) => {
  const ref = useRef(null);
  const [search, setSearch] = useState<string>('');
  const canReadFromIdentity = useCanReadFromIdentity();
  const { data, isFetching = false } = useGetUsersByIds([search], canReadFromIdentity, null, {
    enabled: !!search && search?.trim() !== '',
  });
  const { formatMessage } = useIntl();

  const [results, setResults] = useState<IUser[]>([]);
  useEffect(() => {
    setResults(data ?? []);
  }, [data]);

  const handleUserClick = (user: IUser): void => {
    onClick(user);
    setResults([]);
    setSearch('');
    ref?.current?.handleReset();
  };

  return (
    <>
      <StyledForm {...getTestIdAttr('FindUser')}>
        <Form
          formId={'find-user'}
          ref={ref}
          buttonLabel={formatMessage({
            id: 'CONSTS.SEARCH',
            defaultMessage: 'Search',
          })}
          onSubmit={(values) => {
            setSearch(values.search);
          }}
          schema={[
            {
              disabled: !canReadFromIdentity || isFetching,
              name: 'search',
              required: true,
              type: 'text',
              componentType: 'text',
              defaultValue: '',
              label: formatMessage({ id: 'ADD_USERS.USER', defaultMessage: 'User' }),
              placeholder: users.length
                ? formatMessage({ id: 'ADD_USERS.ADD_ANOTHER', defaultMessage: 'Add another' })
                : formatMessage({
                    id: 'ADD_USERS.ADD_USER',
                    defaultMessage: 'Add user ID, name, or email address',
                  }),
            },
          ]}
        />
      </StyledForm>

      <Container>
        <ResultContainer>
          {!!search && (
            <ResultsIndicator
              quantity={results?.length}
              singularLabel={formatMessage(
                { id: 'USER_SEARCH.RESULT_FOUND', defaultMessage: '{quantity} user found' },
                { quantity: results?.length }
              )}
              pluralLabel={formatMessage(
                { id: 'USER_SEARCH.RESULTS_FOUND', defaultMessage: '{quantity} users found' },
                { quantity: results?.length }
              )}
            />
          )}
        </ResultContainer>
        <div>
          {isFetching && (
            <div style={{ justifyContent: 'center', maxHeight: '100px', display: 'flex' }}>
              <LoadingSpinner
                titleMessage={formatMessage({
                  id: 'ADD_USERS.LOADING_USERS',
                  defaultMessage: 'Loading users',
                })}
              />
            </div>
          )}
          <ListContainer>
            <List>
              {!isFetching &&
                results?.map((user: IUser, index) => (
                  <StyledListItem
                    {...getTestIdAttr('FindUserResults')}
                    variant="navigation"
                    divider
                    key={user?.id}
                    onClick={() => {
                      handleUserClick(user);
                    }}
                  >
                    <CustomerIcon
                      size="xlarge"
                      id={concatString(TAG_GENERAL.ICN_INDEX, {
                        name: USER_ICON,
                        index,
                      })}
                    />
                    <CustomerContainer overshadow={String(user.status !== 'ACTIVE')}>
                      <CustomerHeader
                        id={concatString(TAG_GENERAL.LBL_INDEX, {
                          name: USER_NAME,
                          index,
                        })}
                      >
                        <CustomerName>{user.name}</CustomerName>
                        <EnabledDisabled
                          labelId={concatString(TAG_GENERAL.LBL_INDEX, {
                            name: STATUS,
                            index,
                          })}
                          hideLabel
                          enabled={user.status === 'ACTIVE'}
                          enabledText={formatText(user?.status)}
                          disabledText={formatText(user?.status)}
                        />
                      </CustomerHeader>
                      <CustomerContacts>
                        <Mail size="medium" />
                        <CustomerEmail
                          id={concatString(TAG_GENERAL.LBL_INDEX, {
                            name: USER_EMAIL,
                            index,
                          })}
                        >
                          {user.email}
                        </CustomerEmail>
                      </CustomerContacts>
                    </CustomerContainer>
                  </StyledListItem>
                ))}
            </List>
          </ListContainer>
        </div>
      </Container>
    </>
  );
};

export default FindUser;
