import { IGlobalParametersResponse } from '@moneycomb/mcomb-types/response';
import Bff from '~/Api/Bff';
import { AxiosResponse } from 'axios';
import { UseQueryOptions, useQuery, UseQueryResult } from '@tanstack/react-query';
import { checkError } from '~/redux/saga/sagaActions';
import { useAppDispatch, useSelectedCountry } from '~/hooks';
import { IGlobalParameters } from '~/interfaces';

export const getGlobalParameters = async (): Promise<AxiosResponse<IGlobalParametersResponse>> => {
  return Bff.get<IGlobalParametersResponse>({
    url: '/v1/parameters/global',
  }).then((response) => {
    return response;
  });
};

export const useGetGlobalParameters = (
  options?: UseQueryOptions
): UseQueryResult<IGlobalParametersResponse> => {
  const selectedCountry = useSelectedCountry();
  const dispatch = useAppDispatch();
  return useQuery<IGlobalParameters, any, any, any>({
    queryKey: ['getGlobalParameters', selectedCountry],
    queryFn: async () => {
      const response = await getGlobalParameters();
      return response?.data;
    },
    onSuccess: (e) => {
      dispatch(checkError(e));
    },
    ...options,
  });
};
