import { useFeatureToggleResponse } from './useFeatureToggle';
import { SHOW_SET_USER_NAME_LINK } from '~/constants';
import useSelectedCountry from './useSelectedCountry';
import { useMemo } from 'react';

export const useCanShowProfileNameUpdatePrompt = (): boolean => {
  const selectedCountry = useSelectedCountry();
  const [value, isLoading] = useFeatureToggleResponse(SHOW_SET_USER_NAME_LINK, {
    country: selectedCountry,
  });
  return useMemo<boolean>(() => {
    return value;
  }, [value, isLoading]);
};

export default useCanShowProfileNameUpdatePrompt;
