import { useFeatureToggle, useFeatureToggleResponse } from './useFeatureToggle';
import { CAN_ACCESS_FEATURE_TOGGLES_PAGE } from '~/constants';
import useSelectedCountry from './useSelectedCountry';
import { TFeatureToggleResponse } from '~/interfaces';

export const useCanAccessFeatureTogglesPageResponse = (): TFeatureToggleResponse => {
  const selectedCountry = useSelectedCountry();
  return useFeatureToggleResponse(CAN_ACCESS_FEATURE_TOGGLES_PAGE, { country: selectedCountry });
};

export const useCanAccessFeatureTogglesPage = () => {
  const selectedCountry = useSelectedCountry();
  return useFeatureToggle(CAN_ACCESS_FEATURE_TOGGLES_PAGE, { country: selectedCountry }, false);
};

export default useCanAccessFeatureTogglesPage;
