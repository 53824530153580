import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  countryNoSelection: {
    display: 'flex',
    alignItems: 'center',
    width: '250px',
    height: '40px',
    padding: '16px',
  },
  contentCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  componentStyle: {
    alignSelf: 'center',
    justifySelf: 'start',
  },
  fullWidth: {
    width: '100%',
  },
  root: {
    display: 'flex',
    background: 'rgb(255, 255, 255)',
    border: '1px solid rgba(0,0,0,0.15)',
    borderRadius: '20.5px',
    height: '0px',
    padding: '12px',
    alignItems: 'center',
  },
  outline: {
    border: '1px solid rgba(0,0,0,0)',
    borderRadius: '20.5px',
  },
}));

export default useStyles;
