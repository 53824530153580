import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Paragraph, Grid } from '@hexa-ui/components';
import { getFromSessionStorage, saveToSessionStorage, every, getTestIdAttr } from '~/utils';
import { HAS_DISMISSED_PROFILE_NAME_UPDATE_PROMPT } from '~/constants';
import {
  useIsUserMissingProfileName,
  useCanShowProfileNameUpdatePrompt,
  useAppSelector,
  useAppDispatch,
} from '~/hooks';
import { setDismissedPromptForever } from '~/redux/saga/sagaActions';
import { selectGlobalState } from '~/redux/slices';
import './SetUserProfileOption.css';

const updatePromptDismissedStatus = (value: boolean): void => {
  saveToSessionStorage(HAS_DISMISSED_PROFILE_NAME_UPDATE_PROMPT, value);
};

const SetUserProfileOption = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { haveDismissedPromptForever } = useAppSelector(selectGlobalState);
  const isUserMissingProfileName = useIsUserMissingProfileName();
  const canShowPrompt = useCanShowProfileNameUpdatePrompt();
  const hasDismissedPrompt = getFromSessionStorage<boolean>(
    HAS_DISMISSED_PROFILE_NAME_UPDATE_PROMPT
  );
  const [isPromptDisplayed, setIsPromptDisplayed] = useState<boolean>(
    hasDismissedPrompt === undefined ? true : !hasDismissedPrompt
  );

  const shouldRenderPrompt: boolean = useMemo(() => {
    return every(
      canShowPrompt, // Feature Toggle
      isUserMissingProfileName, // Requires Update
      isPromptDisplayed, // Component state
      !haveDismissedPromptForever
    );
  }, [isUserMissingProfileName, isPromptDisplayed, canShowPrompt, haveDismissedPromptForever]);

  const handleDismiss = (isDismissedPromptForever: boolean = false): void => {
    setIsPromptDisplayed(false);
    updatePromptDismissedStatus(true);
    if (isDismissedPromptForever) {
      dispatch(setDismissedPromptForever({ haveDismissedPromptForever: true }));
    }
  };

  useEffect(() => {
    if (hasDismissedPrompt === undefined) {
      updatePromptDismissedStatus(false);
    }
  }, []);

  if (!shouldRenderPrompt) {
    return null;
  }

  return (
    <Grid.Container {...getTestIdAttr('SetUserProfileOption')} style={{ width: '100%', margin: 0 }}>
      <Grid.Item xs={12} sm={12} md={12} lg={12} xl={12} className="set-user-wrapper">
        <Grid.Item
          xs={8}
          sm={8}
          md={8}
          lg={8}
          xl={8}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <Paragraph style={{ alignItems: 'center', display: 'flex' }}>
            <FormattedMessage
              id={'SET_NAME_OPTION.MESSAGE'}
              defaultMessage={
                'Your user name doesn\'t seem to be updated, it is still "New User". Please\u00A0'
              }
            />
            <Link to="/account" className="link">
              <FormattedMessage
                id={'SET_NAME_OPTION.UPDATE_MESSAGE'}
                defaultMessage={'update the user name'}
              />
            </Link>
          </Paragraph>
        </Grid.Item>
        <Grid.Item
          xs={4}
          sm={4}
          md={4}
          lg={4}
          xl={4}
          style={{ display: 'flex', flexDirection: 'row-reverse' }}
        >
          <Grid.Item
            xs={2}
            sm={2}
            md={2}
            lg={2}
            xl={2}
            style={{ display: 'flex', flexDirection: 'row-reverse' }}
          >
            <Paragraph
              className="link x-link"
              onClick={() => {
                handleDismiss(false);
              }}
              {...getTestIdAttr('SetUserProfileOption-x')}
            >
              x
            </Paragraph>
          </Grid.Item>

          <Paragraph
            className="link"
            onClick={() => {
              handleDismiss(true);
            }}
          >
            <FormattedMessage
              id={'SET_NAME_OPTION.DONT_SHOW_AGAIN_MESSAGE'}
              defaultMessage={'Do not show again'}
            />
          </Paragraph>
        </Grid.Item>
      </Grid.Item>
    </Grid.Container>
  );
};

export default SetUserProfileOption;
