import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  parameters: [],
  openDialog: false,
};

const budgetSlice = createSlice({
  name: 'budgetCreation',
  initialState,
  reducers: {
    setOpenBudgetDialog: (state, action) => {
      state.openDialog = action.payload;
    },
    setBudgetParameters: (state, action) => {
      state.parameters = action.payload.businessUnits;
    },
    resetBudgetParameters: () => {
      return {
        ...initialState,
      };
    },
  },
});

export const { setOpenBudgetDialog, setBudgetParameters, resetBudgetParameters } =
  budgetSlice.actions;
export default budgetSlice.reducer;
