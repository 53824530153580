import { formatRelative } from 'date-fns';
import { useUserDateLocale } from './useUserDateLocale';

export const useLocalizedDatetime = (input: string): string => {
  const userLocale = useUserDateLocale();
  let d = new Date(input);
  if (!input?.endsWith('Z')) {
    try {
      d = new Date(`${input}Z`);
    } catch (e) {}
  }

  return formatRelative(d, new Date(), {
    locale: userLocale,
  });
};
