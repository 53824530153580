import { ISchemaField, IValidationMessages, TComponentType, TValidationType } from '~/interfaces';
import {
  validateArray,
  validateDatePicker,
  validateFloat,
  validateJson,
  validateNumber,
  validateObject,
  validatePercent,
  validateString,
  validateFileUpload,
} from '~/components/Form/helpers/validators';

export const getValidationTypeFromComponentType = (
  val: ISchemaField,
  componentType: TComponentType,
  validationCallbacks: IValidationMessages,
  localeCode: string
): TValidationType | null => {
  switch (componentType) {
    case 'text':
    case 'textarea':
    case 'select':
    case 'radioGroup':
    case 'radio':
    case 'checkbox':
      return validateString(val?.maxLength);
    case 'textWithTags':
    case 'multiselect':
      return validateArray(val, validationCallbacks);
    case 'checkboxgroup':
      return validateObject(val, validationCallbacks);
    case 'number':
      return validateNumber(val, validationCallbacks);
    case 'float':
      return validateFloat(val, validationCallbacks);
    case 'percent':
      return validatePercent(val, validationCallbacks);
    case 'datePicker':
      return validateDatePicker(val, validationCallbacks, localeCode);
    case 'json':
      return validateJson(val, validationCallbacks);
    case 'fileUpload':
      return validateFileUpload(val, validationCallbacks);
  }
  return null;
};
