import { date, DateSchema } from 'yup';
import { formatDate } from '~/utils';
import { ISchemaField, IValidationMessages } from '~/interfaces';

export const validateDatePicker = (
  val: ISchemaField,
  validationCallbacks: IValidationMessages,
  localeCode: string
): DateSchema => {
  let datePickerValidationType = date();

  if (val?.maxDate !== undefined && !!val?.maxDate.length) {
    datePickerValidationType = datePickerValidationType.max(
      val.maxDate,
      validationCallbacks.greaterThanMax(val.label, formatDate(val.maxDate, localeCode))
    );
  }
  if (val?.minDate !== undefined && !!val?.minDate.length) {
    datePickerValidationType = datePickerValidationType.min(
      val.minDate,
      validationCallbacks.lessThanMinDate(val.label, formatDate(val.minDate, localeCode))
    );
  }
  return datePickerValidationType;
};
