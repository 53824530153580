import { IUser } from '@moneycomb/mcomb-types';
import { isNotEmptyObj } from '@moneycomb/utils';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LIFT_ROUTES } from '~/constants';
import { useAppDispatch, useCanReadFromIdentity } from '~/hooks';
import { TToastType, IPOCParameters, IProductParameters } from '~/interfaces';
import { fetchProductFilters } from '~/redux/saga/sagaActions';
import { setIsLoading } from '~/redux/slices';
import { StoreState } from '~/redux/store';
import { getUserById, useSelectedPOCs, useSelectedSKUs, useUserPromoPermissions } from '~/services';

interface IUsersFiltersProps {
  selectedCountry: string;
  setSelectedCountry: React.Dispatch<React.SetStateAction<string>>;
  selectedUser: IUser & {
    index: number;
  };
  setSelectedUser: (
    user?: IUser & {
      index: number;
    }
  ) => void;
  setSelectedPocs: React.Dispatch<React.SetStateAction<IPOCParameters>>;
  setSelectedSKUs: React.Dispatch<React.SetStateAction<IProductParameters>>;
  abortLoading: () => void;
}

interface IPOCsFiltersProps {
  selectedCountry: string;
  selectedUser: IUser & {
    index: number;
  };
  canReadFromIdentity: boolean;
  selectedPOCs: IPOCParameters;
  setSelectedPOCs: React.Dispatch<React.SetStateAction<IPOCParameters>>;
  isLoading: boolean;
  disabled: boolean;
}

interface ISKUsFiltersProps {
  selectedCountry: string;
  selectedUser: IUser & {
    index: number;
  };
  canReadFromIdentity: boolean;
  selectedSKUs: IProductParameters;
  setSelectedSKUs: React.Dispatch<React.SetStateAction<IProductParameters>>;
  isLoading: boolean;
  disabled: boolean;
}

interface IActionButtonsProps {
  handleSave: () => void;
  handleCancel: () => void;
  handleBack: () => void;
  formDisabled: boolean;
  isLoading: boolean;
  isSaving: boolean;
}

interface IToastProps {
  onClose: () => void;
  open: boolean;
  message: string;
  type: TToastType;
}

interface IUsePermissionFiltersForm {
  isLoading: boolean;
  canReadFromIdentity: boolean;
  usersFiltersProps: IUsersFiltersProps;
  pocsFiltersProps: IPOCsFiltersProps;
  skusFiltersProps: ISKUsFiltersProps;
  actionButtonProps: IActionButtonsProps;
  toastProps: IToastProps;
}

export const usePermissionFiltersForm = (): IUsePermissionFiltersForm => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { formatMessage } = useIntl();
  const { isLoading, selectedCountry: _selectedCountry } = useSelector(
    (state: StoreState) => state.countries
  );
  const [selectedCountry, setSelectedCountry] = useState(_selectedCountry);
  const [selectedUser, _setSelectedUser] = useState<IUser & { index: number }>();
  const canReadFromIdentity = useCanReadFromIdentity();
  const [selectedPOCs, setSelectedPOCs] = useState<IPOCParameters>({});
  const [selectedSKUs, setSelectedSKUs] = useState<IProductParameters>({});

  const isPOCEmpty = isNotEmptyObj(selectedPOCs);
  const isSkuEmpty = isNotEmptyObj(selectedSKUs);

  const firstPocConfiguration = useRef(false);
  const firstSkuConfiguration = useRef(false);

  const dispatch = useAppDispatch();

  const { createPromoPermissions, isSaving: userPromoPermIsSaving } = useUserPromoPermissions({
    country: selectedCountry,
  });

  const {
    getSelectedPOCs,
    deleteSelectedPOCs,
    changeSelectedPOCs,
    isSaving: pocsIsSaving,
    setIsSaving: setPOCsIsSaving,
    isLoading: selectedPOCsLoading,
  } = useSelectedPOCs({
    country: selectedCountry,
  });

  const {
    getSelectedSKUs,
    deleteSelectedSKUs,
    changeSelectedSKUs,
    isSaving: SKUsIsSaving,
    setIsSaving: setSKUsIsSaving,
    isLoading: selectedSKUssLoading,
  } = useSelectedSKUs({
    country: selectedCountry,
  });

  const [toast, setToast] = useState<{
    open: boolean;
    message: string;
    type: TToastType;
  }>({
    open: false,
    message: '',
    type: 'info',
  });

  const formDisabled = !selectedUser;

  function setSelectedUser(user?: IUser & { index: number }) {
    _setSelectedUser((prevState) => {
      // return the same state that is equal of doing nothing, to prevent 'clear filters' to be called
      if (prevState?.id === user?.id) {
        return prevState;
      }
      // clear filters
      setSelectedPOCs({});
      setSelectedSKUs({});
      return user;
    });

    if (!user) {
      setSearchParams({});
      return;
    }
    setSearchParams({ userId: user?.id });
  }

  function onToastClose() {
    setToast((state) => ({ ...state, open: false }));
  }

  function thenHandler() {
    setToast({
      open: true,
      type: 'success',
      message: formatMessage({
        id: 'USER_MANAGEMENT_PAGE.TOAST.SUCCESS',
        defaultMessage: 'User permissions configured successfully.',
      }),
    });
    const FOUR_SECONDS = 4000;
    setTimeout(() => {
      onToastClose();
    }, FOUR_SECONDS);
  }

  function catchHandler() {
    setToast({
      open: true,
      type: 'error',
      message: formatMessage({
        id: 'USER_MANAGEMENT_PAGE.TOAST.ERROR',
        defaultMessage:
          'Error while trying to configure users permissions, please try again later.',
      }),
    });
    const SIX_SECONDS = 6000;

    setTimeout(() => {
      onToastClose();
    }, SIX_SECONDS);
  }

  function handleSave() {
    if (firstPocConfiguration.current && firstSkuConfiguration.current) {
      handleFirstConfiguration();
      return;
    }

    const defaultParams = {
      userId: selectedUser?.id,
      country: selectedCountry,
    };

    const skuFunctionToCall = !isSkuEmpty ? deleteSelectedSKUs : changeSelectedSKUs;
    const pocFunctionToCall = !isPOCEmpty ? deleteSelectedPOCs : changeSelectedPOCs;

    if (!firstPocConfiguration.current && !firstSkuConfiguration.current) {
      handleBothConfigurations(defaultParams, skuFunctionToCall, pocFunctionToCall);
    } else if (!firstPocConfiguration.current || !firstSkuConfiguration.current) {
      handleSingleConfiguration(defaultParams, skuFunctionToCall, pocFunctionToCall);
    } else {
      console.error('Something went wrong');
    }
  }

  function handleFirstConfiguration() {
    if (isPOCEmpty) {
      firstPocConfiguration.current = false;
    }
    if (isSkuEmpty) {
      firstSkuConfiguration.current = false;
    }
    createPromoPermissions({
      userId: selectedUser?.id,
      country: selectedCountry,
      ...(isPOCEmpty && { pocParameters: selectedPOCs }),
      ...(isSkuEmpty && { skuParameters: selectedSKUs }),
    })
      .then(thenHandler)
      .catch(catchHandler);
  }

  function handleBothConfigurations(defaultParams, skuFunctionToCall, pocFunctionToCall) {
    Promise.all([
      skuFunctionToCall({
        ...defaultParams,
        skuParameters: selectedSKUs,
      }),
      pocFunctionToCall({
        ...defaultParams,
        pocParameters: selectedPOCs,
      }),
    ])
      .then(thenHandler)
      .catch(catchHandler);
  }

  function handleSingleConfiguration(defaultParams, skuFunctionToCall, pocFunctionToCall) {
    firstSkuConfiguration.current = false;
    firstPocConfiguration.current = false;
    skuFunctionToCall({
      ...defaultParams,
      skuParameters: selectedSKUs,
    })
      .then(thenHandler)
      .catch(catchHandler);
    pocFunctionToCall({
      ...defaultParams,
      pocParameters: selectedPOCs,
    })
      .then(thenHandler)
      .catch(catchHandler);
  }

  function handleCancel() {
    window.location.reload();
  }

  function handleBack() {
    navigate(LIFT_ROUTES.ADMIN);
  }

  function abortLoading() {
    setSKUsIsSaving(false);
    setPOCsIsSaving(false);
  }

  const usersFiltersProps = {
    selectedCountry,
    setSelectedCountry,
    selectedUser,
    setSelectedUser,
    setSelectedSKUs,
    abortLoading,
    setSelectedPocs: setSelectedPOCs,
  };

  const pocsFiltersProps = {
    selectedCountry,
    selectedUser,
    canReadFromIdentity,
    selectedPOCs,
    setSelectedPOCs,
    isLoading: selectedPOCsLoading,
    disabled: formDisabled,
  };

  const skusFiltersProps = {
    selectedCountry,
    selectedUser,
    canReadFromIdentity,
    selectedSKUs,
    setSelectedSKUs,
    isLoading: selectedSKUssLoading,
    disabled: formDisabled,
  };

  const actionButtonProps = {
    handleSave,
    handleCancel,
    handleBack,
    formDisabled,
    isLoading,
    isSaving: pocsIsSaving || SKUsIsSaving || userPromoPermIsSaving,
  };

  const toastProps = {
    ...toast,
    onClose: onToastClose,
  };

  useEffect(() => {
    if (!selectedUser) {
      const userId = searchParams.get('userId');
      if (userId) {
        setIsLoading(true);
        getUserById(userId).then((res) => {
          setSelectedUser(res?.[0] ? { ...res?.[0], index: 0 } : undefined);
        });
      }
    }
  }, [selectedUser]);

  useEffect(() => {
    dispatch(fetchProductFilters());
  }, []);

  useEffect(() => {
    setSelectedUser();
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedUser?.id) {
      Promise.all([
        getSelectedPOCs(selectedUser?.id, selectedCountry)
          .then((res) => {
            if (!res) {
              firstPocConfiguration.current = true;
              return;
            }
            firstPocConfiguration.current = false;
            setSelectedPOCs(res.parameters);
          })
          .finally(() => {}),
        getSelectedSKUs(selectedUser?.id, selectedCountry)
          .then((res) => {
            if (!res) {
              firstSkuConfiguration.current = true;
              return;
            }
            firstSkuConfiguration.current = false;
            setSelectedSKUs(res.parameters);
          })
          .finally(() => {}),
      ]).finally(() => {
        setIsLoading(false);
      });
    }
  }, [selectedUser?.id]);

  return {
    isLoading,
    canReadFromIdentity,
    usersFiltersProps,
    pocsFiltersProps,
    skusFiltersProps,
    actionButtonProps,
    toastProps,
  };
};
