import localForage from 'localforage';

const storage = (dbName: string) => {
  if (!window?.LiftStorage?.[dbName]) {
    //window.console.debug('Create DB Storage instance');
    window.LiftStorage = window.LiftStorage || {};
    window.LiftStorage[dbName] = localForage.createInstance({ name: dbName });
    window.LiftStorage[dbName]
      .ready((error) => {
        if (error) {
          console.error(`Lift database ${dbName} is not ready`, error);
        }
      })
      .then(() => {
        //window.console.debug(`Lift database ${dbName} is ready`);
      });
  }

  return {
    db: window.LiftStorage[dbName],
    getItem: window.LiftStorage[dbName].getItem,
    setItem: window.LiftStorage[dbName].setItem,
    removeItem: window.LiftStorage[dbName].removeItem,
  };
};

export default storage;
