import styled from 'styled-components';

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
  height: 40px;
`;

export const Title = styled.span`
  height: 32px;
  color: rgba(0, 0, 0, 0.9);
  font-family: Barlow, San Francisco, Roboto, Segoe UI, Helvetica, sans-serif;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
`;
